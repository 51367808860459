/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.
import { Injectable } from "@angular/core";
import API, { graphqlOperation, GraphQLResult } from "@aws-amplify/api-graphql";
import { Observable } from "zen-observable-ts";

export interface SubscriptionResponse<T> {
  value: GraphQLResult<T>;
}

export type CreateOrganizacionCafeInput = {
  id?: string | null;
  searchFields?: string | null;
  nombre: string;
  figuraJuridica?: string | null;
  telefono?: string | null;
  email?: string | null;
  anoConstitucion?: number | null;
  regionId: string;
  direccion?: string | null;
  codigoPostal?: string | null;
  coloniaId: string;
  estadoId: string;
  municipioId: string;
  localidadId: string;
  reprNombre?: string | null;
  reprCargo?: string | null;
  reprTelefono?: string | null;
  reprEmail?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type ModelOrganizacionCafeConditionInput = {
  searchFields?: ModelStringInput | null;
  nombre?: ModelStringInput | null;
  figuraJuridica?: ModelStringInput | null;
  telefono?: ModelStringInput | null;
  email?: ModelStringInput | null;
  anoConstitucion?: ModelIntInput | null;
  regionId?: ModelIDInput | null;
  direccion?: ModelStringInput | null;
  codigoPostal?: ModelStringInput | null;
  coloniaId?: ModelIDInput | null;
  estadoId?: ModelIDInput | null;
  municipioId?: ModelIDInput | null;
  localidadId?: ModelIDInput | null;
  reprNombre?: ModelStringInput | null;
  reprCargo?: ModelStringInput | null;
  reprTelefono?: ModelStringInput | null;
  reprEmail?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelOrganizacionCafeConditionInput | null> | null;
  or?: Array<ModelOrganizacionCafeConditionInput | null> | null;
  not?: ModelOrganizacionCafeConditionInput | null;
};

export type ModelStringInput = {
  ne?: string | null;
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  contains?: string | null;
  notContains?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
  size?: ModelSizeInput | null;
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null"
}

export type ModelSizeInput = {
  ne?: number | null;
  eq?: number | null;
  le?: number | null;
  lt?: number | null;
  ge?: number | null;
  gt?: number | null;
  between?: Array<number | null> | null;
};

export type ModelIntInput = {
  ne?: number | null;
  eq?: number | null;
  le?: number | null;
  lt?: number | null;
  ge?: number | null;
  gt?: number | null;
  between?: Array<number | null> | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
};

export type ModelIDInput = {
  ne?: string | null;
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  contains?: string | null;
  notContains?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
  size?: ModelSizeInput | null;
};

export type UpdateOrganizacionCafeInput = {
  id: string;
  searchFields?: string | null;
  nombre?: string | null;
  figuraJuridica?: string | null;
  telefono?: string | null;
  email?: string | null;
  anoConstitucion?: number | null;
  regionId?: string | null;
  direccion?: string | null;
  codigoPostal?: string | null;
  coloniaId?: string | null;
  estadoId?: string | null;
  municipioId?: string | null;
  localidadId?: string | null;
  reprNombre?: string | null;
  reprCargo?: string | null;
  reprTelefono?: string | null;
  reprEmail?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type DeleteOrganizacionCafeInput = {
  id?: string | null;
};

export type CreateRegionInput = {
  id?: string | null;
  nombre: string;
};

export type ModelRegionConditionInput = {
  nombre?: ModelStringInput | null;
  and?: Array<ModelRegionConditionInput | null> | null;
  or?: Array<ModelRegionConditionInput | null> | null;
  not?: ModelRegionConditionInput | null;
};

export type UpdateRegionInput = {
  id: string;
  nombre?: string | null;
};

export type DeleteRegionInput = {
  id?: string | null;
};

export type CreateProductorInput = {
  id?: string | null;
  searchField?: string | null;
  userid?: string | null;
  username?: string | null;
  folioProductorId?: string | null;
  organizacioncafeOrganizacionId?: string | null;
  nombre?: string | null;
  aPaterno?: string | null;
  aMaterno?: string | null;
  fechaNac?: string | null;
  sexo?: string | null;
  curp?: string | null;
  rfc?: string | null;
  direccion?: string | null;
  codigoPostal?: string | null;
  estadoId: string;
  municipioId: string;
  localidadId: string;
  latitud?: number | null;
  longitud?: number | null;
  productorOrganizacionId?: string | null;
};

export type ModelProductorConditionInput = {
  searchField?: ModelStringInput | null;
  userid?: ModelStringInput | null;
  username?: ModelStringInput | null;
  folioProductorId?: ModelIDInput | null;
  organizacioncafeOrganizacionId?: ModelIDInput | null;
  nombre?: ModelStringInput | null;
  aPaterno?: ModelStringInput | null;
  aMaterno?: ModelStringInput | null;
  fechaNac?: ModelStringInput | null;
  sexo?: ModelStringInput | null;
  curp?: ModelStringInput | null;
  rfc?: ModelStringInput | null;
  direccion?: ModelStringInput | null;
  codigoPostal?: ModelStringInput | null;
  estadoId?: ModelIDInput | null;
  municipioId?: ModelIDInput | null;
  localidadId?: ModelIDInput | null;
  latitud?: ModelFloatInput | null;
  longitud?: ModelFloatInput | null;
  and?: Array<ModelProductorConditionInput | null> | null;
  or?: Array<ModelProductorConditionInput | null> | null;
  not?: ModelProductorConditionInput | null;
};

export type ModelFloatInput = {
  ne?: number | null;
  eq?: number | null;
  le?: number | null;
  lt?: number | null;
  ge?: number | null;
  gt?: number | null;
  between?: Array<number | null> | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
};

export type UpdateProductorInput = {
  id: string;
  searchField?: string | null;
  userid?: string | null;
  username?: string | null;
  folioProductorId?: string | null;
  organizacioncafeOrganizacionId?: string | null;
  nombre?: string | null;
  aPaterno?: string | null;
  aMaterno?: string | null;
  fechaNac?: string | null;
  sexo?: string | null;
  curp?: string | null;
  rfc?: string | null;
  direccion?: string | null;
  codigoPostal?: string | null;
  estadoId?: string | null;
  municipioId?: string | null;
  localidadId?: string | null;
  latitud?: number | null;
  longitud?: number | null;
  productorOrganizacionId?: string | null;
};

export type DeleteProductorInput = {
  id?: string | null;
};

export type CreateEntidadInput = {
  id?: string | null;
  nombre: string;
  nombreAbrev: string;
  clave: string;
  pobMasc?: string | null;
  pobFem?: string | null;
  pobTotal?: string | null;
  totalVivHab?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type ModelEntidadConditionInput = {
  nombre?: ModelStringInput | null;
  nombreAbrev?: ModelStringInput | null;
  clave?: ModelStringInput | null;
  pobMasc?: ModelStringInput | null;
  pobFem?: ModelStringInput | null;
  pobTotal?: ModelStringInput | null;
  totalVivHab?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelEntidadConditionInput | null> | null;
  or?: Array<ModelEntidadConditionInput | null> | null;
  not?: ModelEntidadConditionInput | null;
};

export type UpdateEntidadInput = {
  id: string;
  nombre?: string | null;
  nombreAbrev?: string | null;
  clave?: string | null;
  pobMasc?: string | null;
  pobFem?: string | null;
  pobTotal?: string | null;
  totalVivHab?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type DeleteEntidadInput = {
  id?: string | null;
};

export type CreateMunicipioInput = {
  id?: string | null;
  nombre: string;
  clave: string;
  entidadId: string;
  cabecera?: string | null;
  claveCab?: string | null;
  pobMasc?: string | null;
  pobFem?: string | null;
  pobTotal?: string | null;
  totalVivHab?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  nombreEnt?: string | null;
  nombreEntAbrev?: string | null;
};

export type ModelMunicipioConditionInput = {
  nombre?: ModelStringInput | null;
  clave?: ModelStringInput | null;
  entidadId?: ModelStringInput | null;
  cabecera?: ModelStringInput | null;
  claveCab?: ModelStringInput | null;
  pobMasc?: ModelStringInput | null;
  pobFem?: ModelStringInput | null;
  pobTotal?: ModelStringInput | null;
  totalVivHab?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  nombreEnt?: ModelStringInput | null;
  nombreEntAbrev?: ModelStringInput | null;
  and?: Array<ModelMunicipioConditionInput | null> | null;
  or?: Array<ModelMunicipioConditionInput | null> | null;
  not?: ModelMunicipioConditionInput | null;
};

export type UpdateMunicipioInput = {
  id: string;
  nombre?: string | null;
  clave?: string | null;
  entidadId?: string | null;
  cabecera?: string | null;
  claveCab?: string | null;
  pobMasc?: string | null;
  pobFem?: string | null;
  pobTotal?: string | null;
  totalVivHab?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  nombreEnt?: string | null;
  nombreEntAbrev?: string | null;
};

export type DeleteMunicipioInput = {
  id?: string | null;
};

export type CreateLocalidadInput = {
  id?: string | null;
  nombre: string;
  entidadId: string;
  municipioId: string;
  clave: string;
  ambito: string;
  latitud?: number | null;
  longitud?: number | null;
  altitud?: string | null;
  pobMasc?: string | null;
  pobFem?: string | null;
  pobTotal?: string | null;
  totalVivHab?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  nombreEnt?: string | null;
  nombreEntAbrev?: string | null;
  nombreMun?: string | null;
  claveCarta?: string | null;
};

export type ModelLocalidadConditionInput = {
  nombre?: ModelStringInput | null;
  entidadId?: ModelStringInput | null;
  municipioId?: ModelStringInput | null;
  clave?: ModelStringInput | null;
  ambito?: ModelStringInput | null;
  latitud?: ModelFloatInput | null;
  longitud?: ModelFloatInput | null;
  altitud?: ModelStringInput | null;
  pobMasc?: ModelStringInput | null;
  pobFem?: ModelStringInput | null;
  pobTotal?: ModelStringInput | null;
  totalVivHab?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  nombreEnt?: ModelStringInput | null;
  nombreEntAbrev?: ModelStringInput | null;
  nombreMun?: ModelStringInput | null;
  claveCarta?: ModelStringInput | null;
  and?: Array<ModelLocalidadConditionInput | null> | null;
  or?: Array<ModelLocalidadConditionInput | null> | null;
  not?: ModelLocalidadConditionInput | null;
};

export type UpdateLocalidadInput = {
  id: string;
  nombre?: string | null;
  entidadId?: string | null;
  municipioId?: string | null;
  clave?: string | null;
  ambito?: string | null;
  latitud?: number | null;
  longitud?: number | null;
  altitud?: string | null;
  pobMasc?: string | null;
  pobFem?: string | null;
  pobTotal?: string | null;
  totalVivHab?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  nombreEnt?: string | null;
  nombreEntAbrev?: string | null;
  nombreMun?: string | null;
  claveCarta?: string | null;
};

export type DeleteLocalidadInput = {
  id?: string | null;
};

export type CreateCodigoPostalInput = {
  id?: string | null;
  codigo: string;
  asentamiento: string;
  tipoAsent: string;
  String?: string | null;
  claveTipoAsent?: string | null;
  asentCpcons?: string | null;
  zona?: string | null;
  entidadId: string;
  municipioId: string;
  ciudad?: string | null;
  claveCd?: string | null;
  cpOficina?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  nombreMun?: string | null;
  nombreEnt?: string | null;
  dCP?: string | null;
};

export type ModelCodigoPostalConditionInput = {
  codigo?: ModelStringInput | null;
  asentamiento?: ModelStringInput | null;
  tipoAsent?: ModelStringInput | null;
  String?: ModelStringInput | null;
  claveTipoAsent?: ModelStringInput | null;
  asentCpcons?: ModelStringInput | null;
  zona?: ModelStringInput | null;
  entidadId?: ModelStringInput | null;
  municipioId?: ModelStringInput | null;
  ciudad?: ModelStringInput | null;
  claveCd?: ModelStringInput | null;
  cpOficina?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  nombreMun?: ModelStringInput | null;
  nombreEnt?: ModelStringInput | null;
  dCP?: ModelStringInput | null;
  and?: Array<ModelCodigoPostalConditionInput | null> | null;
  or?: Array<ModelCodigoPostalConditionInput | null> | null;
  not?: ModelCodigoPostalConditionInput | null;
};

export type UpdateCodigoPostalInput = {
  id: string;
  codigo?: string | null;
  asentamiento?: string | null;
  tipoAsent?: string | null;
  String?: string | null;
  claveTipoAsent?: string | null;
  asentCpcons?: string | null;
  zona?: string | null;
  entidadId?: string | null;
  municipioId?: string | null;
  ciudad?: string | null;
  claveCd?: string | null;
  cpOficina?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  nombreMun?: string | null;
  nombreEnt?: string | null;
  dCP?: string | null;
};

export type DeleteCodigoPostalInput = {
  id?: string | null;
};

export type CreateCicloInput = {
  id?: string | null;
  nombre: string;
  fechaInicio: string;
  fechaFin: string;
  activo: boolean;
};

export type ModelCicloConditionInput = {
  nombre?: ModelStringInput | null;
  fechaInicio?: ModelStringInput | null;
  fechaFin?: ModelStringInput | null;
  activo?: ModelBooleanInput | null;
  and?: Array<ModelCicloConditionInput | null> | null;
  or?: Array<ModelCicloConditionInput | null> | null;
  not?: ModelCicloConditionInput | null;
};

export type ModelBooleanInput = {
  ne?: boolean | null;
  eq?: boolean | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
};

export type UpdateCicloInput = {
  id: string;
  nombre?: string | null;
  fechaInicio?: string | null;
  fechaFin?: string | null;
  activo?: boolean | null;
};

export type DeleteCicloInput = {
  id?: string | null;
};

export type CreateInfoCicloInput = {
  id?: string | null;
  cicloId: string;
  productorId: string;
  organizacionId: string;
  datosGenerales?: ICDatosGeneralesInput | null;
  produccion?: ICProduccionInput | null;
  calidad?: ICCalidadInput | null;
  mercado?: ICMercadoInput | null;
  desarrollo?: ICDesarrolloCapacidadesInput | null;
};

export type ICDatosGeneralesInput = {
  areasTrabajo?: Array<AreasTrabajo | null> | null;
  infraestructura?: Array<InfraestructuraOrganizacion | null> | null;
  equiposOficina?: Array<EquiposOficina | null> | null;
  certificaciones?: Array<Certificaciones | null> | null;
  estatus?: Estatus | null;
};

export enum AreasTrabajo {
  CONTABLE = "CONTABLE",
  ADMINISTRATIVA = "ADMINISTRATIVA",
  PRODUCCION = "PRODUCCION",
  ACOPIO = "ACOPIO",
  COMERCIALIZACION = "COMERCIALIZACION",
  TRANSFORMACION = "TRANSFORMACION"
}

export enum InfraestructuraOrganizacion {
  OFICINAS = "OFICINAS",
  BODEGAS = "BODEGAS",
  MAQUILADORA = "MAQUILADORA",
  VEHICULOS = "VEHICULOS",
  TORREFACTORA = "TORREFACTORA",
  OTROS = "OTROS"
}

export enum EquiposOficina {
  COMPUTADORAS = "COMPUTADORAS",
  IMPRESORAS = "IMPRESORAS",
  FOTOCOPIADORAS = "FOTOCOPIADORAS",
  TELEFONO = "TELEFONO",
  INTERNET = "INTERNET",
  OTRO = "OTRO"
}

export enum Certificaciones {
  ORGANICO = "ORGANICO",
  COMERCIOJUSTO = "COMERCIOJUSTO",
  AMIGABLEAVES = "AMIGABLEAVES",
  OTROS = "OTROS"
}

export enum Estatus {
  ALTA = "ALTA",
  MEDIA = "MEDIA",
  BAJA = "BAJA"
}

export type ICProduccionInput = {
  superficie?: SuperficieInput | null;
  produccionPromedio?: ProduccionPromedioInput | null;
  jornales?: JornalesInput | null;
  insumos?: InsumosInput | null;
  rendimientoCafetales?: RendimientoCafetales | null;
  factoresRendimiento?: Array<FactoresRendimiento | null> | null;
  condicionesCafetales?: Array<CondicionesCafetales | null> | null;
  variedadesCafe?: Array<VariedadesCafe | null> | null;
  edadPlantaciones?: EdadPlantaciones | null;
  disenioPlantaciones?: DisenioPlantaciones | null;
  densidadPlantaciones?: DensidadPlantaciones | null;
  alturaPlantaciones?: AlturaPlantaciones | null;
  floracion?: RegistroFloracion | null;
};

export type SuperficieInput = {
  cafeOrganico?: number | null;
  cafeTransicion?: number | null;
  basicos?: number | null;
  potrero?: number | null;
  acahual?: number | null;
  montania?: number | null;
};

export type ProduccionPromedioInput = {
  estimacion?: number | null;
};

export type JornalesInput = {
  primeraLimpia?: number | null;
  segundaLimpia?: number | null;
  cajete?: number | null;
  podaYdeshije?: number | null;
  desombre?: number | null;
  abonera?: number | null;
  regarAbono?: number | null;
  resiembra?: number | null;
  controlPlagas?: number | null;
  conservacionSuelo?: number | null;
  semilleroVivero?: number | null;
  cosecha?: number | null;
  beneficioHumedo?: number | null;
  otros?: number | null;
  precioPagado?: number | null;
};

export type InsumosInput = {
  beauveria?: number | null;
  abonoFoliar?: number | null;
  calAgricola?: number | null;
  costalera?: number | null;
  canastos?: number | null;
  mantenimientoEquipos?: number | null;
  certificaciones?: number | null;
  alimentacionPersonal?: number | null;
  otros?: number | null;
};

export enum RendimientoCafetales {
  MUYBAJO = "MUYBAJO",
  BAJO = "BAJO",
  BUENO = "BUENO",
  MUYBUENO = "MUYBUENO"
}

export enum FactoresRendimiento {
  CALIDADGENETICA = "CALIDADGENETICA",
  MANEJO = "MANEJO",
  AMBIENTE = "AMBIENTE",
  OTROS = "OTROS"
}

export enum CondicionesCafetales {
  DETERIORADO = "DETERIORADO",
  REGULARES = "REGULARES",
  BUENAS = "BUENAS",
  MUYBUENAS = "MUYBUENAS"
}

export enum VariedadesCafe {
  TYPICA = "TYPICA",
  BOURBON = "BOURBON",
  MUNDONOVO = "MUNDONOVO",
  CATURRA = "CATURRA",
  CATIMOR = "CATIMOR",
  OTROS = "OTROS"
}

export enum EdadPlantaciones {
  MENOSDE5 = "MENOSDE5",
  ENTRE5Y15 = "ENTRE5Y15",
  ENTRE15Y30 = "ENTRE15Y30",
  MASDE30 = "MASDE30"
}

export enum DisenioPlantaciones {
  TRESBOLILLO = "TRESBOLILLO",
  RECTANGULAR = "RECTANGULAR",
  CURVASANIVEL = "CURVASANIVEL",
  SINMARCOS = "SINMARCOS"
}

export enum DensidadPlantaciones {
  MENOSDE1000 = "MENOSDE1000",
  ENTRE1000Y1500 = "ENTRE1000Y1500",
  MASDE1500 = "MASDE1500"
}

export enum AlturaPlantaciones {
  MENOSDE600 = "MENOSDE600",
  ENTRE600Y900 = "ENTRE600Y900",
  ENTRE900Y1200 = "ENTRE900Y1200",
  MASDE1200 = "MASDE1200"
}

export enum RegistroFloracion {
  NINGUNA = "NINGUNA",
  PRIMERA = "PRIMERA",
  SEGUNDA = "SEGUNDA",
  TERCERA = "TERCERA"
}

export type ICCalidadInput = {
  despulpadoraTipos?: Array<DespulpadoraTipo | null> | null;
  equipoCondicion?: EquipoCondicion | null;
  tanqueTipos?: Array<TanqueTipo | null> | null;
  secadoInfraestructuras?: Array<SecadoInfraestructura | null> | null;
  cafeCorte?: CafeCorte | null;
  fermentacionHora?: FermentacionHora | null;
  conocenCalidad?: ConocenCalidad | null;
  cafeCalidad?: CafeCalidad | null;
};

export enum DespulpadoraTipo {
  CILINDRO = "CILINDRO",
  DISCO = "DISCO",
  BENEFICIO = "BENEFICIO"
}

export enum EquipoCondicion {
  MALA = "MALA",
  REGULAR = "REGULAR",
  BUENA = "BUENA",
  EXCELENTE = "EXCELENTE"
}

export enum TanqueTipo {
  MADERA = "MADERA",
  CEMENTO = "CEMENTO",
  TINA = "TINA"
}

export enum SecadoInfraestructura {
  PATIO = "PATIO",
  ZARANDAS = "ZARANDAS",
  GAS = "GAS"
}

export enum CafeCorte {
  UNO = "UNO",
  DOS = "DOS",
  MASDEDOS = "MASDEDOS"
}

export enum FermentacionHora {
  MENOSDE10 = "MENOSDE10",
  ENTRE10Y15 = "ENTRE10Y15",
  ENTRE15Y20 = "ENTRE15Y20"
}

export enum ConocenCalidad {
  SI = "SI",
  NO = "NO"
}

export enum CafeCalidad {
  MALA = "MALA",
  REGULAR = "REGULAR",
  BUENA = "BUENA",
  EXCELENTE = "EXCELENTE"
}

export type ICMercadoInput = {
  mercado?: MercadoInput | null;
  exportacionMercados?: Array<ExportacionMercado | null> | null;
};

export type MercadoInput = {
  prodAcopiada?: number | null;
  cafeExportado?: number | null;
  cafeVendidoNacional?: number | null;
  cafeVendidoLocal?: number | null;
  precioExportacion?: number | null;
  precioNacional?: number | null;
  precioLocal?: number | null;
  sobreprecio?: number | null;
  clientesExtranjeros?: number | null;
};

export enum ExportacionMercado {
  NORTEAMERICA = "NORTEAMERICA",
  EUROPA = "EUROPA",
  ASIA = "ASIA",
  OTROS = "OTROS"
}

export type ICDesarrolloCapacidadesInput = {
  cursosRecibidos?: CursosRecibido | null;
  cursosTipos?: Array<CursosTipo | null> | null;
  reforzarProcesos?: Array<ReforzarProceso | null> | null;
};

export enum CursosRecibido {
  NINGUNO = "NINGUNO",
  MENOSDE3 = "MENOSDE3",
  ENTRE3Y5 = "ENTRE3Y5",
  ENTRE5Y10 = "ENTRE5Y10",
  MASDE10 = "MASDE10"
}

export enum CursosTipo {
  PRODUCCION = "PRODUCCION",
  CALIDAD = "CALIDAD",
  MERCADO = "MERCADO",
  INNOVATEC = "INNOVATEC",
  OTROS = "OTROS"
}

export enum ReforzarProceso {
  PRODUCCION = "PRODUCCION",
  CALIDAD = "CALIDAD",
  MERCADO = "MERCADO",
  INNOVATEC = "INNOVATEC",
  OTROS = "OTROS"
}

export type ModelInfoCicloConditionInput = {
  cicloId?: ModelIDInput | null;
  productorId?: ModelIDInput | null;
  organizacionId?: ModelIDInput | null;
  and?: Array<ModelInfoCicloConditionInput | null> | null;
  or?: Array<ModelInfoCicloConditionInput | null> | null;
  not?: ModelInfoCicloConditionInput | null;
};

export type UpdateInfoCicloInput = {
  id: string;
  cicloId?: string | null;
  productorId?: string | null;
  organizacionId?: string | null;
  datosGenerales?: ICDatosGeneralesInput | null;
  produccion?: ICProduccionInput | null;
  calidad?: ICCalidadInput | null;
  mercado?: ICMercadoInput | null;
  desarrollo?: ICDesarrolloCapacidadesInput | null;
};

export type DeleteInfoCicloInput = {
  id?: string | null;
};

export type CreatePadronCafetaleroInput = {
  id?: string | null;
  type?: string | null;
  curp?: string | null;
  folioProductorId?: string | null;
  nombre?: string | null;
  aPaterno?: string | null;
  aMaterno?: string | null;
  fechaNac?: string | null;
  sexo?: string | null;
  rfc?: string | null;
  calle?: string | null;
  numero?: string | null;
  colonia?: string | null;
  entidadId?: string | null;
  nombreEnt?: string | null;
  municipioId?: string | null;
  nombreMun?: string | null;
  localidadId?: string | null;
  nombreLoc?: string | null;
  folioProdPred?: string | null;
  folioPredioId?: string | null;
  nombrePredio?: string | null;
  superficieCafe?: number | null;
  predioEntidadId?: string | null;
  predioNombreEnt?: string | null;
  predioMunicipioId?: string | null;
  predioNombreMun?: string | null;
  predioLocalidadId?: string | null;
  predioNombreLoc?: string | null;
  latitudGrados?: string | null;
  longitudGrados?: string | null;
  latitud?: string | null;
  longitud?: string | null;
  searchField?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type ModelPadronCafetaleroConditionInput = {
  type?: ModelStringInput | null;
  curp?: ModelStringInput | null;
  folioProductorId?: ModelStringInput | null;
  nombre?: ModelStringInput | null;
  aPaterno?: ModelStringInput | null;
  aMaterno?: ModelStringInput | null;
  fechaNac?: ModelStringInput | null;
  sexo?: ModelStringInput | null;
  rfc?: ModelStringInput | null;
  calle?: ModelStringInput | null;
  numero?: ModelStringInput | null;
  colonia?: ModelStringInput | null;
  entidadId?: ModelStringInput | null;
  nombreEnt?: ModelStringInput | null;
  municipioId?: ModelStringInput | null;
  nombreMun?: ModelStringInput | null;
  localidadId?: ModelStringInput | null;
  nombreLoc?: ModelStringInput | null;
  folioProdPred?: ModelStringInput | null;
  folioPredioId?: ModelStringInput | null;
  nombrePredio?: ModelStringInput | null;
  superficieCafe?: ModelFloatInput | null;
  predioEntidadId?: ModelStringInput | null;
  predioNombreEnt?: ModelStringInput | null;
  predioMunicipioId?: ModelStringInput | null;
  predioNombreMun?: ModelStringInput | null;
  predioLocalidadId?: ModelStringInput | null;
  predioNombreLoc?: ModelStringInput | null;
  latitudGrados?: ModelStringInput | null;
  longitudGrados?: ModelStringInput | null;
  latitud?: ModelStringInput | null;
  longitud?: ModelStringInput | null;
  searchField?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelPadronCafetaleroConditionInput | null> | null;
  or?: Array<ModelPadronCafetaleroConditionInput | null> | null;
  not?: ModelPadronCafetaleroConditionInput | null;
};

export type UpdatePadronCafetaleroInput = {
  id: string;
  type?: string | null;
  curp?: string | null;
  folioProductorId?: string | null;
  nombre?: string | null;
  aPaterno?: string | null;
  aMaterno?: string | null;
  fechaNac?: string | null;
  sexo?: string | null;
  rfc?: string | null;
  calle?: string | null;
  numero?: string | null;
  colonia?: string | null;
  entidadId?: string | null;
  nombreEnt?: string | null;
  municipioId?: string | null;
  nombreMun?: string | null;
  localidadId?: string | null;
  nombreLoc?: string | null;
  folioProdPred?: string | null;
  folioPredioId?: string | null;
  nombrePredio?: string | null;
  superficieCafe?: number | null;
  predioEntidadId?: string | null;
  predioNombreEnt?: string | null;
  predioMunicipioId?: string | null;
  predioNombreMun?: string | null;
  predioLocalidadId?: string | null;
  predioNombreLoc?: string | null;
  latitudGrados?: string | null;
  longitudGrados?: string | null;
  latitud?: string | null;
  longitud?: string | null;
  searchField?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
};

export type DeletePadronCafetaleroInput = {
  id?: string | null;
};

export type CreateInfoCicloOrganizacionInput = {
  id?: string | null;
  cicloId: string;
  organizacionId: string;
  datosGenerales?: ICODatosGeneralesInput | null;
  produccion?: ICOProduccionInput | null;
  calidad?: ICOCalidadInput | null;
  mercado?: ICOMercadoInput | null;
  desarrollo?: ICODesarrolloCapacidadesInput | null;
};

export type ICODatosGeneralesInput = {
  numeroSocios?: NumeroSociosInput | null;
  haProduccion?: HAProduccionInput | null;
  haPorcentajes?: HAPorcentajesInput | null;
  areasTrabajo?: Array<AreasTrabajoICO | null> | null;
  equiposOficina?: Array<EquiposOficinaICO | null> | null;
  certificaciones?: Array<CertificacionesICO | null> | null;
  estatus?: EstatusICO | null;
};

export type NumeroSociosInput = {
  numHombres?: number | null;
  numMujeres?: number | null;
};

export type HAProduccionInput = {
  cafeOrganico?: number | null;
  cafeTransicion?: number | null;
  otrosCultivos?: number | null;
};

export type HAPorcentajesInput = {
  haMenosDeMedia?: number | null;
  haDeMediaAUna?: number | null;
  haDeUnaADos?: number | null;
  haDeDosACinco?: number | null;
  haDeCincoADiez?: number | null;
  haMasDeDiez?: number | null;
};

export enum AreasTrabajoICO {
  CONTABLE = "CONTABLE",
  ADMINISTRATIVA = "ADMINISTRATIVA",
  PRODUCCION = "PRODUCCION",
  ACOPIO = "ACOPIO",
  COMERCIALIZACION = "COMERCIALIZACION",
  TRANSFORMACION = "TRANSFORMACION"
}

export enum EquiposOficinaICO {
  COMPUTADORAS = "COMPUTADORAS",
  IMPRESORAS = "IMPRESORAS",
  FOTOCOPIADORAS = "FOTOCOPIADORAS",
  TELEFONO = "TELEFONO",
  INTERNET = "INTERNET",
  OTRO = "OTRO"
}

export enum CertificacionesICO {
  ORGANICO = "ORGANICO",
  COMERCIOJUSTO = "COMERCIOJUSTO",
  AMIGABLEAVES = "AMIGABLEAVES",
  OTROS = "OTROS"
}

export enum EstatusICO {
  ALTA = "ALTA",
  MEDIA = "MEDIA",
  BAJA = "BAJA"
}

export type ICOProduccionInput = {
  produccionXproductor?: ProduccionXProductorICOInput | null;
  jornales?: JornalesICOInput | null;
  insumos?: InsumosICOInput | null;
  variedadesCafe?: VariedadesCafeICOInput | null;
  edadPlantaciones?: EdadPlantacionesICOInput | null;
  disenioPlantaciones?: DisenioPlantacionesICOInput | null;
  densidadPlantaciones?: DensidadPlantacionesICOInput | null;
  alturaPlantaciones?: AlturaPlantacionesICOInput | null;
  floracion?: RegistroFloracionICOInput | null;
  rendimientoCafetales?: RendimientoCafetalesICO | null;
  factoresRendimiento?: Array<FactoresRendimientoICO | null> | null;
  condicionesCafetales?: Array<CondicionesCafetalesICO | null> | null;
};

export type ProduccionXProductorICOInput = {
  maximo?: number | null;
  promedio?: number | null;
  minimo?: number | null;
};

export type JornalesICOInput = {
  primeraLimpia?: number | null;
  segundaLimpia?: number | null;
  cajete?: number | null;
  podaYdeshije?: number | null;
  desombre?: number | null;
  abonera?: number | null;
  regarAbono?: number | null;
  resiembra?: number | null;
  controlPlagas?: number | null;
  conservacionSuelo?: number | null;
  semilleroVivero?: number | null;
  cosecha?: number | null;
  beneficioHumedo?: number | null;
  otros?: number | null;
  precioPagado?: number | null;
};

export type InsumosICOInput = {
  beauveria?: number | null;
  abonoFoliar?: number | null;
  calAgricola?: number | null;
  costalera?: number | null;
  canastos?: number | null;
  mantenimientoEquipos?: number | null;
  certificaciones?: number | null;
  alimentacionPersonal?: number | null;
  otros?: number | null;
};

export type VariedadesCafeICOInput = {
  typica?: number | null;
  bourbon?: number | null;
  mundoNovo?: number | null;
  caturra?: number | null;
  catimor?: number | null;
  otros?: number | null;
};

export type EdadPlantacionesICOInput = {
  menosDe5?: number | null;
  entre5y15?: number | null;
  entre15y30?: number | null;
  masDe30?: number | null;
};

export type DisenioPlantacionesICOInput = {
  tresbolillo?: number | null;
  rectangular?: number | null;
  curvasANivel?: number | null;
  sinMarcos?: number | null;
};

export type DensidadPlantacionesICOInput = {
  menosDe1000?: number | null;
  entre1000y1500?: number | null;
  masDe1500?: number | null;
};

export type AlturaPlantacionesICOInput = {
  menosDe600?: number | null;
  entre600y900?: number | null;
  entre900y1200?: number | null;
  masDe1200?: number | null;
};

export type RegistroFloracionICOInput = {
  ninguna?: number | null;
  primera?: number | null;
  segunda?: number | null;
  tercera?: number | null;
};

export enum RendimientoCafetalesICO {
  MUYBAJO = "MUYBAJO",
  BAJO = "BAJO",
  BUENO = "BUENO",
  MUYBUENO = "MUYBUENO"
}

export enum FactoresRendimientoICO {
  CALIDADGENETICA = "CALIDADGENETICA",
  MANEJO = "MANEJO",
  AMBIENTE = "AMBIENTE",
  OTROS = "OTROS"
}

export enum CondicionesCafetalesICO {
  DETERIORADO = "DETERIORADO",
  REGULARES = "REGULARES",
  BUENAS = "BUENAS",
  MUYBUENAS = "MUYBUENAS"
}

export type ICOCalidadInput = {
  infraestructura?: Array<InfraestructuraOrganizacionICO | null> | null;
  despulpadoraTipos?: DespulpadoraTipoICOInput | null;
  equipoCondicion?: EquipoCondicionICOInput | null;
  tanqueTipos?: TanqueTipoICOInput | null;
  secadoInfraestructuras?: SecadoInfraestructuraICOInput | null;
  cafeCorte?: CafeCorteICOInput | null;
  fermentacionHora?: FermentacionHoraICOInput | null;
  conocenCalidad?: ConocenCalidadICO | null;
  cafeCalidad?: CafeCalidadICOInput | null;
};

export enum InfraestructuraOrganizacionICO {
  OFICINAS = "OFICINAS",
  BODEGAS = "BODEGAS",
  MAQUILADORA = "MAQUILADORA",
  VEHICULOS = "VEHICULOS",
  TORREFACTORA = "TORREFACTORA",
  OTROS = "OTROS"
}

export type DespulpadoraTipoICOInput = {
  cilindro?: number | null;
  disco?: number | null;
  beneficio?: number | null;
};

export type EquipoCondicionICOInput = {
  mala?: number | null;
  regular?: number | null;
  buena?: number | null;
  excelente?: number | null;
};

export type TanqueTipoICOInput = {
  madera?: number | null;
  cemento?: number | null;
  tina?: number | null;
};

export type SecadoInfraestructuraICOInput = {
  patio?: number | null;
  zarandas?: number | null;
  gas?: number | null;
  excelente?: number | null;
};

export type CafeCorteICOInput = {
  uno?: number | null;
  dos?: number | null;
  masDeDos?: number | null;
};

export type FermentacionHoraICOInput = {
  menosDe10?: number | null;
  entre10y15?: number | null;
  entre15y20?: number | null;
};

export enum ConocenCalidadICO {
  SI = "SI",
  NO = "NO"
}

export type CafeCalidadICOInput = {
  mala?: number | null;
  regular?: number | null;
  buena?: number | null;
  excelente?: number | null;
};

export type ICOMercadoInput = {
  mercado?: MercadoICOInput | null;
  exportacionMercados?: Array<ExportacionMercadoICO | null> | null;
};

export type MercadoICOInput = {
  prodAcopiada?: number | null;
  cafeExportado?: number | null;
  cafeVendidoNacional?: number | null;
  cafeVendidoLocal?: number | null;
  precioExportacion?: number | null;
  precioNacional?: number | null;
  precioLocal?: number | null;
  sobreprecio?: number | null;
  clientesExtranjeros?: number | null;
};

export enum ExportacionMercadoICO {
  NORTEAMERICA = "NORTEAMERICA",
  EUROPA = "EUROPA",
  ASIA = "ASIA",
  OTROS = "OTROS"
}

export type ICODesarrolloCapacidadesInput = {
  cursosRecibidos?: CursosRecibidosICO | null;
  cursosTipos?: Array<CursosTipo | null> | null;
  reforzarProcesos?: Array<ReforzarProceso | null> | null;
};

export enum CursosRecibidosICO {
  NINGUNO = "NINGUNO",
  MENOSDE3 = "MENOSDE3",
  ENTRE3Y5 = "ENTRE3Y5",
  ENTRE5Y10 = "ENTRE5Y10",
  MASDE10 = "MASDE10"
}

export type ModelInfoCicloOrganizacionConditionInput = {
  cicloId?: ModelIDInput | null;
  organizacionId?: ModelIDInput | null;
  and?: Array<ModelInfoCicloOrganizacionConditionInput | null> | null;
  or?: Array<ModelInfoCicloOrganizacionConditionInput | null> | null;
  not?: ModelInfoCicloOrganizacionConditionInput | null;
};

export type UpdateInfoCicloOrganizacionInput = {
  id: string;
  cicloId?: string | null;
  organizacionId?: string | null;
  datosGenerales?: ICODatosGeneralesInput | null;
  produccion?: ICOProduccionInput | null;
  calidad?: ICOCalidadInput | null;
  mercado?: ICOMercadoInput | null;
  desarrollo?: ICODesarrolloCapacidadesInput | null;
};

export type DeleteInfoCicloOrganizacionInput = {
  id?: string | null;
};

export type CreateUsuarioInput = {
  id?: string | null;
  email: string;
  nombreCompleto: string;
  fechaNacimiento?: string | null;
};

export type ModelUsuarioConditionInput = {
  email?: ModelStringInput | null;
  nombreCompleto?: ModelStringInput | null;
  fechaNacimiento?: ModelStringInput | null;
  and?: Array<ModelUsuarioConditionInput | null> | null;
  or?: Array<ModelUsuarioConditionInput | null> | null;
  not?: ModelUsuarioConditionInput | null;
};

export type UpdateUsuarioInput = {
  id: string;
  email?: string | null;
  nombreCompleto?: string | null;
  fechaNacimiento?: string | null;
};

export type DeleteUsuarioInput = {
  id?: string | null;
};

export type ModelOrganizacionCafeFilterInput = {
  id?: ModelIDInput | null;
  searchFields?: ModelStringInput | null;
  nombre?: ModelStringInput | null;
  figuraJuridica?: ModelStringInput | null;
  telefono?: ModelStringInput | null;
  email?: ModelStringInput | null;
  anoConstitucion?: ModelIntInput | null;
  regionId?: ModelIDInput | null;
  direccion?: ModelStringInput | null;
  codigoPostal?: ModelStringInput | null;
  coloniaId?: ModelIDInput | null;
  estadoId?: ModelIDInput | null;
  municipioId?: ModelIDInput | null;
  localidadId?: ModelIDInput | null;
  reprNombre?: ModelStringInput | null;
  reprCargo?: ModelStringInput | null;
  reprTelefono?: ModelStringInput | null;
  reprEmail?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelOrganizacionCafeFilterInput | null> | null;
  or?: Array<ModelOrganizacionCafeFilterInput | null> | null;
  not?: ModelOrganizacionCafeFilterInput | null;
};

export type ModelRegionFilterInput = {
  id?: ModelIDInput | null;
  nombre?: ModelStringInput | null;
  and?: Array<ModelRegionFilterInput | null> | null;
  or?: Array<ModelRegionFilterInput | null> | null;
  not?: ModelRegionFilterInput | null;
};

export type ModelProductorFilterInput = {
  id?: ModelIDInput | null;
  searchField?: ModelStringInput | null;
  userid?: ModelStringInput | null;
  username?: ModelStringInput | null;
  folioProductorId?: ModelIDInput | null;
  organizacioncafeOrganizacionId?: ModelIDInput | null;
  nombre?: ModelStringInput | null;
  aPaterno?: ModelStringInput | null;
  aMaterno?: ModelStringInput | null;
  fechaNac?: ModelStringInput | null;
  sexo?: ModelStringInput | null;
  curp?: ModelStringInput | null;
  rfc?: ModelStringInput | null;
  direccion?: ModelStringInput | null;
  codigoPostal?: ModelStringInput | null;
  estadoId?: ModelIDInput | null;
  municipioId?: ModelIDInput | null;
  localidadId?: ModelIDInput | null;
  latitud?: ModelFloatInput | null;
  longitud?: ModelFloatInput | null;
  and?: Array<ModelProductorFilterInput | null> | null;
  or?: Array<ModelProductorFilterInput | null> | null;
  not?: ModelProductorFilterInput | null;
};

export type ModelEntidadFilterInput = {
  id?: ModelIDInput | null;
  nombre?: ModelStringInput | null;
  nombreAbrev?: ModelStringInput | null;
  clave?: ModelStringInput | null;
  pobMasc?: ModelStringInput | null;
  pobFem?: ModelStringInput | null;
  pobTotal?: ModelStringInput | null;
  totalVivHab?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelEntidadFilterInput | null> | null;
  or?: Array<ModelEntidadFilterInput | null> | null;
  not?: ModelEntidadFilterInput | null;
};

export type ModelMunicipioFilterInput = {
  id?: ModelIDInput | null;
  nombre?: ModelStringInput | null;
  clave?: ModelStringInput | null;
  entidadId?: ModelStringInput | null;
  cabecera?: ModelStringInput | null;
  claveCab?: ModelStringInput | null;
  pobMasc?: ModelStringInput | null;
  pobFem?: ModelStringInput | null;
  pobTotal?: ModelStringInput | null;
  totalVivHab?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  nombreEnt?: ModelStringInput | null;
  nombreEntAbrev?: ModelStringInput | null;
  and?: Array<ModelMunicipioFilterInput | null> | null;
  or?: Array<ModelMunicipioFilterInput | null> | null;
  not?: ModelMunicipioFilterInput | null;
};

export type ModelLocalidadFilterInput = {
  id?: ModelIDInput | null;
  nombre?: ModelStringInput | null;
  entidadId?: ModelStringInput | null;
  municipioId?: ModelStringInput | null;
  clave?: ModelStringInput | null;
  ambito?: ModelStringInput | null;
  latitud?: ModelFloatInput | null;
  longitud?: ModelFloatInput | null;
  altitud?: ModelStringInput | null;
  pobMasc?: ModelStringInput | null;
  pobFem?: ModelStringInput | null;
  pobTotal?: ModelStringInput | null;
  totalVivHab?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  nombreEnt?: ModelStringInput | null;
  nombreEntAbrev?: ModelStringInput | null;
  nombreMun?: ModelStringInput | null;
  claveCarta?: ModelStringInput | null;
  and?: Array<ModelLocalidadFilterInput | null> | null;
  or?: Array<ModelLocalidadFilterInput | null> | null;
  not?: ModelLocalidadFilterInput | null;
};

export type ModelCodigoPostalFilterInput = {
  id?: ModelIDInput | null;
  codigo?: ModelStringInput | null;
  asentamiento?: ModelStringInput | null;
  tipoAsent?: ModelStringInput | null;
  String?: ModelStringInput | null;
  claveTipoAsent?: ModelStringInput | null;
  asentCpcons?: ModelStringInput | null;
  zona?: ModelStringInput | null;
  entidadId?: ModelStringInput | null;
  municipioId?: ModelStringInput | null;
  ciudad?: ModelStringInput | null;
  claveCd?: ModelStringInput | null;
  cpOficina?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  nombreMun?: ModelStringInput | null;
  nombreEnt?: ModelStringInput | null;
  dCP?: ModelStringInput | null;
  and?: Array<ModelCodigoPostalFilterInput | null> | null;
  or?: Array<ModelCodigoPostalFilterInput | null> | null;
  not?: ModelCodigoPostalFilterInput | null;
};

export type ModelCicloFilterInput = {
  id?: ModelIDInput | null;
  nombre?: ModelStringInput | null;
  fechaInicio?: ModelStringInput | null;
  fechaFin?: ModelStringInput | null;
  activo?: ModelBooleanInput | null;
  and?: Array<ModelCicloFilterInput | null> | null;
  or?: Array<ModelCicloFilterInput | null> | null;
  not?: ModelCicloFilterInput | null;
};

export type ModelInfoCicloFilterInput = {
  id?: ModelIDInput | null;
  cicloId?: ModelIDInput | null;
  productorId?: ModelIDInput | null;
  organizacionId?: ModelIDInput | null;
  and?: Array<ModelInfoCicloFilterInput | null> | null;
  or?: Array<ModelInfoCicloFilterInput | null> | null;
  not?: ModelInfoCicloFilterInput | null;
};

export type ModelPadronCafetaleroFilterInput = {
  id?: ModelIDInput | null;
  type?: ModelStringInput | null;
  curp?: ModelStringInput | null;
  folioProductorId?: ModelStringInput | null;
  nombre?: ModelStringInput | null;
  aPaterno?: ModelStringInput | null;
  aMaterno?: ModelStringInput | null;
  fechaNac?: ModelStringInput | null;
  sexo?: ModelStringInput | null;
  rfc?: ModelStringInput | null;
  calle?: ModelStringInput | null;
  numero?: ModelStringInput | null;
  colonia?: ModelStringInput | null;
  entidadId?: ModelStringInput | null;
  nombreEnt?: ModelStringInput | null;
  municipioId?: ModelStringInput | null;
  nombreMun?: ModelStringInput | null;
  localidadId?: ModelStringInput | null;
  nombreLoc?: ModelStringInput | null;
  folioProdPred?: ModelStringInput | null;
  folioPredioId?: ModelStringInput | null;
  nombrePredio?: ModelStringInput | null;
  superficieCafe?: ModelFloatInput | null;
  predioEntidadId?: ModelStringInput | null;
  predioNombreEnt?: ModelStringInput | null;
  predioMunicipioId?: ModelStringInput | null;
  predioNombreMun?: ModelStringInput | null;
  predioLocalidadId?: ModelStringInput | null;
  predioNombreLoc?: ModelStringInput | null;
  latitudGrados?: ModelStringInput | null;
  longitudGrados?: ModelStringInput | null;
  latitud?: ModelStringInput | null;
  longitud?: ModelStringInput | null;
  searchField?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  and?: Array<ModelPadronCafetaleroFilterInput | null> | null;
  or?: Array<ModelPadronCafetaleroFilterInput | null> | null;
  not?: ModelPadronCafetaleroFilterInput | null;
};

export type ModelInfoCicloOrganizacionFilterInput = {
  id?: ModelIDInput | null;
  cicloId?: ModelIDInput | null;
  organizacionId?: ModelIDInput | null;
  and?: Array<ModelInfoCicloOrganizacionFilterInput | null> | null;
  or?: Array<ModelInfoCicloOrganizacionFilterInput | null> | null;
  not?: ModelInfoCicloOrganizacionFilterInput | null;
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC"
}

export type ModelProductorByFullNameCompositeKeyConditionInput = {
  eq?: ModelProductorByFullNameCompositeKeyInput | null;
  le?: ModelProductorByFullNameCompositeKeyInput | null;
  lt?: ModelProductorByFullNameCompositeKeyInput | null;
  ge?: ModelProductorByFullNameCompositeKeyInput | null;
  gt?: ModelProductorByFullNameCompositeKeyInput | null;
  between?: Array<ModelProductorByFullNameCompositeKeyInput | null> | null;
  beginsWith?: ModelProductorByFullNameCompositeKeyInput | null;
};

export type ModelProductorByFullNameCompositeKeyInput = {
  aPaterno?: string | null;
  aMaterno?: string | null;
};

export type ModelStringKeyConditionInput = {
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
};

export type ModelUsuarioFilterInput = {
  id?: ModelIDInput | null;
  email?: ModelStringInput | null;
  nombreCompleto?: ModelStringInput | null;
  fechaNacimiento?: ModelStringInput | null;
  and?: Array<ModelUsuarioFilterInput | null> | null;
  or?: Array<ModelUsuarioFilterInput | null> | null;
  not?: ModelUsuarioFilterInput | null;
};

export type CreateOrganizacionCafeMutation = {
  __typename: "OrganizacionCafe";
  id: string;
  searchFields: string | null;
  nombre: string;
  figuraJuridica: string | null;
  telefono: string | null;
  email: string | null;
  anoConstitucion: number | null;
  regionId: string;
  region: {
    __typename: "Region";
    id: string;
    nombre: string;
    createdAt: string;
    updatedAt: string;
  } | null;
  direccion: string | null;
  codigoPostal: string | null;
  coloniaId: string;
  colonia: {
    __typename: "CodigoPostal";
    id: string;
    codigo: string;
    asentamiento: string;
    tipoAsent: string;
    String: string | null;
    claveTipoAsent: string | null;
    asentCpcons: string | null;
    zona: string | null;
    entidadId: string;
    municipioId: string;
    ciudad: string | null;
    claveCd: string | null;
    cpOficina: string | null;
    createdAt: string | null;
    updatedAt: string | null;
    nombreMun: string | null;
    nombreEnt: string | null;
    dCP: string | null;
  } | null;
  estadoId: string;
  estado: {
    __typename: "Entidad";
    id: string;
    nombre: string;
    nombreAbrev: string;
    clave: string;
    pobMasc: string | null;
    pobFem: string | null;
    pobTotal: string | null;
    totalVivHab: string | null;
    createdAt: string | null;
    updatedAt: string | null;
  } | null;
  municipioId: string;
  municipio: {
    __typename: "Municipio";
    id: string;
    nombre: string;
    clave: string;
    entidadId: string;
    cabecera: string | null;
    claveCab: string | null;
    pobMasc: string | null;
    pobFem: string | null;
    pobTotal: string | null;
    totalVivHab: string | null;
    createdAt: string | null;
    updatedAt: string | null;
    nombreEnt: string | null;
    nombreEntAbrev: string | null;
  } | null;
  localidadId: string;
  localidad: {
    __typename: "Localidad";
    id: string;
    nombre: string;
    entidadId: string;
    municipioId: string;
    clave: string;
    ambito: string;
    latitud: number | null;
    longitud: number | null;
    altitud: string | null;
    pobMasc: string | null;
    pobFem: string | null;
    pobTotal: string | null;
    totalVivHab: string | null;
    createdAt: string | null;
    updatedAt: string | null;
    nombreEnt: string | null;
    nombreEntAbrev: string | null;
    nombreMun: string | null;
    claveCarta: string | null;
  } | null;
  reprNombre: string | null;
  reprCargo: string | null;
  reprTelefono: string | null;
  reprEmail: string | null;
  createdAt: string | null;
  updatedAt: string | null;
};

export type UpdateOrganizacionCafeMutation = {
  __typename: "OrganizacionCafe";
  id: string;
  searchFields: string | null;
  nombre: string;
  figuraJuridica: string | null;
  telefono: string | null;
  email: string | null;
  anoConstitucion: number | null;
  regionId: string;
  region: {
    __typename: "Region";
    id: string;
    nombre: string;
    createdAt: string;
    updatedAt: string;
  } | null;
  direccion: string | null;
  codigoPostal: string | null;
  coloniaId: string;
  colonia: {
    __typename: "CodigoPostal";
    id: string;
    codigo: string;
    asentamiento: string;
    tipoAsent: string;
    String: string | null;
    claveTipoAsent: string | null;
    asentCpcons: string | null;
    zona: string | null;
    entidadId: string;
    municipioId: string;
    ciudad: string | null;
    claveCd: string | null;
    cpOficina: string | null;
    createdAt: string | null;
    updatedAt: string | null;
    nombreMun: string | null;
    nombreEnt: string | null;
    dCP: string | null;
  } | null;
  estadoId: string;
  estado: {
    __typename: "Entidad";
    id: string;
    nombre: string;
    nombreAbrev: string;
    clave: string;
    pobMasc: string | null;
    pobFem: string | null;
    pobTotal: string | null;
    totalVivHab: string | null;
    createdAt: string | null;
    updatedAt: string | null;
  } | null;
  municipioId: string;
  municipio: {
    __typename: "Municipio";
    id: string;
    nombre: string;
    clave: string;
    entidadId: string;
    cabecera: string | null;
    claveCab: string | null;
    pobMasc: string | null;
    pobFem: string | null;
    pobTotal: string | null;
    totalVivHab: string | null;
    createdAt: string | null;
    updatedAt: string | null;
    nombreEnt: string | null;
    nombreEntAbrev: string | null;
  } | null;
  localidadId: string;
  localidad: {
    __typename: "Localidad";
    id: string;
    nombre: string;
    entidadId: string;
    municipioId: string;
    clave: string;
    ambito: string;
    latitud: number | null;
    longitud: number | null;
    altitud: string | null;
    pobMasc: string | null;
    pobFem: string | null;
    pobTotal: string | null;
    totalVivHab: string | null;
    createdAt: string | null;
    updatedAt: string | null;
    nombreEnt: string | null;
    nombreEntAbrev: string | null;
    nombreMun: string | null;
    claveCarta: string | null;
  } | null;
  reprNombre: string | null;
  reprCargo: string | null;
  reprTelefono: string | null;
  reprEmail: string | null;
  createdAt: string | null;
  updatedAt: string | null;
};

export type DeleteOrganizacionCafeMutation = {
  __typename: "OrganizacionCafe";
  id: string;
  searchFields: string | null;
  nombre: string;
  figuraJuridica: string | null;
  telefono: string | null;
  email: string | null;
  anoConstitucion: number | null;
  regionId: string;
  region: {
    __typename: "Region";
    id: string;
    nombre: string;
    createdAt: string;
    updatedAt: string;
  } | null;
  direccion: string | null;
  codigoPostal: string | null;
  coloniaId: string;
  colonia: {
    __typename: "CodigoPostal";
    id: string;
    codigo: string;
    asentamiento: string;
    tipoAsent: string;
    String: string | null;
    claveTipoAsent: string | null;
    asentCpcons: string | null;
    zona: string | null;
    entidadId: string;
    municipioId: string;
    ciudad: string | null;
    claveCd: string | null;
    cpOficina: string | null;
    createdAt: string | null;
    updatedAt: string | null;
    nombreMun: string | null;
    nombreEnt: string | null;
    dCP: string | null;
  } | null;
  estadoId: string;
  estado: {
    __typename: "Entidad";
    id: string;
    nombre: string;
    nombreAbrev: string;
    clave: string;
    pobMasc: string | null;
    pobFem: string | null;
    pobTotal: string | null;
    totalVivHab: string | null;
    createdAt: string | null;
    updatedAt: string | null;
  } | null;
  municipioId: string;
  municipio: {
    __typename: "Municipio";
    id: string;
    nombre: string;
    clave: string;
    entidadId: string;
    cabecera: string | null;
    claveCab: string | null;
    pobMasc: string | null;
    pobFem: string | null;
    pobTotal: string | null;
    totalVivHab: string | null;
    createdAt: string | null;
    updatedAt: string | null;
    nombreEnt: string | null;
    nombreEntAbrev: string | null;
  } | null;
  localidadId: string;
  localidad: {
    __typename: "Localidad";
    id: string;
    nombre: string;
    entidadId: string;
    municipioId: string;
    clave: string;
    ambito: string;
    latitud: number | null;
    longitud: number | null;
    altitud: string | null;
    pobMasc: string | null;
    pobFem: string | null;
    pobTotal: string | null;
    totalVivHab: string | null;
    createdAt: string | null;
    updatedAt: string | null;
    nombreEnt: string | null;
    nombreEntAbrev: string | null;
    nombreMun: string | null;
    claveCarta: string | null;
  } | null;
  reprNombre: string | null;
  reprCargo: string | null;
  reprTelefono: string | null;
  reprEmail: string | null;
  createdAt: string | null;
  updatedAt: string | null;
};

export type CreateRegionMutation = {
  __typename: "Region";
  id: string;
  nombre: string;
  createdAt: string;
  updatedAt: string;
};

export type UpdateRegionMutation = {
  __typename: "Region";
  id: string;
  nombre: string;
  createdAt: string;
  updatedAt: string;
};

export type DeleteRegionMutation = {
  __typename: "Region";
  id: string;
  nombre: string;
  createdAt: string;
  updatedAt: string;
};

export type CreateProductorMutation = {
  __typename: "Productor";
  id: string;
  searchField: string | null;
  userid: string | null;
  username: string | null;
  folioProductorId: string | null;
  organizacioncafeOrganizacionId: string | null;
  Organizacion: {
    __typename: "OrganizacionCafe";
    id: string;
    searchFields: string | null;
    nombre: string;
    figuraJuridica: string | null;
    telefono: string | null;
    email: string | null;
    anoConstitucion: number | null;
    regionId: string;
    region: {
      __typename: "Region";
      id: string;
      nombre: string;
      createdAt: string;
      updatedAt: string;
    } | null;
    direccion: string | null;
    codigoPostal: string | null;
    coloniaId: string;
    colonia: {
      __typename: "CodigoPostal";
      id: string;
      codigo: string;
      asentamiento: string;
      tipoAsent: string;
      String: string | null;
      claveTipoAsent: string | null;
      asentCpcons: string | null;
      zona: string | null;
      entidadId: string;
      municipioId: string;
      ciudad: string | null;
      claveCd: string | null;
      cpOficina: string | null;
      createdAt: string | null;
      updatedAt: string | null;
      nombreMun: string | null;
      nombreEnt: string | null;
      dCP: string | null;
    } | null;
    estadoId: string;
    estado: {
      __typename: "Entidad";
      id: string;
      nombre: string;
      nombreAbrev: string;
      clave: string;
      pobMasc: string | null;
      pobFem: string | null;
      pobTotal: string | null;
      totalVivHab: string | null;
      createdAt: string | null;
      updatedAt: string | null;
    } | null;
    municipioId: string;
    municipio: {
      __typename: "Municipio";
      id: string;
      nombre: string;
      clave: string;
      entidadId: string;
      cabecera: string | null;
      claveCab: string | null;
      pobMasc: string | null;
      pobFem: string | null;
      pobTotal: string | null;
      totalVivHab: string | null;
      createdAt: string | null;
      updatedAt: string | null;
      nombreEnt: string | null;
      nombreEntAbrev: string | null;
    } | null;
    localidadId: string;
    localidad: {
      __typename: "Localidad";
      id: string;
      nombre: string;
      entidadId: string;
      municipioId: string;
      clave: string;
      ambito: string;
      latitud: number | null;
      longitud: number | null;
      altitud: string | null;
      pobMasc: string | null;
      pobFem: string | null;
      pobTotal: string | null;
      totalVivHab: string | null;
      createdAt: string | null;
      updatedAt: string | null;
      nombreEnt: string | null;
      nombreEntAbrev: string | null;
      nombreMun: string | null;
      claveCarta: string | null;
    } | null;
    reprNombre: string | null;
    reprCargo: string | null;
    reprTelefono: string | null;
    reprEmail: string | null;
    createdAt: string | null;
    updatedAt: string | null;
  } | null;
  nombre: string | null;
  aPaterno: string | null;
  aMaterno: string | null;
  fechaNac: string | null;
  sexo: string | null;
  curp: string | null;
  rfc: string | null;
  direccion: string | null;
  codigoPostal: string | null;
  estadoId: string;
  estado: {
    __typename: "Entidad";
    id: string;
    nombre: string;
    nombreAbrev: string;
    clave: string;
    pobMasc: string | null;
    pobFem: string | null;
    pobTotal: string | null;
    totalVivHab: string | null;
    createdAt: string | null;
    updatedAt: string | null;
  } | null;
  municipioId: string;
  municipio: {
    __typename: "Municipio";
    id: string;
    nombre: string;
    clave: string;
    entidadId: string;
    cabecera: string | null;
    claveCab: string | null;
    pobMasc: string | null;
    pobFem: string | null;
    pobTotal: string | null;
    totalVivHab: string | null;
    createdAt: string | null;
    updatedAt: string | null;
    nombreEnt: string | null;
    nombreEntAbrev: string | null;
  } | null;
  localidadId: string;
  localidad: {
    __typename: "Localidad";
    id: string;
    nombre: string;
    entidadId: string;
    municipioId: string;
    clave: string;
    ambito: string;
    latitud: number | null;
    longitud: number | null;
    altitud: string | null;
    pobMasc: string | null;
    pobFem: string | null;
    pobTotal: string | null;
    totalVivHab: string | null;
    createdAt: string | null;
    updatedAt: string | null;
    nombreEnt: string | null;
    nombreEntAbrev: string | null;
    nombreMun: string | null;
    claveCarta: string | null;
  } | null;
  latitud: number | null;
  longitud: number | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateProductorMutation = {
  __typename: "Productor";
  id: string;
  searchField: string | null;
  userid: string | null;
  username: string | null;
  folioProductorId: string | null;
  organizacioncafeOrganizacionId: string | null;
  Organizacion: {
    __typename: "OrganizacionCafe";
    id: string;
    searchFields: string | null;
    nombre: string;
    figuraJuridica: string | null;
    telefono: string | null;
    email: string | null;
    anoConstitucion: number | null;
    regionId: string;
    region: {
      __typename: "Region";
      id: string;
      nombre: string;
      createdAt: string;
      updatedAt: string;
    } | null;
    direccion: string | null;
    codigoPostal: string | null;
    coloniaId: string;
    colonia: {
      __typename: "CodigoPostal";
      id: string;
      codigo: string;
      asentamiento: string;
      tipoAsent: string;
      String: string | null;
      claveTipoAsent: string | null;
      asentCpcons: string | null;
      zona: string | null;
      entidadId: string;
      municipioId: string;
      ciudad: string | null;
      claveCd: string | null;
      cpOficina: string | null;
      createdAt: string | null;
      updatedAt: string | null;
      nombreMun: string | null;
      nombreEnt: string | null;
      dCP: string | null;
    } | null;
    estadoId: string;
    estado: {
      __typename: "Entidad";
      id: string;
      nombre: string;
      nombreAbrev: string;
      clave: string;
      pobMasc: string | null;
      pobFem: string | null;
      pobTotal: string | null;
      totalVivHab: string | null;
      createdAt: string | null;
      updatedAt: string | null;
    } | null;
    municipioId: string;
    municipio: {
      __typename: "Municipio";
      id: string;
      nombre: string;
      clave: string;
      entidadId: string;
      cabecera: string | null;
      claveCab: string | null;
      pobMasc: string | null;
      pobFem: string | null;
      pobTotal: string | null;
      totalVivHab: string | null;
      createdAt: string | null;
      updatedAt: string | null;
      nombreEnt: string | null;
      nombreEntAbrev: string | null;
    } | null;
    localidadId: string;
    localidad: {
      __typename: "Localidad";
      id: string;
      nombre: string;
      entidadId: string;
      municipioId: string;
      clave: string;
      ambito: string;
      latitud: number | null;
      longitud: number | null;
      altitud: string | null;
      pobMasc: string | null;
      pobFem: string | null;
      pobTotal: string | null;
      totalVivHab: string | null;
      createdAt: string | null;
      updatedAt: string | null;
      nombreEnt: string | null;
      nombreEntAbrev: string | null;
      nombreMun: string | null;
      claveCarta: string | null;
    } | null;
    reprNombre: string | null;
    reprCargo: string | null;
    reprTelefono: string | null;
    reprEmail: string | null;
    createdAt: string | null;
    updatedAt: string | null;
  } | null;
  nombre: string | null;
  aPaterno: string | null;
  aMaterno: string | null;
  fechaNac: string | null;
  sexo: string | null;
  curp: string | null;
  rfc: string | null;
  direccion: string | null;
  codigoPostal: string | null;
  estadoId: string;
  estado: {
    __typename: "Entidad";
    id: string;
    nombre: string;
    nombreAbrev: string;
    clave: string;
    pobMasc: string | null;
    pobFem: string | null;
    pobTotal: string | null;
    totalVivHab: string | null;
    createdAt: string | null;
    updatedAt: string | null;
  } | null;
  municipioId: string;
  municipio: {
    __typename: "Municipio";
    id: string;
    nombre: string;
    clave: string;
    entidadId: string;
    cabecera: string | null;
    claveCab: string | null;
    pobMasc: string | null;
    pobFem: string | null;
    pobTotal: string | null;
    totalVivHab: string | null;
    createdAt: string | null;
    updatedAt: string | null;
    nombreEnt: string | null;
    nombreEntAbrev: string | null;
  } | null;
  localidadId: string;
  localidad: {
    __typename: "Localidad";
    id: string;
    nombre: string;
    entidadId: string;
    municipioId: string;
    clave: string;
    ambito: string;
    latitud: number | null;
    longitud: number | null;
    altitud: string | null;
    pobMasc: string | null;
    pobFem: string | null;
    pobTotal: string | null;
    totalVivHab: string | null;
    createdAt: string | null;
    updatedAt: string | null;
    nombreEnt: string | null;
    nombreEntAbrev: string | null;
    nombreMun: string | null;
    claveCarta: string | null;
  } | null;
  latitud: number | null;
  longitud: number | null;
  createdAt: string;
  updatedAt: string;
};

export type DeleteProductorMutation = {
  __typename: "Productor";
  id: string;
  searchField: string | null;
  userid: string | null;
  username: string | null;
  folioProductorId: string | null;
  organizacioncafeOrganizacionId: string | null;
  Organizacion: {
    __typename: "OrganizacionCafe";
    id: string;
    searchFields: string | null;
    nombre: string;
    figuraJuridica: string | null;
    telefono: string | null;
    email: string | null;
    anoConstitucion: number | null;
    regionId: string;
    region: {
      __typename: "Region";
      id: string;
      nombre: string;
      createdAt: string;
      updatedAt: string;
    } | null;
    direccion: string | null;
    codigoPostal: string | null;
    coloniaId: string;
    colonia: {
      __typename: "CodigoPostal";
      id: string;
      codigo: string;
      asentamiento: string;
      tipoAsent: string;
      String: string | null;
      claveTipoAsent: string | null;
      asentCpcons: string | null;
      zona: string | null;
      entidadId: string;
      municipioId: string;
      ciudad: string | null;
      claveCd: string | null;
      cpOficina: string | null;
      createdAt: string | null;
      updatedAt: string | null;
      nombreMun: string | null;
      nombreEnt: string | null;
      dCP: string | null;
    } | null;
    estadoId: string;
    estado: {
      __typename: "Entidad";
      id: string;
      nombre: string;
      nombreAbrev: string;
      clave: string;
      pobMasc: string | null;
      pobFem: string | null;
      pobTotal: string | null;
      totalVivHab: string | null;
      createdAt: string | null;
      updatedAt: string | null;
    } | null;
    municipioId: string;
    municipio: {
      __typename: "Municipio";
      id: string;
      nombre: string;
      clave: string;
      entidadId: string;
      cabecera: string | null;
      claveCab: string | null;
      pobMasc: string | null;
      pobFem: string | null;
      pobTotal: string | null;
      totalVivHab: string | null;
      createdAt: string | null;
      updatedAt: string | null;
      nombreEnt: string | null;
      nombreEntAbrev: string | null;
    } | null;
    localidadId: string;
    localidad: {
      __typename: "Localidad";
      id: string;
      nombre: string;
      entidadId: string;
      municipioId: string;
      clave: string;
      ambito: string;
      latitud: number | null;
      longitud: number | null;
      altitud: string | null;
      pobMasc: string | null;
      pobFem: string | null;
      pobTotal: string | null;
      totalVivHab: string | null;
      createdAt: string | null;
      updatedAt: string | null;
      nombreEnt: string | null;
      nombreEntAbrev: string | null;
      nombreMun: string | null;
      claveCarta: string | null;
    } | null;
    reprNombre: string | null;
    reprCargo: string | null;
    reprTelefono: string | null;
    reprEmail: string | null;
    createdAt: string | null;
    updatedAt: string | null;
  } | null;
  nombre: string | null;
  aPaterno: string | null;
  aMaterno: string | null;
  fechaNac: string | null;
  sexo: string | null;
  curp: string | null;
  rfc: string | null;
  direccion: string | null;
  codigoPostal: string | null;
  estadoId: string;
  estado: {
    __typename: "Entidad";
    id: string;
    nombre: string;
    nombreAbrev: string;
    clave: string;
    pobMasc: string | null;
    pobFem: string | null;
    pobTotal: string | null;
    totalVivHab: string | null;
    createdAt: string | null;
    updatedAt: string | null;
  } | null;
  municipioId: string;
  municipio: {
    __typename: "Municipio";
    id: string;
    nombre: string;
    clave: string;
    entidadId: string;
    cabecera: string | null;
    claveCab: string | null;
    pobMasc: string | null;
    pobFem: string | null;
    pobTotal: string | null;
    totalVivHab: string | null;
    createdAt: string | null;
    updatedAt: string | null;
    nombreEnt: string | null;
    nombreEntAbrev: string | null;
  } | null;
  localidadId: string;
  localidad: {
    __typename: "Localidad";
    id: string;
    nombre: string;
    entidadId: string;
    municipioId: string;
    clave: string;
    ambito: string;
    latitud: number | null;
    longitud: number | null;
    altitud: string | null;
    pobMasc: string | null;
    pobFem: string | null;
    pobTotal: string | null;
    totalVivHab: string | null;
    createdAt: string | null;
    updatedAt: string | null;
    nombreEnt: string | null;
    nombreEntAbrev: string | null;
    nombreMun: string | null;
    claveCarta: string | null;
  } | null;
  latitud: number | null;
  longitud: number | null;
  createdAt: string;
  updatedAt: string;
};

export type CreateEntidadMutation = {
  __typename: "Entidad";
  id: string;
  nombre: string;
  nombreAbrev: string;
  clave: string;
  pobMasc: string | null;
  pobFem: string | null;
  pobTotal: string | null;
  totalVivHab: string | null;
  createdAt: string | null;
  updatedAt: string | null;
};

export type UpdateEntidadMutation = {
  __typename: "Entidad";
  id: string;
  nombre: string;
  nombreAbrev: string;
  clave: string;
  pobMasc: string | null;
  pobFem: string | null;
  pobTotal: string | null;
  totalVivHab: string | null;
  createdAt: string | null;
  updatedAt: string | null;
};

export type DeleteEntidadMutation = {
  __typename: "Entidad";
  id: string;
  nombre: string;
  nombreAbrev: string;
  clave: string;
  pobMasc: string | null;
  pobFem: string | null;
  pobTotal: string | null;
  totalVivHab: string | null;
  createdAt: string | null;
  updatedAt: string | null;
};

export type CreateMunicipioMutation = {
  __typename: "Municipio";
  id: string;
  nombre: string;
  clave: string;
  entidadId: string;
  cabecera: string | null;
  claveCab: string | null;
  pobMasc: string | null;
  pobFem: string | null;
  pobTotal: string | null;
  totalVivHab: string | null;
  createdAt: string | null;
  updatedAt: string | null;
  nombreEnt: string | null;
  nombreEntAbrev: string | null;
};

export type UpdateMunicipioMutation = {
  __typename: "Municipio";
  id: string;
  nombre: string;
  clave: string;
  entidadId: string;
  cabecera: string | null;
  claveCab: string | null;
  pobMasc: string | null;
  pobFem: string | null;
  pobTotal: string | null;
  totalVivHab: string | null;
  createdAt: string | null;
  updatedAt: string | null;
  nombreEnt: string | null;
  nombreEntAbrev: string | null;
};

export type DeleteMunicipioMutation = {
  __typename: "Municipio";
  id: string;
  nombre: string;
  clave: string;
  entidadId: string;
  cabecera: string | null;
  claveCab: string | null;
  pobMasc: string | null;
  pobFem: string | null;
  pobTotal: string | null;
  totalVivHab: string | null;
  createdAt: string | null;
  updatedAt: string | null;
  nombreEnt: string | null;
  nombreEntAbrev: string | null;
};

export type CreateLocalidadMutation = {
  __typename: "Localidad";
  id: string;
  nombre: string;
  entidadId: string;
  municipioId: string;
  clave: string;
  ambito: string;
  latitud: number | null;
  longitud: number | null;
  altitud: string | null;
  pobMasc: string | null;
  pobFem: string | null;
  pobTotal: string | null;
  totalVivHab: string | null;
  createdAt: string | null;
  updatedAt: string | null;
  nombreEnt: string | null;
  nombreEntAbrev: string | null;
  nombreMun: string | null;
  claveCarta: string | null;
};

export type UpdateLocalidadMutation = {
  __typename: "Localidad";
  id: string;
  nombre: string;
  entidadId: string;
  municipioId: string;
  clave: string;
  ambito: string;
  latitud: number | null;
  longitud: number | null;
  altitud: string | null;
  pobMasc: string | null;
  pobFem: string | null;
  pobTotal: string | null;
  totalVivHab: string | null;
  createdAt: string | null;
  updatedAt: string | null;
  nombreEnt: string | null;
  nombreEntAbrev: string | null;
  nombreMun: string | null;
  claveCarta: string | null;
};

export type DeleteLocalidadMutation = {
  __typename: "Localidad";
  id: string;
  nombre: string;
  entidadId: string;
  municipioId: string;
  clave: string;
  ambito: string;
  latitud: number | null;
  longitud: number | null;
  altitud: string | null;
  pobMasc: string | null;
  pobFem: string | null;
  pobTotal: string | null;
  totalVivHab: string | null;
  createdAt: string | null;
  updatedAt: string | null;
  nombreEnt: string | null;
  nombreEntAbrev: string | null;
  nombreMun: string | null;
  claveCarta: string | null;
};

export type CreateCodigoPostalMutation = {
  __typename: "CodigoPostal";
  id: string;
  codigo: string;
  asentamiento: string;
  tipoAsent: string;
  String: string | null;
  claveTipoAsent: string | null;
  asentCpcons: string | null;
  zona: string | null;
  entidadId: string;
  municipioId: string;
  ciudad: string | null;
  claveCd: string | null;
  cpOficina: string | null;
  createdAt: string | null;
  updatedAt: string | null;
  nombreMun: string | null;
  nombreEnt: string | null;
  dCP: string | null;
};

export type UpdateCodigoPostalMutation = {
  __typename: "CodigoPostal";
  id: string;
  codigo: string;
  asentamiento: string;
  tipoAsent: string;
  String: string | null;
  claveTipoAsent: string | null;
  asentCpcons: string | null;
  zona: string | null;
  entidadId: string;
  municipioId: string;
  ciudad: string | null;
  claveCd: string | null;
  cpOficina: string | null;
  createdAt: string | null;
  updatedAt: string | null;
  nombreMun: string | null;
  nombreEnt: string | null;
  dCP: string | null;
};

export type DeleteCodigoPostalMutation = {
  __typename: "CodigoPostal";
  id: string;
  codigo: string;
  asentamiento: string;
  tipoAsent: string;
  String: string | null;
  claveTipoAsent: string | null;
  asentCpcons: string | null;
  zona: string | null;
  entidadId: string;
  municipioId: string;
  ciudad: string | null;
  claveCd: string | null;
  cpOficina: string | null;
  createdAt: string | null;
  updatedAt: string | null;
  nombreMun: string | null;
  nombreEnt: string | null;
  dCP: string | null;
};

export type CreateCicloMutation = {
  __typename: "Ciclo";
  id: string;
  nombre: string;
  fechaInicio: string;
  fechaFin: string;
  activo: boolean;
  createdAt: string;
  updatedAt: string;
};

export type UpdateCicloMutation = {
  __typename: "Ciclo";
  id: string;
  nombre: string;
  fechaInicio: string;
  fechaFin: string;
  activo: boolean;
  createdAt: string;
  updatedAt: string;
};

export type DeleteCicloMutation = {
  __typename: "Ciclo";
  id: string;
  nombre: string;
  fechaInicio: string;
  fechaFin: string;
  activo: boolean;
  createdAt: string;
  updatedAt: string;
};

export type CreateInfoCicloMutation = {
  __typename: "InfoCiclo";
  id: string;
  cicloId: string;
  ciclo: {
    __typename: "Ciclo";
    id: string;
    nombre: string;
    fechaInicio: string;
    fechaFin: string;
    activo: boolean;
    createdAt: string;
    updatedAt: string;
  } | null;
  productorId: string;
  productor: {
    __typename: "Productor";
    id: string;
    searchField: string | null;
    userid: string | null;
    username: string | null;
    folioProductorId: string | null;
    organizacioncafeOrganizacionId: string | null;
    Organizacion: {
      __typename: "OrganizacionCafe";
      id: string;
      searchFields: string | null;
      nombre: string;
      figuraJuridica: string | null;
      telefono: string | null;
      email: string | null;
      anoConstitucion: number | null;
      regionId: string;
      direccion: string | null;
      codigoPostal: string | null;
      coloniaId: string;
      estadoId: string;
      municipioId: string;
      localidadId: string;
      reprNombre: string | null;
      reprCargo: string | null;
      reprTelefono: string | null;
      reprEmail: string | null;
      createdAt: string | null;
      updatedAt: string | null;
    } | null;
    nombre: string | null;
    aPaterno: string | null;
    aMaterno: string | null;
    fechaNac: string | null;
    sexo: string | null;
    curp: string | null;
    rfc: string | null;
    direccion: string | null;
    codigoPostal: string | null;
    estadoId: string;
    estado: {
      __typename: "Entidad";
      id: string;
      nombre: string;
      nombreAbrev: string;
      clave: string;
      pobMasc: string | null;
      pobFem: string | null;
      pobTotal: string | null;
      totalVivHab: string | null;
      createdAt: string | null;
      updatedAt: string | null;
    } | null;
    municipioId: string;
    municipio: {
      __typename: "Municipio";
      id: string;
      nombre: string;
      clave: string;
      entidadId: string;
      cabecera: string | null;
      claveCab: string | null;
      pobMasc: string | null;
      pobFem: string | null;
      pobTotal: string | null;
      totalVivHab: string | null;
      createdAt: string | null;
      updatedAt: string | null;
      nombreEnt: string | null;
      nombreEntAbrev: string | null;
    } | null;
    localidadId: string;
    localidad: {
      __typename: "Localidad";
      id: string;
      nombre: string;
      entidadId: string;
      municipioId: string;
      clave: string;
      ambito: string;
      latitud: number | null;
      longitud: number | null;
      altitud: string | null;
      pobMasc: string | null;
      pobFem: string | null;
      pobTotal: string | null;
      totalVivHab: string | null;
      createdAt: string | null;
      updatedAt: string | null;
      nombreEnt: string | null;
      nombreEntAbrev: string | null;
      nombreMun: string | null;
      claveCarta: string | null;
    } | null;
    latitud: number | null;
    longitud: number | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  organizacionId: string;
  organizacion: {
    __typename: "OrganizacionCafe";
    id: string;
    searchFields: string | null;
    nombre: string;
    figuraJuridica: string | null;
    telefono: string | null;
    email: string | null;
    anoConstitucion: number | null;
    regionId: string;
    region: {
      __typename: "Region";
      id: string;
      nombre: string;
      createdAt: string;
      updatedAt: string;
    } | null;
    direccion: string | null;
    codigoPostal: string | null;
    coloniaId: string;
    colonia: {
      __typename: "CodigoPostal";
      id: string;
      codigo: string;
      asentamiento: string;
      tipoAsent: string;
      String: string | null;
      claveTipoAsent: string | null;
      asentCpcons: string | null;
      zona: string | null;
      entidadId: string;
      municipioId: string;
      ciudad: string | null;
      claveCd: string | null;
      cpOficina: string | null;
      createdAt: string | null;
      updatedAt: string | null;
      nombreMun: string | null;
      nombreEnt: string | null;
      dCP: string | null;
    } | null;
    estadoId: string;
    estado: {
      __typename: "Entidad";
      id: string;
      nombre: string;
      nombreAbrev: string;
      clave: string;
      pobMasc: string | null;
      pobFem: string | null;
      pobTotal: string | null;
      totalVivHab: string | null;
      createdAt: string | null;
      updatedAt: string | null;
    } | null;
    municipioId: string;
    municipio: {
      __typename: "Municipio";
      id: string;
      nombre: string;
      clave: string;
      entidadId: string;
      cabecera: string | null;
      claveCab: string | null;
      pobMasc: string | null;
      pobFem: string | null;
      pobTotal: string | null;
      totalVivHab: string | null;
      createdAt: string | null;
      updatedAt: string | null;
      nombreEnt: string | null;
      nombreEntAbrev: string | null;
    } | null;
    localidadId: string;
    localidad: {
      __typename: "Localidad";
      id: string;
      nombre: string;
      entidadId: string;
      municipioId: string;
      clave: string;
      ambito: string;
      latitud: number | null;
      longitud: number | null;
      altitud: string | null;
      pobMasc: string | null;
      pobFem: string | null;
      pobTotal: string | null;
      totalVivHab: string | null;
      createdAt: string | null;
      updatedAt: string | null;
      nombreEnt: string | null;
      nombreEntAbrev: string | null;
      nombreMun: string | null;
      claveCarta: string | null;
    } | null;
    reprNombre: string | null;
    reprCargo: string | null;
    reprTelefono: string | null;
    reprEmail: string | null;
    createdAt: string | null;
    updatedAt: string | null;
  } | null;
  datosGenerales: {
    __typename: "ICDatosGenerales";
    areasTrabajo: Array<AreasTrabajo | null> | null;
    infraestructura: Array<InfraestructuraOrganizacion | null> | null;
    equiposOficina: Array<EquiposOficina | null> | null;
    certificaciones: Array<Certificaciones | null> | null;
    estatus: Estatus | null;
  } | null;
  produccion: {
    __typename: "ICProduccion";
    superficie: {
      __typename: "Superficie";
      cafeOrganico: number | null;
      cafeTransicion: number | null;
      basicos: number | null;
      potrero: number | null;
      acahual: number | null;
      montania: number | null;
    } | null;
    produccionPromedio: {
      __typename: "ProduccionPromedio";
      estimacion: number | null;
    } | null;
    jornales: {
      __typename: "Jornales";
      primeraLimpia: number | null;
      segundaLimpia: number | null;
      cajete: number | null;
      podaYdeshije: number | null;
      desombre: number | null;
      abonera: number | null;
      regarAbono: number | null;
      resiembra: number | null;
      controlPlagas: number | null;
      conservacionSuelo: number | null;
      semilleroVivero: number | null;
      cosecha: number | null;
      beneficioHumedo: number | null;
      otros: number | null;
      precioPagado: number | null;
    } | null;
    insumos: {
      __typename: "Insumos";
      beauveria: number | null;
      abonoFoliar: number | null;
      calAgricola: number | null;
      costalera: number | null;
      canastos: number | null;
      mantenimientoEquipos: number | null;
      certificaciones: number | null;
      alimentacionPersonal: number | null;
      otros: number | null;
    } | null;
    rendimientoCafetales: RendimientoCafetales | null;
    factoresRendimiento: Array<FactoresRendimiento | null> | null;
    condicionesCafetales: Array<CondicionesCafetales | null> | null;
    variedadesCafe: Array<VariedadesCafe | null> | null;
    edadPlantaciones: EdadPlantaciones | null;
    disenioPlantaciones: DisenioPlantaciones | null;
    densidadPlantaciones: DensidadPlantaciones | null;
    alturaPlantaciones: AlturaPlantaciones | null;
    floracion: RegistroFloracion | null;
  } | null;
  calidad: {
    __typename: "ICCalidad";
    despulpadoraTipos: Array<DespulpadoraTipo | null> | null;
    equipoCondicion: EquipoCondicion | null;
    tanqueTipos: Array<TanqueTipo | null> | null;
    secadoInfraestructuras: Array<SecadoInfraestructura | null> | null;
    cafeCorte: CafeCorte | null;
    fermentacionHora: FermentacionHora | null;
    conocenCalidad: ConocenCalidad | null;
    cafeCalidad: CafeCalidad | null;
  } | null;
  mercado: {
    __typename: "ICMercado";
    mercado: {
      __typename: "Mercado";
      prodAcopiada: number | null;
      cafeExportado: number | null;
      cafeVendidoNacional: number | null;
      cafeVendidoLocal: number | null;
      precioExportacion: number | null;
      precioNacional: number | null;
      precioLocal: number | null;
      sobreprecio: number | null;
      clientesExtranjeros: number | null;
    } | null;
    exportacionMercados: Array<ExportacionMercado | null> | null;
  } | null;
  desarrollo: {
    __typename: "ICDesarrolloCapacidades";
    cursosRecibidos: CursosRecibido | null;
    cursosTipos: Array<CursosTipo | null> | null;
    reforzarProcesos: Array<ReforzarProceso | null> | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateInfoCicloMutation = {
  __typename: "InfoCiclo";
  id: string;
  cicloId: string;
  ciclo: {
    __typename: "Ciclo";
    id: string;
    nombre: string;
    fechaInicio: string;
    fechaFin: string;
    activo: boolean;
    createdAt: string;
    updatedAt: string;
  } | null;
  productorId: string;
  productor: {
    __typename: "Productor";
    id: string;
    searchField: string | null;
    userid: string | null;
    username: string | null;
    folioProductorId: string | null;
    organizacioncafeOrganizacionId: string | null;
    Organizacion: {
      __typename: "OrganizacionCafe";
      id: string;
      searchFields: string | null;
      nombre: string;
      figuraJuridica: string | null;
      telefono: string | null;
      email: string | null;
      anoConstitucion: number | null;
      regionId: string;
      direccion: string | null;
      codigoPostal: string | null;
      coloniaId: string;
      estadoId: string;
      municipioId: string;
      localidadId: string;
      reprNombre: string | null;
      reprCargo: string | null;
      reprTelefono: string | null;
      reprEmail: string | null;
      createdAt: string | null;
      updatedAt: string | null;
    } | null;
    nombre: string | null;
    aPaterno: string | null;
    aMaterno: string | null;
    fechaNac: string | null;
    sexo: string | null;
    curp: string | null;
    rfc: string | null;
    direccion: string | null;
    codigoPostal: string | null;
    estadoId: string;
    estado: {
      __typename: "Entidad";
      id: string;
      nombre: string;
      nombreAbrev: string;
      clave: string;
      pobMasc: string | null;
      pobFem: string | null;
      pobTotal: string | null;
      totalVivHab: string | null;
      createdAt: string | null;
      updatedAt: string | null;
    } | null;
    municipioId: string;
    municipio: {
      __typename: "Municipio";
      id: string;
      nombre: string;
      clave: string;
      entidadId: string;
      cabecera: string | null;
      claveCab: string | null;
      pobMasc: string | null;
      pobFem: string | null;
      pobTotal: string | null;
      totalVivHab: string | null;
      createdAt: string | null;
      updatedAt: string | null;
      nombreEnt: string | null;
      nombreEntAbrev: string | null;
    } | null;
    localidadId: string;
    localidad: {
      __typename: "Localidad";
      id: string;
      nombre: string;
      entidadId: string;
      municipioId: string;
      clave: string;
      ambito: string;
      latitud: number | null;
      longitud: number | null;
      altitud: string | null;
      pobMasc: string | null;
      pobFem: string | null;
      pobTotal: string | null;
      totalVivHab: string | null;
      createdAt: string | null;
      updatedAt: string | null;
      nombreEnt: string | null;
      nombreEntAbrev: string | null;
      nombreMun: string | null;
      claveCarta: string | null;
    } | null;
    latitud: number | null;
    longitud: number | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  organizacionId: string;
  organizacion: {
    __typename: "OrganizacionCafe";
    id: string;
    searchFields: string | null;
    nombre: string;
    figuraJuridica: string | null;
    telefono: string | null;
    email: string | null;
    anoConstitucion: number | null;
    regionId: string;
    region: {
      __typename: "Region";
      id: string;
      nombre: string;
      createdAt: string;
      updatedAt: string;
    } | null;
    direccion: string | null;
    codigoPostal: string | null;
    coloniaId: string;
    colonia: {
      __typename: "CodigoPostal";
      id: string;
      codigo: string;
      asentamiento: string;
      tipoAsent: string;
      String: string | null;
      claveTipoAsent: string | null;
      asentCpcons: string | null;
      zona: string | null;
      entidadId: string;
      municipioId: string;
      ciudad: string | null;
      claveCd: string | null;
      cpOficina: string | null;
      createdAt: string | null;
      updatedAt: string | null;
      nombreMun: string | null;
      nombreEnt: string | null;
      dCP: string | null;
    } | null;
    estadoId: string;
    estado: {
      __typename: "Entidad";
      id: string;
      nombre: string;
      nombreAbrev: string;
      clave: string;
      pobMasc: string | null;
      pobFem: string | null;
      pobTotal: string | null;
      totalVivHab: string | null;
      createdAt: string | null;
      updatedAt: string | null;
    } | null;
    municipioId: string;
    municipio: {
      __typename: "Municipio";
      id: string;
      nombre: string;
      clave: string;
      entidadId: string;
      cabecera: string | null;
      claveCab: string | null;
      pobMasc: string | null;
      pobFem: string | null;
      pobTotal: string | null;
      totalVivHab: string | null;
      createdAt: string | null;
      updatedAt: string | null;
      nombreEnt: string | null;
      nombreEntAbrev: string | null;
    } | null;
    localidadId: string;
    localidad: {
      __typename: "Localidad";
      id: string;
      nombre: string;
      entidadId: string;
      municipioId: string;
      clave: string;
      ambito: string;
      latitud: number | null;
      longitud: number | null;
      altitud: string | null;
      pobMasc: string | null;
      pobFem: string | null;
      pobTotal: string | null;
      totalVivHab: string | null;
      createdAt: string | null;
      updatedAt: string | null;
      nombreEnt: string | null;
      nombreEntAbrev: string | null;
      nombreMun: string | null;
      claveCarta: string | null;
    } | null;
    reprNombre: string | null;
    reprCargo: string | null;
    reprTelefono: string | null;
    reprEmail: string | null;
    createdAt: string | null;
    updatedAt: string | null;
  } | null;
  datosGenerales: {
    __typename: "ICDatosGenerales";
    areasTrabajo: Array<AreasTrabajo | null> | null;
    infraestructura: Array<InfraestructuraOrganizacion | null> | null;
    equiposOficina: Array<EquiposOficina | null> | null;
    certificaciones: Array<Certificaciones | null> | null;
    estatus: Estatus | null;
  } | null;
  produccion: {
    __typename: "ICProduccion";
    superficie: {
      __typename: "Superficie";
      cafeOrganico: number | null;
      cafeTransicion: number | null;
      basicos: number | null;
      potrero: number | null;
      acahual: number | null;
      montania: number | null;
    } | null;
    produccionPromedio: {
      __typename: "ProduccionPromedio";
      estimacion: number | null;
    } | null;
    jornales: {
      __typename: "Jornales";
      primeraLimpia: number | null;
      segundaLimpia: number | null;
      cajete: number | null;
      podaYdeshije: number | null;
      desombre: number | null;
      abonera: number | null;
      regarAbono: number | null;
      resiembra: number | null;
      controlPlagas: number | null;
      conservacionSuelo: number | null;
      semilleroVivero: number | null;
      cosecha: number | null;
      beneficioHumedo: number | null;
      otros: number | null;
      precioPagado: number | null;
    } | null;
    insumos: {
      __typename: "Insumos";
      beauveria: number | null;
      abonoFoliar: number | null;
      calAgricola: number | null;
      costalera: number | null;
      canastos: number | null;
      mantenimientoEquipos: number | null;
      certificaciones: number | null;
      alimentacionPersonal: number | null;
      otros: number | null;
    } | null;
    rendimientoCafetales: RendimientoCafetales | null;
    factoresRendimiento: Array<FactoresRendimiento | null> | null;
    condicionesCafetales: Array<CondicionesCafetales | null> | null;
    variedadesCafe: Array<VariedadesCafe | null> | null;
    edadPlantaciones: EdadPlantaciones | null;
    disenioPlantaciones: DisenioPlantaciones | null;
    densidadPlantaciones: DensidadPlantaciones | null;
    alturaPlantaciones: AlturaPlantaciones | null;
    floracion: RegistroFloracion | null;
  } | null;
  calidad: {
    __typename: "ICCalidad";
    despulpadoraTipos: Array<DespulpadoraTipo | null> | null;
    equipoCondicion: EquipoCondicion | null;
    tanqueTipos: Array<TanqueTipo | null> | null;
    secadoInfraestructuras: Array<SecadoInfraestructura | null> | null;
    cafeCorte: CafeCorte | null;
    fermentacionHora: FermentacionHora | null;
    conocenCalidad: ConocenCalidad | null;
    cafeCalidad: CafeCalidad | null;
  } | null;
  mercado: {
    __typename: "ICMercado";
    mercado: {
      __typename: "Mercado";
      prodAcopiada: number | null;
      cafeExportado: number | null;
      cafeVendidoNacional: number | null;
      cafeVendidoLocal: number | null;
      precioExportacion: number | null;
      precioNacional: number | null;
      precioLocal: number | null;
      sobreprecio: number | null;
      clientesExtranjeros: number | null;
    } | null;
    exportacionMercados: Array<ExportacionMercado | null> | null;
  } | null;
  desarrollo: {
    __typename: "ICDesarrolloCapacidades";
    cursosRecibidos: CursosRecibido | null;
    cursosTipos: Array<CursosTipo | null> | null;
    reforzarProcesos: Array<ReforzarProceso | null> | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type DeleteInfoCicloMutation = {
  __typename: "InfoCiclo";
  id: string;
  cicloId: string;
  ciclo: {
    __typename: "Ciclo";
    id: string;
    nombre: string;
    fechaInicio: string;
    fechaFin: string;
    activo: boolean;
    createdAt: string;
    updatedAt: string;
  } | null;
  productorId: string;
  productor: {
    __typename: "Productor";
    id: string;
    searchField: string | null;
    userid: string | null;
    username: string | null;
    folioProductorId: string | null;
    organizacioncafeOrganizacionId: string | null;
    Organizacion: {
      __typename: "OrganizacionCafe";
      id: string;
      searchFields: string | null;
      nombre: string;
      figuraJuridica: string | null;
      telefono: string | null;
      email: string | null;
      anoConstitucion: number | null;
      regionId: string;
      direccion: string | null;
      codigoPostal: string | null;
      coloniaId: string;
      estadoId: string;
      municipioId: string;
      localidadId: string;
      reprNombre: string | null;
      reprCargo: string | null;
      reprTelefono: string | null;
      reprEmail: string | null;
      createdAt: string | null;
      updatedAt: string | null;
    } | null;
    nombre: string | null;
    aPaterno: string | null;
    aMaterno: string | null;
    fechaNac: string | null;
    sexo: string | null;
    curp: string | null;
    rfc: string | null;
    direccion: string | null;
    codigoPostal: string | null;
    estadoId: string;
    estado: {
      __typename: "Entidad";
      id: string;
      nombre: string;
      nombreAbrev: string;
      clave: string;
      pobMasc: string | null;
      pobFem: string | null;
      pobTotal: string | null;
      totalVivHab: string | null;
      createdAt: string | null;
      updatedAt: string | null;
    } | null;
    municipioId: string;
    municipio: {
      __typename: "Municipio";
      id: string;
      nombre: string;
      clave: string;
      entidadId: string;
      cabecera: string | null;
      claveCab: string | null;
      pobMasc: string | null;
      pobFem: string | null;
      pobTotal: string | null;
      totalVivHab: string | null;
      createdAt: string | null;
      updatedAt: string | null;
      nombreEnt: string | null;
      nombreEntAbrev: string | null;
    } | null;
    localidadId: string;
    localidad: {
      __typename: "Localidad";
      id: string;
      nombre: string;
      entidadId: string;
      municipioId: string;
      clave: string;
      ambito: string;
      latitud: number | null;
      longitud: number | null;
      altitud: string | null;
      pobMasc: string | null;
      pobFem: string | null;
      pobTotal: string | null;
      totalVivHab: string | null;
      createdAt: string | null;
      updatedAt: string | null;
      nombreEnt: string | null;
      nombreEntAbrev: string | null;
      nombreMun: string | null;
      claveCarta: string | null;
    } | null;
    latitud: number | null;
    longitud: number | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  organizacionId: string;
  organizacion: {
    __typename: "OrganizacionCafe";
    id: string;
    searchFields: string | null;
    nombre: string;
    figuraJuridica: string | null;
    telefono: string | null;
    email: string | null;
    anoConstitucion: number | null;
    regionId: string;
    region: {
      __typename: "Region";
      id: string;
      nombre: string;
      createdAt: string;
      updatedAt: string;
    } | null;
    direccion: string | null;
    codigoPostal: string | null;
    coloniaId: string;
    colonia: {
      __typename: "CodigoPostal";
      id: string;
      codigo: string;
      asentamiento: string;
      tipoAsent: string;
      String: string | null;
      claveTipoAsent: string | null;
      asentCpcons: string | null;
      zona: string | null;
      entidadId: string;
      municipioId: string;
      ciudad: string | null;
      claveCd: string | null;
      cpOficina: string | null;
      createdAt: string | null;
      updatedAt: string | null;
      nombreMun: string | null;
      nombreEnt: string | null;
      dCP: string | null;
    } | null;
    estadoId: string;
    estado: {
      __typename: "Entidad";
      id: string;
      nombre: string;
      nombreAbrev: string;
      clave: string;
      pobMasc: string | null;
      pobFem: string | null;
      pobTotal: string | null;
      totalVivHab: string | null;
      createdAt: string | null;
      updatedAt: string | null;
    } | null;
    municipioId: string;
    municipio: {
      __typename: "Municipio";
      id: string;
      nombre: string;
      clave: string;
      entidadId: string;
      cabecera: string | null;
      claveCab: string | null;
      pobMasc: string | null;
      pobFem: string | null;
      pobTotal: string | null;
      totalVivHab: string | null;
      createdAt: string | null;
      updatedAt: string | null;
      nombreEnt: string | null;
      nombreEntAbrev: string | null;
    } | null;
    localidadId: string;
    localidad: {
      __typename: "Localidad";
      id: string;
      nombre: string;
      entidadId: string;
      municipioId: string;
      clave: string;
      ambito: string;
      latitud: number | null;
      longitud: number | null;
      altitud: string | null;
      pobMasc: string | null;
      pobFem: string | null;
      pobTotal: string | null;
      totalVivHab: string | null;
      createdAt: string | null;
      updatedAt: string | null;
      nombreEnt: string | null;
      nombreEntAbrev: string | null;
      nombreMun: string | null;
      claveCarta: string | null;
    } | null;
    reprNombre: string | null;
    reprCargo: string | null;
    reprTelefono: string | null;
    reprEmail: string | null;
    createdAt: string | null;
    updatedAt: string | null;
  } | null;
  datosGenerales: {
    __typename: "ICDatosGenerales";
    areasTrabajo: Array<AreasTrabajo | null> | null;
    infraestructura: Array<InfraestructuraOrganizacion | null> | null;
    equiposOficina: Array<EquiposOficina | null> | null;
    certificaciones: Array<Certificaciones | null> | null;
    estatus: Estatus | null;
  } | null;
  produccion: {
    __typename: "ICProduccion";
    superficie: {
      __typename: "Superficie";
      cafeOrganico: number | null;
      cafeTransicion: number | null;
      basicos: number | null;
      potrero: number | null;
      acahual: number | null;
      montania: number | null;
    } | null;
    produccionPromedio: {
      __typename: "ProduccionPromedio";
      estimacion: number | null;
    } | null;
    jornales: {
      __typename: "Jornales";
      primeraLimpia: number | null;
      segundaLimpia: number | null;
      cajete: number | null;
      podaYdeshije: number | null;
      desombre: number | null;
      abonera: number | null;
      regarAbono: number | null;
      resiembra: number | null;
      controlPlagas: number | null;
      conservacionSuelo: number | null;
      semilleroVivero: number | null;
      cosecha: number | null;
      beneficioHumedo: number | null;
      otros: number | null;
      precioPagado: number | null;
    } | null;
    insumos: {
      __typename: "Insumos";
      beauveria: number | null;
      abonoFoliar: number | null;
      calAgricola: number | null;
      costalera: number | null;
      canastos: number | null;
      mantenimientoEquipos: number | null;
      certificaciones: number | null;
      alimentacionPersonal: number | null;
      otros: number | null;
    } | null;
    rendimientoCafetales: RendimientoCafetales | null;
    factoresRendimiento: Array<FactoresRendimiento | null> | null;
    condicionesCafetales: Array<CondicionesCafetales | null> | null;
    variedadesCafe: Array<VariedadesCafe | null> | null;
    edadPlantaciones: EdadPlantaciones | null;
    disenioPlantaciones: DisenioPlantaciones | null;
    densidadPlantaciones: DensidadPlantaciones | null;
    alturaPlantaciones: AlturaPlantaciones | null;
    floracion: RegistroFloracion | null;
  } | null;
  calidad: {
    __typename: "ICCalidad";
    despulpadoraTipos: Array<DespulpadoraTipo | null> | null;
    equipoCondicion: EquipoCondicion | null;
    tanqueTipos: Array<TanqueTipo | null> | null;
    secadoInfraestructuras: Array<SecadoInfraestructura | null> | null;
    cafeCorte: CafeCorte | null;
    fermentacionHora: FermentacionHora | null;
    conocenCalidad: ConocenCalidad | null;
    cafeCalidad: CafeCalidad | null;
  } | null;
  mercado: {
    __typename: "ICMercado";
    mercado: {
      __typename: "Mercado";
      prodAcopiada: number | null;
      cafeExportado: number | null;
      cafeVendidoNacional: number | null;
      cafeVendidoLocal: number | null;
      precioExportacion: number | null;
      precioNacional: number | null;
      precioLocal: number | null;
      sobreprecio: number | null;
      clientesExtranjeros: number | null;
    } | null;
    exportacionMercados: Array<ExportacionMercado | null> | null;
  } | null;
  desarrollo: {
    __typename: "ICDesarrolloCapacidades";
    cursosRecibidos: CursosRecibido | null;
    cursosTipos: Array<CursosTipo | null> | null;
    reforzarProcesos: Array<ReforzarProceso | null> | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type CreatePadronCafetaleroMutation = {
  __typename: "PadronCafetalero";
  id: string;
  type: string | null;
  curp: string | null;
  folioProductorId: string | null;
  nombre: string | null;
  aPaterno: string | null;
  aMaterno: string | null;
  fechaNac: string | null;
  sexo: string | null;
  rfc: string | null;
  calle: string | null;
  numero: string | null;
  colonia: string | null;
  entidadId: string | null;
  nombreEnt: string | null;
  municipioId: string | null;
  nombreMun: string | null;
  localidadId: string | null;
  nombreLoc: string | null;
  folioProdPred: string | null;
  folioPredioId: string | null;
  nombrePredio: string | null;
  superficieCafe: number | null;
  predioEntidadId: string | null;
  predioNombreEnt: string | null;
  predioMunicipioId: string | null;
  predioNombreMun: string | null;
  predioLocalidadId: string | null;
  predioNombreLoc: string | null;
  latitudGrados: string | null;
  longitudGrados: string | null;
  latitud: string | null;
  longitud: string | null;
  searchField: string | null;
  createdAt: string | null;
  updatedAt: string | null;
};

export type UpdatePadronCafetaleroMutation = {
  __typename: "PadronCafetalero";
  id: string;
  type: string | null;
  curp: string | null;
  folioProductorId: string | null;
  nombre: string | null;
  aPaterno: string | null;
  aMaterno: string | null;
  fechaNac: string | null;
  sexo: string | null;
  rfc: string | null;
  calle: string | null;
  numero: string | null;
  colonia: string | null;
  entidadId: string | null;
  nombreEnt: string | null;
  municipioId: string | null;
  nombreMun: string | null;
  localidadId: string | null;
  nombreLoc: string | null;
  folioProdPred: string | null;
  folioPredioId: string | null;
  nombrePredio: string | null;
  superficieCafe: number | null;
  predioEntidadId: string | null;
  predioNombreEnt: string | null;
  predioMunicipioId: string | null;
  predioNombreMun: string | null;
  predioLocalidadId: string | null;
  predioNombreLoc: string | null;
  latitudGrados: string | null;
  longitudGrados: string | null;
  latitud: string | null;
  longitud: string | null;
  searchField: string | null;
  createdAt: string | null;
  updatedAt: string | null;
};

export type DeletePadronCafetaleroMutation = {
  __typename: "PadronCafetalero";
  id: string;
  type: string | null;
  curp: string | null;
  folioProductorId: string | null;
  nombre: string | null;
  aPaterno: string | null;
  aMaterno: string | null;
  fechaNac: string | null;
  sexo: string | null;
  rfc: string | null;
  calle: string | null;
  numero: string | null;
  colonia: string | null;
  entidadId: string | null;
  nombreEnt: string | null;
  municipioId: string | null;
  nombreMun: string | null;
  localidadId: string | null;
  nombreLoc: string | null;
  folioProdPred: string | null;
  folioPredioId: string | null;
  nombrePredio: string | null;
  superficieCafe: number | null;
  predioEntidadId: string | null;
  predioNombreEnt: string | null;
  predioMunicipioId: string | null;
  predioNombreMun: string | null;
  predioLocalidadId: string | null;
  predioNombreLoc: string | null;
  latitudGrados: string | null;
  longitudGrados: string | null;
  latitud: string | null;
  longitud: string | null;
  searchField: string | null;
  createdAt: string | null;
  updatedAt: string | null;
};

export type CreateInfoCicloOrganizacionMutation = {
  __typename: "InfoCicloOrganizacion";
  id: string;
  ciclo: {
    __typename: "Ciclo";
    id: string;
    nombre: string;
    fechaInicio: string;
    fechaFin: string;
    activo: boolean;
    createdAt: string;
    updatedAt: string;
  } | null;
  organizacion: {
    __typename: "OrganizacionCafe";
    id: string;
    searchFields: string | null;
    nombre: string;
    figuraJuridica: string | null;
    telefono: string | null;
    email: string | null;
    anoConstitucion: number | null;
    regionId: string;
    region: {
      __typename: "Region";
      id: string;
      nombre: string;
      createdAt: string;
      updatedAt: string;
    } | null;
    direccion: string | null;
    codigoPostal: string | null;
    coloniaId: string;
    colonia: {
      __typename: "CodigoPostal";
      id: string;
      codigo: string;
      asentamiento: string;
      tipoAsent: string;
      String: string | null;
      claveTipoAsent: string | null;
      asentCpcons: string | null;
      zona: string | null;
      entidadId: string;
      municipioId: string;
      ciudad: string | null;
      claveCd: string | null;
      cpOficina: string | null;
      createdAt: string | null;
      updatedAt: string | null;
      nombreMun: string | null;
      nombreEnt: string | null;
      dCP: string | null;
    } | null;
    estadoId: string;
    estado: {
      __typename: "Entidad";
      id: string;
      nombre: string;
      nombreAbrev: string;
      clave: string;
      pobMasc: string | null;
      pobFem: string | null;
      pobTotal: string | null;
      totalVivHab: string | null;
      createdAt: string | null;
      updatedAt: string | null;
    } | null;
    municipioId: string;
    municipio: {
      __typename: "Municipio";
      id: string;
      nombre: string;
      clave: string;
      entidadId: string;
      cabecera: string | null;
      claveCab: string | null;
      pobMasc: string | null;
      pobFem: string | null;
      pobTotal: string | null;
      totalVivHab: string | null;
      createdAt: string | null;
      updatedAt: string | null;
      nombreEnt: string | null;
      nombreEntAbrev: string | null;
    } | null;
    localidadId: string;
    localidad: {
      __typename: "Localidad";
      id: string;
      nombre: string;
      entidadId: string;
      municipioId: string;
      clave: string;
      ambito: string;
      latitud: number | null;
      longitud: number | null;
      altitud: string | null;
      pobMasc: string | null;
      pobFem: string | null;
      pobTotal: string | null;
      totalVivHab: string | null;
      createdAt: string | null;
      updatedAt: string | null;
      nombreEnt: string | null;
      nombreEntAbrev: string | null;
      nombreMun: string | null;
      claveCarta: string | null;
    } | null;
    reprNombre: string | null;
    reprCargo: string | null;
    reprTelefono: string | null;
    reprEmail: string | null;
    createdAt: string | null;
    updatedAt: string | null;
  } | null;
  cicloId: string;
  organizacionId: string;
  datosGenerales: {
    __typename: "ICODatosGenerales";
    numeroSocios: {
      __typename: "NumeroSocios";
      numHombres: number | null;
      numMujeres: number | null;
    } | null;
    haProduccion: {
      __typename: "HAProduccion";
      cafeOrganico: number | null;
      cafeTransicion: number | null;
      otrosCultivos: number | null;
    } | null;
    haPorcentajes: {
      __typename: "HAPorcentajes";
      haMenosDeMedia: number | null;
      haDeMediaAUna: number | null;
      haDeUnaADos: number | null;
      haDeDosACinco: number | null;
      haDeCincoADiez: number | null;
      haMasDeDiez: number | null;
    } | null;
    areasTrabajo: Array<AreasTrabajoICO | null> | null;
    equiposOficina: Array<EquiposOficinaICO | null> | null;
    certificaciones: Array<CertificacionesICO | null> | null;
    estatus: EstatusICO | null;
  } | null;
  produccion: {
    __typename: "ICOProduccion";
    produccionXproductor: {
      __typename: "ProduccionXProductorICO";
      maximo: number | null;
      promedio: number | null;
      minimo: number | null;
    } | null;
    jornales: {
      __typename: "JornalesICO";
      primeraLimpia: number | null;
      segundaLimpia: number | null;
      cajete: number | null;
      podaYdeshije: number | null;
      desombre: number | null;
      abonera: number | null;
      regarAbono: number | null;
      resiembra: number | null;
      controlPlagas: number | null;
      conservacionSuelo: number | null;
      semilleroVivero: number | null;
      cosecha: number | null;
      beneficioHumedo: number | null;
      otros: number | null;
      precioPagado: number | null;
    } | null;
    insumos: {
      __typename: "InsumosICO";
      beauveria: number | null;
      abonoFoliar: number | null;
      calAgricola: number | null;
      costalera: number | null;
      canastos: number | null;
      mantenimientoEquipos: number | null;
      certificaciones: number | null;
      alimentacionPersonal: number | null;
      otros: number | null;
    } | null;
    variedadesCafe: {
      __typename: "VariedadesCafeICO";
      typica: number | null;
      bourbon: number | null;
      mundoNovo: number | null;
      caturra: number | null;
      catimor: number | null;
      otros: number | null;
    } | null;
    edadPlantaciones: {
      __typename: "EdadPlantacionesICO";
      menosDe5: number | null;
      entre5y15: number | null;
      entre15y30: number | null;
      masDe30: number | null;
    } | null;
    disenioPlantaciones: {
      __typename: "DisenioPlantacionesICO";
      tresbolillo: number | null;
      rectangular: number | null;
      curvasANivel: number | null;
      sinMarcos: number | null;
    } | null;
    densidadPlantaciones: {
      __typename: "DensidadPlantacionesICO";
      menosDe1000: number | null;
      entre1000y1500: number | null;
      masDe1500: number | null;
    } | null;
    alturaPlantaciones: {
      __typename: "AlturaPlantacionesICO";
      menosDe600: number | null;
      entre600y900: number | null;
      entre900y1200: number | null;
      masDe1200: number | null;
    } | null;
    floracion: {
      __typename: "RegistroFloracionICO";
      ninguna: number | null;
      primera: number | null;
      segunda: number | null;
      tercera: number | null;
    } | null;
    rendimientoCafetales: RendimientoCafetalesICO | null;
    factoresRendimiento: Array<FactoresRendimientoICO | null> | null;
    condicionesCafetales: Array<CondicionesCafetalesICO | null> | null;
  } | null;
  calidad: {
    __typename: "ICOCalidad";
    infraestructura: Array<InfraestructuraOrganizacionICO | null> | null;
    despulpadoraTipos: {
      __typename: "DespulpadoraTipoICO";
      cilindro: number | null;
      disco: number | null;
      beneficio: number | null;
    } | null;
    equipoCondicion: {
      __typename: "EquipoCondicionICO";
      mala: number | null;
      regular: number | null;
      buena: number | null;
      excelente: number | null;
    } | null;
    tanqueTipos: {
      __typename: "TanqueTipoICO";
      madera: number | null;
      cemento: number | null;
      tina: number | null;
    } | null;
    secadoInfraestructuras: {
      __typename: "SecadoInfraestructuraICO";
      patio: number | null;
      zarandas: number | null;
      gas: number | null;
      excelente: number | null;
    } | null;
    cafeCorte: {
      __typename: "CafeCorteICO";
      uno: number | null;
      dos: number | null;
      masDeDos: number | null;
    } | null;
    fermentacionHora: {
      __typename: "FermentacionHoraICO";
      menosDe10: number | null;
      entre10y15: number | null;
      entre15y20: number | null;
    } | null;
    conocenCalidad: ConocenCalidadICO | null;
    cafeCalidad: {
      __typename: "CafeCalidadICO";
      mala: number | null;
      regular: number | null;
      buena: number | null;
      excelente: number | null;
    } | null;
  } | null;
  mercado: {
    __typename: "ICOMercado";
    mercado: {
      __typename: "MercadoICO";
      prodAcopiada: number | null;
      cafeExportado: number | null;
      cafeVendidoNacional: number | null;
      cafeVendidoLocal: number | null;
      precioExportacion: number | null;
      precioNacional: number | null;
      precioLocal: number | null;
      sobreprecio: number | null;
      clientesExtranjeros: number | null;
    } | null;
    exportacionMercados: Array<ExportacionMercadoICO | null> | null;
  } | null;
  desarrollo: {
    __typename: "ICODesarrolloCapacidades";
    cursosRecibidos: CursosRecibidosICO | null;
    cursosTipos: Array<CursosTipo | null> | null;
    reforzarProcesos: Array<ReforzarProceso | null> | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateInfoCicloOrganizacionMutation = {
  __typename: "InfoCicloOrganizacion";
  id: string;
  ciclo: {
    __typename: "Ciclo";
    id: string;
    nombre: string;
    fechaInicio: string;
    fechaFin: string;
    activo: boolean;
    createdAt: string;
    updatedAt: string;
  } | null;
  organizacion: {
    __typename: "OrganizacionCafe";
    id: string;
    searchFields: string | null;
    nombre: string;
    figuraJuridica: string | null;
    telefono: string | null;
    email: string | null;
    anoConstitucion: number | null;
    regionId: string;
    region: {
      __typename: "Region";
      id: string;
      nombre: string;
      createdAt: string;
      updatedAt: string;
    } | null;
    direccion: string | null;
    codigoPostal: string | null;
    coloniaId: string;
    colonia: {
      __typename: "CodigoPostal";
      id: string;
      codigo: string;
      asentamiento: string;
      tipoAsent: string;
      String: string | null;
      claveTipoAsent: string | null;
      asentCpcons: string | null;
      zona: string | null;
      entidadId: string;
      municipioId: string;
      ciudad: string | null;
      claveCd: string | null;
      cpOficina: string | null;
      createdAt: string | null;
      updatedAt: string | null;
      nombreMun: string | null;
      nombreEnt: string | null;
      dCP: string | null;
    } | null;
    estadoId: string;
    estado: {
      __typename: "Entidad";
      id: string;
      nombre: string;
      nombreAbrev: string;
      clave: string;
      pobMasc: string | null;
      pobFem: string | null;
      pobTotal: string | null;
      totalVivHab: string | null;
      createdAt: string | null;
      updatedAt: string | null;
    } | null;
    municipioId: string;
    municipio: {
      __typename: "Municipio";
      id: string;
      nombre: string;
      clave: string;
      entidadId: string;
      cabecera: string | null;
      claveCab: string | null;
      pobMasc: string | null;
      pobFem: string | null;
      pobTotal: string | null;
      totalVivHab: string | null;
      createdAt: string | null;
      updatedAt: string | null;
      nombreEnt: string | null;
      nombreEntAbrev: string | null;
    } | null;
    localidadId: string;
    localidad: {
      __typename: "Localidad";
      id: string;
      nombre: string;
      entidadId: string;
      municipioId: string;
      clave: string;
      ambito: string;
      latitud: number | null;
      longitud: number | null;
      altitud: string | null;
      pobMasc: string | null;
      pobFem: string | null;
      pobTotal: string | null;
      totalVivHab: string | null;
      createdAt: string | null;
      updatedAt: string | null;
      nombreEnt: string | null;
      nombreEntAbrev: string | null;
      nombreMun: string | null;
      claveCarta: string | null;
    } | null;
    reprNombre: string | null;
    reprCargo: string | null;
    reprTelefono: string | null;
    reprEmail: string | null;
    createdAt: string | null;
    updatedAt: string | null;
  } | null;
  cicloId: string;
  organizacionId: string;
  datosGenerales: {
    __typename: "ICODatosGenerales";
    numeroSocios: {
      __typename: "NumeroSocios";
      numHombres: number | null;
      numMujeres: number | null;
    } | null;
    haProduccion: {
      __typename: "HAProduccion";
      cafeOrganico: number | null;
      cafeTransicion: number | null;
      otrosCultivos: number | null;
    } | null;
    haPorcentajes: {
      __typename: "HAPorcentajes";
      haMenosDeMedia: number | null;
      haDeMediaAUna: number | null;
      haDeUnaADos: number | null;
      haDeDosACinco: number | null;
      haDeCincoADiez: number | null;
      haMasDeDiez: number | null;
    } | null;
    areasTrabajo: Array<AreasTrabajoICO | null> | null;
    equiposOficina: Array<EquiposOficinaICO | null> | null;
    certificaciones: Array<CertificacionesICO | null> | null;
    estatus: EstatusICO | null;
  } | null;
  produccion: {
    __typename: "ICOProduccion";
    produccionXproductor: {
      __typename: "ProduccionXProductorICO";
      maximo: number | null;
      promedio: number | null;
      minimo: number | null;
    } | null;
    jornales: {
      __typename: "JornalesICO";
      primeraLimpia: number | null;
      segundaLimpia: number | null;
      cajete: number | null;
      podaYdeshije: number | null;
      desombre: number | null;
      abonera: number | null;
      regarAbono: number | null;
      resiembra: number | null;
      controlPlagas: number | null;
      conservacionSuelo: number | null;
      semilleroVivero: number | null;
      cosecha: number | null;
      beneficioHumedo: number | null;
      otros: number | null;
      precioPagado: number | null;
    } | null;
    insumos: {
      __typename: "InsumosICO";
      beauveria: number | null;
      abonoFoliar: number | null;
      calAgricola: number | null;
      costalera: number | null;
      canastos: number | null;
      mantenimientoEquipos: number | null;
      certificaciones: number | null;
      alimentacionPersonal: number | null;
      otros: number | null;
    } | null;
    variedadesCafe: {
      __typename: "VariedadesCafeICO";
      typica: number | null;
      bourbon: number | null;
      mundoNovo: number | null;
      caturra: number | null;
      catimor: number | null;
      otros: number | null;
    } | null;
    edadPlantaciones: {
      __typename: "EdadPlantacionesICO";
      menosDe5: number | null;
      entre5y15: number | null;
      entre15y30: number | null;
      masDe30: number | null;
    } | null;
    disenioPlantaciones: {
      __typename: "DisenioPlantacionesICO";
      tresbolillo: number | null;
      rectangular: number | null;
      curvasANivel: number | null;
      sinMarcos: number | null;
    } | null;
    densidadPlantaciones: {
      __typename: "DensidadPlantacionesICO";
      menosDe1000: number | null;
      entre1000y1500: number | null;
      masDe1500: number | null;
    } | null;
    alturaPlantaciones: {
      __typename: "AlturaPlantacionesICO";
      menosDe600: number | null;
      entre600y900: number | null;
      entre900y1200: number | null;
      masDe1200: number | null;
    } | null;
    floracion: {
      __typename: "RegistroFloracionICO";
      ninguna: number | null;
      primera: number | null;
      segunda: number | null;
      tercera: number | null;
    } | null;
    rendimientoCafetales: RendimientoCafetalesICO | null;
    factoresRendimiento: Array<FactoresRendimientoICO | null> | null;
    condicionesCafetales: Array<CondicionesCafetalesICO | null> | null;
  } | null;
  calidad: {
    __typename: "ICOCalidad";
    infraestructura: Array<InfraestructuraOrganizacionICO | null> | null;
    despulpadoraTipos: {
      __typename: "DespulpadoraTipoICO";
      cilindro: number | null;
      disco: number | null;
      beneficio: number | null;
    } | null;
    equipoCondicion: {
      __typename: "EquipoCondicionICO";
      mala: number | null;
      regular: number | null;
      buena: number | null;
      excelente: number | null;
    } | null;
    tanqueTipos: {
      __typename: "TanqueTipoICO";
      madera: number | null;
      cemento: number | null;
      tina: number | null;
    } | null;
    secadoInfraestructuras: {
      __typename: "SecadoInfraestructuraICO";
      patio: number | null;
      zarandas: number | null;
      gas: number | null;
      excelente: number | null;
    } | null;
    cafeCorte: {
      __typename: "CafeCorteICO";
      uno: number | null;
      dos: number | null;
      masDeDos: number | null;
    } | null;
    fermentacionHora: {
      __typename: "FermentacionHoraICO";
      menosDe10: number | null;
      entre10y15: number | null;
      entre15y20: number | null;
    } | null;
    conocenCalidad: ConocenCalidadICO | null;
    cafeCalidad: {
      __typename: "CafeCalidadICO";
      mala: number | null;
      regular: number | null;
      buena: number | null;
      excelente: number | null;
    } | null;
  } | null;
  mercado: {
    __typename: "ICOMercado";
    mercado: {
      __typename: "MercadoICO";
      prodAcopiada: number | null;
      cafeExportado: number | null;
      cafeVendidoNacional: number | null;
      cafeVendidoLocal: number | null;
      precioExportacion: number | null;
      precioNacional: number | null;
      precioLocal: number | null;
      sobreprecio: number | null;
      clientesExtranjeros: number | null;
    } | null;
    exportacionMercados: Array<ExportacionMercadoICO | null> | null;
  } | null;
  desarrollo: {
    __typename: "ICODesarrolloCapacidades";
    cursosRecibidos: CursosRecibidosICO | null;
    cursosTipos: Array<CursosTipo | null> | null;
    reforzarProcesos: Array<ReforzarProceso | null> | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type DeleteInfoCicloOrganizacionMutation = {
  __typename: "InfoCicloOrganizacion";
  id: string;
  ciclo: {
    __typename: "Ciclo";
    id: string;
    nombre: string;
    fechaInicio: string;
    fechaFin: string;
    activo: boolean;
    createdAt: string;
    updatedAt: string;
  } | null;
  organizacion: {
    __typename: "OrganizacionCafe";
    id: string;
    searchFields: string | null;
    nombre: string;
    figuraJuridica: string | null;
    telefono: string | null;
    email: string | null;
    anoConstitucion: number | null;
    regionId: string;
    region: {
      __typename: "Region";
      id: string;
      nombre: string;
      createdAt: string;
      updatedAt: string;
    } | null;
    direccion: string | null;
    codigoPostal: string | null;
    coloniaId: string;
    colonia: {
      __typename: "CodigoPostal";
      id: string;
      codigo: string;
      asentamiento: string;
      tipoAsent: string;
      String: string | null;
      claveTipoAsent: string | null;
      asentCpcons: string | null;
      zona: string | null;
      entidadId: string;
      municipioId: string;
      ciudad: string | null;
      claveCd: string | null;
      cpOficina: string | null;
      createdAt: string | null;
      updatedAt: string | null;
      nombreMun: string | null;
      nombreEnt: string | null;
      dCP: string | null;
    } | null;
    estadoId: string;
    estado: {
      __typename: "Entidad";
      id: string;
      nombre: string;
      nombreAbrev: string;
      clave: string;
      pobMasc: string | null;
      pobFem: string | null;
      pobTotal: string | null;
      totalVivHab: string | null;
      createdAt: string | null;
      updatedAt: string | null;
    } | null;
    municipioId: string;
    municipio: {
      __typename: "Municipio";
      id: string;
      nombre: string;
      clave: string;
      entidadId: string;
      cabecera: string | null;
      claveCab: string | null;
      pobMasc: string | null;
      pobFem: string | null;
      pobTotal: string | null;
      totalVivHab: string | null;
      createdAt: string | null;
      updatedAt: string | null;
      nombreEnt: string | null;
      nombreEntAbrev: string | null;
    } | null;
    localidadId: string;
    localidad: {
      __typename: "Localidad";
      id: string;
      nombre: string;
      entidadId: string;
      municipioId: string;
      clave: string;
      ambito: string;
      latitud: number | null;
      longitud: number | null;
      altitud: string | null;
      pobMasc: string | null;
      pobFem: string | null;
      pobTotal: string | null;
      totalVivHab: string | null;
      createdAt: string | null;
      updatedAt: string | null;
      nombreEnt: string | null;
      nombreEntAbrev: string | null;
      nombreMun: string | null;
      claveCarta: string | null;
    } | null;
    reprNombre: string | null;
    reprCargo: string | null;
    reprTelefono: string | null;
    reprEmail: string | null;
    createdAt: string | null;
    updatedAt: string | null;
  } | null;
  cicloId: string;
  organizacionId: string;
  datosGenerales: {
    __typename: "ICODatosGenerales";
    numeroSocios: {
      __typename: "NumeroSocios";
      numHombres: number | null;
      numMujeres: number | null;
    } | null;
    haProduccion: {
      __typename: "HAProduccion";
      cafeOrganico: number | null;
      cafeTransicion: number | null;
      otrosCultivos: number | null;
    } | null;
    haPorcentajes: {
      __typename: "HAPorcentajes";
      haMenosDeMedia: number | null;
      haDeMediaAUna: number | null;
      haDeUnaADos: number | null;
      haDeDosACinco: number | null;
      haDeCincoADiez: number | null;
      haMasDeDiez: number | null;
    } | null;
    areasTrabajo: Array<AreasTrabajoICO | null> | null;
    equiposOficina: Array<EquiposOficinaICO | null> | null;
    certificaciones: Array<CertificacionesICO | null> | null;
    estatus: EstatusICO | null;
  } | null;
  produccion: {
    __typename: "ICOProduccion";
    produccionXproductor: {
      __typename: "ProduccionXProductorICO";
      maximo: number | null;
      promedio: number | null;
      minimo: number | null;
    } | null;
    jornales: {
      __typename: "JornalesICO";
      primeraLimpia: number | null;
      segundaLimpia: number | null;
      cajete: number | null;
      podaYdeshije: number | null;
      desombre: number | null;
      abonera: number | null;
      regarAbono: number | null;
      resiembra: number | null;
      controlPlagas: number | null;
      conservacionSuelo: number | null;
      semilleroVivero: number | null;
      cosecha: number | null;
      beneficioHumedo: number | null;
      otros: number | null;
      precioPagado: number | null;
    } | null;
    insumos: {
      __typename: "InsumosICO";
      beauveria: number | null;
      abonoFoliar: number | null;
      calAgricola: number | null;
      costalera: number | null;
      canastos: number | null;
      mantenimientoEquipos: number | null;
      certificaciones: number | null;
      alimentacionPersonal: number | null;
      otros: number | null;
    } | null;
    variedadesCafe: {
      __typename: "VariedadesCafeICO";
      typica: number | null;
      bourbon: number | null;
      mundoNovo: number | null;
      caturra: number | null;
      catimor: number | null;
      otros: number | null;
    } | null;
    edadPlantaciones: {
      __typename: "EdadPlantacionesICO";
      menosDe5: number | null;
      entre5y15: number | null;
      entre15y30: number | null;
      masDe30: number | null;
    } | null;
    disenioPlantaciones: {
      __typename: "DisenioPlantacionesICO";
      tresbolillo: number | null;
      rectangular: number | null;
      curvasANivel: number | null;
      sinMarcos: number | null;
    } | null;
    densidadPlantaciones: {
      __typename: "DensidadPlantacionesICO";
      menosDe1000: number | null;
      entre1000y1500: number | null;
      masDe1500: number | null;
    } | null;
    alturaPlantaciones: {
      __typename: "AlturaPlantacionesICO";
      menosDe600: number | null;
      entre600y900: number | null;
      entre900y1200: number | null;
      masDe1200: number | null;
    } | null;
    floracion: {
      __typename: "RegistroFloracionICO";
      ninguna: number | null;
      primera: number | null;
      segunda: number | null;
      tercera: number | null;
    } | null;
    rendimientoCafetales: RendimientoCafetalesICO | null;
    factoresRendimiento: Array<FactoresRendimientoICO | null> | null;
    condicionesCafetales: Array<CondicionesCafetalesICO | null> | null;
  } | null;
  calidad: {
    __typename: "ICOCalidad";
    infraestructura: Array<InfraestructuraOrganizacionICO | null> | null;
    despulpadoraTipos: {
      __typename: "DespulpadoraTipoICO";
      cilindro: number | null;
      disco: number | null;
      beneficio: number | null;
    } | null;
    equipoCondicion: {
      __typename: "EquipoCondicionICO";
      mala: number | null;
      regular: number | null;
      buena: number | null;
      excelente: number | null;
    } | null;
    tanqueTipos: {
      __typename: "TanqueTipoICO";
      madera: number | null;
      cemento: number | null;
      tina: number | null;
    } | null;
    secadoInfraestructuras: {
      __typename: "SecadoInfraestructuraICO";
      patio: number | null;
      zarandas: number | null;
      gas: number | null;
      excelente: number | null;
    } | null;
    cafeCorte: {
      __typename: "CafeCorteICO";
      uno: number | null;
      dos: number | null;
      masDeDos: number | null;
    } | null;
    fermentacionHora: {
      __typename: "FermentacionHoraICO";
      menosDe10: number | null;
      entre10y15: number | null;
      entre15y20: number | null;
    } | null;
    conocenCalidad: ConocenCalidadICO | null;
    cafeCalidad: {
      __typename: "CafeCalidadICO";
      mala: number | null;
      regular: number | null;
      buena: number | null;
      excelente: number | null;
    } | null;
  } | null;
  mercado: {
    __typename: "ICOMercado";
    mercado: {
      __typename: "MercadoICO";
      prodAcopiada: number | null;
      cafeExportado: number | null;
      cafeVendidoNacional: number | null;
      cafeVendidoLocal: number | null;
      precioExportacion: number | null;
      precioNacional: number | null;
      precioLocal: number | null;
      sobreprecio: number | null;
      clientesExtranjeros: number | null;
    } | null;
    exportacionMercados: Array<ExportacionMercadoICO | null> | null;
  } | null;
  desarrollo: {
    __typename: "ICODesarrolloCapacidades";
    cursosRecibidos: CursosRecibidosICO | null;
    cursosTipos: Array<CursosTipo | null> | null;
    reforzarProcesos: Array<ReforzarProceso | null> | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type CreateUsuarioMutation = {
  __typename: "Usuario";
  id: string;
  email: string;
  nombreCompleto: string;
  fechaNacimiento: string | null;
  createdAt: string;
  updatedAt: string;
  owner: string | null;
};

export type UpdateUsuarioMutation = {
  __typename: "Usuario";
  id: string;
  email: string;
  nombreCompleto: string;
  fechaNacimiento: string | null;
  createdAt: string;
  updatedAt: string;
  owner: string | null;
};

export type DeleteUsuarioMutation = {
  __typename: "Usuario";
  id: string;
  email: string;
  nombreCompleto: string;
  fechaNacimiento: string | null;
  createdAt: string;
  updatedAt: string;
  owner: string | null;
};

export type GetOrganizacionCafeQuery = {
  __typename: "OrganizacionCafe";
  id: string;
  searchFields: string | null;
  nombre: string;
  figuraJuridica: string | null;
  telefono: string | null;
  email: string | null;
  anoConstitucion: number | null;
  regionId: string;
  region: {
    __typename: "Region";
    id: string;
    nombre: string;
    createdAt: string;
    updatedAt: string;
  } | null;
  direccion: string | null;
  codigoPostal: string | null;
  coloniaId: string;
  colonia: {
    __typename: "CodigoPostal";
    id: string;
    codigo: string;
    asentamiento: string;
    tipoAsent: string;
    String: string | null;
    claveTipoAsent: string | null;
    asentCpcons: string | null;
    zona: string | null;
    entidadId: string;
    municipioId: string;
    ciudad: string | null;
    claveCd: string | null;
    cpOficina: string | null;
    createdAt: string | null;
    updatedAt: string | null;
    nombreMun: string | null;
    nombreEnt: string | null;
    dCP: string | null;
  } | null;
  estadoId: string;
  estado: {
    __typename: "Entidad";
    id: string;
    nombre: string;
    nombreAbrev: string;
    clave: string;
    pobMasc: string | null;
    pobFem: string | null;
    pobTotal: string | null;
    totalVivHab: string | null;
    createdAt: string | null;
    updatedAt: string | null;
  } | null;
  municipioId: string;
  municipio: {
    __typename: "Municipio";
    id: string;
    nombre: string;
    clave: string;
    entidadId: string;
    cabecera: string | null;
    claveCab: string | null;
    pobMasc: string | null;
    pobFem: string | null;
    pobTotal: string | null;
    totalVivHab: string | null;
    createdAt: string | null;
    updatedAt: string | null;
    nombreEnt: string | null;
    nombreEntAbrev: string | null;
  } | null;
  localidadId: string;
  localidad: {
    __typename: "Localidad";
    id: string;
    nombre: string;
    entidadId: string;
    municipioId: string;
    clave: string;
    ambito: string;
    latitud: number | null;
    longitud: number | null;
    altitud: string | null;
    pobMasc: string | null;
    pobFem: string | null;
    pobTotal: string | null;
    totalVivHab: string | null;
    createdAt: string | null;
    updatedAt: string | null;
    nombreEnt: string | null;
    nombreEntAbrev: string | null;
    nombreMun: string | null;
    claveCarta: string | null;
  } | null;
  reprNombre: string | null;
  reprCargo: string | null;
  reprTelefono: string | null;
  reprEmail: string | null;
  createdAt: string | null;
  updatedAt: string | null;
};

export type ListOrganizacionCafesQuery = {
  __typename: "ModelOrganizacionCafeConnection";
  items: Array<{
    __typename: "OrganizacionCafe";
    id: string;
    searchFields: string | null;
    nombre: string;
    figuraJuridica: string | null;
    telefono: string | null;
    email: string | null;
    anoConstitucion: number | null;
    regionId: string;
    region: {
      __typename: "Region";
      id: string;
      nombre: string;
      createdAt: string;
      updatedAt: string;
    } | null;
    direccion: string | null;
    codigoPostal: string | null;
    coloniaId: string;
    colonia: {
      __typename: "CodigoPostal";
      id: string;
      codigo: string;
      asentamiento: string;
      tipoAsent: string;
      String: string | null;
      claveTipoAsent: string | null;
      asentCpcons: string | null;
      zona: string | null;
      entidadId: string;
      municipioId: string;
      ciudad: string | null;
      claveCd: string | null;
      cpOficina: string | null;
      createdAt: string | null;
      updatedAt: string | null;
      nombreMun: string | null;
      nombreEnt: string | null;
      dCP: string | null;
    } | null;
    estadoId: string;
    estado: {
      __typename: "Entidad";
      id: string;
      nombre: string;
      nombreAbrev: string;
      clave: string;
      pobMasc: string | null;
      pobFem: string | null;
      pobTotal: string | null;
      totalVivHab: string | null;
      createdAt: string | null;
      updatedAt: string | null;
    } | null;
    municipioId: string;
    municipio: {
      __typename: "Municipio";
      id: string;
      nombre: string;
      clave: string;
      entidadId: string;
      cabecera: string | null;
      claveCab: string | null;
      pobMasc: string | null;
      pobFem: string | null;
      pobTotal: string | null;
      totalVivHab: string | null;
      createdAt: string | null;
      updatedAt: string | null;
      nombreEnt: string | null;
      nombreEntAbrev: string | null;
    } | null;
    localidadId: string;
    localidad: {
      __typename: "Localidad";
      id: string;
      nombre: string;
      entidadId: string;
      municipioId: string;
      clave: string;
      ambito: string;
      latitud: number | null;
      longitud: number | null;
      altitud: string | null;
      pobMasc: string | null;
      pobFem: string | null;
      pobTotal: string | null;
      totalVivHab: string | null;
      createdAt: string | null;
      updatedAt: string | null;
      nombreEnt: string | null;
      nombreEntAbrev: string | null;
      nombreMun: string | null;
      claveCarta: string | null;
    } | null;
    reprNombre: string | null;
    reprCargo: string | null;
    reprTelefono: string | null;
    reprEmail: string | null;
    createdAt: string | null;
    updatedAt: string | null;
  } | null> | null;
  nextToken: string | null;
};

export type GetRegionQuery = {
  __typename: "Region";
  id: string;
  nombre: string;
  createdAt: string;
  updatedAt: string;
};

export type ListRegionsQuery = {
  __typename: "ModelRegionConnection";
  items: Array<{
    __typename: "Region";
    id: string;
    nombre: string;
    createdAt: string;
    updatedAt: string;
  } | null> | null;
  nextToken: string | null;
};

export type GetProductorQuery = {
  __typename: "Productor";
  id: string;
  searchField: string | null;
  userid: string | null;
  username: string | null;
  folioProductorId: string | null;
  organizacioncafeOrganizacionId: string | null;
  Organizacion: {
    __typename: "OrganizacionCafe";
    id: string;
    searchFields: string | null;
    nombre: string;
    figuraJuridica: string | null;
    telefono: string | null;
    email: string | null;
    anoConstitucion: number | null;
    regionId: string;
    region: {
      __typename: "Region";
      id: string;
      nombre: string;
      createdAt: string;
      updatedAt: string;
    } | null;
    direccion: string | null;
    codigoPostal: string | null;
    coloniaId: string;
    colonia: {
      __typename: "CodigoPostal";
      id: string;
      codigo: string;
      asentamiento: string;
      tipoAsent: string;
      String: string | null;
      claveTipoAsent: string | null;
      asentCpcons: string | null;
      zona: string | null;
      entidadId: string;
      municipioId: string;
      ciudad: string | null;
      claveCd: string | null;
      cpOficina: string | null;
      createdAt: string | null;
      updatedAt: string | null;
      nombreMun: string | null;
      nombreEnt: string | null;
      dCP: string | null;
    } | null;
    estadoId: string;
    estado: {
      __typename: "Entidad";
      id: string;
      nombre: string;
      nombreAbrev: string;
      clave: string;
      pobMasc: string | null;
      pobFem: string | null;
      pobTotal: string | null;
      totalVivHab: string | null;
      createdAt: string | null;
      updatedAt: string | null;
    } | null;
    municipioId: string;
    municipio: {
      __typename: "Municipio";
      id: string;
      nombre: string;
      clave: string;
      entidadId: string;
      cabecera: string | null;
      claveCab: string | null;
      pobMasc: string | null;
      pobFem: string | null;
      pobTotal: string | null;
      totalVivHab: string | null;
      createdAt: string | null;
      updatedAt: string | null;
      nombreEnt: string | null;
      nombreEntAbrev: string | null;
    } | null;
    localidadId: string;
    localidad: {
      __typename: "Localidad";
      id: string;
      nombre: string;
      entidadId: string;
      municipioId: string;
      clave: string;
      ambito: string;
      latitud: number | null;
      longitud: number | null;
      altitud: string | null;
      pobMasc: string | null;
      pobFem: string | null;
      pobTotal: string | null;
      totalVivHab: string | null;
      createdAt: string | null;
      updatedAt: string | null;
      nombreEnt: string | null;
      nombreEntAbrev: string | null;
      nombreMun: string | null;
      claveCarta: string | null;
    } | null;
    reprNombre: string | null;
    reprCargo: string | null;
    reprTelefono: string | null;
    reprEmail: string | null;
    createdAt: string | null;
    updatedAt: string | null;
  } | null;
  nombre: string | null;
  aPaterno: string | null;
  aMaterno: string | null;
  fechaNac: string | null;
  sexo: string | null;
  curp: string | null;
  rfc: string | null;
  direccion: string | null;
  codigoPostal: string | null;
  estadoId: string;
  estado: {
    __typename: "Entidad";
    id: string;
    nombre: string;
    nombreAbrev: string;
    clave: string;
    pobMasc: string | null;
    pobFem: string | null;
    pobTotal: string | null;
    totalVivHab: string | null;
    createdAt: string | null;
    updatedAt: string | null;
  } | null;
  municipioId: string;
  municipio: {
    __typename: "Municipio";
    id: string;
    nombre: string;
    clave: string;
    entidadId: string;
    cabecera: string | null;
    claveCab: string | null;
    pobMasc: string | null;
    pobFem: string | null;
    pobTotal: string | null;
    totalVivHab: string | null;
    createdAt: string | null;
    updatedAt: string | null;
    nombreEnt: string | null;
    nombreEntAbrev: string | null;
  } | null;
  localidadId: string;
  localidad: {
    __typename: "Localidad";
    id: string;
    nombre: string;
    entidadId: string;
    municipioId: string;
    clave: string;
    ambito: string;
    latitud: number | null;
    longitud: number | null;
    altitud: string | null;
    pobMasc: string | null;
    pobFem: string | null;
    pobTotal: string | null;
    totalVivHab: string | null;
    createdAt: string | null;
    updatedAt: string | null;
    nombreEnt: string | null;
    nombreEntAbrev: string | null;
    nombreMun: string | null;
    claveCarta: string | null;
  } | null;
  latitud: number | null;
  longitud: number | null;
  createdAt: string;
  updatedAt: string;
};

export type ListProductorsQuery = {
  __typename: "ModelProductorConnection";
  items: Array<{
    __typename: "Productor";
    id: string;
    searchField: string | null;
    userid: string | null;
    username: string | null;
    folioProductorId: string | null;
    organizacioncafeOrganizacionId: string | null;
    Organizacion: {
      __typename: "OrganizacionCafe";
      id: string;
      searchFields: string | null;
      nombre: string;
      figuraJuridica: string | null;
      telefono: string | null;
      email: string | null;
      anoConstitucion: number | null;
      regionId: string;
      direccion: string | null;
      codigoPostal: string | null;
      coloniaId: string;
      estadoId: string;
      municipioId: string;
      localidadId: string;
      reprNombre: string | null;
      reprCargo: string | null;
      reprTelefono: string | null;
      reprEmail: string | null;
      createdAt: string | null;
      updatedAt: string | null;
    } | null;
    nombre: string | null;
    aPaterno: string | null;
    aMaterno: string | null;
    fechaNac: string | null;
    sexo: string | null;
    curp: string | null;
    rfc: string | null;
    direccion: string | null;
    codigoPostal: string | null;
    estadoId: string;
    estado: {
      __typename: "Entidad";
      id: string;
      nombre: string;
      nombreAbrev: string;
      clave: string;
      pobMasc: string | null;
      pobFem: string | null;
      pobTotal: string | null;
      totalVivHab: string | null;
      createdAt: string | null;
      updatedAt: string | null;
    } | null;
    municipioId: string;
    municipio: {
      __typename: "Municipio";
      id: string;
      nombre: string;
      clave: string;
      entidadId: string;
      cabecera: string | null;
      claveCab: string | null;
      pobMasc: string | null;
      pobFem: string | null;
      pobTotal: string | null;
      totalVivHab: string | null;
      createdAt: string | null;
      updatedAt: string | null;
      nombreEnt: string | null;
      nombreEntAbrev: string | null;
    } | null;
    localidadId: string;
    localidad: {
      __typename: "Localidad";
      id: string;
      nombre: string;
      entidadId: string;
      municipioId: string;
      clave: string;
      ambito: string;
      latitud: number | null;
      longitud: number | null;
      altitud: string | null;
      pobMasc: string | null;
      pobFem: string | null;
      pobTotal: string | null;
      totalVivHab: string | null;
      createdAt: string | null;
      updatedAt: string | null;
      nombreEnt: string | null;
      nombreEntAbrev: string | null;
      nombreMun: string | null;
      claveCarta: string | null;
    } | null;
    latitud: number | null;
    longitud: number | null;
    createdAt: string;
    updatedAt: string;
  } | null> | null;
  nextToken: string | null;
};

export type GetEntidadQuery = {
  __typename: "Entidad";
  id: string;
  nombre: string;
  nombreAbrev: string;
  clave: string;
  pobMasc: string | null;
  pobFem: string | null;
  pobTotal: string | null;
  totalVivHab: string | null;
  createdAt: string | null;
  updatedAt: string | null;
};

export type ListEntidadsQuery = {
  __typename: "ModelEntidadConnection";
  items: Array<{
    __typename: "Entidad";
    id: string;
    nombre: string;
    nombreAbrev: string;
    clave: string;
    pobMasc: string | null;
    pobFem: string | null;
    pobTotal: string | null;
    totalVivHab: string | null;
    createdAt: string | null;
    updatedAt: string | null;
  } | null> | null;
  nextToken: string | null;
};

export type GetMunicipioQuery = {
  __typename: "Municipio";
  id: string;
  nombre: string;
  clave: string;
  entidadId: string;
  cabecera: string | null;
  claveCab: string | null;
  pobMasc: string | null;
  pobFem: string | null;
  pobTotal: string | null;
  totalVivHab: string | null;
  createdAt: string | null;
  updatedAt: string | null;
  nombreEnt: string | null;
  nombreEntAbrev: string | null;
};

export type ListMunicipiosQuery = {
  __typename: "ModelMunicipioConnection";
  items: Array<{
    __typename: "Municipio";
    id: string;
    nombre: string;
    clave: string;
    entidadId: string;
    cabecera: string | null;
    claveCab: string | null;
    pobMasc: string | null;
    pobFem: string | null;
    pobTotal: string | null;
    totalVivHab: string | null;
    createdAt: string | null;
    updatedAt: string | null;
    nombreEnt: string | null;
    nombreEntAbrev: string | null;
  } | null> | null;
  nextToken: string | null;
};

export type GetLocalidadQuery = {
  __typename: "Localidad";
  id: string;
  nombre: string;
  entidadId: string;
  municipioId: string;
  clave: string;
  ambito: string;
  latitud: number | null;
  longitud: number | null;
  altitud: string | null;
  pobMasc: string | null;
  pobFem: string | null;
  pobTotal: string | null;
  totalVivHab: string | null;
  createdAt: string | null;
  updatedAt: string | null;
  nombreEnt: string | null;
  nombreEntAbrev: string | null;
  nombreMun: string | null;
  claveCarta: string | null;
};

export type ListLocalidadsQuery = {
  __typename: "ModelLocalidadConnection";
  items: Array<{
    __typename: "Localidad";
    id: string;
    nombre: string;
    entidadId: string;
    municipioId: string;
    clave: string;
    ambito: string;
    latitud: number | null;
    longitud: number | null;
    altitud: string | null;
    pobMasc: string | null;
    pobFem: string | null;
    pobTotal: string | null;
    totalVivHab: string | null;
    createdAt: string | null;
    updatedAt: string | null;
    nombreEnt: string | null;
    nombreEntAbrev: string | null;
    nombreMun: string | null;
    claveCarta: string | null;
  } | null> | null;
  nextToken: string | null;
};

export type GetCodigoPostalQuery = {
  __typename: "CodigoPostal";
  id: string;
  codigo: string;
  asentamiento: string;
  tipoAsent: string;
  String: string | null;
  claveTipoAsent: string | null;
  asentCpcons: string | null;
  zona: string | null;
  entidadId: string;
  municipioId: string;
  ciudad: string | null;
  claveCd: string | null;
  cpOficina: string | null;
  createdAt: string | null;
  updatedAt: string | null;
  nombreMun: string | null;
  nombreEnt: string | null;
  dCP: string | null;
};

export type ListCodigoPostalsQuery = {
  __typename: "ModelCodigoPostalConnection";
  items: Array<{
    __typename: "CodigoPostal";
    id: string;
    codigo: string;
    asentamiento: string;
    tipoAsent: string;
    String: string | null;
    claveTipoAsent: string | null;
    asentCpcons: string | null;
    zona: string | null;
    entidadId: string;
    municipioId: string;
    ciudad: string | null;
    claveCd: string | null;
    cpOficina: string | null;
    createdAt: string | null;
    updatedAt: string | null;
    nombreMun: string | null;
    nombreEnt: string | null;
    dCP: string | null;
  } | null> | null;
  nextToken: string | null;
};

export type GetCicloQuery = {
  __typename: "Ciclo";
  id: string;
  nombre: string;
  fechaInicio: string;
  fechaFin: string;
  activo: boolean;
  createdAt: string;
  updatedAt: string;
};

export type ListCiclosQuery = {
  __typename: "ModelCicloConnection";
  items: Array<{
    __typename: "Ciclo";
    id: string;
    nombre: string;
    fechaInicio: string;
    fechaFin: string;
    activo: boolean;
    createdAt: string;
    updatedAt: string;
  } | null> | null;
  nextToken: string | null;
};

export type GetInfoCicloQuery = {
  __typename: "InfoCiclo";
  id: string;
  cicloId: string;
  ciclo: {
    __typename: "Ciclo";
    id: string;
    nombre: string;
    fechaInicio: string;
    fechaFin: string;
    activo: boolean;
    createdAt: string;
    updatedAt: string;
  } | null;
  productorId: string;
  productor: {
    __typename: "Productor";
    id: string;
    searchField: string | null;
    userid: string | null;
    username: string | null;
    folioProductorId: string | null;
    organizacioncafeOrganizacionId: string | null;
    Organizacion: {
      __typename: "OrganizacionCafe";
      id: string;
      searchFields: string | null;
      nombre: string;
      figuraJuridica: string | null;
      telefono: string | null;
      email: string | null;
      anoConstitucion: number | null;
      regionId: string;
      direccion: string | null;
      codigoPostal: string | null;
      coloniaId: string;
      estadoId: string;
      municipioId: string;
      localidadId: string;
      reprNombre: string | null;
      reprCargo: string | null;
      reprTelefono: string | null;
      reprEmail: string | null;
      createdAt: string | null;
      updatedAt: string | null;
    } | null;
    nombre: string | null;
    aPaterno: string | null;
    aMaterno: string | null;
    fechaNac: string | null;
    sexo: string | null;
    curp: string | null;
    rfc: string | null;
    direccion: string | null;
    codigoPostal: string | null;
    estadoId: string;
    estado: {
      __typename: "Entidad";
      id: string;
      nombre: string;
      nombreAbrev: string;
      clave: string;
      pobMasc: string | null;
      pobFem: string | null;
      pobTotal: string | null;
      totalVivHab: string | null;
      createdAt: string | null;
      updatedAt: string | null;
    } | null;
    municipioId: string;
    municipio: {
      __typename: "Municipio";
      id: string;
      nombre: string;
      clave: string;
      entidadId: string;
      cabecera: string | null;
      claveCab: string | null;
      pobMasc: string | null;
      pobFem: string | null;
      pobTotal: string | null;
      totalVivHab: string | null;
      createdAt: string | null;
      updatedAt: string | null;
      nombreEnt: string | null;
      nombreEntAbrev: string | null;
    } | null;
    localidadId: string;
    localidad: {
      __typename: "Localidad";
      id: string;
      nombre: string;
      entidadId: string;
      municipioId: string;
      clave: string;
      ambito: string;
      latitud: number | null;
      longitud: number | null;
      altitud: string | null;
      pobMasc: string | null;
      pobFem: string | null;
      pobTotal: string | null;
      totalVivHab: string | null;
      createdAt: string | null;
      updatedAt: string | null;
      nombreEnt: string | null;
      nombreEntAbrev: string | null;
      nombreMun: string | null;
      claveCarta: string | null;
    } | null;
    latitud: number | null;
    longitud: number | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  organizacionId: string;
  organizacion: {
    __typename: "OrganizacionCafe";
    id: string;
    searchFields: string | null;
    nombre: string;
    figuraJuridica: string | null;
    telefono: string | null;
    email: string | null;
    anoConstitucion: number | null;
    regionId: string;
    region: {
      __typename: "Region";
      id: string;
      nombre: string;
      createdAt: string;
      updatedAt: string;
    } | null;
    direccion: string | null;
    codigoPostal: string | null;
    coloniaId: string;
    colonia: {
      __typename: "CodigoPostal";
      id: string;
      codigo: string;
      asentamiento: string;
      tipoAsent: string;
      String: string | null;
      claveTipoAsent: string | null;
      asentCpcons: string | null;
      zona: string | null;
      entidadId: string;
      municipioId: string;
      ciudad: string | null;
      claveCd: string | null;
      cpOficina: string | null;
      createdAt: string | null;
      updatedAt: string | null;
      nombreMun: string | null;
      nombreEnt: string | null;
      dCP: string | null;
    } | null;
    estadoId: string;
    estado: {
      __typename: "Entidad";
      id: string;
      nombre: string;
      nombreAbrev: string;
      clave: string;
      pobMasc: string | null;
      pobFem: string | null;
      pobTotal: string | null;
      totalVivHab: string | null;
      createdAt: string | null;
      updatedAt: string | null;
    } | null;
    municipioId: string;
    municipio: {
      __typename: "Municipio";
      id: string;
      nombre: string;
      clave: string;
      entidadId: string;
      cabecera: string | null;
      claveCab: string | null;
      pobMasc: string | null;
      pobFem: string | null;
      pobTotal: string | null;
      totalVivHab: string | null;
      createdAt: string | null;
      updatedAt: string | null;
      nombreEnt: string | null;
      nombreEntAbrev: string | null;
    } | null;
    localidadId: string;
    localidad: {
      __typename: "Localidad";
      id: string;
      nombre: string;
      entidadId: string;
      municipioId: string;
      clave: string;
      ambito: string;
      latitud: number | null;
      longitud: number | null;
      altitud: string | null;
      pobMasc: string | null;
      pobFem: string | null;
      pobTotal: string | null;
      totalVivHab: string | null;
      createdAt: string | null;
      updatedAt: string | null;
      nombreEnt: string | null;
      nombreEntAbrev: string | null;
      nombreMun: string | null;
      claveCarta: string | null;
    } | null;
    reprNombre: string | null;
    reprCargo: string | null;
    reprTelefono: string | null;
    reprEmail: string | null;
    createdAt: string | null;
    updatedAt: string | null;
  } | null;
  datosGenerales: {
    __typename: "ICDatosGenerales";
    areasTrabajo: Array<AreasTrabajo | null> | null;
    infraestructura: Array<InfraestructuraOrganizacion | null> | null;
    equiposOficina: Array<EquiposOficina | null> | null;
    certificaciones: Array<Certificaciones | null> | null;
    estatus: Estatus | null;
  } | null;
  produccion: {
    __typename: "ICProduccion";
    superficie: {
      __typename: "Superficie";
      cafeOrganico: number | null;
      cafeTransicion: number | null;
      basicos: number | null;
      potrero: number | null;
      acahual: number | null;
      montania: number | null;
    } | null;
    produccionPromedio: {
      __typename: "ProduccionPromedio";
      estimacion: number | null;
    } | null;
    jornales: {
      __typename: "Jornales";
      primeraLimpia: number | null;
      segundaLimpia: number | null;
      cajete: number | null;
      podaYdeshije: number | null;
      desombre: number | null;
      abonera: number | null;
      regarAbono: number | null;
      resiembra: number | null;
      controlPlagas: number | null;
      conservacionSuelo: number | null;
      semilleroVivero: number | null;
      cosecha: number | null;
      beneficioHumedo: number | null;
      otros: number | null;
      precioPagado: number | null;
    } | null;
    insumos: {
      __typename: "Insumos";
      beauveria: number | null;
      abonoFoliar: number | null;
      calAgricola: number | null;
      costalera: number | null;
      canastos: number | null;
      mantenimientoEquipos: number | null;
      certificaciones: number | null;
      alimentacionPersonal: number | null;
      otros: number | null;
    } | null;
    rendimientoCafetales: RendimientoCafetales | null;
    factoresRendimiento: Array<FactoresRendimiento | null> | null;
    condicionesCafetales: Array<CondicionesCafetales | null> | null;
    variedadesCafe: Array<VariedadesCafe | null> | null;
    edadPlantaciones: EdadPlantaciones | null;
    disenioPlantaciones: DisenioPlantaciones | null;
    densidadPlantaciones: DensidadPlantaciones | null;
    alturaPlantaciones: AlturaPlantaciones | null;
    floracion: RegistroFloracion | null;
  } | null;
  calidad: {
    __typename: "ICCalidad";
    despulpadoraTipos: Array<DespulpadoraTipo | null> | null;
    equipoCondicion: EquipoCondicion | null;
    tanqueTipos: Array<TanqueTipo | null> | null;
    secadoInfraestructuras: Array<SecadoInfraestructura | null> | null;
    cafeCorte: CafeCorte | null;
    fermentacionHora: FermentacionHora | null;
    conocenCalidad: ConocenCalidad | null;
    cafeCalidad: CafeCalidad | null;
  } | null;
  mercado: {
    __typename: "ICMercado";
    mercado: {
      __typename: "Mercado";
      prodAcopiada: number | null;
      cafeExportado: number | null;
      cafeVendidoNacional: number | null;
      cafeVendidoLocal: number | null;
      precioExportacion: number | null;
      precioNacional: number | null;
      precioLocal: number | null;
      sobreprecio: number | null;
      clientesExtranjeros: number | null;
    } | null;
    exportacionMercados: Array<ExportacionMercado | null> | null;
  } | null;
  desarrollo: {
    __typename: "ICDesarrolloCapacidades";
    cursosRecibidos: CursosRecibido | null;
    cursosTipos: Array<CursosTipo | null> | null;
    reforzarProcesos: Array<ReforzarProceso | null> | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type ListInfoCiclosQuery = {
  __typename: "ModelInfoCicloConnection";
  items: Array<{
    __typename: "InfoCiclo";
    id: string;
    cicloId: string;
    ciclo: {
      __typename: "Ciclo";
      id: string;
      nombre: string;
      fechaInicio: string;
      fechaFin: string;
      activo: boolean;
      createdAt: string;
      updatedAt: string;
    } | null;
    productorId: string;
    productor: {
      __typename: "Productor";
      id: string;
      searchField: string | null;
      userid: string | null;
      username: string | null;
      folioProductorId: string | null;
      organizacioncafeOrganizacionId: string | null;
      nombre: string | null;
      aPaterno: string | null;
      aMaterno: string | null;
      fechaNac: string | null;
      sexo: string | null;
      curp: string | null;
      rfc: string | null;
      direccion: string | null;
      codigoPostal: string | null;
      estadoId: string;
      municipioId: string;
      localidadId: string;
      latitud: number | null;
      longitud: number | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    organizacionId: string;
    organizacion: {
      __typename: "OrganizacionCafe";
      id: string;
      searchFields: string | null;
      nombre: string;
      figuraJuridica: string | null;
      telefono: string | null;
      email: string | null;
      anoConstitucion: number | null;
      regionId: string;
      direccion: string | null;
      codigoPostal: string | null;
      coloniaId: string;
      estadoId: string;
      municipioId: string;
      localidadId: string;
      reprNombre: string | null;
      reprCargo: string | null;
      reprTelefono: string | null;
      reprEmail: string | null;
      createdAt: string | null;
      updatedAt: string | null;
    } | null;
    datosGenerales: {
      __typename: "ICDatosGenerales";
      areasTrabajo: Array<AreasTrabajo | null> | null;
      infraestructura: Array<InfraestructuraOrganizacion | null> | null;
      equiposOficina: Array<EquiposOficina | null> | null;
      certificaciones: Array<Certificaciones | null> | null;
      estatus: Estatus | null;
    } | null;
    produccion: {
      __typename: "ICProduccion";
      rendimientoCafetales: RendimientoCafetales | null;
      factoresRendimiento: Array<FactoresRendimiento | null> | null;
      condicionesCafetales: Array<CondicionesCafetales | null> | null;
      variedadesCafe: Array<VariedadesCafe | null> | null;
      edadPlantaciones: EdadPlantaciones | null;
      disenioPlantaciones: DisenioPlantaciones | null;
      densidadPlantaciones: DensidadPlantaciones | null;
      alturaPlantaciones: AlturaPlantaciones | null;
      floracion: RegistroFloracion | null;
    } | null;
    calidad: {
      __typename: "ICCalidad";
      despulpadoraTipos: Array<DespulpadoraTipo | null> | null;
      equipoCondicion: EquipoCondicion | null;
      tanqueTipos: Array<TanqueTipo | null> | null;
      secadoInfraestructuras: Array<SecadoInfraestructura | null> | null;
      cafeCorte: CafeCorte | null;
      fermentacionHora: FermentacionHora | null;
      conocenCalidad: ConocenCalidad | null;
      cafeCalidad: CafeCalidad | null;
    } | null;
    mercado: {
      __typename: "ICMercado";
      exportacionMercados: Array<ExportacionMercado | null> | null;
    } | null;
    desarrollo: {
      __typename: "ICDesarrolloCapacidades";
      cursosRecibidos: CursosRecibido | null;
      cursosTipos: Array<CursosTipo | null> | null;
      reforzarProcesos: Array<ReforzarProceso | null> | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null> | null;
  nextToken: string | null;
};

export type GetPadronCafetaleroQuery = {
  __typename: "PadronCafetalero";
  id: string;
  type: string | null;
  curp: string | null;
  folioProductorId: string | null;
  nombre: string | null;
  aPaterno: string | null;
  aMaterno: string | null;
  fechaNac: string | null;
  sexo: string | null;
  rfc: string | null;
  calle: string | null;
  numero: string | null;
  colonia: string | null;
  entidadId: string | null;
  nombreEnt: string | null;
  municipioId: string | null;
  nombreMun: string | null;
  localidadId: string | null;
  nombreLoc: string | null;
  folioProdPred: string | null;
  folioPredioId: string | null;
  nombrePredio: string | null;
  superficieCafe: number | null;
  predioEntidadId: string | null;
  predioNombreEnt: string | null;
  predioMunicipioId: string | null;
  predioNombreMun: string | null;
  predioLocalidadId: string | null;
  predioNombreLoc: string | null;
  latitudGrados: string | null;
  longitudGrados: string | null;
  latitud: string | null;
  longitud: string | null;
  searchField: string | null;
  createdAt: string | null;
  updatedAt: string | null;
};

export type ListPadronCafetalerosQuery = {
  __typename: "ModelPadronCafetaleroConnection";
  items: Array<{
    __typename: "PadronCafetalero";
    id: string;
    type: string | null;
    curp: string | null;
    folioProductorId: string | null;
    nombre: string | null;
    aPaterno: string | null;
    aMaterno: string | null;
    fechaNac: string | null;
    sexo: string | null;
    rfc: string | null;
    calle: string | null;
    numero: string | null;
    colonia: string | null;
    entidadId: string | null;
    nombreEnt: string | null;
    municipioId: string | null;
    nombreMun: string | null;
    localidadId: string | null;
    nombreLoc: string | null;
    folioProdPred: string | null;
    folioPredioId: string | null;
    nombrePredio: string | null;
    superficieCafe: number | null;
    predioEntidadId: string | null;
    predioNombreEnt: string | null;
    predioMunicipioId: string | null;
    predioNombreMun: string | null;
    predioLocalidadId: string | null;
    predioNombreLoc: string | null;
    latitudGrados: string | null;
    longitudGrados: string | null;
    latitud: string | null;
    longitud: string | null;
    searchField: string | null;
    createdAt: string | null;
    updatedAt: string | null;
  } | null> | null;
  nextToken: string | null;
};

export type GetInfoCicloOrganizacionQuery = {
  __typename: "InfoCicloOrganizacion";
  id: string;
  ciclo: {
    __typename: "Ciclo";
    id: string;
    nombre: string;
    fechaInicio: string;
    fechaFin: string;
    activo: boolean;
    createdAt: string;
    updatedAt: string;
  } | null;
  organizacion: {
    __typename: "OrganizacionCafe";
    id: string;
    searchFields: string | null;
    nombre: string;
    figuraJuridica: string | null;
    telefono: string | null;
    email: string | null;
    anoConstitucion: number | null;
    regionId: string;
    region: {
      __typename: "Region";
      id: string;
      nombre: string;
      createdAt: string;
      updatedAt: string;
    } | null;
    direccion: string | null;
    codigoPostal: string | null;
    coloniaId: string;
    colonia: {
      __typename: "CodigoPostal";
      id: string;
      codigo: string;
      asentamiento: string;
      tipoAsent: string;
      String: string | null;
      claveTipoAsent: string | null;
      asentCpcons: string | null;
      zona: string | null;
      entidadId: string;
      municipioId: string;
      ciudad: string | null;
      claveCd: string | null;
      cpOficina: string | null;
      createdAt: string | null;
      updatedAt: string | null;
      nombreMun: string | null;
      nombreEnt: string | null;
      dCP: string | null;
    } | null;
    estadoId: string;
    estado: {
      __typename: "Entidad";
      id: string;
      nombre: string;
      nombreAbrev: string;
      clave: string;
      pobMasc: string | null;
      pobFem: string | null;
      pobTotal: string | null;
      totalVivHab: string | null;
      createdAt: string | null;
      updatedAt: string | null;
    } | null;
    municipioId: string;
    municipio: {
      __typename: "Municipio";
      id: string;
      nombre: string;
      clave: string;
      entidadId: string;
      cabecera: string | null;
      claveCab: string | null;
      pobMasc: string | null;
      pobFem: string | null;
      pobTotal: string | null;
      totalVivHab: string | null;
      createdAt: string | null;
      updatedAt: string | null;
      nombreEnt: string | null;
      nombreEntAbrev: string | null;
    } | null;
    localidadId: string;
    localidad: {
      __typename: "Localidad";
      id: string;
      nombre: string;
      entidadId: string;
      municipioId: string;
      clave: string;
      ambito: string;
      latitud: number | null;
      longitud: number | null;
      altitud: string | null;
      pobMasc: string | null;
      pobFem: string | null;
      pobTotal: string | null;
      totalVivHab: string | null;
      createdAt: string | null;
      updatedAt: string | null;
      nombreEnt: string | null;
      nombreEntAbrev: string | null;
      nombreMun: string | null;
      claveCarta: string | null;
    } | null;
    reprNombre: string | null;
    reprCargo: string | null;
    reprTelefono: string | null;
    reprEmail: string | null;
    createdAt: string | null;
    updatedAt: string | null;
  } | null;
  cicloId: string;
  organizacionId: string;
  datosGenerales: {
    __typename: "ICODatosGenerales";
    numeroSocios: {
      __typename: "NumeroSocios";
      numHombres: number | null;
      numMujeres: number | null;
    } | null;
    haProduccion: {
      __typename: "HAProduccion";
      cafeOrganico: number | null;
      cafeTransicion: number | null;
      otrosCultivos: number | null;
    } | null;
    haPorcentajes: {
      __typename: "HAPorcentajes";
      haMenosDeMedia: number | null;
      haDeMediaAUna: number | null;
      haDeUnaADos: number | null;
      haDeDosACinco: number | null;
      haDeCincoADiez: number | null;
      haMasDeDiez: number | null;
    } | null;
    areasTrabajo: Array<AreasTrabajoICO | null> | null;
    equiposOficina: Array<EquiposOficinaICO | null> | null;
    certificaciones: Array<CertificacionesICO | null> | null;
    estatus: EstatusICO | null;
  } | null;
  produccion: {
    __typename: "ICOProduccion";
    produccionXproductor: {
      __typename: "ProduccionXProductorICO";
      maximo: number | null;
      promedio: number | null;
      minimo: number | null;
    } | null;
    jornales: {
      __typename: "JornalesICO";
      primeraLimpia: number | null;
      segundaLimpia: number | null;
      cajete: number | null;
      podaYdeshije: number | null;
      desombre: number | null;
      abonera: number | null;
      regarAbono: number | null;
      resiembra: number | null;
      controlPlagas: number | null;
      conservacionSuelo: number | null;
      semilleroVivero: number | null;
      cosecha: number | null;
      beneficioHumedo: number | null;
      otros: number | null;
      precioPagado: number | null;
    } | null;
    insumos: {
      __typename: "InsumosICO";
      beauveria: number | null;
      abonoFoliar: number | null;
      calAgricola: number | null;
      costalera: number | null;
      canastos: number | null;
      mantenimientoEquipos: number | null;
      certificaciones: number | null;
      alimentacionPersonal: number | null;
      otros: number | null;
    } | null;
    variedadesCafe: {
      __typename: "VariedadesCafeICO";
      typica: number | null;
      bourbon: number | null;
      mundoNovo: number | null;
      caturra: number | null;
      catimor: number | null;
      otros: number | null;
    } | null;
    edadPlantaciones: {
      __typename: "EdadPlantacionesICO";
      menosDe5: number | null;
      entre5y15: number | null;
      entre15y30: number | null;
      masDe30: number | null;
    } | null;
    disenioPlantaciones: {
      __typename: "DisenioPlantacionesICO";
      tresbolillo: number | null;
      rectangular: number | null;
      curvasANivel: number | null;
      sinMarcos: number | null;
    } | null;
    densidadPlantaciones: {
      __typename: "DensidadPlantacionesICO";
      menosDe1000: number | null;
      entre1000y1500: number | null;
      masDe1500: number | null;
    } | null;
    alturaPlantaciones: {
      __typename: "AlturaPlantacionesICO";
      menosDe600: number | null;
      entre600y900: number | null;
      entre900y1200: number | null;
      masDe1200: number | null;
    } | null;
    floracion: {
      __typename: "RegistroFloracionICO";
      ninguna: number | null;
      primera: number | null;
      segunda: number | null;
      tercera: number | null;
    } | null;
    rendimientoCafetales: RendimientoCafetalesICO | null;
    factoresRendimiento: Array<FactoresRendimientoICO | null> | null;
    condicionesCafetales: Array<CondicionesCafetalesICO | null> | null;
  } | null;
  calidad: {
    __typename: "ICOCalidad";
    infraestructura: Array<InfraestructuraOrganizacionICO | null> | null;
    despulpadoraTipos: {
      __typename: "DespulpadoraTipoICO";
      cilindro: number | null;
      disco: number | null;
      beneficio: number | null;
    } | null;
    equipoCondicion: {
      __typename: "EquipoCondicionICO";
      mala: number | null;
      regular: number | null;
      buena: number | null;
      excelente: number | null;
    } | null;
    tanqueTipos: {
      __typename: "TanqueTipoICO";
      madera: number | null;
      cemento: number | null;
      tina: number | null;
    } | null;
    secadoInfraestructuras: {
      __typename: "SecadoInfraestructuraICO";
      patio: number | null;
      zarandas: number | null;
      gas: number | null;
      excelente: number | null;
    } | null;
    cafeCorte: {
      __typename: "CafeCorteICO";
      uno: number | null;
      dos: number | null;
      masDeDos: number | null;
    } | null;
    fermentacionHora: {
      __typename: "FermentacionHoraICO";
      menosDe10: number | null;
      entre10y15: number | null;
      entre15y20: number | null;
    } | null;
    conocenCalidad: ConocenCalidadICO | null;
    cafeCalidad: {
      __typename: "CafeCalidadICO";
      mala: number | null;
      regular: number | null;
      buena: number | null;
      excelente: number | null;
    } | null;
  } | null;
  mercado: {
    __typename: "ICOMercado";
    mercado: {
      __typename: "MercadoICO";
      prodAcopiada: number | null;
      cafeExportado: number | null;
      cafeVendidoNacional: number | null;
      cafeVendidoLocal: number | null;
      precioExportacion: number | null;
      precioNacional: number | null;
      precioLocal: number | null;
      sobreprecio: number | null;
      clientesExtranjeros: number | null;
    } | null;
    exportacionMercados: Array<ExportacionMercadoICO | null> | null;
  } | null;
  desarrollo: {
    __typename: "ICODesarrolloCapacidades";
    cursosRecibidos: CursosRecibidosICO | null;
    cursosTipos: Array<CursosTipo | null> | null;
    reforzarProcesos: Array<ReforzarProceso | null> | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type ListInfoCicloOrganizacionsQuery = {
  __typename: "ModelInfoCicloOrganizacionConnection";
  items: Array<{
    __typename: "InfoCicloOrganizacion";
    id: string;
    ciclo: {
      __typename: "Ciclo";
      id: string;
      nombre: string;
      fechaInicio: string;
      fechaFin: string;
      activo: boolean;
      createdAt: string;
      updatedAt: string;
    } | null;
    organizacion: {
      __typename: "OrganizacionCafe";
      id: string;
      searchFields: string | null;
      nombre: string;
      figuraJuridica: string | null;
      telefono: string | null;
      email: string | null;
      anoConstitucion: number | null;
      regionId: string;
      direccion: string | null;
      codigoPostal: string | null;
      coloniaId: string;
      estadoId: string;
      municipioId: string;
      localidadId: string;
      reprNombre: string | null;
      reprCargo: string | null;
      reprTelefono: string | null;
      reprEmail: string | null;
      createdAt: string | null;
      updatedAt: string | null;
    } | null;
    cicloId: string;
    organizacionId: string;
    datosGenerales: {
      __typename: "ICODatosGenerales";
      areasTrabajo: Array<AreasTrabajoICO | null> | null;
      equiposOficina: Array<EquiposOficinaICO | null> | null;
      certificaciones: Array<CertificacionesICO | null> | null;
      estatus: EstatusICO | null;
    } | null;
    produccion: {
      __typename: "ICOProduccion";
      rendimientoCafetales: RendimientoCafetalesICO | null;
      factoresRendimiento: Array<FactoresRendimientoICO | null> | null;
      condicionesCafetales: Array<CondicionesCafetalesICO | null> | null;
    } | null;
    calidad: {
      __typename: "ICOCalidad";
      infraestructura: Array<InfraestructuraOrganizacionICO | null> | null;
      conocenCalidad: ConocenCalidadICO | null;
    } | null;
    mercado: {
      __typename: "ICOMercado";
      exportacionMercados: Array<ExportacionMercadoICO | null> | null;
    } | null;
    desarrollo: {
      __typename: "ICODesarrolloCapacidades";
      cursosRecibidos: CursosRecibidosICO | null;
      cursosTipos: Array<CursosTipo | null> | null;
      reforzarProcesos: Array<ReforzarProceso | null> | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null> | null;
  nextToken: string | null;
};

export type ProductorByUsernameQuery = {
  __typename: "ModelProductorConnection";
  items: Array<{
    __typename: "Productor";
    id: string;
    searchField: string | null;
    userid: string | null;
    username: string | null;
    folioProductorId: string | null;
    organizacioncafeOrganizacionId: string | null;
    Organizacion: {
      __typename: "OrganizacionCafe";
      id: string;
      searchFields: string | null;
      nombre: string;
      figuraJuridica: string | null;
      telefono: string | null;
      email: string | null;
      anoConstitucion: number | null;
      regionId: string;
      direccion: string | null;
      codigoPostal: string | null;
      coloniaId: string;
      estadoId: string;
      municipioId: string;
      localidadId: string;
      reprNombre: string | null;
      reprCargo: string | null;
      reprTelefono: string | null;
      reprEmail: string | null;
      createdAt: string | null;
      updatedAt: string | null;
    } | null;
    nombre: string | null;
    aPaterno: string | null;
    aMaterno: string | null;
    fechaNac: string | null;
    sexo: string | null;
    curp: string | null;
    rfc: string | null;
    direccion: string | null;
    codigoPostal: string | null;
    estadoId: string;
    estado: {
      __typename: "Entidad";
      id: string;
      nombre: string;
      nombreAbrev: string;
      clave: string;
      pobMasc: string | null;
      pobFem: string | null;
      pobTotal: string | null;
      totalVivHab: string | null;
      createdAt: string | null;
      updatedAt: string | null;
    } | null;
    municipioId: string;
    municipio: {
      __typename: "Municipio";
      id: string;
      nombre: string;
      clave: string;
      entidadId: string;
      cabecera: string | null;
      claveCab: string | null;
      pobMasc: string | null;
      pobFem: string | null;
      pobTotal: string | null;
      totalVivHab: string | null;
      createdAt: string | null;
      updatedAt: string | null;
      nombreEnt: string | null;
      nombreEntAbrev: string | null;
    } | null;
    localidadId: string;
    localidad: {
      __typename: "Localidad";
      id: string;
      nombre: string;
      entidadId: string;
      municipioId: string;
      clave: string;
      ambito: string;
      latitud: number | null;
      longitud: number | null;
      altitud: string | null;
      pobMasc: string | null;
      pobFem: string | null;
      pobTotal: string | null;
      totalVivHab: string | null;
      createdAt: string | null;
      updatedAt: string | null;
      nombreEnt: string | null;
      nombreEntAbrev: string | null;
      nombreMun: string | null;
      claveCarta: string | null;
    } | null;
    latitud: number | null;
    longitud: number | null;
    createdAt: string;
    updatedAt: string;
  } | null> | null;
  nextToken: string | null;
};

export type ProductorByNameQuery = {
  __typename: "ModelProductorConnection";
  items: Array<{
    __typename: "Productor";
    id: string;
    searchField: string | null;
    userid: string | null;
    username: string | null;
    folioProductorId: string | null;
    organizacioncafeOrganizacionId: string | null;
    Organizacion: {
      __typename: "OrganizacionCafe";
      id: string;
      searchFields: string | null;
      nombre: string;
      figuraJuridica: string | null;
      telefono: string | null;
      email: string | null;
      anoConstitucion: number | null;
      regionId: string;
      direccion: string | null;
      codigoPostal: string | null;
      coloniaId: string;
      estadoId: string;
      municipioId: string;
      localidadId: string;
      reprNombre: string | null;
      reprCargo: string | null;
      reprTelefono: string | null;
      reprEmail: string | null;
      createdAt: string | null;
      updatedAt: string | null;
    } | null;
    nombre: string | null;
    aPaterno: string | null;
    aMaterno: string | null;
    fechaNac: string | null;
    sexo: string | null;
    curp: string | null;
    rfc: string | null;
    direccion: string | null;
    codigoPostal: string | null;
    estadoId: string;
    estado: {
      __typename: "Entidad";
      id: string;
      nombre: string;
      nombreAbrev: string;
      clave: string;
      pobMasc: string | null;
      pobFem: string | null;
      pobTotal: string | null;
      totalVivHab: string | null;
      createdAt: string | null;
      updatedAt: string | null;
    } | null;
    municipioId: string;
    municipio: {
      __typename: "Municipio";
      id: string;
      nombre: string;
      clave: string;
      entidadId: string;
      cabecera: string | null;
      claveCab: string | null;
      pobMasc: string | null;
      pobFem: string | null;
      pobTotal: string | null;
      totalVivHab: string | null;
      createdAt: string | null;
      updatedAt: string | null;
      nombreEnt: string | null;
      nombreEntAbrev: string | null;
    } | null;
    localidadId: string;
    localidad: {
      __typename: "Localidad";
      id: string;
      nombre: string;
      entidadId: string;
      municipioId: string;
      clave: string;
      ambito: string;
      latitud: number | null;
      longitud: number | null;
      altitud: string | null;
      pobMasc: string | null;
      pobFem: string | null;
      pobTotal: string | null;
      totalVivHab: string | null;
      createdAt: string | null;
      updatedAt: string | null;
      nombreEnt: string | null;
      nombreEntAbrev: string | null;
      nombreMun: string | null;
      claveCarta: string | null;
    } | null;
    latitud: number | null;
    longitud: number | null;
    createdAt: string;
    updatedAt: string;
  } | null> | null;
  nextToken: string | null;
};

export type MunicipioByEntidadQuery = {
  __typename: "ModelMunicipioConnection";
  items: Array<{
    __typename: "Municipio";
    id: string;
    nombre: string;
    clave: string;
    entidadId: string;
    cabecera: string | null;
    claveCab: string | null;
    pobMasc: string | null;
    pobFem: string | null;
    pobTotal: string | null;
    totalVivHab: string | null;
    createdAt: string | null;
    updatedAt: string | null;
    nombreEnt: string | null;
    nombreEntAbrev: string | null;
  } | null> | null;
  nextToken: string | null;
};

export type LocalidadByEntidadQuery = {
  __typename: "ModelLocalidadConnection";
  items: Array<{
    __typename: "Localidad";
    id: string;
    nombre: string;
    entidadId: string;
    municipioId: string;
    clave: string;
    ambito: string;
    latitud: number | null;
    longitud: number | null;
    altitud: string | null;
    pobMasc: string | null;
    pobFem: string | null;
    pobTotal: string | null;
    totalVivHab: string | null;
    createdAt: string | null;
    updatedAt: string | null;
    nombreEnt: string | null;
    nombreEntAbrev: string | null;
    nombreMun: string | null;
    claveCarta: string | null;
  } | null> | null;
  nextToken: string | null;
};

export type LocalidadByMunicipioQuery = {
  __typename: "ModelLocalidadConnection";
  items: Array<{
    __typename: "Localidad";
    id: string;
    nombre: string;
    entidadId: string;
    municipioId: string;
    clave: string;
    ambito: string;
    latitud: number | null;
    longitud: number | null;
    altitud: string | null;
    pobMasc: string | null;
    pobFem: string | null;
    pobTotal: string | null;
    totalVivHab: string | null;
    createdAt: string | null;
    updatedAt: string | null;
    nombreEnt: string | null;
    nombreEntAbrev: string | null;
    nombreMun: string | null;
    claveCarta: string | null;
  } | null> | null;
  nextToken: string | null;
};

export type LocalidadByEntidadAndMunicipioQuery = {
  __typename: "ModelLocalidadConnection";
  items: Array<{
    __typename: "Localidad";
    id: string;
    nombre: string;
    entidadId: string;
    municipioId: string;
    clave: string;
    ambito: string;
    latitud: number | null;
    longitud: number | null;
    altitud: string | null;
    pobMasc: string | null;
    pobFem: string | null;
    pobTotal: string | null;
    totalVivHab: string | null;
    createdAt: string | null;
    updatedAt: string | null;
    nombreEnt: string | null;
    nombreEntAbrev: string | null;
    nombreMun: string | null;
    claveCarta: string | null;
  } | null> | null;
  nextToken: string | null;
};

export type CpByEntidadQuery = {
  __typename: "ModelCodigoPostalConnection";
  items: Array<{
    __typename: "CodigoPostal";
    id: string;
    codigo: string;
    asentamiento: string;
    tipoAsent: string;
    String: string | null;
    claveTipoAsent: string | null;
    asentCpcons: string | null;
    zona: string | null;
    entidadId: string;
    municipioId: string;
    ciudad: string | null;
    claveCd: string | null;
    cpOficina: string | null;
    createdAt: string | null;
    updatedAt: string | null;
    nombreMun: string | null;
    nombreEnt: string | null;
    dCP: string | null;
  } | null> | null;
  nextToken: string | null;
};

export type CpByMunicipioQuery = {
  __typename: "ModelCodigoPostalConnection";
  items: Array<{
    __typename: "CodigoPostal";
    id: string;
    codigo: string;
    asentamiento: string;
    tipoAsent: string;
    String: string | null;
    claveTipoAsent: string | null;
    asentCpcons: string | null;
    zona: string | null;
    entidadId: string;
    municipioId: string;
    ciudad: string | null;
    claveCd: string | null;
    cpOficina: string | null;
    createdAt: string | null;
    updatedAt: string | null;
    nombreMun: string | null;
    nombreEnt: string | null;
    dCP: string | null;
  } | null> | null;
  nextToken: string | null;
};

export type CpByEntidadAndMunicipioQuery = {
  __typename: "ModelCodigoPostalConnection";
  items: Array<{
    __typename: "CodigoPostal";
    id: string;
    codigo: string;
    asentamiento: string;
    tipoAsent: string;
    String: string | null;
    claveTipoAsent: string | null;
    asentCpcons: string | null;
    zona: string | null;
    entidadId: string;
    municipioId: string;
    ciudad: string | null;
    claveCd: string | null;
    cpOficina: string | null;
    createdAt: string | null;
    updatedAt: string | null;
    nombreMun: string | null;
    nombreEnt: string | null;
    dCP: string | null;
  } | null> | null;
  nextToken: string | null;
};

export type CpByCodigoQuery = {
  __typename: "ModelCodigoPostalConnection";
  items: Array<{
    __typename: "CodigoPostal";
    id: string;
    codigo: string;
    asentamiento: string;
    tipoAsent: string;
    String: string | null;
    claveTipoAsent: string | null;
    asentCpcons: string | null;
    zona: string | null;
    entidadId: string;
    municipioId: string;
    ciudad: string | null;
    claveCd: string | null;
    cpOficina: string | null;
    createdAt: string | null;
    updatedAt: string | null;
    nombreMun: string | null;
    nombreEnt: string | null;
    dCP: string | null;
  } | null> | null;
  nextToken: string | null;
};

export type CicloByNombreQuery = {
  __typename: "ModelCicloConnection";
  items: Array<{
    __typename: "Ciclo";
    id: string;
    nombre: string;
    fechaInicio: string;
    fechaFin: string;
    activo: boolean;
    createdAt: string;
    updatedAt: string;
  } | null> | null;
  nextToken: string | null;
};

export type InfoCiclosByProductorQuery = {
  __typename: "ModelInfoCicloConnection";
  items: Array<{
    __typename: "InfoCiclo";
    id: string;
    cicloId: string;
    ciclo: {
      __typename: "Ciclo";
      id: string;
      nombre: string;
      fechaInicio: string;
      fechaFin: string;
      activo: boolean;
      createdAt: string;
      updatedAt: string;
    } | null;
    productorId: string;
    productor: {
      __typename: "Productor";
      id: string;
      searchField: string | null;
      userid: string | null;
      username: string | null;
      folioProductorId: string | null;
      organizacioncafeOrganizacionId: string | null;
      nombre: string | null;
      aPaterno: string | null;
      aMaterno: string | null;
      fechaNac: string | null;
      sexo: string | null;
      curp: string | null;
      rfc: string | null;
      direccion: string | null;
      codigoPostal: string | null;
      estadoId: string;
      municipioId: string;
      localidadId: string;
      latitud: number | null;
      longitud: number | null;
      createdAt: string;
      updatedAt: string;
    } | null;
    organizacionId: string;
    organizacion: {
      __typename: "OrganizacionCafe";
      id: string;
      searchFields: string | null;
      nombre: string;
      figuraJuridica: string | null;
      telefono: string | null;
      email: string | null;
      anoConstitucion: number | null;
      regionId: string;
      direccion: string | null;
      codigoPostal: string | null;
      coloniaId: string;
      estadoId: string;
      municipioId: string;
      localidadId: string;
      reprNombre: string | null;
      reprCargo: string | null;
      reprTelefono: string | null;
      reprEmail: string | null;
      createdAt: string | null;
      updatedAt: string | null;
    } | null;
    datosGenerales: {
      __typename: "ICDatosGenerales";
      areasTrabajo: Array<AreasTrabajo | null> | null;
      infraestructura: Array<InfraestructuraOrganizacion | null> | null;
      equiposOficina: Array<EquiposOficina | null> | null;
      certificaciones: Array<Certificaciones | null> | null;
      estatus: Estatus | null;
    } | null;
    produccion: {
      __typename: "ICProduccion";
      rendimientoCafetales: RendimientoCafetales | null;
      factoresRendimiento: Array<FactoresRendimiento | null> | null;
      condicionesCafetales: Array<CondicionesCafetales | null> | null;
      variedadesCafe: Array<VariedadesCafe | null> | null;
      edadPlantaciones: EdadPlantaciones | null;
      disenioPlantaciones: DisenioPlantaciones | null;
      densidadPlantaciones: DensidadPlantaciones | null;
      alturaPlantaciones: AlturaPlantaciones | null;
      floracion: RegistroFloracion | null;
    } | null;
    calidad: {
      __typename: "ICCalidad";
      despulpadoraTipos: Array<DespulpadoraTipo | null> | null;
      equipoCondicion: EquipoCondicion | null;
      tanqueTipos: Array<TanqueTipo | null> | null;
      secadoInfraestructuras: Array<SecadoInfraestructura | null> | null;
      cafeCorte: CafeCorte | null;
      fermentacionHora: FermentacionHora | null;
      conocenCalidad: ConocenCalidad | null;
      cafeCalidad: CafeCalidad | null;
    } | null;
    mercado: {
      __typename: "ICMercado";
      exportacionMercados: Array<ExportacionMercado | null> | null;
    } | null;
    desarrollo: {
      __typename: "ICDesarrolloCapacidades";
      cursosRecibidos: CursosRecibido | null;
      cursosTipos: Array<CursosTipo | null> | null;
      reforzarProcesos: Array<ReforzarProceso | null> | null;
    } | null;
    createdAt: string;
    updatedAt: string;
  } | null> | null;
  nextToken: string | null;
};

export type PadronByFolioProductorQuery = {
  __typename: "ModelPadronCafetaleroConnection";
  items: Array<{
    __typename: "PadronCafetalero";
    id: string;
    type: string | null;
    curp: string | null;
    folioProductorId: string | null;
    nombre: string | null;
    aPaterno: string | null;
    aMaterno: string | null;
    fechaNac: string | null;
    sexo: string | null;
    rfc: string | null;
    calle: string | null;
    numero: string | null;
    colonia: string | null;
    entidadId: string | null;
    nombreEnt: string | null;
    municipioId: string | null;
    nombreMun: string | null;
    localidadId: string | null;
    nombreLoc: string | null;
    folioProdPred: string | null;
    folioPredioId: string | null;
    nombrePredio: string | null;
    superficieCafe: number | null;
    predioEntidadId: string | null;
    predioNombreEnt: string | null;
    predioMunicipioId: string | null;
    predioNombreMun: string | null;
    predioLocalidadId: string | null;
    predioNombreLoc: string | null;
    latitudGrados: string | null;
    longitudGrados: string | null;
    latitud: string | null;
    longitud: string | null;
    searchField: string | null;
    createdAt: string | null;
    updatedAt: string | null;
  } | null> | null;
  nextToken: string | null;
};

export type GetUsuarioQuery = {
  __typename: "Usuario";
  id: string;
  email: string;
  nombreCompleto: string;
  fechaNacimiento: string | null;
  createdAt: string;
  updatedAt: string;
  owner: string | null;
};

export type ListUsuariosQuery = {
  __typename: "ModelUsuarioConnection";
  items: Array<{
    __typename: "Usuario";
    id: string;
    email: string;
    nombreCompleto: string;
    fechaNacimiento: string | null;
    createdAt: string;
    updatedAt: string;
    owner: string | null;
  } | null> | null;
  nextToken: string | null;
};

export type OnCreateOrganizacionCafeSubscription = {
  __typename: "OrganizacionCafe";
  id: string;
  searchFields: string | null;
  nombre: string;
  figuraJuridica: string | null;
  telefono: string | null;
  email: string | null;
  anoConstitucion: number | null;
  regionId: string;
  region: {
    __typename: "Region";
    id: string;
    nombre: string;
    createdAt: string;
    updatedAt: string;
  } | null;
  direccion: string | null;
  codigoPostal: string | null;
  coloniaId: string;
  colonia: {
    __typename: "CodigoPostal";
    id: string;
    codigo: string;
    asentamiento: string;
    tipoAsent: string;
    String: string | null;
    claveTipoAsent: string | null;
    asentCpcons: string | null;
    zona: string | null;
    entidadId: string;
    municipioId: string;
    ciudad: string | null;
    claveCd: string | null;
    cpOficina: string | null;
    createdAt: string | null;
    updatedAt: string | null;
    nombreMun: string | null;
    nombreEnt: string | null;
    dCP: string | null;
  } | null;
  estadoId: string;
  estado: {
    __typename: "Entidad";
    id: string;
    nombre: string;
    nombreAbrev: string;
    clave: string;
    pobMasc: string | null;
    pobFem: string | null;
    pobTotal: string | null;
    totalVivHab: string | null;
    createdAt: string | null;
    updatedAt: string | null;
  } | null;
  municipioId: string;
  municipio: {
    __typename: "Municipio";
    id: string;
    nombre: string;
    clave: string;
    entidadId: string;
    cabecera: string | null;
    claveCab: string | null;
    pobMasc: string | null;
    pobFem: string | null;
    pobTotal: string | null;
    totalVivHab: string | null;
    createdAt: string | null;
    updatedAt: string | null;
    nombreEnt: string | null;
    nombreEntAbrev: string | null;
  } | null;
  localidadId: string;
  localidad: {
    __typename: "Localidad";
    id: string;
    nombre: string;
    entidadId: string;
    municipioId: string;
    clave: string;
    ambito: string;
    latitud: number | null;
    longitud: number | null;
    altitud: string | null;
    pobMasc: string | null;
    pobFem: string | null;
    pobTotal: string | null;
    totalVivHab: string | null;
    createdAt: string | null;
    updatedAt: string | null;
    nombreEnt: string | null;
    nombreEntAbrev: string | null;
    nombreMun: string | null;
    claveCarta: string | null;
  } | null;
  reprNombre: string | null;
  reprCargo: string | null;
  reprTelefono: string | null;
  reprEmail: string | null;
  createdAt: string | null;
  updatedAt: string | null;
};

export type OnUpdateOrganizacionCafeSubscription = {
  __typename: "OrganizacionCafe";
  id: string;
  searchFields: string | null;
  nombre: string;
  figuraJuridica: string | null;
  telefono: string | null;
  email: string | null;
  anoConstitucion: number | null;
  regionId: string;
  region: {
    __typename: "Region";
    id: string;
    nombre: string;
    createdAt: string;
    updatedAt: string;
  } | null;
  direccion: string | null;
  codigoPostal: string | null;
  coloniaId: string;
  colonia: {
    __typename: "CodigoPostal";
    id: string;
    codigo: string;
    asentamiento: string;
    tipoAsent: string;
    String: string | null;
    claveTipoAsent: string | null;
    asentCpcons: string | null;
    zona: string | null;
    entidadId: string;
    municipioId: string;
    ciudad: string | null;
    claveCd: string | null;
    cpOficina: string | null;
    createdAt: string | null;
    updatedAt: string | null;
    nombreMun: string | null;
    nombreEnt: string | null;
    dCP: string | null;
  } | null;
  estadoId: string;
  estado: {
    __typename: "Entidad";
    id: string;
    nombre: string;
    nombreAbrev: string;
    clave: string;
    pobMasc: string | null;
    pobFem: string | null;
    pobTotal: string | null;
    totalVivHab: string | null;
    createdAt: string | null;
    updatedAt: string | null;
  } | null;
  municipioId: string;
  municipio: {
    __typename: "Municipio";
    id: string;
    nombre: string;
    clave: string;
    entidadId: string;
    cabecera: string | null;
    claveCab: string | null;
    pobMasc: string | null;
    pobFem: string | null;
    pobTotal: string | null;
    totalVivHab: string | null;
    createdAt: string | null;
    updatedAt: string | null;
    nombreEnt: string | null;
    nombreEntAbrev: string | null;
  } | null;
  localidadId: string;
  localidad: {
    __typename: "Localidad";
    id: string;
    nombre: string;
    entidadId: string;
    municipioId: string;
    clave: string;
    ambito: string;
    latitud: number | null;
    longitud: number | null;
    altitud: string | null;
    pobMasc: string | null;
    pobFem: string | null;
    pobTotal: string | null;
    totalVivHab: string | null;
    createdAt: string | null;
    updatedAt: string | null;
    nombreEnt: string | null;
    nombreEntAbrev: string | null;
    nombreMun: string | null;
    claveCarta: string | null;
  } | null;
  reprNombre: string | null;
  reprCargo: string | null;
  reprTelefono: string | null;
  reprEmail: string | null;
  createdAt: string | null;
  updatedAt: string | null;
};

export type OnDeleteOrganizacionCafeSubscription = {
  __typename: "OrganizacionCafe";
  id: string;
  searchFields: string | null;
  nombre: string;
  figuraJuridica: string | null;
  telefono: string | null;
  email: string | null;
  anoConstitucion: number | null;
  regionId: string;
  region: {
    __typename: "Region";
    id: string;
    nombre: string;
    createdAt: string;
    updatedAt: string;
  } | null;
  direccion: string | null;
  codigoPostal: string | null;
  coloniaId: string;
  colonia: {
    __typename: "CodigoPostal";
    id: string;
    codigo: string;
    asentamiento: string;
    tipoAsent: string;
    String: string | null;
    claveTipoAsent: string | null;
    asentCpcons: string | null;
    zona: string | null;
    entidadId: string;
    municipioId: string;
    ciudad: string | null;
    claveCd: string | null;
    cpOficina: string | null;
    createdAt: string | null;
    updatedAt: string | null;
    nombreMun: string | null;
    nombreEnt: string | null;
    dCP: string | null;
  } | null;
  estadoId: string;
  estado: {
    __typename: "Entidad";
    id: string;
    nombre: string;
    nombreAbrev: string;
    clave: string;
    pobMasc: string | null;
    pobFem: string | null;
    pobTotal: string | null;
    totalVivHab: string | null;
    createdAt: string | null;
    updatedAt: string | null;
  } | null;
  municipioId: string;
  municipio: {
    __typename: "Municipio";
    id: string;
    nombre: string;
    clave: string;
    entidadId: string;
    cabecera: string | null;
    claveCab: string | null;
    pobMasc: string | null;
    pobFem: string | null;
    pobTotal: string | null;
    totalVivHab: string | null;
    createdAt: string | null;
    updatedAt: string | null;
    nombreEnt: string | null;
    nombreEntAbrev: string | null;
  } | null;
  localidadId: string;
  localidad: {
    __typename: "Localidad";
    id: string;
    nombre: string;
    entidadId: string;
    municipioId: string;
    clave: string;
    ambito: string;
    latitud: number | null;
    longitud: number | null;
    altitud: string | null;
    pobMasc: string | null;
    pobFem: string | null;
    pobTotal: string | null;
    totalVivHab: string | null;
    createdAt: string | null;
    updatedAt: string | null;
    nombreEnt: string | null;
    nombreEntAbrev: string | null;
    nombreMun: string | null;
    claveCarta: string | null;
  } | null;
  reprNombre: string | null;
  reprCargo: string | null;
  reprTelefono: string | null;
  reprEmail: string | null;
  createdAt: string | null;
  updatedAt: string | null;
};

export type OnCreateRegionSubscription = {
  __typename: "Region";
  id: string;
  nombre: string;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateRegionSubscription = {
  __typename: "Region";
  id: string;
  nombre: string;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteRegionSubscription = {
  __typename: "Region";
  id: string;
  nombre: string;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateProductorSubscription = {
  __typename: "Productor";
  id: string;
  searchField: string | null;
  userid: string | null;
  username: string | null;
  folioProductorId: string | null;
  organizacioncafeOrganizacionId: string | null;
  Organizacion: {
    __typename: "OrganizacionCafe";
    id: string;
    searchFields: string | null;
    nombre: string;
    figuraJuridica: string | null;
    telefono: string | null;
    email: string | null;
    anoConstitucion: number | null;
    regionId: string;
    region: {
      __typename: "Region";
      id: string;
      nombre: string;
      createdAt: string;
      updatedAt: string;
    } | null;
    direccion: string | null;
    codigoPostal: string | null;
    coloniaId: string;
    colonia: {
      __typename: "CodigoPostal";
      id: string;
      codigo: string;
      asentamiento: string;
      tipoAsent: string;
      String: string | null;
      claveTipoAsent: string | null;
      asentCpcons: string | null;
      zona: string | null;
      entidadId: string;
      municipioId: string;
      ciudad: string | null;
      claveCd: string | null;
      cpOficina: string | null;
      createdAt: string | null;
      updatedAt: string | null;
      nombreMun: string | null;
      nombreEnt: string | null;
      dCP: string | null;
    } | null;
    estadoId: string;
    estado: {
      __typename: "Entidad";
      id: string;
      nombre: string;
      nombreAbrev: string;
      clave: string;
      pobMasc: string | null;
      pobFem: string | null;
      pobTotal: string | null;
      totalVivHab: string | null;
      createdAt: string | null;
      updatedAt: string | null;
    } | null;
    municipioId: string;
    municipio: {
      __typename: "Municipio";
      id: string;
      nombre: string;
      clave: string;
      entidadId: string;
      cabecera: string | null;
      claveCab: string | null;
      pobMasc: string | null;
      pobFem: string | null;
      pobTotal: string | null;
      totalVivHab: string | null;
      createdAt: string | null;
      updatedAt: string | null;
      nombreEnt: string | null;
      nombreEntAbrev: string | null;
    } | null;
    localidadId: string;
    localidad: {
      __typename: "Localidad";
      id: string;
      nombre: string;
      entidadId: string;
      municipioId: string;
      clave: string;
      ambito: string;
      latitud: number | null;
      longitud: number | null;
      altitud: string | null;
      pobMasc: string | null;
      pobFem: string | null;
      pobTotal: string | null;
      totalVivHab: string | null;
      createdAt: string | null;
      updatedAt: string | null;
      nombreEnt: string | null;
      nombreEntAbrev: string | null;
      nombreMun: string | null;
      claveCarta: string | null;
    } | null;
    reprNombre: string | null;
    reprCargo: string | null;
    reprTelefono: string | null;
    reprEmail: string | null;
    createdAt: string | null;
    updatedAt: string | null;
  } | null;
  nombre: string | null;
  aPaterno: string | null;
  aMaterno: string | null;
  fechaNac: string | null;
  sexo: string | null;
  curp: string | null;
  rfc: string | null;
  direccion: string | null;
  codigoPostal: string | null;
  estadoId: string;
  estado: {
    __typename: "Entidad";
    id: string;
    nombre: string;
    nombreAbrev: string;
    clave: string;
    pobMasc: string | null;
    pobFem: string | null;
    pobTotal: string | null;
    totalVivHab: string | null;
    createdAt: string | null;
    updatedAt: string | null;
  } | null;
  municipioId: string;
  municipio: {
    __typename: "Municipio";
    id: string;
    nombre: string;
    clave: string;
    entidadId: string;
    cabecera: string | null;
    claveCab: string | null;
    pobMasc: string | null;
    pobFem: string | null;
    pobTotal: string | null;
    totalVivHab: string | null;
    createdAt: string | null;
    updatedAt: string | null;
    nombreEnt: string | null;
    nombreEntAbrev: string | null;
  } | null;
  localidadId: string;
  localidad: {
    __typename: "Localidad";
    id: string;
    nombre: string;
    entidadId: string;
    municipioId: string;
    clave: string;
    ambito: string;
    latitud: number | null;
    longitud: number | null;
    altitud: string | null;
    pobMasc: string | null;
    pobFem: string | null;
    pobTotal: string | null;
    totalVivHab: string | null;
    createdAt: string | null;
    updatedAt: string | null;
    nombreEnt: string | null;
    nombreEntAbrev: string | null;
    nombreMun: string | null;
    claveCarta: string | null;
  } | null;
  latitud: number | null;
  longitud: number | null;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateProductorSubscription = {
  __typename: "Productor";
  id: string;
  searchField: string | null;
  userid: string | null;
  username: string | null;
  folioProductorId: string | null;
  organizacioncafeOrganizacionId: string | null;
  Organizacion: {
    __typename: "OrganizacionCafe";
    id: string;
    searchFields: string | null;
    nombre: string;
    figuraJuridica: string | null;
    telefono: string | null;
    email: string | null;
    anoConstitucion: number | null;
    regionId: string;
    region: {
      __typename: "Region";
      id: string;
      nombre: string;
      createdAt: string;
      updatedAt: string;
    } | null;
    direccion: string | null;
    codigoPostal: string | null;
    coloniaId: string;
    colonia: {
      __typename: "CodigoPostal";
      id: string;
      codigo: string;
      asentamiento: string;
      tipoAsent: string;
      String: string | null;
      claveTipoAsent: string | null;
      asentCpcons: string | null;
      zona: string | null;
      entidadId: string;
      municipioId: string;
      ciudad: string | null;
      claveCd: string | null;
      cpOficina: string | null;
      createdAt: string | null;
      updatedAt: string | null;
      nombreMun: string | null;
      nombreEnt: string | null;
      dCP: string | null;
    } | null;
    estadoId: string;
    estado: {
      __typename: "Entidad";
      id: string;
      nombre: string;
      nombreAbrev: string;
      clave: string;
      pobMasc: string | null;
      pobFem: string | null;
      pobTotal: string | null;
      totalVivHab: string | null;
      createdAt: string | null;
      updatedAt: string | null;
    } | null;
    municipioId: string;
    municipio: {
      __typename: "Municipio";
      id: string;
      nombre: string;
      clave: string;
      entidadId: string;
      cabecera: string | null;
      claveCab: string | null;
      pobMasc: string | null;
      pobFem: string | null;
      pobTotal: string | null;
      totalVivHab: string | null;
      createdAt: string | null;
      updatedAt: string | null;
      nombreEnt: string | null;
      nombreEntAbrev: string | null;
    } | null;
    localidadId: string;
    localidad: {
      __typename: "Localidad";
      id: string;
      nombre: string;
      entidadId: string;
      municipioId: string;
      clave: string;
      ambito: string;
      latitud: number | null;
      longitud: number | null;
      altitud: string | null;
      pobMasc: string | null;
      pobFem: string | null;
      pobTotal: string | null;
      totalVivHab: string | null;
      createdAt: string | null;
      updatedAt: string | null;
      nombreEnt: string | null;
      nombreEntAbrev: string | null;
      nombreMun: string | null;
      claveCarta: string | null;
    } | null;
    reprNombre: string | null;
    reprCargo: string | null;
    reprTelefono: string | null;
    reprEmail: string | null;
    createdAt: string | null;
    updatedAt: string | null;
  } | null;
  nombre: string | null;
  aPaterno: string | null;
  aMaterno: string | null;
  fechaNac: string | null;
  sexo: string | null;
  curp: string | null;
  rfc: string | null;
  direccion: string | null;
  codigoPostal: string | null;
  estadoId: string;
  estado: {
    __typename: "Entidad";
    id: string;
    nombre: string;
    nombreAbrev: string;
    clave: string;
    pobMasc: string | null;
    pobFem: string | null;
    pobTotal: string | null;
    totalVivHab: string | null;
    createdAt: string | null;
    updatedAt: string | null;
  } | null;
  municipioId: string;
  municipio: {
    __typename: "Municipio";
    id: string;
    nombre: string;
    clave: string;
    entidadId: string;
    cabecera: string | null;
    claveCab: string | null;
    pobMasc: string | null;
    pobFem: string | null;
    pobTotal: string | null;
    totalVivHab: string | null;
    createdAt: string | null;
    updatedAt: string | null;
    nombreEnt: string | null;
    nombreEntAbrev: string | null;
  } | null;
  localidadId: string;
  localidad: {
    __typename: "Localidad";
    id: string;
    nombre: string;
    entidadId: string;
    municipioId: string;
    clave: string;
    ambito: string;
    latitud: number | null;
    longitud: number | null;
    altitud: string | null;
    pobMasc: string | null;
    pobFem: string | null;
    pobTotal: string | null;
    totalVivHab: string | null;
    createdAt: string | null;
    updatedAt: string | null;
    nombreEnt: string | null;
    nombreEntAbrev: string | null;
    nombreMun: string | null;
    claveCarta: string | null;
  } | null;
  latitud: number | null;
  longitud: number | null;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteProductorSubscription = {
  __typename: "Productor";
  id: string;
  searchField: string | null;
  userid: string | null;
  username: string | null;
  folioProductorId: string | null;
  organizacioncafeOrganizacionId: string | null;
  Organizacion: {
    __typename: "OrganizacionCafe";
    id: string;
    searchFields: string | null;
    nombre: string;
    figuraJuridica: string | null;
    telefono: string | null;
    email: string | null;
    anoConstitucion: number | null;
    regionId: string;
    region: {
      __typename: "Region";
      id: string;
      nombre: string;
      createdAt: string;
      updatedAt: string;
    } | null;
    direccion: string | null;
    codigoPostal: string | null;
    coloniaId: string;
    colonia: {
      __typename: "CodigoPostal";
      id: string;
      codigo: string;
      asentamiento: string;
      tipoAsent: string;
      String: string | null;
      claveTipoAsent: string | null;
      asentCpcons: string | null;
      zona: string | null;
      entidadId: string;
      municipioId: string;
      ciudad: string | null;
      claveCd: string | null;
      cpOficina: string | null;
      createdAt: string | null;
      updatedAt: string | null;
      nombreMun: string | null;
      nombreEnt: string | null;
      dCP: string | null;
    } | null;
    estadoId: string;
    estado: {
      __typename: "Entidad";
      id: string;
      nombre: string;
      nombreAbrev: string;
      clave: string;
      pobMasc: string | null;
      pobFem: string | null;
      pobTotal: string | null;
      totalVivHab: string | null;
      createdAt: string | null;
      updatedAt: string | null;
    } | null;
    municipioId: string;
    municipio: {
      __typename: "Municipio";
      id: string;
      nombre: string;
      clave: string;
      entidadId: string;
      cabecera: string | null;
      claveCab: string | null;
      pobMasc: string | null;
      pobFem: string | null;
      pobTotal: string | null;
      totalVivHab: string | null;
      createdAt: string | null;
      updatedAt: string | null;
      nombreEnt: string | null;
      nombreEntAbrev: string | null;
    } | null;
    localidadId: string;
    localidad: {
      __typename: "Localidad";
      id: string;
      nombre: string;
      entidadId: string;
      municipioId: string;
      clave: string;
      ambito: string;
      latitud: number | null;
      longitud: number | null;
      altitud: string | null;
      pobMasc: string | null;
      pobFem: string | null;
      pobTotal: string | null;
      totalVivHab: string | null;
      createdAt: string | null;
      updatedAt: string | null;
      nombreEnt: string | null;
      nombreEntAbrev: string | null;
      nombreMun: string | null;
      claveCarta: string | null;
    } | null;
    reprNombre: string | null;
    reprCargo: string | null;
    reprTelefono: string | null;
    reprEmail: string | null;
    createdAt: string | null;
    updatedAt: string | null;
  } | null;
  nombre: string | null;
  aPaterno: string | null;
  aMaterno: string | null;
  fechaNac: string | null;
  sexo: string | null;
  curp: string | null;
  rfc: string | null;
  direccion: string | null;
  codigoPostal: string | null;
  estadoId: string;
  estado: {
    __typename: "Entidad";
    id: string;
    nombre: string;
    nombreAbrev: string;
    clave: string;
    pobMasc: string | null;
    pobFem: string | null;
    pobTotal: string | null;
    totalVivHab: string | null;
    createdAt: string | null;
    updatedAt: string | null;
  } | null;
  municipioId: string;
  municipio: {
    __typename: "Municipio";
    id: string;
    nombre: string;
    clave: string;
    entidadId: string;
    cabecera: string | null;
    claveCab: string | null;
    pobMasc: string | null;
    pobFem: string | null;
    pobTotal: string | null;
    totalVivHab: string | null;
    createdAt: string | null;
    updatedAt: string | null;
    nombreEnt: string | null;
    nombreEntAbrev: string | null;
  } | null;
  localidadId: string;
  localidad: {
    __typename: "Localidad";
    id: string;
    nombre: string;
    entidadId: string;
    municipioId: string;
    clave: string;
    ambito: string;
    latitud: number | null;
    longitud: number | null;
    altitud: string | null;
    pobMasc: string | null;
    pobFem: string | null;
    pobTotal: string | null;
    totalVivHab: string | null;
    createdAt: string | null;
    updatedAt: string | null;
    nombreEnt: string | null;
    nombreEntAbrev: string | null;
    nombreMun: string | null;
    claveCarta: string | null;
  } | null;
  latitud: number | null;
  longitud: number | null;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateEntidadSubscription = {
  __typename: "Entidad";
  id: string;
  nombre: string;
  nombreAbrev: string;
  clave: string;
  pobMasc: string | null;
  pobFem: string | null;
  pobTotal: string | null;
  totalVivHab: string | null;
  createdAt: string | null;
  updatedAt: string | null;
};

export type OnUpdateEntidadSubscription = {
  __typename: "Entidad";
  id: string;
  nombre: string;
  nombreAbrev: string;
  clave: string;
  pobMasc: string | null;
  pobFem: string | null;
  pobTotal: string | null;
  totalVivHab: string | null;
  createdAt: string | null;
  updatedAt: string | null;
};

export type OnDeleteEntidadSubscription = {
  __typename: "Entidad";
  id: string;
  nombre: string;
  nombreAbrev: string;
  clave: string;
  pobMasc: string | null;
  pobFem: string | null;
  pobTotal: string | null;
  totalVivHab: string | null;
  createdAt: string | null;
  updatedAt: string | null;
};

export type OnCreateMunicipioSubscription = {
  __typename: "Municipio";
  id: string;
  nombre: string;
  clave: string;
  entidadId: string;
  cabecera: string | null;
  claveCab: string | null;
  pobMasc: string | null;
  pobFem: string | null;
  pobTotal: string | null;
  totalVivHab: string | null;
  createdAt: string | null;
  updatedAt: string | null;
  nombreEnt: string | null;
  nombreEntAbrev: string | null;
};

export type OnUpdateMunicipioSubscription = {
  __typename: "Municipio";
  id: string;
  nombre: string;
  clave: string;
  entidadId: string;
  cabecera: string | null;
  claveCab: string | null;
  pobMasc: string | null;
  pobFem: string | null;
  pobTotal: string | null;
  totalVivHab: string | null;
  createdAt: string | null;
  updatedAt: string | null;
  nombreEnt: string | null;
  nombreEntAbrev: string | null;
};

export type OnDeleteMunicipioSubscription = {
  __typename: "Municipio";
  id: string;
  nombre: string;
  clave: string;
  entidadId: string;
  cabecera: string | null;
  claveCab: string | null;
  pobMasc: string | null;
  pobFem: string | null;
  pobTotal: string | null;
  totalVivHab: string | null;
  createdAt: string | null;
  updatedAt: string | null;
  nombreEnt: string | null;
  nombreEntAbrev: string | null;
};

export type OnCreateLocalidadSubscription = {
  __typename: "Localidad";
  id: string;
  nombre: string;
  entidadId: string;
  municipioId: string;
  clave: string;
  ambito: string;
  latitud: number | null;
  longitud: number | null;
  altitud: string | null;
  pobMasc: string | null;
  pobFem: string | null;
  pobTotal: string | null;
  totalVivHab: string | null;
  createdAt: string | null;
  updatedAt: string | null;
  nombreEnt: string | null;
  nombreEntAbrev: string | null;
  nombreMun: string | null;
  claveCarta: string | null;
};

export type OnUpdateLocalidadSubscription = {
  __typename: "Localidad";
  id: string;
  nombre: string;
  entidadId: string;
  municipioId: string;
  clave: string;
  ambito: string;
  latitud: number | null;
  longitud: number | null;
  altitud: string | null;
  pobMasc: string | null;
  pobFem: string | null;
  pobTotal: string | null;
  totalVivHab: string | null;
  createdAt: string | null;
  updatedAt: string | null;
  nombreEnt: string | null;
  nombreEntAbrev: string | null;
  nombreMun: string | null;
  claveCarta: string | null;
};

export type OnDeleteLocalidadSubscription = {
  __typename: "Localidad";
  id: string;
  nombre: string;
  entidadId: string;
  municipioId: string;
  clave: string;
  ambito: string;
  latitud: number | null;
  longitud: number | null;
  altitud: string | null;
  pobMasc: string | null;
  pobFem: string | null;
  pobTotal: string | null;
  totalVivHab: string | null;
  createdAt: string | null;
  updatedAt: string | null;
  nombreEnt: string | null;
  nombreEntAbrev: string | null;
  nombreMun: string | null;
  claveCarta: string | null;
};

export type OnCreateCodigoPostalSubscription = {
  __typename: "CodigoPostal";
  id: string;
  codigo: string;
  asentamiento: string;
  tipoAsent: string;
  String: string | null;
  claveTipoAsent: string | null;
  asentCpcons: string | null;
  zona: string | null;
  entidadId: string;
  municipioId: string;
  ciudad: string | null;
  claveCd: string | null;
  cpOficina: string | null;
  createdAt: string | null;
  updatedAt: string | null;
  nombreMun: string | null;
  nombreEnt: string | null;
  dCP: string | null;
};

export type OnUpdateCodigoPostalSubscription = {
  __typename: "CodigoPostal";
  id: string;
  codigo: string;
  asentamiento: string;
  tipoAsent: string;
  String: string | null;
  claveTipoAsent: string | null;
  asentCpcons: string | null;
  zona: string | null;
  entidadId: string;
  municipioId: string;
  ciudad: string | null;
  claveCd: string | null;
  cpOficina: string | null;
  createdAt: string | null;
  updatedAt: string | null;
  nombreMun: string | null;
  nombreEnt: string | null;
  dCP: string | null;
};

export type OnDeleteCodigoPostalSubscription = {
  __typename: "CodigoPostal";
  id: string;
  codigo: string;
  asentamiento: string;
  tipoAsent: string;
  String: string | null;
  claveTipoAsent: string | null;
  asentCpcons: string | null;
  zona: string | null;
  entidadId: string;
  municipioId: string;
  ciudad: string | null;
  claveCd: string | null;
  cpOficina: string | null;
  createdAt: string | null;
  updatedAt: string | null;
  nombreMun: string | null;
  nombreEnt: string | null;
  dCP: string | null;
};

export type OnCreateCicloSubscription = {
  __typename: "Ciclo";
  id: string;
  nombre: string;
  fechaInicio: string;
  fechaFin: string;
  activo: boolean;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateCicloSubscription = {
  __typename: "Ciclo";
  id: string;
  nombre: string;
  fechaInicio: string;
  fechaFin: string;
  activo: boolean;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteCicloSubscription = {
  __typename: "Ciclo";
  id: string;
  nombre: string;
  fechaInicio: string;
  fechaFin: string;
  activo: boolean;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateInfoCicloSubscription = {
  __typename: "InfoCiclo";
  id: string;
  cicloId: string;
  ciclo: {
    __typename: "Ciclo";
    id: string;
    nombre: string;
    fechaInicio: string;
    fechaFin: string;
    activo: boolean;
    createdAt: string;
    updatedAt: string;
  } | null;
  productorId: string;
  productor: {
    __typename: "Productor";
    id: string;
    searchField: string | null;
    userid: string | null;
    username: string | null;
    folioProductorId: string | null;
    organizacioncafeOrganizacionId: string | null;
    Organizacion: {
      __typename: "OrganizacionCafe";
      id: string;
      searchFields: string | null;
      nombre: string;
      figuraJuridica: string | null;
      telefono: string | null;
      email: string | null;
      anoConstitucion: number | null;
      regionId: string;
      direccion: string | null;
      codigoPostal: string | null;
      coloniaId: string;
      estadoId: string;
      municipioId: string;
      localidadId: string;
      reprNombre: string | null;
      reprCargo: string | null;
      reprTelefono: string | null;
      reprEmail: string | null;
      createdAt: string | null;
      updatedAt: string | null;
    } | null;
    nombre: string | null;
    aPaterno: string | null;
    aMaterno: string | null;
    fechaNac: string | null;
    sexo: string | null;
    curp: string | null;
    rfc: string | null;
    direccion: string | null;
    codigoPostal: string | null;
    estadoId: string;
    estado: {
      __typename: "Entidad";
      id: string;
      nombre: string;
      nombreAbrev: string;
      clave: string;
      pobMasc: string | null;
      pobFem: string | null;
      pobTotal: string | null;
      totalVivHab: string | null;
      createdAt: string | null;
      updatedAt: string | null;
    } | null;
    municipioId: string;
    municipio: {
      __typename: "Municipio";
      id: string;
      nombre: string;
      clave: string;
      entidadId: string;
      cabecera: string | null;
      claveCab: string | null;
      pobMasc: string | null;
      pobFem: string | null;
      pobTotal: string | null;
      totalVivHab: string | null;
      createdAt: string | null;
      updatedAt: string | null;
      nombreEnt: string | null;
      nombreEntAbrev: string | null;
    } | null;
    localidadId: string;
    localidad: {
      __typename: "Localidad";
      id: string;
      nombre: string;
      entidadId: string;
      municipioId: string;
      clave: string;
      ambito: string;
      latitud: number | null;
      longitud: number | null;
      altitud: string | null;
      pobMasc: string | null;
      pobFem: string | null;
      pobTotal: string | null;
      totalVivHab: string | null;
      createdAt: string | null;
      updatedAt: string | null;
      nombreEnt: string | null;
      nombreEntAbrev: string | null;
      nombreMun: string | null;
      claveCarta: string | null;
    } | null;
    latitud: number | null;
    longitud: number | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  organizacionId: string;
  organizacion: {
    __typename: "OrganizacionCafe";
    id: string;
    searchFields: string | null;
    nombre: string;
    figuraJuridica: string | null;
    telefono: string | null;
    email: string | null;
    anoConstitucion: number | null;
    regionId: string;
    region: {
      __typename: "Region";
      id: string;
      nombre: string;
      createdAt: string;
      updatedAt: string;
    } | null;
    direccion: string | null;
    codigoPostal: string | null;
    coloniaId: string;
    colonia: {
      __typename: "CodigoPostal";
      id: string;
      codigo: string;
      asentamiento: string;
      tipoAsent: string;
      String: string | null;
      claveTipoAsent: string | null;
      asentCpcons: string | null;
      zona: string | null;
      entidadId: string;
      municipioId: string;
      ciudad: string | null;
      claveCd: string | null;
      cpOficina: string | null;
      createdAt: string | null;
      updatedAt: string | null;
      nombreMun: string | null;
      nombreEnt: string | null;
      dCP: string | null;
    } | null;
    estadoId: string;
    estado: {
      __typename: "Entidad";
      id: string;
      nombre: string;
      nombreAbrev: string;
      clave: string;
      pobMasc: string | null;
      pobFem: string | null;
      pobTotal: string | null;
      totalVivHab: string | null;
      createdAt: string | null;
      updatedAt: string | null;
    } | null;
    municipioId: string;
    municipio: {
      __typename: "Municipio";
      id: string;
      nombre: string;
      clave: string;
      entidadId: string;
      cabecera: string | null;
      claveCab: string | null;
      pobMasc: string | null;
      pobFem: string | null;
      pobTotal: string | null;
      totalVivHab: string | null;
      createdAt: string | null;
      updatedAt: string | null;
      nombreEnt: string | null;
      nombreEntAbrev: string | null;
    } | null;
    localidadId: string;
    localidad: {
      __typename: "Localidad";
      id: string;
      nombre: string;
      entidadId: string;
      municipioId: string;
      clave: string;
      ambito: string;
      latitud: number | null;
      longitud: number | null;
      altitud: string | null;
      pobMasc: string | null;
      pobFem: string | null;
      pobTotal: string | null;
      totalVivHab: string | null;
      createdAt: string | null;
      updatedAt: string | null;
      nombreEnt: string | null;
      nombreEntAbrev: string | null;
      nombreMun: string | null;
      claveCarta: string | null;
    } | null;
    reprNombre: string | null;
    reprCargo: string | null;
    reprTelefono: string | null;
    reprEmail: string | null;
    createdAt: string | null;
    updatedAt: string | null;
  } | null;
  datosGenerales: {
    __typename: "ICDatosGenerales";
    areasTrabajo: Array<AreasTrabajo | null> | null;
    infraestructura: Array<InfraestructuraOrganizacion | null> | null;
    equiposOficina: Array<EquiposOficina | null> | null;
    certificaciones: Array<Certificaciones | null> | null;
    estatus: Estatus | null;
  } | null;
  produccion: {
    __typename: "ICProduccion";
    superficie: {
      __typename: "Superficie";
      cafeOrganico: number | null;
      cafeTransicion: number | null;
      basicos: number | null;
      potrero: number | null;
      acahual: number | null;
      montania: number | null;
    } | null;
    produccionPromedio: {
      __typename: "ProduccionPromedio";
      estimacion: number | null;
    } | null;
    jornales: {
      __typename: "Jornales";
      primeraLimpia: number | null;
      segundaLimpia: number | null;
      cajete: number | null;
      podaYdeshije: number | null;
      desombre: number | null;
      abonera: number | null;
      regarAbono: number | null;
      resiembra: number | null;
      controlPlagas: number | null;
      conservacionSuelo: number | null;
      semilleroVivero: number | null;
      cosecha: number | null;
      beneficioHumedo: number | null;
      otros: number | null;
      precioPagado: number | null;
    } | null;
    insumos: {
      __typename: "Insumos";
      beauveria: number | null;
      abonoFoliar: number | null;
      calAgricola: number | null;
      costalera: number | null;
      canastos: number | null;
      mantenimientoEquipos: number | null;
      certificaciones: number | null;
      alimentacionPersonal: number | null;
      otros: number | null;
    } | null;
    rendimientoCafetales: RendimientoCafetales | null;
    factoresRendimiento: Array<FactoresRendimiento | null> | null;
    condicionesCafetales: Array<CondicionesCafetales | null> | null;
    variedadesCafe: Array<VariedadesCafe | null> | null;
    edadPlantaciones: EdadPlantaciones | null;
    disenioPlantaciones: DisenioPlantaciones | null;
    densidadPlantaciones: DensidadPlantaciones | null;
    alturaPlantaciones: AlturaPlantaciones | null;
    floracion: RegistroFloracion | null;
  } | null;
  calidad: {
    __typename: "ICCalidad";
    despulpadoraTipos: Array<DespulpadoraTipo | null> | null;
    equipoCondicion: EquipoCondicion | null;
    tanqueTipos: Array<TanqueTipo | null> | null;
    secadoInfraestructuras: Array<SecadoInfraestructura | null> | null;
    cafeCorte: CafeCorte | null;
    fermentacionHora: FermentacionHora | null;
    conocenCalidad: ConocenCalidad | null;
    cafeCalidad: CafeCalidad | null;
  } | null;
  mercado: {
    __typename: "ICMercado";
    mercado: {
      __typename: "Mercado";
      prodAcopiada: number | null;
      cafeExportado: number | null;
      cafeVendidoNacional: number | null;
      cafeVendidoLocal: number | null;
      precioExportacion: number | null;
      precioNacional: number | null;
      precioLocal: number | null;
      sobreprecio: number | null;
      clientesExtranjeros: number | null;
    } | null;
    exportacionMercados: Array<ExportacionMercado | null> | null;
  } | null;
  desarrollo: {
    __typename: "ICDesarrolloCapacidades";
    cursosRecibidos: CursosRecibido | null;
    cursosTipos: Array<CursosTipo | null> | null;
    reforzarProcesos: Array<ReforzarProceso | null> | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateInfoCicloSubscription = {
  __typename: "InfoCiclo";
  id: string;
  cicloId: string;
  ciclo: {
    __typename: "Ciclo";
    id: string;
    nombre: string;
    fechaInicio: string;
    fechaFin: string;
    activo: boolean;
    createdAt: string;
    updatedAt: string;
  } | null;
  productorId: string;
  productor: {
    __typename: "Productor";
    id: string;
    searchField: string | null;
    userid: string | null;
    username: string | null;
    folioProductorId: string | null;
    organizacioncafeOrganizacionId: string | null;
    Organizacion: {
      __typename: "OrganizacionCafe";
      id: string;
      searchFields: string | null;
      nombre: string;
      figuraJuridica: string | null;
      telefono: string | null;
      email: string | null;
      anoConstitucion: number | null;
      regionId: string;
      direccion: string | null;
      codigoPostal: string | null;
      coloniaId: string;
      estadoId: string;
      municipioId: string;
      localidadId: string;
      reprNombre: string | null;
      reprCargo: string | null;
      reprTelefono: string | null;
      reprEmail: string | null;
      createdAt: string | null;
      updatedAt: string | null;
    } | null;
    nombre: string | null;
    aPaterno: string | null;
    aMaterno: string | null;
    fechaNac: string | null;
    sexo: string | null;
    curp: string | null;
    rfc: string | null;
    direccion: string | null;
    codigoPostal: string | null;
    estadoId: string;
    estado: {
      __typename: "Entidad";
      id: string;
      nombre: string;
      nombreAbrev: string;
      clave: string;
      pobMasc: string | null;
      pobFem: string | null;
      pobTotal: string | null;
      totalVivHab: string | null;
      createdAt: string | null;
      updatedAt: string | null;
    } | null;
    municipioId: string;
    municipio: {
      __typename: "Municipio";
      id: string;
      nombre: string;
      clave: string;
      entidadId: string;
      cabecera: string | null;
      claveCab: string | null;
      pobMasc: string | null;
      pobFem: string | null;
      pobTotal: string | null;
      totalVivHab: string | null;
      createdAt: string | null;
      updatedAt: string | null;
      nombreEnt: string | null;
      nombreEntAbrev: string | null;
    } | null;
    localidadId: string;
    localidad: {
      __typename: "Localidad";
      id: string;
      nombre: string;
      entidadId: string;
      municipioId: string;
      clave: string;
      ambito: string;
      latitud: number | null;
      longitud: number | null;
      altitud: string | null;
      pobMasc: string | null;
      pobFem: string | null;
      pobTotal: string | null;
      totalVivHab: string | null;
      createdAt: string | null;
      updatedAt: string | null;
      nombreEnt: string | null;
      nombreEntAbrev: string | null;
      nombreMun: string | null;
      claveCarta: string | null;
    } | null;
    latitud: number | null;
    longitud: number | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  organizacionId: string;
  organizacion: {
    __typename: "OrganizacionCafe";
    id: string;
    searchFields: string | null;
    nombre: string;
    figuraJuridica: string | null;
    telefono: string | null;
    email: string | null;
    anoConstitucion: number | null;
    regionId: string;
    region: {
      __typename: "Region";
      id: string;
      nombre: string;
      createdAt: string;
      updatedAt: string;
    } | null;
    direccion: string | null;
    codigoPostal: string | null;
    coloniaId: string;
    colonia: {
      __typename: "CodigoPostal";
      id: string;
      codigo: string;
      asentamiento: string;
      tipoAsent: string;
      String: string | null;
      claveTipoAsent: string | null;
      asentCpcons: string | null;
      zona: string | null;
      entidadId: string;
      municipioId: string;
      ciudad: string | null;
      claveCd: string | null;
      cpOficina: string | null;
      createdAt: string | null;
      updatedAt: string | null;
      nombreMun: string | null;
      nombreEnt: string | null;
      dCP: string | null;
    } | null;
    estadoId: string;
    estado: {
      __typename: "Entidad";
      id: string;
      nombre: string;
      nombreAbrev: string;
      clave: string;
      pobMasc: string | null;
      pobFem: string | null;
      pobTotal: string | null;
      totalVivHab: string | null;
      createdAt: string | null;
      updatedAt: string | null;
    } | null;
    municipioId: string;
    municipio: {
      __typename: "Municipio";
      id: string;
      nombre: string;
      clave: string;
      entidadId: string;
      cabecera: string | null;
      claveCab: string | null;
      pobMasc: string | null;
      pobFem: string | null;
      pobTotal: string | null;
      totalVivHab: string | null;
      createdAt: string | null;
      updatedAt: string | null;
      nombreEnt: string | null;
      nombreEntAbrev: string | null;
    } | null;
    localidadId: string;
    localidad: {
      __typename: "Localidad";
      id: string;
      nombre: string;
      entidadId: string;
      municipioId: string;
      clave: string;
      ambito: string;
      latitud: number | null;
      longitud: number | null;
      altitud: string | null;
      pobMasc: string | null;
      pobFem: string | null;
      pobTotal: string | null;
      totalVivHab: string | null;
      createdAt: string | null;
      updatedAt: string | null;
      nombreEnt: string | null;
      nombreEntAbrev: string | null;
      nombreMun: string | null;
      claveCarta: string | null;
    } | null;
    reprNombre: string | null;
    reprCargo: string | null;
    reprTelefono: string | null;
    reprEmail: string | null;
    createdAt: string | null;
    updatedAt: string | null;
  } | null;
  datosGenerales: {
    __typename: "ICDatosGenerales";
    areasTrabajo: Array<AreasTrabajo | null> | null;
    infraestructura: Array<InfraestructuraOrganizacion | null> | null;
    equiposOficina: Array<EquiposOficina | null> | null;
    certificaciones: Array<Certificaciones | null> | null;
    estatus: Estatus | null;
  } | null;
  produccion: {
    __typename: "ICProduccion";
    superficie: {
      __typename: "Superficie";
      cafeOrganico: number | null;
      cafeTransicion: number | null;
      basicos: number | null;
      potrero: number | null;
      acahual: number | null;
      montania: number | null;
    } | null;
    produccionPromedio: {
      __typename: "ProduccionPromedio";
      estimacion: number | null;
    } | null;
    jornales: {
      __typename: "Jornales";
      primeraLimpia: number | null;
      segundaLimpia: number | null;
      cajete: number | null;
      podaYdeshije: number | null;
      desombre: number | null;
      abonera: number | null;
      regarAbono: number | null;
      resiembra: number | null;
      controlPlagas: number | null;
      conservacionSuelo: number | null;
      semilleroVivero: number | null;
      cosecha: number | null;
      beneficioHumedo: number | null;
      otros: number | null;
      precioPagado: number | null;
    } | null;
    insumos: {
      __typename: "Insumos";
      beauveria: number | null;
      abonoFoliar: number | null;
      calAgricola: number | null;
      costalera: number | null;
      canastos: number | null;
      mantenimientoEquipos: number | null;
      certificaciones: number | null;
      alimentacionPersonal: number | null;
      otros: number | null;
    } | null;
    rendimientoCafetales: RendimientoCafetales | null;
    factoresRendimiento: Array<FactoresRendimiento | null> | null;
    condicionesCafetales: Array<CondicionesCafetales | null> | null;
    variedadesCafe: Array<VariedadesCafe | null> | null;
    edadPlantaciones: EdadPlantaciones | null;
    disenioPlantaciones: DisenioPlantaciones | null;
    densidadPlantaciones: DensidadPlantaciones | null;
    alturaPlantaciones: AlturaPlantaciones | null;
    floracion: RegistroFloracion | null;
  } | null;
  calidad: {
    __typename: "ICCalidad";
    despulpadoraTipos: Array<DespulpadoraTipo | null> | null;
    equipoCondicion: EquipoCondicion | null;
    tanqueTipos: Array<TanqueTipo | null> | null;
    secadoInfraestructuras: Array<SecadoInfraestructura | null> | null;
    cafeCorte: CafeCorte | null;
    fermentacionHora: FermentacionHora | null;
    conocenCalidad: ConocenCalidad | null;
    cafeCalidad: CafeCalidad | null;
  } | null;
  mercado: {
    __typename: "ICMercado";
    mercado: {
      __typename: "Mercado";
      prodAcopiada: number | null;
      cafeExportado: number | null;
      cafeVendidoNacional: number | null;
      cafeVendidoLocal: number | null;
      precioExportacion: number | null;
      precioNacional: number | null;
      precioLocal: number | null;
      sobreprecio: number | null;
      clientesExtranjeros: number | null;
    } | null;
    exportacionMercados: Array<ExportacionMercado | null> | null;
  } | null;
  desarrollo: {
    __typename: "ICDesarrolloCapacidades";
    cursosRecibidos: CursosRecibido | null;
    cursosTipos: Array<CursosTipo | null> | null;
    reforzarProcesos: Array<ReforzarProceso | null> | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteInfoCicloSubscription = {
  __typename: "InfoCiclo";
  id: string;
  cicloId: string;
  ciclo: {
    __typename: "Ciclo";
    id: string;
    nombre: string;
    fechaInicio: string;
    fechaFin: string;
    activo: boolean;
    createdAt: string;
    updatedAt: string;
  } | null;
  productorId: string;
  productor: {
    __typename: "Productor";
    id: string;
    searchField: string | null;
    userid: string | null;
    username: string | null;
    folioProductorId: string | null;
    organizacioncafeOrganizacionId: string | null;
    Organizacion: {
      __typename: "OrganizacionCafe";
      id: string;
      searchFields: string | null;
      nombre: string;
      figuraJuridica: string | null;
      telefono: string | null;
      email: string | null;
      anoConstitucion: number | null;
      regionId: string;
      direccion: string | null;
      codigoPostal: string | null;
      coloniaId: string;
      estadoId: string;
      municipioId: string;
      localidadId: string;
      reprNombre: string | null;
      reprCargo: string | null;
      reprTelefono: string | null;
      reprEmail: string | null;
      createdAt: string | null;
      updatedAt: string | null;
    } | null;
    nombre: string | null;
    aPaterno: string | null;
    aMaterno: string | null;
    fechaNac: string | null;
    sexo: string | null;
    curp: string | null;
    rfc: string | null;
    direccion: string | null;
    codigoPostal: string | null;
    estadoId: string;
    estado: {
      __typename: "Entidad";
      id: string;
      nombre: string;
      nombreAbrev: string;
      clave: string;
      pobMasc: string | null;
      pobFem: string | null;
      pobTotal: string | null;
      totalVivHab: string | null;
      createdAt: string | null;
      updatedAt: string | null;
    } | null;
    municipioId: string;
    municipio: {
      __typename: "Municipio";
      id: string;
      nombre: string;
      clave: string;
      entidadId: string;
      cabecera: string | null;
      claveCab: string | null;
      pobMasc: string | null;
      pobFem: string | null;
      pobTotal: string | null;
      totalVivHab: string | null;
      createdAt: string | null;
      updatedAt: string | null;
      nombreEnt: string | null;
      nombreEntAbrev: string | null;
    } | null;
    localidadId: string;
    localidad: {
      __typename: "Localidad";
      id: string;
      nombre: string;
      entidadId: string;
      municipioId: string;
      clave: string;
      ambito: string;
      latitud: number | null;
      longitud: number | null;
      altitud: string | null;
      pobMasc: string | null;
      pobFem: string | null;
      pobTotal: string | null;
      totalVivHab: string | null;
      createdAt: string | null;
      updatedAt: string | null;
      nombreEnt: string | null;
      nombreEntAbrev: string | null;
      nombreMun: string | null;
      claveCarta: string | null;
    } | null;
    latitud: number | null;
    longitud: number | null;
    createdAt: string;
    updatedAt: string;
  } | null;
  organizacionId: string;
  organizacion: {
    __typename: "OrganizacionCafe";
    id: string;
    searchFields: string | null;
    nombre: string;
    figuraJuridica: string | null;
    telefono: string | null;
    email: string | null;
    anoConstitucion: number | null;
    regionId: string;
    region: {
      __typename: "Region";
      id: string;
      nombre: string;
      createdAt: string;
      updatedAt: string;
    } | null;
    direccion: string | null;
    codigoPostal: string | null;
    coloniaId: string;
    colonia: {
      __typename: "CodigoPostal";
      id: string;
      codigo: string;
      asentamiento: string;
      tipoAsent: string;
      String: string | null;
      claveTipoAsent: string | null;
      asentCpcons: string | null;
      zona: string | null;
      entidadId: string;
      municipioId: string;
      ciudad: string | null;
      claveCd: string | null;
      cpOficina: string | null;
      createdAt: string | null;
      updatedAt: string | null;
      nombreMun: string | null;
      nombreEnt: string | null;
      dCP: string | null;
    } | null;
    estadoId: string;
    estado: {
      __typename: "Entidad";
      id: string;
      nombre: string;
      nombreAbrev: string;
      clave: string;
      pobMasc: string | null;
      pobFem: string | null;
      pobTotal: string | null;
      totalVivHab: string | null;
      createdAt: string | null;
      updatedAt: string | null;
    } | null;
    municipioId: string;
    municipio: {
      __typename: "Municipio";
      id: string;
      nombre: string;
      clave: string;
      entidadId: string;
      cabecera: string | null;
      claveCab: string | null;
      pobMasc: string | null;
      pobFem: string | null;
      pobTotal: string | null;
      totalVivHab: string | null;
      createdAt: string | null;
      updatedAt: string | null;
      nombreEnt: string | null;
      nombreEntAbrev: string | null;
    } | null;
    localidadId: string;
    localidad: {
      __typename: "Localidad";
      id: string;
      nombre: string;
      entidadId: string;
      municipioId: string;
      clave: string;
      ambito: string;
      latitud: number | null;
      longitud: number | null;
      altitud: string | null;
      pobMasc: string | null;
      pobFem: string | null;
      pobTotal: string | null;
      totalVivHab: string | null;
      createdAt: string | null;
      updatedAt: string | null;
      nombreEnt: string | null;
      nombreEntAbrev: string | null;
      nombreMun: string | null;
      claveCarta: string | null;
    } | null;
    reprNombre: string | null;
    reprCargo: string | null;
    reprTelefono: string | null;
    reprEmail: string | null;
    createdAt: string | null;
    updatedAt: string | null;
  } | null;
  datosGenerales: {
    __typename: "ICDatosGenerales";
    areasTrabajo: Array<AreasTrabajo | null> | null;
    infraestructura: Array<InfraestructuraOrganizacion | null> | null;
    equiposOficina: Array<EquiposOficina | null> | null;
    certificaciones: Array<Certificaciones | null> | null;
    estatus: Estatus | null;
  } | null;
  produccion: {
    __typename: "ICProduccion";
    superficie: {
      __typename: "Superficie";
      cafeOrganico: number | null;
      cafeTransicion: number | null;
      basicos: number | null;
      potrero: number | null;
      acahual: number | null;
      montania: number | null;
    } | null;
    produccionPromedio: {
      __typename: "ProduccionPromedio";
      estimacion: number | null;
    } | null;
    jornales: {
      __typename: "Jornales";
      primeraLimpia: number | null;
      segundaLimpia: number | null;
      cajete: number | null;
      podaYdeshije: number | null;
      desombre: number | null;
      abonera: number | null;
      regarAbono: number | null;
      resiembra: number | null;
      controlPlagas: number | null;
      conservacionSuelo: number | null;
      semilleroVivero: number | null;
      cosecha: number | null;
      beneficioHumedo: number | null;
      otros: number | null;
      precioPagado: number | null;
    } | null;
    insumos: {
      __typename: "Insumos";
      beauveria: number | null;
      abonoFoliar: number | null;
      calAgricola: number | null;
      costalera: number | null;
      canastos: number | null;
      mantenimientoEquipos: number | null;
      certificaciones: number | null;
      alimentacionPersonal: number | null;
      otros: number | null;
    } | null;
    rendimientoCafetales: RendimientoCafetales | null;
    factoresRendimiento: Array<FactoresRendimiento | null> | null;
    condicionesCafetales: Array<CondicionesCafetales | null> | null;
    variedadesCafe: Array<VariedadesCafe | null> | null;
    edadPlantaciones: EdadPlantaciones | null;
    disenioPlantaciones: DisenioPlantaciones | null;
    densidadPlantaciones: DensidadPlantaciones | null;
    alturaPlantaciones: AlturaPlantaciones | null;
    floracion: RegistroFloracion | null;
  } | null;
  calidad: {
    __typename: "ICCalidad";
    despulpadoraTipos: Array<DespulpadoraTipo | null> | null;
    equipoCondicion: EquipoCondicion | null;
    tanqueTipos: Array<TanqueTipo | null> | null;
    secadoInfraestructuras: Array<SecadoInfraestructura | null> | null;
    cafeCorte: CafeCorte | null;
    fermentacionHora: FermentacionHora | null;
    conocenCalidad: ConocenCalidad | null;
    cafeCalidad: CafeCalidad | null;
  } | null;
  mercado: {
    __typename: "ICMercado";
    mercado: {
      __typename: "Mercado";
      prodAcopiada: number | null;
      cafeExportado: number | null;
      cafeVendidoNacional: number | null;
      cafeVendidoLocal: number | null;
      precioExportacion: number | null;
      precioNacional: number | null;
      precioLocal: number | null;
      sobreprecio: number | null;
      clientesExtranjeros: number | null;
    } | null;
    exportacionMercados: Array<ExportacionMercado | null> | null;
  } | null;
  desarrollo: {
    __typename: "ICDesarrolloCapacidades";
    cursosRecibidos: CursosRecibido | null;
    cursosTipos: Array<CursosTipo | null> | null;
    reforzarProcesos: Array<ReforzarProceso | null> | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type OnCreatePadronCafetaleroSubscription = {
  __typename: "PadronCafetalero";
  id: string;
  type: string | null;
  curp: string | null;
  folioProductorId: string | null;
  nombre: string | null;
  aPaterno: string | null;
  aMaterno: string | null;
  fechaNac: string | null;
  sexo: string | null;
  rfc: string | null;
  calle: string | null;
  numero: string | null;
  colonia: string | null;
  entidadId: string | null;
  nombreEnt: string | null;
  municipioId: string | null;
  nombreMun: string | null;
  localidadId: string | null;
  nombreLoc: string | null;
  folioProdPred: string | null;
  folioPredioId: string | null;
  nombrePredio: string | null;
  superficieCafe: number | null;
  predioEntidadId: string | null;
  predioNombreEnt: string | null;
  predioMunicipioId: string | null;
  predioNombreMun: string | null;
  predioLocalidadId: string | null;
  predioNombreLoc: string | null;
  latitudGrados: string | null;
  longitudGrados: string | null;
  latitud: string | null;
  longitud: string | null;
  searchField: string | null;
  createdAt: string | null;
  updatedAt: string | null;
};

export type OnUpdatePadronCafetaleroSubscription = {
  __typename: "PadronCafetalero";
  id: string;
  type: string | null;
  curp: string | null;
  folioProductorId: string | null;
  nombre: string | null;
  aPaterno: string | null;
  aMaterno: string | null;
  fechaNac: string | null;
  sexo: string | null;
  rfc: string | null;
  calle: string | null;
  numero: string | null;
  colonia: string | null;
  entidadId: string | null;
  nombreEnt: string | null;
  municipioId: string | null;
  nombreMun: string | null;
  localidadId: string | null;
  nombreLoc: string | null;
  folioProdPred: string | null;
  folioPredioId: string | null;
  nombrePredio: string | null;
  superficieCafe: number | null;
  predioEntidadId: string | null;
  predioNombreEnt: string | null;
  predioMunicipioId: string | null;
  predioNombreMun: string | null;
  predioLocalidadId: string | null;
  predioNombreLoc: string | null;
  latitudGrados: string | null;
  longitudGrados: string | null;
  latitud: string | null;
  longitud: string | null;
  searchField: string | null;
  createdAt: string | null;
  updatedAt: string | null;
};

export type OnDeletePadronCafetaleroSubscription = {
  __typename: "PadronCafetalero";
  id: string;
  type: string | null;
  curp: string | null;
  folioProductorId: string | null;
  nombre: string | null;
  aPaterno: string | null;
  aMaterno: string | null;
  fechaNac: string | null;
  sexo: string | null;
  rfc: string | null;
  calle: string | null;
  numero: string | null;
  colonia: string | null;
  entidadId: string | null;
  nombreEnt: string | null;
  municipioId: string | null;
  nombreMun: string | null;
  localidadId: string | null;
  nombreLoc: string | null;
  folioProdPred: string | null;
  folioPredioId: string | null;
  nombrePredio: string | null;
  superficieCafe: number | null;
  predioEntidadId: string | null;
  predioNombreEnt: string | null;
  predioMunicipioId: string | null;
  predioNombreMun: string | null;
  predioLocalidadId: string | null;
  predioNombreLoc: string | null;
  latitudGrados: string | null;
  longitudGrados: string | null;
  latitud: string | null;
  longitud: string | null;
  searchField: string | null;
  createdAt: string | null;
  updatedAt: string | null;
};

export type OnCreateInfoCicloOrganizacionSubscription = {
  __typename: "InfoCicloOrganizacion";
  id: string;
  ciclo: {
    __typename: "Ciclo";
    id: string;
    nombre: string;
    fechaInicio: string;
    fechaFin: string;
    activo: boolean;
    createdAt: string;
    updatedAt: string;
  } | null;
  organizacion: {
    __typename: "OrganizacionCafe";
    id: string;
    searchFields: string | null;
    nombre: string;
    figuraJuridica: string | null;
    telefono: string | null;
    email: string | null;
    anoConstitucion: number | null;
    regionId: string;
    region: {
      __typename: "Region";
      id: string;
      nombre: string;
      createdAt: string;
      updatedAt: string;
    } | null;
    direccion: string | null;
    codigoPostal: string | null;
    coloniaId: string;
    colonia: {
      __typename: "CodigoPostal";
      id: string;
      codigo: string;
      asentamiento: string;
      tipoAsent: string;
      String: string | null;
      claveTipoAsent: string | null;
      asentCpcons: string | null;
      zona: string | null;
      entidadId: string;
      municipioId: string;
      ciudad: string | null;
      claveCd: string | null;
      cpOficina: string | null;
      createdAt: string | null;
      updatedAt: string | null;
      nombreMun: string | null;
      nombreEnt: string | null;
      dCP: string | null;
    } | null;
    estadoId: string;
    estado: {
      __typename: "Entidad";
      id: string;
      nombre: string;
      nombreAbrev: string;
      clave: string;
      pobMasc: string | null;
      pobFem: string | null;
      pobTotal: string | null;
      totalVivHab: string | null;
      createdAt: string | null;
      updatedAt: string | null;
    } | null;
    municipioId: string;
    municipio: {
      __typename: "Municipio";
      id: string;
      nombre: string;
      clave: string;
      entidadId: string;
      cabecera: string | null;
      claveCab: string | null;
      pobMasc: string | null;
      pobFem: string | null;
      pobTotal: string | null;
      totalVivHab: string | null;
      createdAt: string | null;
      updatedAt: string | null;
      nombreEnt: string | null;
      nombreEntAbrev: string | null;
    } | null;
    localidadId: string;
    localidad: {
      __typename: "Localidad";
      id: string;
      nombre: string;
      entidadId: string;
      municipioId: string;
      clave: string;
      ambito: string;
      latitud: number | null;
      longitud: number | null;
      altitud: string | null;
      pobMasc: string | null;
      pobFem: string | null;
      pobTotal: string | null;
      totalVivHab: string | null;
      createdAt: string | null;
      updatedAt: string | null;
      nombreEnt: string | null;
      nombreEntAbrev: string | null;
      nombreMun: string | null;
      claveCarta: string | null;
    } | null;
    reprNombre: string | null;
    reprCargo: string | null;
    reprTelefono: string | null;
    reprEmail: string | null;
    createdAt: string | null;
    updatedAt: string | null;
  } | null;
  cicloId: string;
  organizacionId: string;
  datosGenerales: {
    __typename: "ICODatosGenerales";
    numeroSocios: {
      __typename: "NumeroSocios";
      numHombres: number | null;
      numMujeres: number | null;
    } | null;
    haProduccion: {
      __typename: "HAProduccion";
      cafeOrganico: number | null;
      cafeTransicion: number | null;
      otrosCultivos: number | null;
    } | null;
    haPorcentajes: {
      __typename: "HAPorcentajes";
      haMenosDeMedia: number | null;
      haDeMediaAUna: number | null;
      haDeUnaADos: number | null;
      haDeDosACinco: number | null;
      haDeCincoADiez: number | null;
      haMasDeDiez: number | null;
    } | null;
    areasTrabajo: Array<AreasTrabajoICO | null> | null;
    equiposOficina: Array<EquiposOficinaICO | null> | null;
    certificaciones: Array<CertificacionesICO | null> | null;
    estatus: EstatusICO | null;
  } | null;
  produccion: {
    __typename: "ICOProduccion";
    produccionXproductor: {
      __typename: "ProduccionXProductorICO";
      maximo: number | null;
      promedio: number | null;
      minimo: number | null;
    } | null;
    jornales: {
      __typename: "JornalesICO";
      primeraLimpia: number | null;
      segundaLimpia: number | null;
      cajete: number | null;
      podaYdeshije: number | null;
      desombre: number | null;
      abonera: number | null;
      regarAbono: number | null;
      resiembra: number | null;
      controlPlagas: number | null;
      conservacionSuelo: number | null;
      semilleroVivero: number | null;
      cosecha: number | null;
      beneficioHumedo: number | null;
      otros: number | null;
      precioPagado: number | null;
    } | null;
    insumos: {
      __typename: "InsumosICO";
      beauveria: number | null;
      abonoFoliar: number | null;
      calAgricola: number | null;
      costalera: number | null;
      canastos: number | null;
      mantenimientoEquipos: number | null;
      certificaciones: number | null;
      alimentacionPersonal: number | null;
      otros: number | null;
    } | null;
    variedadesCafe: {
      __typename: "VariedadesCafeICO";
      typica: number | null;
      bourbon: number | null;
      mundoNovo: number | null;
      caturra: number | null;
      catimor: number | null;
      otros: number | null;
    } | null;
    edadPlantaciones: {
      __typename: "EdadPlantacionesICO";
      menosDe5: number | null;
      entre5y15: number | null;
      entre15y30: number | null;
      masDe30: number | null;
    } | null;
    disenioPlantaciones: {
      __typename: "DisenioPlantacionesICO";
      tresbolillo: number | null;
      rectangular: number | null;
      curvasANivel: number | null;
      sinMarcos: number | null;
    } | null;
    densidadPlantaciones: {
      __typename: "DensidadPlantacionesICO";
      menosDe1000: number | null;
      entre1000y1500: number | null;
      masDe1500: number | null;
    } | null;
    alturaPlantaciones: {
      __typename: "AlturaPlantacionesICO";
      menosDe600: number | null;
      entre600y900: number | null;
      entre900y1200: number | null;
      masDe1200: number | null;
    } | null;
    floracion: {
      __typename: "RegistroFloracionICO";
      ninguna: number | null;
      primera: number | null;
      segunda: number | null;
      tercera: number | null;
    } | null;
    rendimientoCafetales: RendimientoCafetalesICO | null;
    factoresRendimiento: Array<FactoresRendimientoICO | null> | null;
    condicionesCafetales: Array<CondicionesCafetalesICO | null> | null;
  } | null;
  calidad: {
    __typename: "ICOCalidad";
    infraestructura: Array<InfraestructuraOrganizacionICO | null> | null;
    despulpadoraTipos: {
      __typename: "DespulpadoraTipoICO";
      cilindro: number | null;
      disco: number | null;
      beneficio: number | null;
    } | null;
    equipoCondicion: {
      __typename: "EquipoCondicionICO";
      mala: number | null;
      regular: number | null;
      buena: number | null;
      excelente: number | null;
    } | null;
    tanqueTipos: {
      __typename: "TanqueTipoICO";
      madera: number | null;
      cemento: number | null;
      tina: number | null;
    } | null;
    secadoInfraestructuras: {
      __typename: "SecadoInfraestructuraICO";
      patio: number | null;
      zarandas: number | null;
      gas: number | null;
      excelente: number | null;
    } | null;
    cafeCorte: {
      __typename: "CafeCorteICO";
      uno: number | null;
      dos: number | null;
      masDeDos: number | null;
    } | null;
    fermentacionHora: {
      __typename: "FermentacionHoraICO";
      menosDe10: number | null;
      entre10y15: number | null;
      entre15y20: number | null;
    } | null;
    conocenCalidad: ConocenCalidadICO | null;
    cafeCalidad: {
      __typename: "CafeCalidadICO";
      mala: number | null;
      regular: number | null;
      buena: number | null;
      excelente: number | null;
    } | null;
  } | null;
  mercado: {
    __typename: "ICOMercado";
    mercado: {
      __typename: "MercadoICO";
      prodAcopiada: number | null;
      cafeExportado: number | null;
      cafeVendidoNacional: number | null;
      cafeVendidoLocal: number | null;
      precioExportacion: number | null;
      precioNacional: number | null;
      precioLocal: number | null;
      sobreprecio: number | null;
      clientesExtranjeros: number | null;
    } | null;
    exportacionMercados: Array<ExportacionMercadoICO | null> | null;
  } | null;
  desarrollo: {
    __typename: "ICODesarrolloCapacidades";
    cursosRecibidos: CursosRecibidosICO | null;
    cursosTipos: Array<CursosTipo | null> | null;
    reforzarProcesos: Array<ReforzarProceso | null> | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateInfoCicloOrganizacionSubscription = {
  __typename: "InfoCicloOrganizacion";
  id: string;
  ciclo: {
    __typename: "Ciclo";
    id: string;
    nombre: string;
    fechaInicio: string;
    fechaFin: string;
    activo: boolean;
    createdAt: string;
    updatedAt: string;
  } | null;
  organizacion: {
    __typename: "OrganizacionCafe";
    id: string;
    searchFields: string | null;
    nombre: string;
    figuraJuridica: string | null;
    telefono: string | null;
    email: string | null;
    anoConstitucion: number | null;
    regionId: string;
    region: {
      __typename: "Region";
      id: string;
      nombre: string;
      createdAt: string;
      updatedAt: string;
    } | null;
    direccion: string | null;
    codigoPostal: string | null;
    coloniaId: string;
    colonia: {
      __typename: "CodigoPostal";
      id: string;
      codigo: string;
      asentamiento: string;
      tipoAsent: string;
      String: string | null;
      claveTipoAsent: string | null;
      asentCpcons: string | null;
      zona: string | null;
      entidadId: string;
      municipioId: string;
      ciudad: string | null;
      claveCd: string | null;
      cpOficina: string | null;
      createdAt: string | null;
      updatedAt: string | null;
      nombreMun: string | null;
      nombreEnt: string | null;
      dCP: string | null;
    } | null;
    estadoId: string;
    estado: {
      __typename: "Entidad";
      id: string;
      nombre: string;
      nombreAbrev: string;
      clave: string;
      pobMasc: string | null;
      pobFem: string | null;
      pobTotal: string | null;
      totalVivHab: string | null;
      createdAt: string | null;
      updatedAt: string | null;
    } | null;
    municipioId: string;
    municipio: {
      __typename: "Municipio";
      id: string;
      nombre: string;
      clave: string;
      entidadId: string;
      cabecera: string | null;
      claveCab: string | null;
      pobMasc: string | null;
      pobFem: string | null;
      pobTotal: string | null;
      totalVivHab: string | null;
      createdAt: string | null;
      updatedAt: string | null;
      nombreEnt: string | null;
      nombreEntAbrev: string | null;
    } | null;
    localidadId: string;
    localidad: {
      __typename: "Localidad";
      id: string;
      nombre: string;
      entidadId: string;
      municipioId: string;
      clave: string;
      ambito: string;
      latitud: number | null;
      longitud: number | null;
      altitud: string | null;
      pobMasc: string | null;
      pobFem: string | null;
      pobTotal: string | null;
      totalVivHab: string | null;
      createdAt: string | null;
      updatedAt: string | null;
      nombreEnt: string | null;
      nombreEntAbrev: string | null;
      nombreMun: string | null;
      claveCarta: string | null;
    } | null;
    reprNombre: string | null;
    reprCargo: string | null;
    reprTelefono: string | null;
    reprEmail: string | null;
    createdAt: string | null;
    updatedAt: string | null;
  } | null;
  cicloId: string;
  organizacionId: string;
  datosGenerales: {
    __typename: "ICODatosGenerales";
    numeroSocios: {
      __typename: "NumeroSocios";
      numHombres: number | null;
      numMujeres: number | null;
    } | null;
    haProduccion: {
      __typename: "HAProduccion";
      cafeOrganico: number | null;
      cafeTransicion: number | null;
      otrosCultivos: number | null;
    } | null;
    haPorcentajes: {
      __typename: "HAPorcentajes";
      haMenosDeMedia: number | null;
      haDeMediaAUna: number | null;
      haDeUnaADos: number | null;
      haDeDosACinco: number | null;
      haDeCincoADiez: number | null;
      haMasDeDiez: number | null;
    } | null;
    areasTrabajo: Array<AreasTrabajoICO | null> | null;
    equiposOficina: Array<EquiposOficinaICO | null> | null;
    certificaciones: Array<CertificacionesICO | null> | null;
    estatus: EstatusICO | null;
  } | null;
  produccion: {
    __typename: "ICOProduccion";
    produccionXproductor: {
      __typename: "ProduccionXProductorICO";
      maximo: number | null;
      promedio: number | null;
      minimo: number | null;
    } | null;
    jornales: {
      __typename: "JornalesICO";
      primeraLimpia: number | null;
      segundaLimpia: number | null;
      cajete: number | null;
      podaYdeshije: number | null;
      desombre: number | null;
      abonera: number | null;
      regarAbono: number | null;
      resiembra: number | null;
      controlPlagas: number | null;
      conservacionSuelo: number | null;
      semilleroVivero: number | null;
      cosecha: number | null;
      beneficioHumedo: number | null;
      otros: number | null;
      precioPagado: number | null;
    } | null;
    insumos: {
      __typename: "InsumosICO";
      beauveria: number | null;
      abonoFoliar: number | null;
      calAgricola: number | null;
      costalera: number | null;
      canastos: number | null;
      mantenimientoEquipos: number | null;
      certificaciones: number | null;
      alimentacionPersonal: number | null;
      otros: number | null;
    } | null;
    variedadesCafe: {
      __typename: "VariedadesCafeICO";
      typica: number | null;
      bourbon: number | null;
      mundoNovo: number | null;
      caturra: number | null;
      catimor: number | null;
      otros: number | null;
    } | null;
    edadPlantaciones: {
      __typename: "EdadPlantacionesICO";
      menosDe5: number | null;
      entre5y15: number | null;
      entre15y30: number | null;
      masDe30: number | null;
    } | null;
    disenioPlantaciones: {
      __typename: "DisenioPlantacionesICO";
      tresbolillo: number | null;
      rectangular: number | null;
      curvasANivel: number | null;
      sinMarcos: number | null;
    } | null;
    densidadPlantaciones: {
      __typename: "DensidadPlantacionesICO";
      menosDe1000: number | null;
      entre1000y1500: number | null;
      masDe1500: number | null;
    } | null;
    alturaPlantaciones: {
      __typename: "AlturaPlantacionesICO";
      menosDe600: number | null;
      entre600y900: number | null;
      entre900y1200: number | null;
      masDe1200: number | null;
    } | null;
    floracion: {
      __typename: "RegistroFloracionICO";
      ninguna: number | null;
      primera: number | null;
      segunda: number | null;
      tercera: number | null;
    } | null;
    rendimientoCafetales: RendimientoCafetalesICO | null;
    factoresRendimiento: Array<FactoresRendimientoICO | null> | null;
    condicionesCafetales: Array<CondicionesCafetalesICO | null> | null;
  } | null;
  calidad: {
    __typename: "ICOCalidad";
    infraestructura: Array<InfraestructuraOrganizacionICO | null> | null;
    despulpadoraTipos: {
      __typename: "DespulpadoraTipoICO";
      cilindro: number | null;
      disco: number | null;
      beneficio: number | null;
    } | null;
    equipoCondicion: {
      __typename: "EquipoCondicionICO";
      mala: number | null;
      regular: number | null;
      buena: number | null;
      excelente: number | null;
    } | null;
    tanqueTipos: {
      __typename: "TanqueTipoICO";
      madera: number | null;
      cemento: number | null;
      tina: number | null;
    } | null;
    secadoInfraestructuras: {
      __typename: "SecadoInfraestructuraICO";
      patio: number | null;
      zarandas: number | null;
      gas: number | null;
      excelente: number | null;
    } | null;
    cafeCorte: {
      __typename: "CafeCorteICO";
      uno: number | null;
      dos: number | null;
      masDeDos: number | null;
    } | null;
    fermentacionHora: {
      __typename: "FermentacionHoraICO";
      menosDe10: number | null;
      entre10y15: number | null;
      entre15y20: number | null;
    } | null;
    conocenCalidad: ConocenCalidadICO | null;
    cafeCalidad: {
      __typename: "CafeCalidadICO";
      mala: number | null;
      regular: number | null;
      buena: number | null;
      excelente: number | null;
    } | null;
  } | null;
  mercado: {
    __typename: "ICOMercado";
    mercado: {
      __typename: "MercadoICO";
      prodAcopiada: number | null;
      cafeExportado: number | null;
      cafeVendidoNacional: number | null;
      cafeVendidoLocal: number | null;
      precioExportacion: number | null;
      precioNacional: number | null;
      precioLocal: number | null;
      sobreprecio: number | null;
      clientesExtranjeros: number | null;
    } | null;
    exportacionMercados: Array<ExportacionMercadoICO | null> | null;
  } | null;
  desarrollo: {
    __typename: "ICODesarrolloCapacidades";
    cursosRecibidos: CursosRecibidosICO | null;
    cursosTipos: Array<CursosTipo | null> | null;
    reforzarProcesos: Array<ReforzarProceso | null> | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteInfoCicloOrganizacionSubscription = {
  __typename: "InfoCicloOrganizacion";
  id: string;
  ciclo: {
    __typename: "Ciclo";
    id: string;
    nombre: string;
    fechaInicio: string;
    fechaFin: string;
    activo: boolean;
    createdAt: string;
    updatedAt: string;
  } | null;
  organizacion: {
    __typename: "OrganizacionCafe";
    id: string;
    searchFields: string | null;
    nombre: string;
    figuraJuridica: string | null;
    telefono: string | null;
    email: string | null;
    anoConstitucion: number | null;
    regionId: string;
    region: {
      __typename: "Region";
      id: string;
      nombre: string;
      createdAt: string;
      updatedAt: string;
    } | null;
    direccion: string | null;
    codigoPostal: string | null;
    coloniaId: string;
    colonia: {
      __typename: "CodigoPostal";
      id: string;
      codigo: string;
      asentamiento: string;
      tipoAsent: string;
      String: string | null;
      claveTipoAsent: string | null;
      asentCpcons: string | null;
      zona: string | null;
      entidadId: string;
      municipioId: string;
      ciudad: string | null;
      claveCd: string | null;
      cpOficina: string | null;
      createdAt: string | null;
      updatedAt: string | null;
      nombreMun: string | null;
      nombreEnt: string | null;
      dCP: string | null;
    } | null;
    estadoId: string;
    estado: {
      __typename: "Entidad";
      id: string;
      nombre: string;
      nombreAbrev: string;
      clave: string;
      pobMasc: string | null;
      pobFem: string | null;
      pobTotal: string | null;
      totalVivHab: string | null;
      createdAt: string | null;
      updatedAt: string | null;
    } | null;
    municipioId: string;
    municipio: {
      __typename: "Municipio";
      id: string;
      nombre: string;
      clave: string;
      entidadId: string;
      cabecera: string | null;
      claveCab: string | null;
      pobMasc: string | null;
      pobFem: string | null;
      pobTotal: string | null;
      totalVivHab: string | null;
      createdAt: string | null;
      updatedAt: string | null;
      nombreEnt: string | null;
      nombreEntAbrev: string | null;
    } | null;
    localidadId: string;
    localidad: {
      __typename: "Localidad";
      id: string;
      nombre: string;
      entidadId: string;
      municipioId: string;
      clave: string;
      ambito: string;
      latitud: number | null;
      longitud: number | null;
      altitud: string | null;
      pobMasc: string | null;
      pobFem: string | null;
      pobTotal: string | null;
      totalVivHab: string | null;
      createdAt: string | null;
      updatedAt: string | null;
      nombreEnt: string | null;
      nombreEntAbrev: string | null;
      nombreMun: string | null;
      claveCarta: string | null;
    } | null;
    reprNombre: string | null;
    reprCargo: string | null;
    reprTelefono: string | null;
    reprEmail: string | null;
    createdAt: string | null;
    updatedAt: string | null;
  } | null;
  cicloId: string;
  organizacionId: string;
  datosGenerales: {
    __typename: "ICODatosGenerales";
    numeroSocios: {
      __typename: "NumeroSocios";
      numHombres: number | null;
      numMujeres: number | null;
    } | null;
    haProduccion: {
      __typename: "HAProduccion";
      cafeOrganico: number | null;
      cafeTransicion: number | null;
      otrosCultivos: number | null;
    } | null;
    haPorcentajes: {
      __typename: "HAPorcentajes";
      haMenosDeMedia: number | null;
      haDeMediaAUna: number | null;
      haDeUnaADos: number | null;
      haDeDosACinco: number | null;
      haDeCincoADiez: number | null;
      haMasDeDiez: number | null;
    } | null;
    areasTrabajo: Array<AreasTrabajoICO | null> | null;
    equiposOficina: Array<EquiposOficinaICO | null> | null;
    certificaciones: Array<CertificacionesICO | null> | null;
    estatus: EstatusICO | null;
  } | null;
  produccion: {
    __typename: "ICOProduccion";
    produccionXproductor: {
      __typename: "ProduccionXProductorICO";
      maximo: number | null;
      promedio: number | null;
      minimo: number | null;
    } | null;
    jornales: {
      __typename: "JornalesICO";
      primeraLimpia: number | null;
      segundaLimpia: number | null;
      cajete: number | null;
      podaYdeshije: number | null;
      desombre: number | null;
      abonera: number | null;
      regarAbono: number | null;
      resiembra: number | null;
      controlPlagas: number | null;
      conservacionSuelo: number | null;
      semilleroVivero: number | null;
      cosecha: number | null;
      beneficioHumedo: number | null;
      otros: number | null;
      precioPagado: number | null;
    } | null;
    insumos: {
      __typename: "InsumosICO";
      beauveria: number | null;
      abonoFoliar: number | null;
      calAgricola: number | null;
      costalera: number | null;
      canastos: number | null;
      mantenimientoEquipos: number | null;
      certificaciones: number | null;
      alimentacionPersonal: number | null;
      otros: number | null;
    } | null;
    variedadesCafe: {
      __typename: "VariedadesCafeICO";
      typica: number | null;
      bourbon: number | null;
      mundoNovo: number | null;
      caturra: number | null;
      catimor: number | null;
      otros: number | null;
    } | null;
    edadPlantaciones: {
      __typename: "EdadPlantacionesICO";
      menosDe5: number | null;
      entre5y15: number | null;
      entre15y30: number | null;
      masDe30: number | null;
    } | null;
    disenioPlantaciones: {
      __typename: "DisenioPlantacionesICO";
      tresbolillo: number | null;
      rectangular: number | null;
      curvasANivel: number | null;
      sinMarcos: number | null;
    } | null;
    densidadPlantaciones: {
      __typename: "DensidadPlantacionesICO";
      menosDe1000: number | null;
      entre1000y1500: number | null;
      masDe1500: number | null;
    } | null;
    alturaPlantaciones: {
      __typename: "AlturaPlantacionesICO";
      menosDe600: number | null;
      entre600y900: number | null;
      entre900y1200: number | null;
      masDe1200: number | null;
    } | null;
    floracion: {
      __typename: "RegistroFloracionICO";
      ninguna: number | null;
      primera: number | null;
      segunda: number | null;
      tercera: number | null;
    } | null;
    rendimientoCafetales: RendimientoCafetalesICO | null;
    factoresRendimiento: Array<FactoresRendimientoICO | null> | null;
    condicionesCafetales: Array<CondicionesCafetalesICO | null> | null;
  } | null;
  calidad: {
    __typename: "ICOCalidad";
    infraestructura: Array<InfraestructuraOrganizacionICO | null> | null;
    despulpadoraTipos: {
      __typename: "DespulpadoraTipoICO";
      cilindro: number | null;
      disco: number | null;
      beneficio: number | null;
    } | null;
    equipoCondicion: {
      __typename: "EquipoCondicionICO";
      mala: number | null;
      regular: number | null;
      buena: number | null;
      excelente: number | null;
    } | null;
    tanqueTipos: {
      __typename: "TanqueTipoICO";
      madera: number | null;
      cemento: number | null;
      tina: number | null;
    } | null;
    secadoInfraestructuras: {
      __typename: "SecadoInfraestructuraICO";
      patio: number | null;
      zarandas: number | null;
      gas: number | null;
      excelente: number | null;
    } | null;
    cafeCorte: {
      __typename: "CafeCorteICO";
      uno: number | null;
      dos: number | null;
      masDeDos: number | null;
    } | null;
    fermentacionHora: {
      __typename: "FermentacionHoraICO";
      menosDe10: number | null;
      entre10y15: number | null;
      entre15y20: number | null;
    } | null;
    conocenCalidad: ConocenCalidadICO | null;
    cafeCalidad: {
      __typename: "CafeCalidadICO";
      mala: number | null;
      regular: number | null;
      buena: number | null;
      excelente: number | null;
    } | null;
  } | null;
  mercado: {
    __typename: "ICOMercado";
    mercado: {
      __typename: "MercadoICO";
      prodAcopiada: number | null;
      cafeExportado: number | null;
      cafeVendidoNacional: number | null;
      cafeVendidoLocal: number | null;
      precioExportacion: number | null;
      precioNacional: number | null;
      precioLocal: number | null;
      sobreprecio: number | null;
      clientesExtranjeros: number | null;
    } | null;
    exportacionMercados: Array<ExportacionMercadoICO | null> | null;
  } | null;
  desarrollo: {
    __typename: "ICODesarrolloCapacidades";
    cursosRecibidos: CursosRecibidosICO | null;
    cursosTipos: Array<CursosTipo | null> | null;
    reforzarProcesos: Array<ReforzarProceso | null> | null;
  } | null;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateUsuarioSubscription = {
  __typename: "Usuario";
  id: string;
  email: string;
  nombreCompleto: string;
  fechaNacimiento: string | null;
  createdAt: string;
  updatedAt: string;
  owner: string | null;
};

export type OnUpdateUsuarioSubscription = {
  __typename: "Usuario";
  id: string;
  email: string;
  nombreCompleto: string;
  fechaNacimiento: string | null;
  createdAt: string;
  updatedAt: string;
  owner: string | null;
};

export type OnDeleteUsuarioSubscription = {
  __typename: "Usuario";
  id: string;
  email: string;
  nombreCompleto: string;
  fechaNacimiento: string | null;
  createdAt: string;
  updatedAt: string;
  owner: string | null;
};

@Injectable({
  providedIn: "root"
})
export class APIService {
  async CreateOrganizacionCafe(
    input: CreateOrganizacionCafeInput,
    condition?: ModelOrganizacionCafeConditionInput
  ): Promise<CreateOrganizacionCafeMutation> {
    const statement = `mutation CreateOrganizacionCafe($input: CreateOrganizacionCafeInput!, $condition: ModelOrganizacionCafeConditionInput) {
        createOrganizacionCafe(input: $input, condition: $condition) {
          __typename
          id
          searchFields
          nombre
          figuraJuridica
          telefono
          email
          anoConstitucion
          regionId
          region {
            __typename
            id
            nombre
            createdAt
            updatedAt
          }
          direccion
          codigoPostal
          coloniaId
          colonia {
            __typename
            id
            codigo
            asentamiento
            tipoAsent
            String
            claveTipoAsent
            asentCpcons
            zona
            entidadId
            municipioId
            ciudad
            claveCd
            cpOficina
            createdAt
            updatedAt
            nombreMun
            nombreEnt
            dCP
          }
          estadoId
          estado {
            __typename
            id
            nombre
            nombreAbrev
            clave
            pobMasc
            pobFem
            pobTotal
            totalVivHab
            createdAt
            updatedAt
          }
          municipioId
          municipio {
            __typename
            id
            nombre
            clave
            entidadId
            cabecera
            claveCab
            pobMasc
            pobFem
            pobTotal
            totalVivHab
            createdAt
            updatedAt
            nombreEnt
            nombreEntAbrev
          }
          localidadId
          localidad {
            __typename
            id
            nombre
            entidadId
            municipioId
            clave
            ambito
            latitud
            longitud
            altitud
            pobMasc
            pobFem
            pobTotal
            totalVivHab
            createdAt
            updatedAt
            nombreEnt
            nombreEntAbrev
            nombreMun
            claveCarta
          }
          reprNombre
          reprCargo
          reprTelefono
          reprEmail
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateOrganizacionCafeMutation>response.data.createOrganizacionCafe;
  }
  async UpdateOrganizacionCafe(
    input: UpdateOrganizacionCafeInput,
    condition?: ModelOrganizacionCafeConditionInput
  ): Promise<UpdateOrganizacionCafeMutation> {
    const statement = `mutation UpdateOrganizacionCafe($input: UpdateOrganizacionCafeInput!, $condition: ModelOrganizacionCafeConditionInput) {
        updateOrganizacionCafe(input: $input, condition: $condition) {
          __typename
          id
          searchFields
          nombre
          figuraJuridica
          telefono
          email
          anoConstitucion
          regionId
          region {
            __typename
            id
            nombre
            createdAt
            updatedAt
          }
          direccion
          codigoPostal
          coloniaId
          colonia {
            __typename
            id
            codigo
            asentamiento
            tipoAsent
            String
            claveTipoAsent
            asentCpcons
            zona
            entidadId
            municipioId
            ciudad
            claveCd
            cpOficina
            createdAt
            updatedAt
            nombreMun
            nombreEnt
            dCP
          }
          estadoId
          estado {
            __typename
            id
            nombre
            nombreAbrev
            clave
            pobMasc
            pobFem
            pobTotal
            totalVivHab
            createdAt
            updatedAt
          }
          municipioId
          municipio {
            __typename
            id
            nombre
            clave
            entidadId
            cabecera
            claveCab
            pobMasc
            pobFem
            pobTotal
            totalVivHab
            createdAt
            updatedAt
            nombreEnt
            nombreEntAbrev
          }
          localidadId
          localidad {
            __typename
            id
            nombre
            entidadId
            municipioId
            clave
            ambito
            latitud
            longitud
            altitud
            pobMasc
            pobFem
            pobTotal
            totalVivHab
            createdAt
            updatedAt
            nombreEnt
            nombreEntAbrev
            nombreMun
            claveCarta
          }
          reprNombre
          reprCargo
          reprTelefono
          reprEmail
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateOrganizacionCafeMutation>response.data.updateOrganizacionCafe;
  }
  async DeleteOrganizacionCafe(
    input: DeleteOrganizacionCafeInput,
    condition?: ModelOrganizacionCafeConditionInput
  ): Promise<DeleteOrganizacionCafeMutation> {
    const statement = `mutation DeleteOrganizacionCafe($input: DeleteOrganizacionCafeInput!, $condition: ModelOrganizacionCafeConditionInput) {
        deleteOrganizacionCafe(input: $input, condition: $condition) {
          __typename
          id
          searchFields
          nombre
          figuraJuridica
          telefono
          email
          anoConstitucion
          regionId
          region {
            __typename
            id
            nombre
            createdAt
            updatedAt
          }
          direccion
          codigoPostal
          coloniaId
          colonia {
            __typename
            id
            codigo
            asentamiento
            tipoAsent
            String
            claveTipoAsent
            asentCpcons
            zona
            entidadId
            municipioId
            ciudad
            claveCd
            cpOficina
            createdAt
            updatedAt
            nombreMun
            nombreEnt
            dCP
          }
          estadoId
          estado {
            __typename
            id
            nombre
            nombreAbrev
            clave
            pobMasc
            pobFem
            pobTotal
            totalVivHab
            createdAt
            updatedAt
          }
          municipioId
          municipio {
            __typename
            id
            nombre
            clave
            entidadId
            cabecera
            claveCab
            pobMasc
            pobFem
            pobTotal
            totalVivHab
            createdAt
            updatedAt
            nombreEnt
            nombreEntAbrev
          }
          localidadId
          localidad {
            __typename
            id
            nombre
            entidadId
            municipioId
            clave
            ambito
            latitud
            longitud
            altitud
            pobMasc
            pobFem
            pobTotal
            totalVivHab
            createdAt
            updatedAt
            nombreEnt
            nombreEntAbrev
            nombreMun
            claveCarta
          }
          reprNombre
          reprCargo
          reprTelefono
          reprEmail
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteOrganizacionCafeMutation>response.data.deleteOrganizacionCafe;
  }
  async CreateRegion(
    input: CreateRegionInput,
    condition?: ModelRegionConditionInput
  ): Promise<CreateRegionMutation> {
    const statement = `mutation CreateRegion($input: CreateRegionInput!, $condition: ModelRegionConditionInput) {
        createRegion(input: $input, condition: $condition) {
          __typename
          id
          nombre
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateRegionMutation>response.data.createRegion;
  }
  async UpdateRegion(
    input: UpdateRegionInput,
    condition?: ModelRegionConditionInput
  ): Promise<UpdateRegionMutation> {
    const statement = `mutation UpdateRegion($input: UpdateRegionInput!, $condition: ModelRegionConditionInput) {
        updateRegion(input: $input, condition: $condition) {
          __typename
          id
          nombre
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateRegionMutation>response.data.updateRegion;
  }
  async DeleteRegion(
    input: DeleteRegionInput,
    condition?: ModelRegionConditionInput
  ): Promise<DeleteRegionMutation> {
    const statement = `mutation DeleteRegion($input: DeleteRegionInput!, $condition: ModelRegionConditionInput) {
        deleteRegion(input: $input, condition: $condition) {
          __typename
          id
          nombre
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteRegionMutation>response.data.deleteRegion;
  }
  async CreateProductor(
    input: CreateProductorInput,
    condition?: ModelProductorConditionInput
  ): Promise<CreateProductorMutation> {
    const statement = `mutation CreateProductor($input: CreateProductorInput!, $condition: ModelProductorConditionInput) {
        createProductor(input: $input, condition: $condition) {
          __typename
          id
          searchField
          userid
          username
          folioProductorId
          organizacioncafeOrganizacionId
          Organizacion {
            __typename
            id
            searchFields
            nombre
            figuraJuridica
            telefono
            email
            anoConstitucion
            regionId
            region {
              __typename
              id
              nombre
              createdAt
              updatedAt
            }
            direccion
            codigoPostal
            coloniaId
            colonia {
              __typename
              id
              codigo
              asentamiento
              tipoAsent
              String
              claveTipoAsent
              asentCpcons
              zona
              entidadId
              municipioId
              ciudad
              claveCd
              cpOficina
              createdAt
              updatedAt
              nombreMun
              nombreEnt
              dCP
            }
            estadoId
            estado {
              __typename
              id
              nombre
              nombreAbrev
              clave
              pobMasc
              pobFem
              pobTotal
              totalVivHab
              createdAt
              updatedAt
            }
            municipioId
            municipio {
              __typename
              id
              nombre
              clave
              entidadId
              cabecera
              claveCab
              pobMasc
              pobFem
              pobTotal
              totalVivHab
              createdAt
              updatedAt
              nombreEnt
              nombreEntAbrev
            }
            localidadId
            localidad {
              __typename
              id
              nombre
              entidadId
              municipioId
              clave
              ambito
              latitud
              longitud
              altitud
              pobMasc
              pobFem
              pobTotal
              totalVivHab
              createdAt
              updatedAt
              nombreEnt
              nombreEntAbrev
              nombreMun
              claveCarta
            }
            reprNombre
            reprCargo
            reprTelefono
            reprEmail
            createdAt
            updatedAt
          }
          nombre
          aPaterno
          aMaterno
          fechaNac
          sexo
          curp
          rfc
          direccion
          codigoPostal
          estadoId
          estado {
            __typename
            id
            nombre
            nombreAbrev
            clave
            pobMasc
            pobFem
            pobTotal
            totalVivHab
            createdAt
            updatedAt
          }
          municipioId
          municipio {
            __typename
            id
            nombre
            clave
            entidadId
            cabecera
            claveCab
            pobMasc
            pobFem
            pobTotal
            totalVivHab
            createdAt
            updatedAt
            nombreEnt
            nombreEntAbrev
          }
          localidadId
          localidad {
            __typename
            id
            nombre
            entidadId
            municipioId
            clave
            ambito
            latitud
            longitud
            altitud
            pobMasc
            pobFem
            pobTotal
            totalVivHab
            createdAt
            updatedAt
            nombreEnt
            nombreEntAbrev
            nombreMun
            claveCarta
          }
          latitud
          longitud
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateProductorMutation>response.data.createProductor;
  }
  async UpdateProductor(
    input: UpdateProductorInput,
    condition?: ModelProductorConditionInput
  ): Promise<UpdateProductorMutation> {
    const statement = `mutation UpdateProductor($input: UpdateProductorInput!, $condition: ModelProductorConditionInput) {
        updateProductor(input: $input, condition: $condition) {
          __typename
          id
          searchField
          userid
          username
          folioProductorId
          organizacioncafeOrganizacionId
          Organizacion {
            __typename
            id
            searchFields
            nombre
            figuraJuridica
            telefono
            email
            anoConstitucion
            regionId
            region {
              __typename
              id
              nombre
              createdAt
              updatedAt
            }
            direccion
            codigoPostal
            coloniaId
            colonia {
              __typename
              id
              codigo
              asentamiento
              tipoAsent
              String
              claveTipoAsent
              asentCpcons
              zona
              entidadId
              municipioId
              ciudad
              claveCd
              cpOficina
              createdAt
              updatedAt
              nombreMun
              nombreEnt
              dCP
            }
            estadoId
            estado {
              __typename
              id
              nombre
              nombreAbrev
              clave
              pobMasc
              pobFem
              pobTotal
              totalVivHab
              createdAt
              updatedAt
            }
            municipioId
            municipio {
              __typename
              id
              nombre
              clave
              entidadId
              cabecera
              claveCab
              pobMasc
              pobFem
              pobTotal
              totalVivHab
              createdAt
              updatedAt
              nombreEnt
              nombreEntAbrev
            }
            localidadId
            localidad {
              __typename
              id
              nombre
              entidadId
              municipioId
              clave
              ambito
              latitud
              longitud
              altitud
              pobMasc
              pobFem
              pobTotal
              totalVivHab
              createdAt
              updatedAt
              nombreEnt
              nombreEntAbrev
              nombreMun
              claveCarta
            }
            reprNombre
            reprCargo
            reprTelefono
            reprEmail
            createdAt
            updatedAt
          }
          nombre
          aPaterno
          aMaterno
          fechaNac
          sexo
          curp
          rfc
          direccion
          codigoPostal
          estadoId
          estado {
            __typename
            id
            nombre
            nombreAbrev
            clave
            pobMasc
            pobFem
            pobTotal
            totalVivHab
            createdAt
            updatedAt
          }
          municipioId
          municipio {
            __typename
            id
            nombre
            clave
            entidadId
            cabecera
            claveCab
            pobMasc
            pobFem
            pobTotal
            totalVivHab
            createdAt
            updatedAt
            nombreEnt
            nombreEntAbrev
          }
          localidadId
          localidad {
            __typename
            id
            nombre
            entidadId
            municipioId
            clave
            ambito
            latitud
            longitud
            altitud
            pobMasc
            pobFem
            pobTotal
            totalVivHab
            createdAt
            updatedAt
            nombreEnt
            nombreEntAbrev
            nombreMun
            claveCarta
          }
          latitud
          longitud
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateProductorMutation>response.data.updateProductor;
  }
  async DeleteProductor(
    input: DeleteProductorInput,
    condition?: ModelProductorConditionInput
  ): Promise<DeleteProductorMutation> {
    const statement = `mutation DeleteProductor($input: DeleteProductorInput!, $condition: ModelProductorConditionInput) {
        deleteProductor(input: $input, condition: $condition) {
          __typename
          id
          searchField
          userid
          username
          folioProductorId
          organizacioncafeOrganizacionId
          Organizacion {
            __typename
            id
            searchFields
            nombre
            figuraJuridica
            telefono
            email
            anoConstitucion
            regionId
            region {
              __typename
              id
              nombre
              createdAt
              updatedAt
            }
            direccion
            codigoPostal
            coloniaId
            colonia {
              __typename
              id
              codigo
              asentamiento
              tipoAsent
              String
              claveTipoAsent
              asentCpcons
              zona
              entidadId
              municipioId
              ciudad
              claveCd
              cpOficina
              createdAt
              updatedAt
              nombreMun
              nombreEnt
              dCP
            }
            estadoId
            estado {
              __typename
              id
              nombre
              nombreAbrev
              clave
              pobMasc
              pobFem
              pobTotal
              totalVivHab
              createdAt
              updatedAt
            }
            municipioId
            municipio {
              __typename
              id
              nombre
              clave
              entidadId
              cabecera
              claveCab
              pobMasc
              pobFem
              pobTotal
              totalVivHab
              createdAt
              updatedAt
              nombreEnt
              nombreEntAbrev
            }
            localidadId
            localidad {
              __typename
              id
              nombre
              entidadId
              municipioId
              clave
              ambito
              latitud
              longitud
              altitud
              pobMasc
              pobFem
              pobTotal
              totalVivHab
              createdAt
              updatedAt
              nombreEnt
              nombreEntAbrev
              nombreMun
              claveCarta
            }
            reprNombre
            reprCargo
            reprTelefono
            reprEmail
            createdAt
            updatedAt
          }
          nombre
          aPaterno
          aMaterno
          fechaNac
          sexo
          curp
          rfc
          direccion
          codigoPostal
          estadoId
          estado {
            __typename
            id
            nombre
            nombreAbrev
            clave
            pobMasc
            pobFem
            pobTotal
            totalVivHab
            createdAt
            updatedAt
          }
          municipioId
          municipio {
            __typename
            id
            nombre
            clave
            entidadId
            cabecera
            claveCab
            pobMasc
            pobFem
            pobTotal
            totalVivHab
            createdAt
            updatedAt
            nombreEnt
            nombreEntAbrev
          }
          localidadId
          localidad {
            __typename
            id
            nombre
            entidadId
            municipioId
            clave
            ambito
            latitud
            longitud
            altitud
            pobMasc
            pobFem
            pobTotal
            totalVivHab
            createdAt
            updatedAt
            nombreEnt
            nombreEntAbrev
            nombreMun
            claveCarta
          }
          latitud
          longitud
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteProductorMutation>response.data.deleteProductor;
  }
  async CreateEntidad(
    input: CreateEntidadInput,
    condition?: ModelEntidadConditionInput
  ): Promise<CreateEntidadMutation> {
    const statement = `mutation CreateEntidad($input: CreateEntidadInput!, $condition: ModelEntidadConditionInput) {
        createEntidad(input: $input, condition: $condition) {
          __typename
          id
          nombre
          nombreAbrev
          clave
          pobMasc
          pobFem
          pobTotal
          totalVivHab
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateEntidadMutation>response.data.createEntidad;
  }
  async UpdateEntidad(
    input: UpdateEntidadInput,
    condition?: ModelEntidadConditionInput
  ): Promise<UpdateEntidadMutation> {
    const statement = `mutation UpdateEntidad($input: UpdateEntidadInput!, $condition: ModelEntidadConditionInput) {
        updateEntidad(input: $input, condition: $condition) {
          __typename
          id
          nombre
          nombreAbrev
          clave
          pobMasc
          pobFem
          pobTotal
          totalVivHab
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateEntidadMutation>response.data.updateEntidad;
  }
  async DeleteEntidad(
    input: DeleteEntidadInput,
    condition?: ModelEntidadConditionInput
  ): Promise<DeleteEntidadMutation> {
    const statement = `mutation DeleteEntidad($input: DeleteEntidadInput!, $condition: ModelEntidadConditionInput) {
        deleteEntidad(input: $input, condition: $condition) {
          __typename
          id
          nombre
          nombreAbrev
          clave
          pobMasc
          pobFem
          pobTotal
          totalVivHab
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteEntidadMutation>response.data.deleteEntidad;
  }
  async CreateMunicipio(
    input: CreateMunicipioInput,
    condition?: ModelMunicipioConditionInput
  ): Promise<CreateMunicipioMutation> {
    const statement = `mutation CreateMunicipio($input: CreateMunicipioInput!, $condition: ModelMunicipioConditionInput) {
        createMunicipio(input: $input, condition: $condition) {
          __typename
          id
          nombre
          clave
          entidadId
          cabecera
          claveCab
          pobMasc
          pobFem
          pobTotal
          totalVivHab
          createdAt
          updatedAt
          nombreEnt
          nombreEntAbrev
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateMunicipioMutation>response.data.createMunicipio;
  }
  async UpdateMunicipio(
    input: UpdateMunicipioInput,
    condition?: ModelMunicipioConditionInput
  ): Promise<UpdateMunicipioMutation> {
    const statement = `mutation UpdateMunicipio($input: UpdateMunicipioInput!, $condition: ModelMunicipioConditionInput) {
        updateMunicipio(input: $input, condition: $condition) {
          __typename
          id
          nombre
          clave
          entidadId
          cabecera
          claveCab
          pobMasc
          pobFem
          pobTotal
          totalVivHab
          createdAt
          updatedAt
          nombreEnt
          nombreEntAbrev
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateMunicipioMutation>response.data.updateMunicipio;
  }
  async DeleteMunicipio(
    input: DeleteMunicipioInput,
    condition?: ModelMunicipioConditionInput
  ): Promise<DeleteMunicipioMutation> {
    const statement = `mutation DeleteMunicipio($input: DeleteMunicipioInput!, $condition: ModelMunicipioConditionInput) {
        deleteMunicipio(input: $input, condition: $condition) {
          __typename
          id
          nombre
          clave
          entidadId
          cabecera
          claveCab
          pobMasc
          pobFem
          pobTotal
          totalVivHab
          createdAt
          updatedAt
          nombreEnt
          nombreEntAbrev
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteMunicipioMutation>response.data.deleteMunicipio;
  }
  async CreateLocalidad(
    input: CreateLocalidadInput,
    condition?: ModelLocalidadConditionInput
  ): Promise<CreateLocalidadMutation> {
    const statement = `mutation CreateLocalidad($input: CreateLocalidadInput!, $condition: ModelLocalidadConditionInput) {
        createLocalidad(input: $input, condition: $condition) {
          __typename
          id
          nombre
          entidadId
          municipioId
          clave
          ambito
          latitud
          longitud
          altitud
          pobMasc
          pobFem
          pobTotal
          totalVivHab
          createdAt
          updatedAt
          nombreEnt
          nombreEntAbrev
          nombreMun
          claveCarta
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateLocalidadMutation>response.data.createLocalidad;
  }
  async UpdateLocalidad(
    input: UpdateLocalidadInput,
    condition?: ModelLocalidadConditionInput
  ): Promise<UpdateLocalidadMutation> {
    const statement = `mutation UpdateLocalidad($input: UpdateLocalidadInput!, $condition: ModelLocalidadConditionInput) {
        updateLocalidad(input: $input, condition: $condition) {
          __typename
          id
          nombre
          entidadId
          municipioId
          clave
          ambito
          latitud
          longitud
          altitud
          pobMasc
          pobFem
          pobTotal
          totalVivHab
          createdAt
          updatedAt
          nombreEnt
          nombreEntAbrev
          nombreMun
          claveCarta
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateLocalidadMutation>response.data.updateLocalidad;
  }
  async DeleteLocalidad(
    input: DeleteLocalidadInput,
    condition?: ModelLocalidadConditionInput
  ): Promise<DeleteLocalidadMutation> {
    const statement = `mutation DeleteLocalidad($input: DeleteLocalidadInput!, $condition: ModelLocalidadConditionInput) {
        deleteLocalidad(input: $input, condition: $condition) {
          __typename
          id
          nombre
          entidadId
          municipioId
          clave
          ambito
          latitud
          longitud
          altitud
          pobMasc
          pobFem
          pobTotal
          totalVivHab
          createdAt
          updatedAt
          nombreEnt
          nombreEntAbrev
          nombreMun
          claveCarta
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteLocalidadMutation>response.data.deleteLocalidad;
  }
  async CreateCodigoPostal(
    input: CreateCodigoPostalInput,
    condition?: ModelCodigoPostalConditionInput
  ): Promise<CreateCodigoPostalMutation> {
    const statement = `mutation CreateCodigoPostal($input: CreateCodigoPostalInput!, $condition: ModelCodigoPostalConditionInput) {
        createCodigoPostal(input: $input, condition: $condition) {
          __typename
          id
          codigo
          asentamiento
          tipoAsent
          String
          claveTipoAsent
          asentCpcons
          zona
          entidadId
          municipioId
          ciudad
          claveCd
          cpOficina
          createdAt
          updatedAt
          nombreMun
          nombreEnt
          dCP
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateCodigoPostalMutation>response.data.createCodigoPostal;
  }
  async UpdateCodigoPostal(
    input: UpdateCodigoPostalInput,
    condition?: ModelCodigoPostalConditionInput
  ): Promise<UpdateCodigoPostalMutation> {
    const statement = `mutation UpdateCodigoPostal($input: UpdateCodigoPostalInput!, $condition: ModelCodigoPostalConditionInput) {
        updateCodigoPostal(input: $input, condition: $condition) {
          __typename
          id
          codigo
          asentamiento
          tipoAsent
          String
          claveTipoAsent
          asentCpcons
          zona
          entidadId
          municipioId
          ciudad
          claveCd
          cpOficina
          createdAt
          updatedAt
          nombreMun
          nombreEnt
          dCP
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateCodigoPostalMutation>response.data.updateCodigoPostal;
  }
  async DeleteCodigoPostal(
    input: DeleteCodigoPostalInput,
    condition?: ModelCodigoPostalConditionInput
  ): Promise<DeleteCodigoPostalMutation> {
    const statement = `mutation DeleteCodigoPostal($input: DeleteCodigoPostalInput!, $condition: ModelCodigoPostalConditionInput) {
        deleteCodigoPostal(input: $input, condition: $condition) {
          __typename
          id
          codigo
          asentamiento
          tipoAsent
          String
          claveTipoAsent
          asentCpcons
          zona
          entidadId
          municipioId
          ciudad
          claveCd
          cpOficina
          createdAt
          updatedAt
          nombreMun
          nombreEnt
          dCP
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteCodigoPostalMutation>response.data.deleteCodigoPostal;
  }
  async CreateCiclo(
    input: CreateCicloInput,
    condition?: ModelCicloConditionInput
  ): Promise<CreateCicloMutation> {
    const statement = `mutation CreateCiclo($input: CreateCicloInput!, $condition: ModelCicloConditionInput) {
        createCiclo(input: $input, condition: $condition) {
          __typename
          id
          nombre
          fechaInicio
          fechaFin
          activo
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateCicloMutation>response.data.createCiclo;
  }
  async UpdateCiclo(
    input: UpdateCicloInput,
    condition?: ModelCicloConditionInput
  ): Promise<UpdateCicloMutation> {
    const statement = `mutation UpdateCiclo($input: UpdateCicloInput!, $condition: ModelCicloConditionInput) {
        updateCiclo(input: $input, condition: $condition) {
          __typename
          id
          nombre
          fechaInicio
          fechaFin
          activo
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateCicloMutation>response.data.updateCiclo;
  }
  async DeleteCiclo(
    input: DeleteCicloInput,
    condition?: ModelCicloConditionInput
  ): Promise<DeleteCicloMutation> {
    const statement = `mutation DeleteCiclo($input: DeleteCicloInput!, $condition: ModelCicloConditionInput) {
        deleteCiclo(input: $input, condition: $condition) {
          __typename
          id
          nombre
          fechaInicio
          fechaFin
          activo
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteCicloMutation>response.data.deleteCiclo;
  }
  async CreateInfoCiclo(
    input: CreateInfoCicloInput,
    condition?: ModelInfoCicloConditionInput
  ): Promise<CreateInfoCicloMutation> {
    const statement = `mutation CreateInfoCiclo($input: CreateInfoCicloInput!, $condition: ModelInfoCicloConditionInput) {
        createInfoCiclo(input: $input, condition: $condition) {
          __typename
          id
          cicloId
          ciclo {
            __typename
            id
            nombre
            fechaInicio
            fechaFin
            activo
            createdAt
            updatedAt
          }
          productorId
          productor {
            __typename
            id
            searchField
            userid
            username
            folioProductorId
            organizacioncafeOrganizacionId
            Organizacion {
              __typename
              id
              searchFields
              nombre
              figuraJuridica
              telefono
              email
              anoConstitucion
              regionId
              direccion
              codigoPostal
              coloniaId
              estadoId
              municipioId
              localidadId
              reprNombre
              reprCargo
              reprTelefono
              reprEmail
              createdAt
              updatedAt
            }
            nombre
            aPaterno
            aMaterno
            fechaNac
            sexo
            curp
            rfc
            direccion
            codigoPostal
            estadoId
            estado {
              __typename
              id
              nombre
              nombreAbrev
              clave
              pobMasc
              pobFem
              pobTotal
              totalVivHab
              createdAt
              updatedAt
            }
            municipioId
            municipio {
              __typename
              id
              nombre
              clave
              entidadId
              cabecera
              claveCab
              pobMasc
              pobFem
              pobTotal
              totalVivHab
              createdAt
              updatedAt
              nombreEnt
              nombreEntAbrev
            }
            localidadId
            localidad {
              __typename
              id
              nombre
              entidadId
              municipioId
              clave
              ambito
              latitud
              longitud
              altitud
              pobMasc
              pobFem
              pobTotal
              totalVivHab
              createdAt
              updatedAt
              nombreEnt
              nombreEntAbrev
              nombreMun
              claveCarta
            }
            latitud
            longitud
            createdAt
            updatedAt
          }
          organizacionId
          organizacion {
            __typename
            id
            searchFields
            nombre
            figuraJuridica
            telefono
            email
            anoConstitucion
            regionId
            region {
              __typename
              id
              nombre
              createdAt
              updatedAt
            }
            direccion
            codigoPostal
            coloniaId
            colonia {
              __typename
              id
              codigo
              asentamiento
              tipoAsent
              String
              claveTipoAsent
              asentCpcons
              zona
              entidadId
              municipioId
              ciudad
              claveCd
              cpOficina
              createdAt
              updatedAt
              nombreMun
              nombreEnt
              dCP
            }
            estadoId
            estado {
              __typename
              id
              nombre
              nombreAbrev
              clave
              pobMasc
              pobFem
              pobTotal
              totalVivHab
              createdAt
              updatedAt
            }
            municipioId
            municipio {
              __typename
              id
              nombre
              clave
              entidadId
              cabecera
              claveCab
              pobMasc
              pobFem
              pobTotal
              totalVivHab
              createdAt
              updatedAt
              nombreEnt
              nombreEntAbrev
            }
            localidadId
            localidad {
              __typename
              id
              nombre
              entidadId
              municipioId
              clave
              ambito
              latitud
              longitud
              altitud
              pobMasc
              pobFem
              pobTotal
              totalVivHab
              createdAt
              updatedAt
              nombreEnt
              nombreEntAbrev
              nombreMun
              claveCarta
            }
            reprNombre
            reprCargo
            reprTelefono
            reprEmail
            createdAt
            updatedAt
          }
          datosGenerales {
            __typename
            areasTrabajo
            infraestructura
            equiposOficina
            certificaciones
            estatus
          }
          produccion {
            __typename
            superficie {
              __typename
              cafeOrganico
              cafeTransicion
              basicos
              potrero
              acahual
              montania
            }
            produccionPromedio {
              __typename
              estimacion
            }
            jornales {
              __typename
              primeraLimpia
              segundaLimpia
              cajete
              podaYdeshije
              desombre
              abonera
              regarAbono
              resiembra
              controlPlagas
              conservacionSuelo
              semilleroVivero
              cosecha
              beneficioHumedo
              otros
              precioPagado
            }
            insumos {
              __typename
              beauveria
              abonoFoliar
              calAgricola
              costalera
              canastos
              mantenimientoEquipos
              certificaciones
              alimentacionPersonal
              otros
            }
            rendimientoCafetales
            factoresRendimiento
            condicionesCafetales
            variedadesCafe
            edadPlantaciones
            disenioPlantaciones
            densidadPlantaciones
            alturaPlantaciones
            floracion
          }
          calidad {
            __typename
            despulpadoraTipos
            equipoCondicion
            tanqueTipos
            secadoInfraestructuras
            cafeCorte
            fermentacionHora
            conocenCalidad
            cafeCalidad
          }
          mercado {
            __typename
            mercado {
              __typename
              prodAcopiada
              cafeExportado
              cafeVendidoNacional
              cafeVendidoLocal
              precioExportacion
              precioNacional
              precioLocal
              sobreprecio
              clientesExtranjeros
            }
            exportacionMercados
          }
          desarrollo {
            __typename
            cursosRecibidos
            cursosTipos
            reforzarProcesos
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateInfoCicloMutation>response.data.createInfoCiclo;
  }
  async UpdateInfoCiclo(
    input: UpdateInfoCicloInput,
    condition?: ModelInfoCicloConditionInput
  ): Promise<UpdateInfoCicloMutation> {
    const statement = `mutation UpdateInfoCiclo($input: UpdateInfoCicloInput!, $condition: ModelInfoCicloConditionInput) {
        updateInfoCiclo(input: $input, condition: $condition) {
          __typename
          id
          cicloId
          ciclo {
            __typename
            id
            nombre
            fechaInicio
            fechaFin
            activo
            createdAt
            updatedAt
          }
          productorId
          productor {
            __typename
            id
            searchField
            userid
            username
            folioProductorId
            organizacioncafeOrganizacionId
            Organizacion {
              __typename
              id
              searchFields
              nombre
              figuraJuridica
              telefono
              email
              anoConstitucion
              regionId
              direccion
              codigoPostal
              coloniaId
              estadoId
              municipioId
              localidadId
              reprNombre
              reprCargo
              reprTelefono
              reprEmail
              createdAt
              updatedAt
            }
            nombre
            aPaterno
            aMaterno
            fechaNac
            sexo
            curp
            rfc
            direccion
            codigoPostal
            estadoId
            estado {
              __typename
              id
              nombre
              nombreAbrev
              clave
              pobMasc
              pobFem
              pobTotal
              totalVivHab
              createdAt
              updatedAt
            }
            municipioId
            municipio {
              __typename
              id
              nombre
              clave
              entidadId
              cabecera
              claveCab
              pobMasc
              pobFem
              pobTotal
              totalVivHab
              createdAt
              updatedAt
              nombreEnt
              nombreEntAbrev
            }
            localidadId
            localidad {
              __typename
              id
              nombre
              entidadId
              municipioId
              clave
              ambito
              latitud
              longitud
              altitud
              pobMasc
              pobFem
              pobTotal
              totalVivHab
              createdAt
              updatedAt
              nombreEnt
              nombreEntAbrev
              nombreMun
              claveCarta
            }
            latitud
            longitud
            createdAt
            updatedAt
          }
          organizacionId
          organizacion {
            __typename
            id
            searchFields
            nombre
            figuraJuridica
            telefono
            email
            anoConstitucion
            regionId
            region {
              __typename
              id
              nombre
              createdAt
              updatedAt
            }
            direccion
            codigoPostal
            coloniaId
            colonia {
              __typename
              id
              codigo
              asentamiento
              tipoAsent
              String
              claveTipoAsent
              asentCpcons
              zona
              entidadId
              municipioId
              ciudad
              claveCd
              cpOficina
              createdAt
              updatedAt
              nombreMun
              nombreEnt
              dCP
            }
            estadoId
            estado {
              __typename
              id
              nombre
              nombreAbrev
              clave
              pobMasc
              pobFem
              pobTotal
              totalVivHab
              createdAt
              updatedAt
            }
            municipioId
            municipio {
              __typename
              id
              nombre
              clave
              entidadId
              cabecera
              claveCab
              pobMasc
              pobFem
              pobTotal
              totalVivHab
              createdAt
              updatedAt
              nombreEnt
              nombreEntAbrev
            }
            localidadId
            localidad {
              __typename
              id
              nombre
              entidadId
              municipioId
              clave
              ambito
              latitud
              longitud
              altitud
              pobMasc
              pobFem
              pobTotal
              totalVivHab
              createdAt
              updatedAt
              nombreEnt
              nombreEntAbrev
              nombreMun
              claveCarta
            }
            reprNombre
            reprCargo
            reprTelefono
            reprEmail
            createdAt
            updatedAt
          }
          datosGenerales {
            __typename
            areasTrabajo
            infraestructura
            equiposOficina
            certificaciones
            estatus
          }
          produccion {
            __typename
            superficie {
              __typename
              cafeOrganico
              cafeTransicion
              basicos
              potrero
              acahual
              montania
            }
            produccionPromedio {
              __typename
              estimacion
            }
            jornales {
              __typename
              primeraLimpia
              segundaLimpia
              cajete
              podaYdeshije
              desombre
              abonera
              regarAbono
              resiembra
              controlPlagas
              conservacionSuelo
              semilleroVivero
              cosecha
              beneficioHumedo
              otros
              precioPagado
            }
            insumos {
              __typename
              beauveria
              abonoFoliar
              calAgricola
              costalera
              canastos
              mantenimientoEquipos
              certificaciones
              alimentacionPersonal
              otros
            }
            rendimientoCafetales
            factoresRendimiento
            condicionesCafetales
            variedadesCafe
            edadPlantaciones
            disenioPlantaciones
            densidadPlantaciones
            alturaPlantaciones
            floracion
          }
          calidad {
            __typename
            despulpadoraTipos
            equipoCondicion
            tanqueTipos
            secadoInfraestructuras
            cafeCorte
            fermentacionHora
            conocenCalidad
            cafeCalidad
          }
          mercado {
            __typename
            mercado {
              __typename
              prodAcopiada
              cafeExportado
              cafeVendidoNacional
              cafeVendidoLocal
              precioExportacion
              precioNacional
              precioLocal
              sobreprecio
              clientesExtranjeros
            }
            exportacionMercados
          }
          desarrollo {
            __typename
            cursosRecibidos
            cursosTipos
            reforzarProcesos
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateInfoCicloMutation>response.data.updateInfoCiclo;
  }
  async DeleteInfoCiclo(
    input: DeleteInfoCicloInput,
    condition?: ModelInfoCicloConditionInput
  ): Promise<DeleteInfoCicloMutation> {
    const statement = `mutation DeleteInfoCiclo($input: DeleteInfoCicloInput!, $condition: ModelInfoCicloConditionInput) {
        deleteInfoCiclo(input: $input, condition: $condition) {
          __typename
          id
          cicloId
          ciclo {
            __typename
            id
            nombre
            fechaInicio
            fechaFin
            activo
            createdAt
            updatedAt
          }
          productorId
          productor {
            __typename
            id
            searchField
            userid
            username
            folioProductorId
            organizacioncafeOrganizacionId
            Organizacion {
              __typename
              id
              searchFields
              nombre
              figuraJuridica
              telefono
              email
              anoConstitucion
              regionId
              direccion
              codigoPostal
              coloniaId
              estadoId
              municipioId
              localidadId
              reprNombre
              reprCargo
              reprTelefono
              reprEmail
              createdAt
              updatedAt
            }
            nombre
            aPaterno
            aMaterno
            fechaNac
            sexo
            curp
            rfc
            direccion
            codigoPostal
            estadoId
            estado {
              __typename
              id
              nombre
              nombreAbrev
              clave
              pobMasc
              pobFem
              pobTotal
              totalVivHab
              createdAt
              updatedAt
            }
            municipioId
            municipio {
              __typename
              id
              nombre
              clave
              entidadId
              cabecera
              claveCab
              pobMasc
              pobFem
              pobTotal
              totalVivHab
              createdAt
              updatedAt
              nombreEnt
              nombreEntAbrev
            }
            localidadId
            localidad {
              __typename
              id
              nombre
              entidadId
              municipioId
              clave
              ambito
              latitud
              longitud
              altitud
              pobMasc
              pobFem
              pobTotal
              totalVivHab
              createdAt
              updatedAt
              nombreEnt
              nombreEntAbrev
              nombreMun
              claveCarta
            }
            latitud
            longitud
            createdAt
            updatedAt
          }
          organizacionId
          organizacion {
            __typename
            id
            searchFields
            nombre
            figuraJuridica
            telefono
            email
            anoConstitucion
            regionId
            region {
              __typename
              id
              nombre
              createdAt
              updatedAt
            }
            direccion
            codigoPostal
            coloniaId
            colonia {
              __typename
              id
              codigo
              asentamiento
              tipoAsent
              String
              claveTipoAsent
              asentCpcons
              zona
              entidadId
              municipioId
              ciudad
              claveCd
              cpOficina
              createdAt
              updatedAt
              nombreMun
              nombreEnt
              dCP
            }
            estadoId
            estado {
              __typename
              id
              nombre
              nombreAbrev
              clave
              pobMasc
              pobFem
              pobTotal
              totalVivHab
              createdAt
              updatedAt
            }
            municipioId
            municipio {
              __typename
              id
              nombre
              clave
              entidadId
              cabecera
              claveCab
              pobMasc
              pobFem
              pobTotal
              totalVivHab
              createdAt
              updatedAt
              nombreEnt
              nombreEntAbrev
            }
            localidadId
            localidad {
              __typename
              id
              nombre
              entidadId
              municipioId
              clave
              ambito
              latitud
              longitud
              altitud
              pobMasc
              pobFem
              pobTotal
              totalVivHab
              createdAt
              updatedAt
              nombreEnt
              nombreEntAbrev
              nombreMun
              claveCarta
            }
            reprNombre
            reprCargo
            reprTelefono
            reprEmail
            createdAt
            updatedAt
          }
          datosGenerales {
            __typename
            areasTrabajo
            infraestructura
            equiposOficina
            certificaciones
            estatus
          }
          produccion {
            __typename
            superficie {
              __typename
              cafeOrganico
              cafeTransicion
              basicos
              potrero
              acahual
              montania
            }
            produccionPromedio {
              __typename
              estimacion
            }
            jornales {
              __typename
              primeraLimpia
              segundaLimpia
              cajete
              podaYdeshije
              desombre
              abonera
              regarAbono
              resiembra
              controlPlagas
              conservacionSuelo
              semilleroVivero
              cosecha
              beneficioHumedo
              otros
              precioPagado
            }
            insumos {
              __typename
              beauveria
              abonoFoliar
              calAgricola
              costalera
              canastos
              mantenimientoEquipos
              certificaciones
              alimentacionPersonal
              otros
            }
            rendimientoCafetales
            factoresRendimiento
            condicionesCafetales
            variedadesCafe
            edadPlantaciones
            disenioPlantaciones
            densidadPlantaciones
            alturaPlantaciones
            floracion
          }
          calidad {
            __typename
            despulpadoraTipos
            equipoCondicion
            tanqueTipos
            secadoInfraestructuras
            cafeCorte
            fermentacionHora
            conocenCalidad
            cafeCalidad
          }
          mercado {
            __typename
            mercado {
              __typename
              prodAcopiada
              cafeExportado
              cafeVendidoNacional
              cafeVendidoLocal
              precioExportacion
              precioNacional
              precioLocal
              sobreprecio
              clientesExtranjeros
            }
            exportacionMercados
          }
          desarrollo {
            __typename
            cursosRecibidos
            cursosTipos
            reforzarProcesos
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteInfoCicloMutation>response.data.deleteInfoCiclo;
  }
  async CreatePadronCafetalero(
    input: CreatePadronCafetaleroInput,
    condition?: ModelPadronCafetaleroConditionInput
  ): Promise<CreatePadronCafetaleroMutation> {
    const statement = `mutation CreatePadronCafetalero($input: CreatePadronCafetaleroInput!, $condition: ModelPadronCafetaleroConditionInput) {
        createPadronCafetalero(input: $input, condition: $condition) {
          __typename
          id
          type
          curp
          folioProductorId
          nombre
          aPaterno
          aMaterno
          fechaNac
          sexo
          rfc
          calle
          numero
          colonia
          entidadId
          nombreEnt
          municipioId
          nombreMun
          localidadId
          nombreLoc
          folioProdPred
          folioPredioId
          nombrePredio
          superficieCafe
          predioEntidadId
          predioNombreEnt
          predioMunicipioId
          predioNombreMun
          predioLocalidadId
          predioNombreLoc
          latitudGrados
          longitudGrados
          latitud
          longitud
          searchField
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreatePadronCafetaleroMutation>response.data.createPadronCafetalero;
  }
  async UpdatePadronCafetalero(
    input: UpdatePadronCafetaleroInput,
    condition?: ModelPadronCafetaleroConditionInput
  ): Promise<UpdatePadronCafetaleroMutation> {
    const statement = `mutation UpdatePadronCafetalero($input: UpdatePadronCafetaleroInput!, $condition: ModelPadronCafetaleroConditionInput) {
        updatePadronCafetalero(input: $input, condition: $condition) {
          __typename
          id
          type
          curp
          folioProductorId
          nombre
          aPaterno
          aMaterno
          fechaNac
          sexo
          rfc
          calle
          numero
          colonia
          entidadId
          nombreEnt
          municipioId
          nombreMun
          localidadId
          nombreLoc
          folioProdPred
          folioPredioId
          nombrePredio
          superficieCafe
          predioEntidadId
          predioNombreEnt
          predioMunicipioId
          predioNombreMun
          predioLocalidadId
          predioNombreLoc
          latitudGrados
          longitudGrados
          latitud
          longitud
          searchField
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdatePadronCafetaleroMutation>response.data.updatePadronCafetalero;
  }
  async DeletePadronCafetalero(
    input: DeletePadronCafetaleroInput,
    condition?: ModelPadronCafetaleroConditionInput
  ): Promise<DeletePadronCafetaleroMutation> {
    const statement = `mutation DeletePadronCafetalero($input: DeletePadronCafetaleroInput!, $condition: ModelPadronCafetaleroConditionInput) {
        deletePadronCafetalero(input: $input, condition: $condition) {
          __typename
          id
          type
          curp
          folioProductorId
          nombre
          aPaterno
          aMaterno
          fechaNac
          sexo
          rfc
          calle
          numero
          colonia
          entidadId
          nombreEnt
          municipioId
          nombreMun
          localidadId
          nombreLoc
          folioProdPred
          folioPredioId
          nombrePredio
          superficieCafe
          predioEntidadId
          predioNombreEnt
          predioMunicipioId
          predioNombreMun
          predioLocalidadId
          predioNombreLoc
          latitudGrados
          longitudGrados
          latitud
          longitud
          searchField
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeletePadronCafetaleroMutation>response.data.deletePadronCafetalero;
  }
  async CreateInfoCicloOrganizacion(
    input: CreateInfoCicloOrganizacionInput,
    condition?: ModelInfoCicloOrganizacionConditionInput
  ): Promise<CreateInfoCicloOrganizacionMutation> {
    const statement = `mutation CreateInfoCicloOrganizacion($input: CreateInfoCicloOrganizacionInput!, $condition: ModelInfoCicloOrganizacionConditionInput) {
        createInfoCicloOrganizacion(input: $input, condition: $condition) {
          __typename
          id
          ciclo {
            __typename
            id
            nombre
            fechaInicio
            fechaFin
            activo
            createdAt
            updatedAt
          }
          organizacion {
            __typename
            id
            searchFields
            nombre
            figuraJuridica
            telefono
            email
            anoConstitucion
            regionId
            region {
              __typename
              id
              nombre
              createdAt
              updatedAt
            }
            direccion
            codigoPostal
            coloniaId
            colonia {
              __typename
              id
              codigo
              asentamiento
              tipoAsent
              String
              claveTipoAsent
              asentCpcons
              zona
              entidadId
              municipioId
              ciudad
              claveCd
              cpOficina
              createdAt
              updatedAt
              nombreMun
              nombreEnt
              dCP
            }
            estadoId
            estado {
              __typename
              id
              nombre
              nombreAbrev
              clave
              pobMasc
              pobFem
              pobTotal
              totalVivHab
              createdAt
              updatedAt
            }
            municipioId
            municipio {
              __typename
              id
              nombre
              clave
              entidadId
              cabecera
              claveCab
              pobMasc
              pobFem
              pobTotal
              totalVivHab
              createdAt
              updatedAt
              nombreEnt
              nombreEntAbrev
            }
            localidadId
            localidad {
              __typename
              id
              nombre
              entidadId
              municipioId
              clave
              ambito
              latitud
              longitud
              altitud
              pobMasc
              pobFem
              pobTotal
              totalVivHab
              createdAt
              updatedAt
              nombreEnt
              nombreEntAbrev
              nombreMun
              claveCarta
            }
            reprNombre
            reprCargo
            reprTelefono
            reprEmail
            createdAt
            updatedAt
          }
          cicloId
          organizacionId
          datosGenerales {
            __typename
            numeroSocios {
              __typename
              numHombres
              numMujeres
            }
            haProduccion {
              __typename
              cafeOrganico
              cafeTransicion
              otrosCultivos
            }
            haPorcentajes {
              __typename
              haMenosDeMedia
              haDeMediaAUna
              haDeUnaADos
              haDeDosACinco
              haDeCincoADiez
              haMasDeDiez
            }
            areasTrabajo
            equiposOficina
            certificaciones
            estatus
          }
          produccion {
            __typename
            produccionXproductor {
              __typename
              maximo
              promedio
              minimo
            }
            jornales {
              __typename
              primeraLimpia
              segundaLimpia
              cajete
              podaYdeshije
              desombre
              abonera
              regarAbono
              resiembra
              controlPlagas
              conservacionSuelo
              semilleroVivero
              cosecha
              beneficioHumedo
              otros
              precioPagado
            }
            insumos {
              __typename
              beauveria
              abonoFoliar
              calAgricola
              costalera
              canastos
              mantenimientoEquipos
              certificaciones
              alimentacionPersonal
              otros
            }
            variedadesCafe {
              __typename
              typica
              bourbon
              mundoNovo
              caturra
              catimor
              otros
            }
            edadPlantaciones {
              __typename
              menosDe5
              entre5y15
              entre15y30
              masDe30
            }
            disenioPlantaciones {
              __typename
              tresbolillo
              rectangular
              curvasANivel
              sinMarcos
            }
            densidadPlantaciones {
              __typename
              menosDe1000
              entre1000y1500
              masDe1500
            }
            alturaPlantaciones {
              __typename
              menosDe600
              entre600y900
              entre900y1200
              masDe1200
            }
            floracion {
              __typename
              ninguna
              primera
              segunda
              tercera
            }
            rendimientoCafetales
            factoresRendimiento
            condicionesCafetales
          }
          calidad {
            __typename
            infraestructura
            despulpadoraTipos {
              __typename
              cilindro
              disco
              beneficio
            }
            equipoCondicion {
              __typename
              mala
              regular
              buena
              excelente
            }
            tanqueTipos {
              __typename
              madera
              cemento
              tina
            }
            secadoInfraestructuras {
              __typename
              patio
              zarandas
              gas
              excelente
            }
            cafeCorte {
              __typename
              uno
              dos
              masDeDos
            }
            fermentacionHora {
              __typename
              menosDe10
              entre10y15
              entre15y20
            }
            conocenCalidad
            cafeCalidad {
              __typename
              mala
              regular
              buena
              excelente
            }
          }
          mercado {
            __typename
            mercado {
              __typename
              prodAcopiada
              cafeExportado
              cafeVendidoNacional
              cafeVendidoLocal
              precioExportacion
              precioNacional
              precioLocal
              sobreprecio
              clientesExtranjeros
            }
            exportacionMercados
          }
          desarrollo {
            __typename
            cursosRecibidos
            cursosTipos
            reforzarProcesos
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateInfoCicloOrganizacionMutation>(
      response.data.createInfoCicloOrganizacion
    );
  }
  async UpdateInfoCicloOrganizacion(
    input: UpdateInfoCicloOrganizacionInput,
    condition?: ModelInfoCicloOrganizacionConditionInput
  ): Promise<UpdateInfoCicloOrganizacionMutation> {
    const statement = `mutation UpdateInfoCicloOrganizacion($input: UpdateInfoCicloOrganizacionInput!, $condition: ModelInfoCicloOrganizacionConditionInput) {
        updateInfoCicloOrganizacion(input: $input, condition: $condition) {
          __typename
          id
          ciclo {
            __typename
            id
            nombre
            fechaInicio
            fechaFin
            activo
            createdAt
            updatedAt
          }
          organizacion {
            __typename
            id
            searchFields
            nombre
            figuraJuridica
            telefono
            email
            anoConstitucion
            regionId
            region {
              __typename
              id
              nombre
              createdAt
              updatedAt
            }
            direccion
            codigoPostal
            coloniaId
            colonia {
              __typename
              id
              codigo
              asentamiento
              tipoAsent
              String
              claveTipoAsent
              asentCpcons
              zona
              entidadId
              municipioId
              ciudad
              claveCd
              cpOficina
              createdAt
              updatedAt
              nombreMun
              nombreEnt
              dCP
            }
            estadoId
            estado {
              __typename
              id
              nombre
              nombreAbrev
              clave
              pobMasc
              pobFem
              pobTotal
              totalVivHab
              createdAt
              updatedAt
            }
            municipioId
            municipio {
              __typename
              id
              nombre
              clave
              entidadId
              cabecera
              claveCab
              pobMasc
              pobFem
              pobTotal
              totalVivHab
              createdAt
              updatedAt
              nombreEnt
              nombreEntAbrev
            }
            localidadId
            localidad {
              __typename
              id
              nombre
              entidadId
              municipioId
              clave
              ambito
              latitud
              longitud
              altitud
              pobMasc
              pobFem
              pobTotal
              totalVivHab
              createdAt
              updatedAt
              nombreEnt
              nombreEntAbrev
              nombreMun
              claveCarta
            }
            reprNombre
            reprCargo
            reprTelefono
            reprEmail
            createdAt
            updatedAt
          }
          cicloId
          organizacionId
          datosGenerales {
            __typename
            numeroSocios {
              __typename
              numHombres
              numMujeres
            }
            haProduccion {
              __typename
              cafeOrganico
              cafeTransicion
              otrosCultivos
            }
            haPorcentajes {
              __typename
              haMenosDeMedia
              haDeMediaAUna
              haDeUnaADos
              haDeDosACinco
              haDeCincoADiez
              haMasDeDiez
            }
            areasTrabajo
            equiposOficina
            certificaciones
            estatus
          }
          produccion {
            __typename
            produccionXproductor {
              __typename
              maximo
              promedio
              minimo
            }
            jornales {
              __typename
              primeraLimpia
              segundaLimpia
              cajete
              podaYdeshije
              desombre
              abonera
              regarAbono
              resiembra
              controlPlagas
              conservacionSuelo
              semilleroVivero
              cosecha
              beneficioHumedo
              otros
              precioPagado
            }
            insumos {
              __typename
              beauveria
              abonoFoliar
              calAgricola
              costalera
              canastos
              mantenimientoEquipos
              certificaciones
              alimentacionPersonal
              otros
            }
            variedadesCafe {
              __typename
              typica
              bourbon
              mundoNovo
              caturra
              catimor
              otros
            }
            edadPlantaciones {
              __typename
              menosDe5
              entre5y15
              entre15y30
              masDe30
            }
            disenioPlantaciones {
              __typename
              tresbolillo
              rectangular
              curvasANivel
              sinMarcos
            }
            densidadPlantaciones {
              __typename
              menosDe1000
              entre1000y1500
              masDe1500
            }
            alturaPlantaciones {
              __typename
              menosDe600
              entre600y900
              entre900y1200
              masDe1200
            }
            floracion {
              __typename
              ninguna
              primera
              segunda
              tercera
            }
            rendimientoCafetales
            factoresRendimiento
            condicionesCafetales
          }
          calidad {
            __typename
            infraestructura
            despulpadoraTipos {
              __typename
              cilindro
              disco
              beneficio
            }
            equipoCondicion {
              __typename
              mala
              regular
              buena
              excelente
            }
            tanqueTipos {
              __typename
              madera
              cemento
              tina
            }
            secadoInfraestructuras {
              __typename
              patio
              zarandas
              gas
              excelente
            }
            cafeCorte {
              __typename
              uno
              dos
              masDeDos
            }
            fermentacionHora {
              __typename
              menosDe10
              entre10y15
              entre15y20
            }
            conocenCalidad
            cafeCalidad {
              __typename
              mala
              regular
              buena
              excelente
            }
          }
          mercado {
            __typename
            mercado {
              __typename
              prodAcopiada
              cafeExportado
              cafeVendidoNacional
              cafeVendidoLocal
              precioExportacion
              precioNacional
              precioLocal
              sobreprecio
              clientesExtranjeros
            }
            exportacionMercados
          }
          desarrollo {
            __typename
            cursosRecibidos
            cursosTipos
            reforzarProcesos
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateInfoCicloOrganizacionMutation>(
      response.data.updateInfoCicloOrganizacion
    );
  }
  async DeleteInfoCicloOrganizacion(
    input: DeleteInfoCicloOrganizacionInput,
    condition?: ModelInfoCicloOrganizacionConditionInput
  ): Promise<DeleteInfoCicloOrganizacionMutation> {
    const statement = `mutation DeleteInfoCicloOrganizacion($input: DeleteInfoCicloOrganizacionInput!, $condition: ModelInfoCicloOrganizacionConditionInput) {
        deleteInfoCicloOrganizacion(input: $input, condition: $condition) {
          __typename
          id
          ciclo {
            __typename
            id
            nombre
            fechaInicio
            fechaFin
            activo
            createdAt
            updatedAt
          }
          organizacion {
            __typename
            id
            searchFields
            nombre
            figuraJuridica
            telefono
            email
            anoConstitucion
            regionId
            region {
              __typename
              id
              nombre
              createdAt
              updatedAt
            }
            direccion
            codigoPostal
            coloniaId
            colonia {
              __typename
              id
              codigo
              asentamiento
              tipoAsent
              String
              claveTipoAsent
              asentCpcons
              zona
              entidadId
              municipioId
              ciudad
              claveCd
              cpOficina
              createdAt
              updatedAt
              nombreMun
              nombreEnt
              dCP
            }
            estadoId
            estado {
              __typename
              id
              nombre
              nombreAbrev
              clave
              pobMasc
              pobFem
              pobTotal
              totalVivHab
              createdAt
              updatedAt
            }
            municipioId
            municipio {
              __typename
              id
              nombre
              clave
              entidadId
              cabecera
              claveCab
              pobMasc
              pobFem
              pobTotal
              totalVivHab
              createdAt
              updatedAt
              nombreEnt
              nombreEntAbrev
            }
            localidadId
            localidad {
              __typename
              id
              nombre
              entidadId
              municipioId
              clave
              ambito
              latitud
              longitud
              altitud
              pobMasc
              pobFem
              pobTotal
              totalVivHab
              createdAt
              updatedAt
              nombreEnt
              nombreEntAbrev
              nombreMun
              claveCarta
            }
            reprNombre
            reprCargo
            reprTelefono
            reprEmail
            createdAt
            updatedAt
          }
          cicloId
          organizacionId
          datosGenerales {
            __typename
            numeroSocios {
              __typename
              numHombres
              numMujeres
            }
            haProduccion {
              __typename
              cafeOrganico
              cafeTransicion
              otrosCultivos
            }
            haPorcentajes {
              __typename
              haMenosDeMedia
              haDeMediaAUna
              haDeUnaADos
              haDeDosACinco
              haDeCincoADiez
              haMasDeDiez
            }
            areasTrabajo
            equiposOficina
            certificaciones
            estatus
          }
          produccion {
            __typename
            produccionXproductor {
              __typename
              maximo
              promedio
              minimo
            }
            jornales {
              __typename
              primeraLimpia
              segundaLimpia
              cajete
              podaYdeshije
              desombre
              abonera
              regarAbono
              resiembra
              controlPlagas
              conservacionSuelo
              semilleroVivero
              cosecha
              beneficioHumedo
              otros
              precioPagado
            }
            insumos {
              __typename
              beauveria
              abonoFoliar
              calAgricola
              costalera
              canastos
              mantenimientoEquipos
              certificaciones
              alimentacionPersonal
              otros
            }
            variedadesCafe {
              __typename
              typica
              bourbon
              mundoNovo
              caturra
              catimor
              otros
            }
            edadPlantaciones {
              __typename
              menosDe5
              entre5y15
              entre15y30
              masDe30
            }
            disenioPlantaciones {
              __typename
              tresbolillo
              rectangular
              curvasANivel
              sinMarcos
            }
            densidadPlantaciones {
              __typename
              menosDe1000
              entre1000y1500
              masDe1500
            }
            alturaPlantaciones {
              __typename
              menosDe600
              entre600y900
              entre900y1200
              masDe1200
            }
            floracion {
              __typename
              ninguna
              primera
              segunda
              tercera
            }
            rendimientoCafetales
            factoresRendimiento
            condicionesCafetales
          }
          calidad {
            __typename
            infraestructura
            despulpadoraTipos {
              __typename
              cilindro
              disco
              beneficio
            }
            equipoCondicion {
              __typename
              mala
              regular
              buena
              excelente
            }
            tanqueTipos {
              __typename
              madera
              cemento
              tina
            }
            secadoInfraestructuras {
              __typename
              patio
              zarandas
              gas
              excelente
            }
            cafeCorte {
              __typename
              uno
              dos
              masDeDos
            }
            fermentacionHora {
              __typename
              menosDe10
              entre10y15
              entre15y20
            }
            conocenCalidad
            cafeCalidad {
              __typename
              mala
              regular
              buena
              excelente
            }
          }
          mercado {
            __typename
            mercado {
              __typename
              prodAcopiada
              cafeExportado
              cafeVendidoNacional
              cafeVendidoLocal
              precioExportacion
              precioNacional
              precioLocal
              sobreprecio
              clientesExtranjeros
            }
            exportacionMercados
          }
          desarrollo {
            __typename
            cursosRecibidos
            cursosTipos
            reforzarProcesos
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteInfoCicloOrganizacionMutation>(
      response.data.deleteInfoCicloOrganizacion
    );
  }
  async CreateUsuario(
    input: CreateUsuarioInput,
    condition?: ModelUsuarioConditionInput
  ): Promise<CreateUsuarioMutation> {
    const statement = `mutation CreateUsuario($input: CreateUsuarioInput!, $condition: ModelUsuarioConditionInput) {
        createUsuario(input: $input, condition: $condition) {
          __typename
          id
          email
          nombreCompleto
          fechaNacimiento
          createdAt
          updatedAt
          owner
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateUsuarioMutation>response.data.createUsuario;
  }
  async UpdateUsuario(
    input: UpdateUsuarioInput,
    condition?: ModelUsuarioConditionInput
  ): Promise<UpdateUsuarioMutation> {
    const statement = `mutation UpdateUsuario($input: UpdateUsuarioInput!, $condition: ModelUsuarioConditionInput) {
        updateUsuario(input: $input, condition: $condition) {
          __typename
          id
          email
          nombreCompleto
          fechaNacimiento
          createdAt
          updatedAt
          owner
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateUsuarioMutation>response.data.updateUsuario;
  }
  async DeleteUsuario(
    input: DeleteUsuarioInput,
    condition?: ModelUsuarioConditionInput
  ): Promise<DeleteUsuarioMutation> {
    const statement = `mutation DeleteUsuario($input: DeleteUsuarioInput!, $condition: ModelUsuarioConditionInput) {
        deleteUsuario(input: $input, condition: $condition) {
          __typename
          id
          email
          nombreCompleto
          fechaNacimiento
          createdAt
          updatedAt
          owner
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteUsuarioMutation>response.data.deleteUsuario;
  }
  async GetOrganizacionCafe(id: string): Promise<GetOrganizacionCafeQuery> {
    const statement = `query GetOrganizacionCafe($id: ID!) {
        getOrganizacionCafe(id: $id) {
          __typename
          id
          searchFields
          nombre
          figuraJuridica
          telefono
          email
          anoConstitucion
          regionId
          region {
            __typename
            id
            nombre
            createdAt
            updatedAt
          }
          direccion
          codigoPostal
          coloniaId
          colonia {
            __typename
            id
            codigo
            asentamiento
            tipoAsent
            String
            claveTipoAsent
            asentCpcons
            zona
            entidadId
            municipioId
            ciudad
            claveCd
            cpOficina
            createdAt
            updatedAt
            nombreMun
            nombreEnt
            dCP
          }
          estadoId
          estado {
            __typename
            id
            nombre
            nombreAbrev
            clave
            pobMasc
            pobFem
            pobTotal
            totalVivHab
            createdAt
            updatedAt
          }
          municipioId
          municipio {
            __typename
            id
            nombre
            clave
            entidadId
            cabecera
            claveCab
            pobMasc
            pobFem
            pobTotal
            totalVivHab
            createdAt
            updatedAt
            nombreEnt
            nombreEntAbrev
          }
          localidadId
          localidad {
            __typename
            id
            nombre
            entidadId
            municipioId
            clave
            ambito
            latitud
            longitud
            altitud
            pobMasc
            pobFem
            pobTotal
            totalVivHab
            createdAt
            updatedAt
            nombreEnt
            nombreEntAbrev
            nombreMun
            claveCarta
          }
          reprNombre
          reprCargo
          reprTelefono
          reprEmail
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetOrganizacionCafeQuery>response.data.getOrganizacionCafe;
  }
  async ListOrganizacionCafes(
    filter?: ModelOrganizacionCafeFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListOrganizacionCafesQuery> {
    const statement = `query ListOrganizacionCafes($filter: ModelOrganizacionCafeFilterInput, $limit: Int, $nextToken: String) {
        listOrganizacionCafes(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            searchFields
            nombre
            figuraJuridica
            telefono
            email
            anoConstitucion
            regionId
            region {
              __typename
              id
              nombre
              createdAt
              updatedAt
            }
            direccion
            codigoPostal
            coloniaId
            colonia {
              __typename
              id
              codigo
              asentamiento
              tipoAsent
              String
              claveTipoAsent
              asentCpcons
              zona
              entidadId
              municipioId
              ciudad
              claveCd
              cpOficina
              createdAt
              updatedAt
              nombreMun
              nombreEnt
              dCP
            }
            estadoId
            estado {
              __typename
              id
              nombre
              nombreAbrev
              clave
              pobMasc
              pobFem
              pobTotal
              totalVivHab
              createdAt
              updatedAt
            }
            municipioId
            municipio {
              __typename
              id
              nombre
              clave
              entidadId
              cabecera
              claveCab
              pobMasc
              pobFem
              pobTotal
              totalVivHab
              createdAt
              updatedAt
              nombreEnt
              nombreEntAbrev
            }
            localidadId
            localidad {
              __typename
              id
              nombre
              entidadId
              municipioId
              clave
              ambito
              latitud
              longitud
              altitud
              pobMasc
              pobFem
              pobTotal
              totalVivHab
              createdAt
              updatedAt
              nombreEnt
              nombreEntAbrev
              nombreMun
              claveCarta
            }
            reprNombre
            reprCargo
            reprTelefono
            reprEmail
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListOrganizacionCafesQuery>response.data.listOrganizacionCafes;
  }
  async GetRegion(id: string): Promise<GetRegionQuery> {
    const statement = `query GetRegion($id: ID!) {
        getRegion(id: $id) {
          __typename
          id
          nombre
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetRegionQuery>response.data.getRegion;
  }
  async ListRegions(
    filter?: ModelRegionFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListRegionsQuery> {
    const statement = `query ListRegions($filter: ModelRegionFilterInput, $limit: Int, $nextToken: String) {
        listRegions(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            nombre
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListRegionsQuery>response.data.listRegions;
  }
  async GetProductor(id: string): Promise<GetProductorQuery> {
    const statement = `query GetProductor($id: ID!) {
        getProductor(id: $id) {
          __typename
          id
          searchField
          userid
          username
          folioProductorId
          organizacioncafeOrganizacionId
          Organizacion {
            __typename
            id
            searchFields
            nombre
            figuraJuridica
            telefono
            email
            anoConstitucion
            regionId
            region {
              __typename
              id
              nombre
              createdAt
              updatedAt
            }
            direccion
            codigoPostal
            coloniaId
            colonia {
              __typename
              id
              codigo
              asentamiento
              tipoAsent
              String
              claveTipoAsent
              asentCpcons
              zona
              entidadId
              municipioId
              ciudad
              claveCd
              cpOficina
              createdAt
              updatedAt
              nombreMun
              nombreEnt
              dCP
            }
            estadoId
            estado {
              __typename
              id
              nombre
              nombreAbrev
              clave
              pobMasc
              pobFem
              pobTotal
              totalVivHab
              createdAt
              updatedAt
            }
            municipioId
            municipio {
              __typename
              id
              nombre
              clave
              entidadId
              cabecera
              claveCab
              pobMasc
              pobFem
              pobTotal
              totalVivHab
              createdAt
              updatedAt
              nombreEnt
              nombreEntAbrev
            }
            localidadId
            localidad {
              __typename
              id
              nombre
              entidadId
              municipioId
              clave
              ambito
              latitud
              longitud
              altitud
              pobMasc
              pobFem
              pobTotal
              totalVivHab
              createdAt
              updatedAt
              nombreEnt
              nombreEntAbrev
              nombreMun
              claveCarta
            }
            reprNombre
            reprCargo
            reprTelefono
            reprEmail
            createdAt
            updatedAt
          }
          nombre
          aPaterno
          aMaterno
          fechaNac
          sexo
          curp
          rfc
          direccion
          codigoPostal
          estadoId
          estado {
            __typename
            id
            nombre
            nombreAbrev
            clave
            pobMasc
            pobFem
            pobTotal
            totalVivHab
            createdAt
            updatedAt
          }
          municipioId
          municipio {
            __typename
            id
            nombre
            clave
            entidadId
            cabecera
            claveCab
            pobMasc
            pobFem
            pobTotal
            totalVivHab
            createdAt
            updatedAt
            nombreEnt
            nombreEntAbrev
          }
          localidadId
          localidad {
            __typename
            id
            nombre
            entidadId
            municipioId
            clave
            ambito
            latitud
            longitud
            altitud
            pobMasc
            pobFem
            pobTotal
            totalVivHab
            createdAt
            updatedAt
            nombreEnt
            nombreEntAbrev
            nombreMun
            claveCarta
          }
          latitud
          longitud
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetProductorQuery>response.data.getProductor;
  }
  async ListProductors(
    filter?: ModelProductorFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListProductorsQuery> {
    const statement = `query ListProductors($filter: ModelProductorFilterInput, $limit: Int, $nextToken: String) {
        listProductors(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            searchField
            userid
            username
            folioProductorId
            organizacioncafeOrganizacionId
            Organizacion {
              __typename
              id
              searchFields
              nombre
              figuraJuridica
              telefono
              email
              anoConstitucion
              regionId
              direccion
              codigoPostal
              coloniaId
              estadoId
              municipioId
              localidadId
              reprNombre
              reprCargo
              reprTelefono
              reprEmail
              createdAt
              updatedAt
            }
            nombre
            aPaterno
            aMaterno
            fechaNac
            sexo
            curp
            rfc
            direccion
            codigoPostal
            estadoId
            estado {
              __typename
              id
              nombre
              nombreAbrev
              clave
              pobMasc
              pobFem
              pobTotal
              totalVivHab
              createdAt
              updatedAt
            }
            municipioId
            municipio {
              __typename
              id
              nombre
              clave
              entidadId
              cabecera
              claveCab
              pobMasc
              pobFem
              pobTotal
              totalVivHab
              createdAt
              updatedAt
              nombreEnt
              nombreEntAbrev
            }
            localidadId
            localidad {
              __typename
              id
              nombre
              entidadId
              municipioId
              clave
              ambito
              latitud
              longitud
              altitud
              pobMasc
              pobFem
              pobTotal
              totalVivHab
              createdAt
              updatedAt
              nombreEnt
              nombreEntAbrev
              nombreMun
              claveCarta
            }
            latitud
            longitud
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListProductorsQuery>response.data.listProductors;
  }
  async GetEntidad(id: string): Promise<GetEntidadQuery> {
    const statement = `query GetEntidad($id: ID!) {
        getEntidad(id: $id) {
          __typename
          id
          nombre
          nombreAbrev
          clave
          pobMasc
          pobFem
          pobTotal
          totalVivHab
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetEntidadQuery>response.data.getEntidad;
  }
  async ListEntidads(
    filter?: ModelEntidadFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListEntidadsQuery> {
    const statement = `query ListEntidads($filter: ModelEntidadFilterInput, $limit: Int, $nextToken: String) {
        listEntidads(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            nombre
            nombreAbrev
            clave
            pobMasc
            pobFem
            pobTotal
            totalVivHab
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListEntidadsQuery>response.data.listEntidads;
  }
  async GetMunicipio(id: string): Promise<GetMunicipioQuery> {
    const statement = `query GetMunicipio($id: ID!) {
        getMunicipio(id: $id) {
          __typename
          id
          nombre
          clave
          entidadId
          cabecera
          claveCab
          pobMasc
          pobFem
          pobTotal
          totalVivHab
          createdAt
          updatedAt
          nombreEnt
          nombreEntAbrev
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetMunicipioQuery>response.data.getMunicipio;
  }
  async ListMunicipios(
    filter?: ModelMunicipioFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListMunicipiosQuery> {
    const statement = `query ListMunicipios($filter: ModelMunicipioFilterInput, $limit: Int, $nextToken: String) {
        listMunicipios(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            nombre
            clave
            entidadId
            cabecera
            claveCab
            pobMasc
            pobFem
            pobTotal
            totalVivHab
            createdAt
            updatedAt
            nombreEnt
            nombreEntAbrev
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListMunicipiosQuery>response.data.listMunicipios;
  }
  async GetLocalidad(id: string): Promise<GetLocalidadQuery> {
    const statement = `query GetLocalidad($id: ID!) {
        getLocalidad(id: $id) {
          __typename
          id
          nombre
          entidadId
          municipioId
          clave
          ambito
          latitud
          longitud
          altitud
          pobMasc
          pobFem
          pobTotal
          totalVivHab
          createdAt
          updatedAt
          nombreEnt
          nombreEntAbrev
          nombreMun
          claveCarta
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetLocalidadQuery>response.data.getLocalidad;
  }
  async ListLocalidads(
    filter?: ModelLocalidadFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListLocalidadsQuery> {
    const statement = `query ListLocalidads($filter: ModelLocalidadFilterInput, $limit: Int, $nextToken: String) {
        listLocalidads(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            nombre
            entidadId
            municipioId
            clave
            ambito
            latitud
            longitud
            altitud
            pobMasc
            pobFem
            pobTotal
            totalVivHab
            createdAt
            updatedAt
            nombreEnt
            nombreEntAbrev
            nombreMun
            claveCarta
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListLocalidadsQuery>response.data.listLocalidads;
  }
  async GetCodigoPostal(id: string): Promise<GetCodigoPostalQuery> {
    const statement = `query GetCodigoPostal($id: ID!) {
        getCodigoPostal(id: $id) {
          __typename
          id
          codigo
          asentamiento
          tipoAsent
          String
          claveTipoAsent
          asentCpcons
          zona
          entidadId
          municipioId
          ciudad
          claveCd
          cpOficina
          createdAt
          updatedAt
          nombreMun
          nombreEnt
          dCP
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetCodigoPostalQuery>response.data.getCodigoPostal;
  }
  async ListCodigoPostals(
    filter?: ModelCodigoPostalFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListCodigoPostalsQuery> {
    const statement = `query ListCodigoPostals($filter: ModelCodigoPostalFilterInput, $limit: Int, $nextToken: String) {
        listCodigoPostals(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            codigo
            asentamiento
            tipoAsent
            String
            claveTipoAsent
            asentCpcons
            zona
            entidadId
            municipioId
            ciudad
            claveCd
            cpOficina
            createdAt
            updatedAt
            nombreMun
            nombreEnt
            dCP
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListCodigoPostalsQuery>response.data.listCodigoPostals;
  }
  async GetCiclo(id: string): Promise<GetCicloQuery> {
    const statement = `query GetCiclo($id: ID!) {
        getCiclo(id: $id) {
          __typename
          id
          nombre
          fechaInicio
          fechaFin
          activo
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetCicloQuery>response.data.getCiclo;
  }
  async ListCiclos(
    filter?: ModelCicloFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListCiclosQuery> {
    const statement = `query ListCiclos($filter: ModelCicloFilterInput, $limit: Int, $nextToken: String) {
        listCiclos(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            nombre
            fechaInicio
            fechaFin
            activo
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListCiclosQuery>response.data.listCiclos;
  }
  async GetInfoCiclo(id: string): Promise<GetInfoCicloQuery> {
    const statement = `query GetInfoCiclo($id: ID!) {
        getInfoCiclo(id: $id) {
          __typename
          id
          cicloId
          ciclo {
            __typename
            id
            nombre
            fechaInicio
            fechaFin
            activo
            createdAt
            updatedAt
          }
          productorId
          productor {
            __typename
            id
            searchField
            userid
            username
            folioProductorId
            organizacioncafeOrganizacionId
            Organizacion {
              __typename
              id
              searchFields
              nombre
              figuraJuridica
              telefono
              email
              anoConstitucion
              regionId
              direccion
              codigoPostal
              coloniaId
              estadoId
              municipioId
              localidadId
              reprNombre
              reprCargo
              reprTelefono
              reprEmail
              createdAt
              updatedAt
            }
            nombre
            aPaterno
            aMaterno
            fechaNac
            sexo
            curp
            rfc
            direccion
            codigoPostal
            estadoId
            estado {
              __typename
              id
              nombre
              nombreAbrev
              clave
              pobMasc
              pobFem
              pobTotal
              totalVivHab
              createdAt
              updatedAt
            }
            municipioId
            municipio {
              __typename
              id
              nombre
              clave
              entidadId
              cabecera
              claveCab
              pobMasc
              pobFem
              pobTotal
              totalVivHab
              createdAt
              updatedAt
              nombreEnt
              nombreEntAbrev
            }
            localidadId
            localidad {
              __typename
              id
              nombre
              entidadId
              municipioId
              clave
              ambito
              latitud
              longitud
              altitud
              pobMasc
              pobFem
              pobTotal
              totalVivHab
              createdAt
              updatedAt
              nombreEnt
              nombreEntAbrev
              nombreMun
              claveCarta
            }
            latitud
            longitud
            createdAt
            updatedAt
          }
          organizacionId
          organizacion {
            __typename
            id
            searchFields
            nombre
            figuraJuridica
            telefono
            email
            anoConstitucion
            regionId
            region {
              __typename
              id
              nombre
              createdAt
              updatedAt
            }
            direccion
            codigoPostal
            coloniaId
            colonia {
              __typename
              id
              codigo
              asentamiento
              tipoAsent
              String
              claveTipoAsent
              asentCpcons
              zona
              entidadId
              municipioId
              ciudad
              claveCd
              cpOficina
              createdAt
              updatedAt
              nombreMun
              nombreEnt
              dCP
            }
            estadoId
            estado {
              __typename
              id
              nombre
              nombreAbrev
              clave
              pobMasc
              pobFem
              pobTotal
              totalVivHab
              createdAt
              updatedAt
            }
            municipioId
            municipio {
              __typename
              id
              nombre
              clave
              entidadId
              cabecera
              claveCab
              pobMasc
              pobFem
              pobTotal
              totalVivHab
              createdAt
              updatedAt
              nombreEnt
              nombreEntAbrev
            }
            localidadId
            localidad {
              __typename
              id
              nombre
              entidadId
              municipioId
              clave
              ambito
              latitud
              longitud
              altitud
              pobMasc
              pobFem
              pobTotal
              totalVivHab
              createdAt
              updatedAt
              nombreEnt
              nombreEntAbrev
              nombreMun
              claveCarta
            }
            reprNombre
            reprCargo
            reprTelefono
            reprEmail
            createdAt
            updatedAt
          }
          datosGenerales {
            __typename
            areasTrabajo
            infraestructura
            equiposOficina
            certificaciones
            estatus
          }
          produccion {
            __typename
            superficie {
              __typename
              cafeOrganico
              cafeTransicion
              basicos
              potrero
              acahual
              montania
            }
            produccionPromedio {
              __typename
              estimacion
            }
            jornales {
              __typename
              primeraLimpia
              segundaLimpia
              cajete
              podaYdeshije
              desombre
              abonera
              regarAbono
              resiembra
              controlPlagas
              conservacionSuelo
              semilleroVivero
              cosecha
              beneficioHumedo
              otros
              precioPagado
            }
            insumos {
              __typename
              beauveria
              abonoFoliar
              calAgricola
              costalera
              canastos
              mantenimientoEquipos
              certificaciones
              alimentacionPersonal
              otros
            }
            rendimientoCafetales
            factoresRendimiento
            condicionesCafetales
            variedadesCafe
            edadPlantaciones
            disenioPlantaciones
            densidadPlantaciones
            alturaPlantaciones
            floracion
          }
          calidad {
            __typename
            despulpadoraTipos
            equipoCondicion
            tanqueTipos
            secadoInfraestructuras
            cafeCorte
            fermentacionHora
            conocenCalidad
            cafeCalidad
          }
          mercado {
            __typename
            mercado {
              __typename
              prodAcopiada
              cafeExportado
              cafeVendidoNacional
              cafeVendidoLocal
              precioExportacion
              precioNacional
              precioLocal
              sobreprecio
              clientesExtranjeros
            }
            exportacionMercados
          }
          desarrollo {
            __typename
            cursosRecibidos
            cursosTipos
            reforzarProcesos
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetInfoCicloQuery>response.data.getInfoCiclo;
  }
  async ListInfoCiclos(
    filter?: ModelInfoCicloFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListInfoCiclosQuery> {
    const statement = `query ListInfoCiclos($filter: ModelInfoCicloFilterInput, $limit: Int, $nextToken: String) {
        listInfoCiclos(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            cicloId
            ciclo {
              __typename
              id
              nombre
              fechaInicio
              fechaFin
              activo
              createdAt
              updatedAt
            }
            productorId
            productor {
              __typename
              id
              searchField
              userid
              username
              folioProductorId
              organizacioncafeOrganizacionId
              nombre
              aPaterno
              aMaterno
              fechaNac
              sexo
              curp
              rfc
              direccion
              codigoPostal
              estadoId
              municipioId
              localidadId
              latitud
              longitud
              createdAt
              updatedAt
            }
            organizacionId
            organizacion {
              __typename
              id
              searchFields
              nombre
              figuraJuridica
              telefono
              email
              anoConstitucion
              regionId
              direccion
              codigoPostal
              coloniaId
              estadoId
              municipioId
              localidadId
              reprNombre
              reprCargo
              reprTelefono
              reprEmail
              createdAt
              updatedAt
            }
            datosGenerales {
              __typename
              areasTrabajo
              infraestructura
              equiposOficina
              certificaciones
              estatus
            }
            produccion {
              __typename
              rendimientoCafetales
              factoresRendimiento
              condicionesCafetales
              variedadesCafe
              edadPlantaciones
              disenioPlantaciones
              densidadPlantaciones
              alturaPlantaciones
              floracion
            }
            calidad {
              __typename
              despulpadoraTipos
              equipoCondicion
              tanqueTipos
              secadoInfraestructuras
              cafeCorte
              fermentacionHora
              conocenCalidad
              cafeCalidad
            }
            mercado {
              __typename
              exportacionMercados
            }
            desarrollo {
              __typename
              cursosRecibidos
              cursosTipos
              reforzarProcesos
            }
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListInfoCiclosQuery>response.data.listInfoCiclos;
  }
  async GetPadronCafetalero(id: string): Promise<GetPadronCafetaleroQuery> {
    const statement = `query GetPadronCafetalero($id: ID!) {
        getPadronCafetalero(id: $id) {
          __typename
          id
          type
          curp
          folioProductorId
          nombre
          aPaterno
          aMaterno
          fechaNac
          sexo
          rfc
          calle
          numero
          colonia
          entidadId
          nombreEnt
          municipioId
          nombreMun
          localidadId
          nombreLoc
          folioProdPred
          folioPredioId
          nombrePredio
          superficieCafe
          predioEntidadId
          predioNombreEnt
          predioMunicipioId
          predioNombreMun
          predioLocalidadId
          predioNombreLoc
          latitudGrados
          longitudGrados
          latitud
          longitud
          searchField
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetPadronCafetaleroQuery>response.data.getPadronCafetalero;
  }
  async ListPadronCafetaleros(
    filter?: ModelPadronCafetaleroFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListPadronCafetalerosQuery> {
    const statement = `query ListPadronCafetaleros($filter: ModelPadronCafetaleroFilterInput, $limit: Int, $nextToken: String) {
        listPadronCafetaleros(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            type
            curp
            folioProductorId
            nombre
            aPaterno
            aMaterno
            fechaNac
            sexo
            rfc
            calle
            numero
            colonia
            entidadId
            nombreEnt
            municipioId
            nombreMun
            localidadId
            nombreLoc
            folioProdPred
            folioPredioId
            nombrePredio
            superficieCafe
            predioEntidadId
            predioNombreEnt
            predioMunicipioId
            predioNombreMun
            predioLocalidadId
            predioNombreLoc
            latitudGrados
            longitudGrados
            latitud
            longitud
            searchField
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListPadronCafetalerosQuery>response.data.listPadronCafetaleros;
  }
  async GetInfoCicloOrganizacion(
    id: string
  ): Promise<GetInfoCicloOrganizacionQuery> {
    const statement = `query GetInfoCicloOrganizacion($id: ID!) {
        getInfoCicloOrganizacion(id: $id) {
          __typename
          id
          ciclo {
            __typename
            id
            nombre
            fechaInicio
            fechaFin
            activo
            createdAt
            updatedAt
          }
          organizacion {
            __typename
            id
            searchFields
            nombre
            figuraJuridica
            telefono
            email
            anoConstitucion
            regionId
            region {
              __typename
              id
              nombre
              createdAt
              updatedAt
            }
            direccion
            codigoPostal
            coloniaId
            colonia {
              __typename
              id
              codigo
              asentamiento
              tipoAsent
              String
              claveTipoAsent
              asentCpcons
              zona
              entidadId
              municipioId
              ciudad
              claveCd
              cpOficina
              createdAt
              updatedAt
              nombreMun
              nombreEnt
              dCP
            }
            estadoId
            estado {
              __typename
              id
              nombre
              nombreAbrev
              clave
              pobMasc
              pobFem
              pobTotal
              totalVivHab
              createdAt
              updatedAt
            }
            municipioId
            municipio {
              __typename
              id
              nombre
              clave
              entidadId
              cabecera
              claveCab
              pobMasc
              pobFem
              pobTotal
              totalVivHab
              createdAt
              updatedAt
              nombreEnt
              nombreEntAbrev
            }
            localidadId
            localidad {
              __typename
              id
              nombre
              entidadId
              municipioId
              clave
              ambito
              latitud
              longitud
              altitud
              pobMasc
              pobFem
              pobTotal
              totalVivHab
              createdAt
              updatedAt
              nombreEnt
              nombreEntAbrev
              nombreMun
              claveCarta
            }
            reprNombre
            reprCargo
            reprTelefono
            reprEmail
            createdAt
            updatedAt
          }
          cicloId
          organizacionId
          datosGenerales {
            __typename
            numeroSocios {
              __typename
              numHombres
              numMujeres
            }
            haProduccion {
              __typename
              cafeOrganico
              cafeTransicion
              otrosCultivos
            }
            haPorcentajes {
              __typename
              haMenosDeMedia
              haDeMediaAUna
              haDeUnaADos
              haDeDosACinco
              haDeCincoADiez
              haMasDeDiez
            }
            areasTrabajo
            equiposOficina
            certificaciones
            estatus
          }
          produccion {
            __typename
            produccionXproductor {
              __typename
              maximo
              promedio
              minimo
            }
            jornales {
              __typename
              primeraLimpia
              segundaLimpia
              cajete
              podaYdeshije
              desombre
              abonera
              regarAbono
              resiembra
              controlPlagas
              conservacionSuelo
              semilleroVivero
              cosecha
              beneficioHumedo
              otros
              precioPagado
            }
            insumos {
              __typename
              beauveria
              abonoFoliar
              calAgricola
              costalera
              canastos
              mantenimientoEquipos
              certificaciones
              alimentacionPersonal
              otros
            }
            variedadesCafe {
              __typename
              typica
              bourbon
              mundoNovo
              caturra
              catimor
              otros
            }
            edadPlantaciones {
              __typename
              menosDe5
              entre5y15
              entre15y30
              masDe30
            }
            disenioPlantaciones {
              __typename
              tresbolillo
              rectangular
              curvasANivel
              sinMarcos
            }
            densidadPlantaciones {
              __typename
              menosDe1000
              entre1000y1500
              masDe1500
            }
            alturaPlantaciones {
              __typename
              menosDe600
              entre600y900
              entre900y1200
              masDe1200
            }
            floracion {
              __typename
              ninguna
              primera
              segunda
              tercera
            }
            rendimientoCafetales
            factoresRendimiento
            condicionesCafetales
          }
          calidad {
            __typename
            infraestructura
            despulpadoraTipos {
              __typename
              cilindro
              disco
              beneficio
            }
            equipoCondicion {
              __typename
              mala
              regular
              buena
              excelente
            }
            tanqueTipos {
              __typename
              madera
              cemento
              tina
            }
            secadoInfraestructuras {
              __typename
              patio
              zarandas
              gas
              excelente
            }
            cafeCorte {
              __typename
              uno
              dos
              masDeDos
            }
            fermentacionHora {
              __typename
              menosDe10
              entre10y15
              entre15y20
            }
            conocenCalidad
            cafeCalidad {
              __typename
              mala
              regular
              buena
              excelente
            }
          }
          mercado {
            __typename
            mercado {
              __typename
              prodAcopiada
              cafeExportado
              cafeVendidoNacional
              cafeVendidoLocal
              precioExportacion
              precioNacional
              precioLocal
              sobreprecio
              clientesExtranjeros
            }
            exportacionMercados
          }
          desarrollo {
            __typename
            cursosRecibidos
            cursosTipos
            reforzarProcesos
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetInfoCicloOrganizacionQuery>(
      response.data.getInfoCicloOrganizacion
    );
  }
  async ListInfoCicloOrganizacions(
    filter?: ModelInfoCicloOrganizacionFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListInfoCicloOrganizacionsQuery> {
    const statement = `query ListInfoCicloOrganizacions($filter: ModelInfoCicloOrganizacionFilterInput, $limit: Int, $nextToken: String) {
        listInfoCicloOrganizacions(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            ciclo {
              __typename
              id
              nombre
              fechaInicio
              fechaFin
              activo
              createdAt
              updatedAt
            }
            organizacion {
              __typename
              id
              searchFields
              nombre
              figuraJuridica
              telefono
              email
              anoConstitucion
              regionId
              direccion
              codigoPostal
              coloniaId
              estadoId
              municipioId
              localidadId
              reprNombre
              reprCargo
              reprTelefono
              reprEmail
              createdAt
              updatedAt
            }
            cicloId
            organizacionId
            datosGenerales {
              __typename
              areasTrabajo
              equiposOficina
              certificaciones
              estatus
            }
            produccion {
              __typename
              rendimientoCafetales
              factoresRendimiento
              condicionesCafetales
            }
            calidad {
              __typename
              infraestructura
              conocenCalidad
            }
            mercado {
              __typename
              exportacionMercados
            }
            desarrollo {
              __typename
              cursosRecibidos
              cursosTipos
              reforzarProcesos
            }
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListInfoCicloOrganizacionsQuery>(
      response.data.listInfoCicloOrganizacions
    );
  }
  async ProductorByUsername(
    username?: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelProductorFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ProductorByUsernameQuery> {
    const statement = `query ProductorByUsername($username: String, $sortDirection: ModelSortDirection, $filter: ModelProductorFilterInput, $limit: Int, $nextToken: String) {
        productorByUsername(username: $username, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            searchField
            userid
            username
            folioProductorId
            organizacioncafeOrganizacionId
            Organizacion {
              __typename
              id
              searchFields
              nombre
              figuraJuridica
              telefono
              email
              anoConstitucion
              regionId
              direccion
              codigoPostal
              coloniaId
              estadoId
              municipioId
              localidadId
              reprNombre
              reprCargo
              reprTelefono
              reprEmail
              createdAt
              updatedAt
            }
            nombre
            aPaterno
            aMaterno
            fechaNac
            sexo
            curp
            rfc
            direccion
            codigoPostal
            estadoId
            estado {
              __typename
              id
              nombre
              nombreAbrev
              clave
              pobMasc
              pobFem
              pobTotal
              totalVivHab
              createdAt
              updatedAt
            }
            municipioId
            municipio {
              __typename
              id
              nombre
              clave
              entidadId
              cabecera
              claveCab
              pobMasc
              pobFem
              pobTotal
              totalVivHab
              createdAt
              updatedAt
              nombreEnt
              nombreEntAbrev
            }
            localidadId
            localidad {
              __typename
              id
              nombre
              entidadId
              municipioId
              clave
              ambito
              latitud
              longitud
              altitud
              pobMasc
              pobFem
              pobTotal
              totalVivHab
              createdAt
              updatedAt
              nombreEnt
              nombreEntAbrev
              nombreMun
              claveCarta
            }
            latitud
            longitud
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (username) {
      gqlAPIServiceArguments.username = username;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ProductorByUsernameQuery>response.data.productorByUsername;
  }
  async ProductorByName(
    nombre?: string,
    aPaternoAMaterno?: ModelProductorByFullNameCompositeKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelProductorFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ProductorByNameQuery> {
    const statement = `query ProductorByName($nombre: String, $aPaternoAMaterno: ModelProductorByFullNameCompositeKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelProductorFilterInput, $limit: Int, $nextToken: String) {
        productorByName(nombre: $nombre, aPaternoAMaterno: $aPaternoAMaterno, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            searchField
            userid
            username
            folioProductorId
            organizacioncafeOrganizacionId
            Organizacion {
              __typename
              id
              searchFields
              nombre
              figuraJuridica
              telefono
              email
              anoConstitucion
              regionId
              direccion
              codigoPostal
              coloniaId
              estadoId
              municipioId
              localidadId
              reprNombre
              reprCargo
              reprTelefono
              reprEmail
              createdAt
              updatedAt
            }
            nombre
            aPaterno
            aMaterno
            fechaNac
            sexo
            curp
            rfc
            direccion
            codigoPostal
            estadoId
            estado {
              __typename
              id
              nombre
              nombreAbrev
              clave
              pobMasc
              pobFem
              pobTotal
              totalVivHab
              createdAt
              updatedAt
            }
            municipioId
            municipio {
              __typename
              id
              nombre
              clave
              entidadId
              cabecera
              claveCab
              pobMasc
              pobFem
              pobTotal
              totalVivHab
              createdAt
              updatedAt
              nombreEnt
              nombreEntAbrev
            }
            localidadId
            localidad {
              __typename
              id
              nombre
              entidadId
              municipioId
              clave
              ambito
              latitud
              longitud
              altitud
              pobMasc
              pobFem
              pobTotal
              totalVivHab
              createdAt
              updatedAt
              nombreEnt
              nombreEntAbrev
              nombreMun
              claveCarta
            }
            latitud
            longitud
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (nombre) {
      gqlAPIServiceArguments.nombre = nombre;
    }
    if (aPaternoAMaterno) {
      gqlAPIServiceArguments.aPaternoAMaterno = aPaternoAMaterno;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ProductorByNameQuery>response.data.productorByName;
  }
  async MunicipioByEntidad(
    entidadId?: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelMunicipioFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<MunicipioByEntidadQuery> {
    const statement = `query MunicipioByEntidad($entidadId: String, $sortDirection: ModelSortDirection, $filter: ModelMunicipioFilterInput, $limit: Int, $nextToken: String) {
        municipioByEntidad(entidadId: $entidadId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            nombre
            clave
            entidadId
            cabecera
            claveCab
            pobMasc
            pobFem
            pobTotal
            totalVivHab
            createdAt
            updatedAt
            nombreEnt
            nombreEntAbrev
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (entidadId) {
      gqlAPIServiceArguments.entidadId = entidadId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <MunicipioByEntidadQuery>response.data.municipioByEntidad;
  }
  async LocalidadByEntidad(
    entidadId?: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelLocalidadFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<LocalidadByEntidadQuery> {
    const statement = `query LocalidadByEntidad($entidadId: String, $sortDirection: ModelSortDirection, $filter: ModelLocalidadFilterInput, $limit: Int, $nextToken: String) {
        localidadByEntidad(entidadId: $entidadId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            nombre
            entidadId
            municipioId
            clave
            ambito
            latitud
            longitud
            altitud
            pobMasc
            pobFem
            pobTotal
            totalVivHab
            createdAt
            updatedAt
            nombreEnt
            nombreEntAbrev
            nombreMun
            claveCarta
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (entidadId) {
      gqlAPIServiceArguments.entidadId = entidadId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <LocalidadByEntidadQuery>response.data.localidadByEntidad;
  }
  async LocalidadByMunicipio(
    municipioId?: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelLocalidadFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<LocalidadByMunicipioQuery> {
    const statement = `query LocalidadByMunicipio($municipioId: String, $sortDirection: ModelSortDirection, $filter: ModelLocalidadFilterInput, $limit: Int, $nextToken: String) {
        localidadByMunicipio(municipioId: $municipioId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            nombre
            entidadId
            municipioId
            clave
            ambito
            latitud
            longitud
            altitud
            pobMasc
            pobFem
            pobTotal
            totalVivHab
            createdAt
            updatedAt
            nombreEnt
            nombreEntAbrev
            nombreMun
            claveCarta
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (municipioId) {
      gqlAPIServiceArguments.municipioId = municipioId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <LocalidadByMunicipioQuery>response.data.localidadByMunicipio;
  }
  async LocalidadByEntidadAndMunicipio(
    entidadId?: string,
    municipioId?: ModelStringKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelLocalidadFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<LocalidadByEntidadAndMunicipioQuery> {
    const statement = `query LocalidadByEntidadAndMunicipio($entidadId: String, $municipioId: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelLocalidadFilterInput, $limit: Int, $nextToken: String) {
        localidadByEntidadAndMunicipio(entidadId: $entidadId, municipioId: $municipioId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            nombre
            entidadId
            municipioId
            clave
            ambito
            latitud
            longitud
            altitud
            pobMasc
            pobFem
            pobTotal
            totalVivHab
            createdAt
            updatedAt
            nombreEnt
            nombreEntAbrev
            nombreMun
            claveCarta
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (entidadId) {
      gqlAPIServiceArguments.entidadId = entidadId;
    }
    if (municipioId) {
      gqlAPIServiceArguments.municipioId = municipioId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <LocalidadByEntidadAndMunicipioQuery>(
      response.data.localidadByEntidadAndMunicipio
    );
  }
  async CpByEntidad(
    entidadId?: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelCodigoPostalFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<CpByEntidadQuery> {
    const statement = `query CpByEntidad($entidadId: String, $sortDirection: ModelSortDirection, $filter: ModelCodigoPostalFilterInput, $limit: Int, $nextToken: String) {
        cpByEntidad(entidadId: $entidadId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            codigo
            asentamiento
            tipoAsent
            String
            claveTipoAsent
            asentCpcons
            zona
            entidadId
            municipioId
            ciudad
            claveCd
            cpOficina
            createdAt
            updatedAt
            nombreMun
            nombreEnt
            dCP
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (entidadId) {
      gqlAPIServiceArguments.entidadId = entidadId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CpByEntidadQuery>response.data.cpByEntidad;
  }
  async CpByMunicipio(
    municipioId?: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelCodigoPostalFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<CpByMunicipioQuery> {
    const statement = `query CpByMunicipio($municipioId: String, $sortDirection: ModelSortDirection, $filter: ModelCodigoPostalFilterInput, $limit: Int, $nextToken: String) {
        cpByMunicipio(municipioId: $municipioId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            codigo
            asentamiento
            tipoAsent
            String
            claveTipoAsent
            asentCpcons
            zona
            entidadId
            municipioId
            ciudad
            claveCd
            cpOficina
            createdAt
            updatedAt
            nombreMun
            nombreEnt
            dCP
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (municipioId) {
      gqlAPIServiceArguments.municipioId = municipioId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CpByMunicipioQuery>response.data.cpByMunicipio;
  }
  async CpByEntidadAndMunicipio(
    entidadId?: string,
    municipioId?: ModelStringKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelCodigoPostalFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<CpByEntidadAndMunicipioQuery> {
    const statement = `query CpByEntidadAndMunicipio($entidadId: String, $municipioId: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelCodigoPostalFilterInput, $limit: Int, $nextToken: String) {
        cpByEntidadAndMunicipio(entidadId: $entidadId, municipioId: $municipioId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            codigo
            asentamiento
            tipoAsent
            String
            claveTipoAsent
            asentCpcons
            zona
            entidadId
            municipioId
            ciudad
            claveCd
            cpOficina
            createdAt
            updatedAt
            nombreMun
            nombreEnt
            dCP
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (entidadId) {
      gqlAPIServiceArguments.entidadId = entidadId;
    }
    if (municipioId) {
      gqlAPIServiceArguments.municipioId = municipioId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CpByEntidadAndMunicipioQuery>response.data.cpByEntidadAndMunicipio;
  }
  async CpByCodigo(
    codigo?: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelCodigoPostalFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<CpByCodigoQuery> {
    const statement = `query CpByCodigo($codigo: String, $sortDirection: ModelSortDirection, $filter: ModelCodigoPostalFilterInput, $limit: Int, $nextToken: String) {
        cpByCodigo(codigo: $codigo, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            codigo
            asentamiento
            tipoAsent
            String
            claveTipoAsent
            asentCpcons
            zona
            entidadId
            municipioId
            ciudad
            claveCd
            cpOficina
            createdAt
            updatedAt
            nombreMun
            nombreEnt
            dCP
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (codigo) {
      gqlAPIServiceArguments.codigo = codigo;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CpByCodigoQuery>response.data.cpByCodigo;
  }
  async CicloByNombre(
    nombre?: string,
    fechaInicio?: ModelStringKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelCicloFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<CicloByNombreQuery> {
    const statement = `query CicloByNombre($nombre: String, $fechaInicio: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelCicloFilterInput, $limit: Int, $nextToken: String) {
        cicloByNombre(nombre: $nombre, fechaInicio: $fechaInicio, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            nombre
            fechaInicio
            fechaFin
            activo
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (nombre) {
      gqlAPIServiceArguments.nombre = nombre;
    }
    if (fechaInicio) {
      gqlAPIServiceArguments.fechaInicio = fechaInicio;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CicloByNombreQuery>response.data.cicloByNombre;
  }
  async InfoCiclosByProductor(
    productorId?: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelInfoCicloFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<InfoCiclosByProductorQuery> {
    const statement = `query InfoCiclosByProductor($productorId: ID, $sortDirection: ModelSortDirection, $filter: ModelInfoCicloFilterInput, $limit: Int, $nextToken: String) {
        infoCiclosByProductor(productorId: $productorId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            cicloId
            ciclo {
              __typename
              id
              nombre
              fechaInicio
              fechaFin
              activo
              createdAt
              updatedAt
            }
            productorId
            productor {
              __typename
              id
              searchField
              userid
              username
              folioProductorId
              organizacioncafeOrganizacionId
              nombre
              aPaterno
              aMaterno
              fechaNac
              sexo
              curp
              rfc
              direccion
              codigoPostal
              estadoId
              municipioId
              localidadId
              latitud
              longitud
              createdAt
              updatedAt
            }
            organizacionId
            organizacion {
              __typename
              id
              searchFields
              nombre
              figuraJuridica
              telefono
              email
              anoConstitucion
              regionId
              direccion
              codigoPostal
              coloniaId
              estadoId
              municipioId
              localidadId
              reprNombre
              reprCargo
              reprTelefono
              reprEmail
              createdAt
              updatedAt
            }
            datosGenerales {
              __typename
              areasTrabajo
              infraestructura
              equiposOficina
              certificaciones
              estatus
            }
            produccion {
              __typename
              rendimientoCafetales
              factoresRendimiento
              condicionesCafetales
              variedadesCafe
              edadPlantaciones
              disenioPlantaciones
              densidadPlantaciones
              alturaPlantaciones
              floracion
            }
            calidad {
              __typename
              despulpadoraTipos
              equipoCondicion
              tanqueTipos
              secadoInfraestructuras
              cafeCorte
              fermentacionHora
              conocenCalidad
              cafeCalidad
            }
            mercado {
              __typename
              exportacionMercados
            }
            desarrollo {
              __typename
              cursosRecibidos
              cursosTipos
              reforzarProcesos
            }
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (productorId) {
      gqlAPIServiceArguments.productorId = productorId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <InfoCiclosByProductorQuery>response.data.infoCiclosByProductor;
  }
  async PadronByFolioProductor(
    folioProductorId?: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelPadronCafetaleroFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<PadronByFolioProductorQuery> {
    const statement = `query PadronByFolioProductor($folioProductorId: String, $sortDirection: ModelSortDirection, $filter: ModelPadronCafetaleroFilterInput, $limit: Int, $nextToken: String) {
        padronByFolioProductor(folioProductorId: $folioProductorId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            type
            curp
            folioProductorId
            nombre
            aPaterno
            aMaterno
            fechaNac
            sexo
            rfc
            calle
            numero
            colonia
            entidadId
            nombreEnt
            municipioId
            nombreMun
            localidadId
            nombreLoc
            folioProdPred
            folioPredioId
            nombrePredio
            superficieCafe
            predioEntidadId
            predioNombreEnt
            predioMunicipioId
            predioNombreMun
            predioLocalidadId
            predioNombreLoc
            latitudGrados
            longitudGrados
            latitud
            longitud
            searchField
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (folioProductorId) {
      gqlAPIServiceArguments.folioProductorId = folioProductorId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <PadronByFolioProductorQuery>response.data.padronByFolioProductor;
  }
  async GetUsuario(id: string): Promise<GetUsuarioQuery> {
    const statement = `query GetUsuario($id: ID!) {
        getUsuario(id: $id) {
          __typename
          id
          email
          nombreCompleto
          fechaNacimiento
          createdAt
          updatedAt
          owner
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetUsuarioQuery>response.data.getUsuario;
  }
  async ListUsuarios(
    filter?: ModelUsuarioFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListUsuariosQuery> {
    const statement = `query ListUsuarios($filter: ModelUsuarioFilterInput, $limit: Int, $nextToken: String) {
        listUsuarios(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            email
            nombreCompleto
            fechaNacimiento
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListUsuariosQuery>response.data.listUsuarios;
  }
  OnCreateOrganizacionCafeListener: Observable<
    SubscriptionResponse<OnCreateOrganizacionCafeSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateOrganizacionCafe {
        onCreateOrganizacionCafe {
          __typename
          id
          searchFields
          nombre
          figuraJuridica
          telefono
          email
          anoConstitucion
          regionId
          region {
            __typename
            id
            nombre
            createdAt
            updatedAt
          }
          direccion
          codigoPostal
          coloniaId
          colonia {
            __typename
            id
            codigo
            asentamiento
            tipoAsent
            String
            claveTipoAsent
            asentCpcons
            zona
            entidadId
            municipioId
            ciudad
            claveCd
            cpOficina
            createdAt
            updatedAt
            nombreMun
            nombreEnt
            dCP
          }
          estadoId
          estado {
            __typename
            id
            nombre
            nombreAbrev
            clave
            pobMasc
            pobFem
            pobTotal
            totalVivHab
            createdAt
            updatedAt
          }
          municipioId
          municipio {
            __typename
            id
            nombre
            clave
            entidadId
            cabecera
            claveCab
            pobMasc
            pobFem
            pobTotal
            totalVivHab
            createdAt
            updatedAt
            nombreEnt
            nombreEntAbrev
          }
          localidadId
          localidad {
            __typename
            id
            nombre
            entidadId
            municipioId
            clave
            ambito
            latitud
            longitud
            altitud
            pobMasc
            pobFem
            pobTotal
            totalVivHab
            createdAt
            updatedAt
            nombreEnt
            nombreEntAbrev
            nombreMun
            claveCarta
          }
          reprNombre
          reprCargo
          reprTelefono
          reprEmail
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnCreateOrganizacionCafeSubscription>>;

  OnUpdateOrganizacionCafeListener: Observable<
    SubscriptionResponse<OnUpdateOrganizacionCafeSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateOrganizacionCafe {
        onUpdateOrganizacionCafe {
          __typename
          id
          searchFields
          nombre
          figuraJuridica
          telefono
          email
          anoConstitucion
          regionId
          region {
            __typename
            id
            nombre
            createdAt
            updatedAt
          }
          direccion
          codigoPostal
          coloniaId
          colonia {
            __typename
            id
            codigo
            asentamiento
            tipoAsent
            String
            claveTipoAsent
            asentCpcons
            zona
            entidadId
            municipioId
            ciudad
            claveCd
            cpOficina
            createdAt
            updatedAt
            nombreMun
            nombreEnt
            dCP
          }
          estadoId
          estado {
            __typename
            id
            nombre
            nombreAbrev
            clave
            pobMasc
            pobFem
            pobTotal
            totalVivHab
            createdAt
            updatedAt
          }
          municipioId
          municipio {
            __typename
            id
            nombre
            clave
            entidadId
            cabecera
            claveCab
            pobMasc
            pobFem
            pobTotal
            totalVivHab
            createdAt
            updatedAt
            nombreEnt
            nombreEntAbrev
          }
          localidadId
          localidad {
            __typename
            id
            nombre
            entidadId
            municipioId
            clave
            ambito
            latitud
            longitud
            altitud
            pobMasc
            pobFem
            pobTotal
            totalVivHab
            createdAt
            updatedAt
            nombreEnt
            nombreEntAbrev
            nombreMun
            claveCarta
          }
          reprNombre
          reprCargo
          reprTelefono
          reprEmail
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnUpdateOrganizacionCafeSubscription>>;

  OnDeleteOrganizacionCafeListener: Observable<
    SubscriptionResponse<OnDeleteOrganizacionCafeSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteOrganizacionCafe {
        onDeleteOrganizacionCafe {
          __typename
          id
          searchFields
          nombre
          figuraJuridica
          telefono
          email
          anoConstitucion
          regionId
          region {
            __typename
            id
            nombre
            createdAt
            updatedAt
          }
          direccion
          codigoPostal
          coloniaId
          colonia {
            __typename
            id
            codigo
            asentamiento
            tipoAsent
            String
            claveTipoAsent
            asentCpcons
            zona
            entidadId
            municipioId
            ciudad
            claveCd
            cpOficina
            createdAt
            updatedAt
            nombreMun
            nombreEnt
            dCP
          }
          estadoId
          estado {
            __typename
            id
            nombre
            nombreAbrev
            clave
            pobMasc
            pobFem
            pobTotal
            totalVivHab
            createdAt
            updatedAt
          }
          municipioId
          municipio {
            __typename
            id
            nombre
            clave
            entidadId
            cabecera
            claveCab
            pobMasc
            pobFem
            pobTotal
            totalVivHab
            createdAt
            updatedAt
            nombreEnt
            nombreEntAbrev
          }
          localidadId
          localidad {
            __typename
            id
            nombre
            entidadId
            municipioId
            clave
            ambito
            latitud
            longitud
            altitud
            pobMasc
            pobFem
            pobTotal
            totalVivHab
            createdAt
            updatedAt
            nombreEnt
            nombreEntAbrev
            nombreMun
            claveCarta
          }
          reprNombre
          reprCargo
          reprTelefono
          reprEmail
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnDeleteOrganizacionCafeSubscription>>;

  OnCreateRegionListener: Observable<
    SubscriptionResponse<OnCreateRegionSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateRegion {
        onCreateRegion {
          __typename
          id
          nombre
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnCreateRegionSubscription>>;

  OnUpdateRegionListener: Observable<
    SubscriptionResponse<OnUpdateRegionSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateRegion {
        onUpdateRegion {
          __typename
          id
          nombre
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnUpdateRegionSubscription>>;

  OnDeleteRegionListener: Observable<
    SubscriptionResponse<OnDeleteRegionSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteRegion {
        onDeleteRegion {
          __typename
          id
          nombre
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnDeleteRegionSubscription>>;

  OnCreateProductorListener: Observable<
    SubscriptionResponse<OnCreateProductorSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateProductor {
        onCreateProductor {
          __typename
          id
          searchField
          userid
          username
          folioProductorId
          organizacioncafeOrganizacionId
          Organizacion {
            __typename
            id
            searchFields
            nombre
            figuraJuridica
            telefono
            email
            anoConstitucion
            regionId
            region {
              __typename
              id
              nombre
              createdAt
              updatedAt
            }
            direccion
            codigoPostal
            coloniaId
            colonia {
              __typename
              id
              codigo
              asentamiento
              tipoAsent
              String
              claveTipoAsent
              asentCpcons
              zona
              entidadId
              municipioId
              ciudad
              claveCd
              cpOficina
              createdAt
              updatedAt
              nombreMun
              nombreEnt
              dCP
            }
            estadoId
            estado {
              __typename
              id
              nombre
              nombreAbrev
              clave
              pobMasc
              pobFem
              pobTotal
              totalVivHab
              createdAt
              updatedAt
            }
            municipioId
            municipio {
              __typename
              id
              nombre
              clave
              entidadId
              cabecera
              claveCab
              pobMasc
              pobFem
              pobTotal
              totalVivHab
              createdAt
              updatedAt
              nombreEnt
              nombreEntAbrev
            }
            localidadId
            localidad {
              __typename
              id
              nombre
              entidadId
              municipioId
              clave
              ambito
              latitud
              longitud
              altitud
              pobMasc
              pobFem
              pobTotal
              totalVivHab
              createdAt
              updatedAt
              nombreEnt
              nombreEntAbrev
              nombreMun
              claveCarta
            }
            reprNombre
            reprCargo
            reprTelefono
            reprEmail
            createdAt
            updatedAt
          }
          nombre
          aPaterno
          aMaterno
          fechaNac
          sexo
          curp
          rfc
          direccion
          codigoPostal
          estadoId
          estado {
            __typename
            id
            nombre
            nombreAbrev
            clave
            pobMasc
            pobFem
            pobTotal
            totalVivHab
            createdAt
            updatedAt
          }
          municipioId
          municipio {
            __typename
            id
            nombre
            clave
            entidadId
            cabecera
            claveCab
            pobMasc
            pobFem
            pobTotal
            totalVivHab
            createdAt
            updatedAt
            nombreEnt
            nombreEntAbrev
          }
          localidadId
          localidad {
            __typename
            id
            nombre
            entidadId
            municipioId
            clave
            ambito
            latitud
            longitud
            altitud
            pobMasc
            pobFem
            pobTotal
            totalVivHab
            createdAt
            updatedAt
            nombreEnt
            nombreEntAbrev
            nombreMun
            claveCarta
          }
          latitud
          longitud
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnCreateProductorSubscription>>;

  OnUpdateProductorListener: Observable<
    SubscriptionResponse<OnUpdateProductorSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateProductor {
        onUpdateProductor {
          __typename
          id
          searchField
          userid
          username
          folioProductorId
          organizacioncafeOrganizacionId
          Organizacion {
            __typename
            id
            searchFields
            nombre
            figuraJuridica
            telefono
            email
            anoConstitucion
            regionId
            region {
              __typename
              id
              nombre
              createdAt
              updatedAt
            }
            direccion
            codigoPostal
            coloniaId
            colonia {
              __typename
              id
              codigo
              asentamiento
              tipoAsent
              String
              claveTipoAsent
              asentCpcons
              zona
              entidadId
              municipioId
              ciudad
              claveCd
              cpOficina
              createdAt
              updatedAt
              nombreMun
              nombreEnt
              dCP
            }
            estadoId
            estado {
              __typename
              id
              nombre
              nombreAbrev
              clave
              pobMasc
              pobFem
              pobTotal
              totalVivHab
              createdAt
              updatedAt
            }
            municipioId
            municipio {
              __typename
              id
              nombre
              clave
              entidadId
              cabecera
              claveCab
              pobMasc
              pobFem
              pobTotal
              totalVivHab
              createdAt
              updatedAt
              nombreEnt
              nombreEntAbrev
            }
            localidadId
            localidad {
              __typename
              id
              nombre
              entidadId
              municipioId
              clave
              ambito
              latitud
              longitud
              altitud
              pobMasc
              pobFem
              pobTotal
              totalVivHab
              createdAt
              updatedAt
              nombreEnt
              nombreEntAbrev
              nombreMun
              claveCarta
            }
            reprNombre
            reprCargo
            reprTelefono
            reprEmail
            createdAt
            updatedAt
          }
          nombre
          aPaterno
          aMaterno
          fechaNac
          sexo
          curp
          rfc
          direccion
          codigoPostal
          estadoId
          estado {
            __typename
            id
            nombre
            nombreAbrev
            clave
            pobMasc
            pobFem
            pobTotal
            totalVivHab
            createdAt
            updatedAt
          }
          municipioId
          municipio {
            __typename
            id
            nombre
            clave
            entidadId
            cabecera
            claveCab
            pobMasc
            pobFem
            pobTotal
            totalVivHab
            createdAt
            updatedAt
            nombreEnt
            nombreEntAbrev
          }
          localidadId
          localidad {
            __typename
            id
            nombre
            entidadId
            municipioId
            clave
            ambito
            latitud
            longitud
            altitud
            pobMasc
            pobFem
            pobTotal
            totalVivHab
            createdAt
            updatedAt
            nombreEnt
            nombreEntAbrev
            nombreMun
            claveCarta
          }
          latitud
          longitud
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnUpdateProductorSubscription>>;

  OnDeleteProductorListener: Observable<
    SubscriptionResponse<OnDeleteProductorSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteProductor {
        onDeleteProductor {
          __typename
          id
          searchField
          userid
          username
          folioProductorId
          organizacioncafeOrganizacionId
          Organizacion {
            __typename
            id
            searchFields
            nombre
            figuraJuridica
            telefono
            email
            anoConstitucion
            regionId
            region {
              __typename
              id
              nombre
              createdAt
              updatedAt
            }
            direccion
            codigoPostal
            coloniaId
            colonia {
              __typename
              id
              codigo
              asentamiento
              tipoAsent
              String
              claveTipoAsent
              asentCpcons
              zona
              entidadId
              municipioId
              ciudad
              claveCd
              cpOficina
              createdAt
              updatedAt
              nombreMun
              nombreEnt
              dCP
            }
            estadoId
            estado {
              __typename
              id
              nombre
              nombreAbrev
              clave
              pobMasc
              pobFem
              pobTotal
              totalVivHab
              createdAt
              updatedAt
            }
            municipioId
            municipio {
              __typename
              id
              nombre
              clave
              entidadId
              cabecera
              claveCab
              pobMasc
              pobFem
              pobTotal
              totalVivHab
              createdAt
              updatedAt
              nombreEnt
              nombreEntAbrev
            }
            localidadId
            localidad {
              __typename
              id
              nombre
              entidadId
              municipioId
              clave
              ambito
              latitud
              longitud
              altitud
              pobMasc
              pobFem
              pobTotal
              totalVivHab
              createdAt
              updatedAt
              nombreEnt
              nombreEntAbrev
              nombreMun
              claveCarta
            }
            reprNombre
            reprCargo
            reprTelefono
            reprEmail
            createdAt
            updatedAt
          }
          nombre
          aPaterno
          aMaterno
          fechaNac
          sexo
          curp
          rfc
          direccion
          codigoPostal
          estadoId
          estado {
            __typename
            id
            nombre
            nombreAbrev
            clave
            pobMasc
            pobFem
            pobTotal
            totalVivHab
            createdAt
            updatedAt
          }
          municipioId
          municipio {
            __typename
            id
            nombre
            clave
            entidadId
            cabecera
            claveCab
            pobMasc
            pobFem
            pobTotal
            totalVivHab
            createdAt
            updatedAt
            nombreEnt
            nombreEntAbrev
          }
          localidadId
          localidad {
            __typename
            id
            nombre
            entidadId
            municipioId
            clave
            ambito
            latitud
            longitud
            altitud
            pobMasc
            pobFem
            pobTotal
            totalVivHab
            createdAt
            updatedAt
            nombreEnt
            nombreEntAbrev
            nombreMun
            claveCarta
          }
          latitud
          longitud
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnDeleteProductorSubscription>>;

  OnCreateEntidadListener: Observable<
    SubscriptionResponse<OnCreateEntidadSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateEntidad {
        onCreateEntidad {
          __typename
          id
          nombre
          nombreAbrev
          clave
          pobMasc
          pobFem
          pobTotal
          totalVivHab
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnCreateEntidadSubscription>>;

  OnUpdateEntidadListener: Observable<
    SubscriptionResponse<OnUpdateEntidadSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateEntidad {
        onUpdateEntidad {
          __typename
          id
          nombre
          nombreAbrev
          clave
          pobMasc
          pobFem
          pobTotal
          totalVivHab
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnUpdateEntidadSubscription>>;

  OnDeleteEntidadListener: Observable<
    SubscriptionResponse<OnDeleteEntidadSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteEntidad {
        onDeleteEntidad {
          __typename
          id
          nombre
          nombreAbrev
          clave
          pobMasc
          pobFem
          pobTotal
          totalVivHab
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnDeleteEntidadSubscription>>;

  OnCreateMunicipioListener: Observable<
    SubscriptionResponse<OnCreateMunicipioSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateMunicipio {
        onCreateMunicipio {
          __typename
          id
          nombre
          clave
          entidadId
          cabecera
          claveCab
          pobMasc
          pobFem
          pobTotal
          totalVivHab
          createdAt
          updatedAt
          nombreEnt
          nombreEntAbrev
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnCreateMunicipioSubscription>>;

  OnUpdateMunicipioListener: Observable<
    SubscriptionResponse<OnUpdateMunicipioSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateMunicipio {
        onUpdateMunicipio {
          __typename
          id
          nombre
          clave
          entidadId
          cabecera
          claveCab
          pobMasc
          pobFem
          pobTotal
          totalVivHab
          createdAt
          updatedAt
          nombreEnt
          nombreEntAbrev
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnUpdateMunicipioSubscription>>;

  OnDeleteMunicipioListener: Observable<
    SubscriptionResponse<OnDeleteMunicipioSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteMunicipio {
        onDeleteMunicipio {
          __typename
          id
          nombre
          clave
          entidadId
          cabecera
          claveCab
          pobMasc
          pobFem
          pobTotal
          totalVivHab
          createdAt
          updatedAt
          nombreEnt
          nombreEntAbrev
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnDeleteMunicipioSubscription>>;

  OnCreateLocalidadListener: Observable<
    SubscriptionResponse<OnCreateLocalidadSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateLocalidad {
        onCreateLocalidad {
          __typename
          id
          nombre
          entidadId
          municipioId
          clave
          ambito
          latitud
          longitud
          altitud
          pobMasc
          pobFem
          pobTotal
          totalVivHab
          createdAt
          updatedAt
          nombreEnt
          nombreEntAbrev
          nombreMun
          claveCarta
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnCreateLocalidadSubscription>>;

  OnUpdateLocalidadListener: Observable<
    SubscriptionResponse<OnUpdateLocalidadSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateLocalidad {
        onUpdateLocalidad {
          __typename
          id
          nombre
          entidadId
          municipioId
          clave
          ambito
          latitud
          longitud
          altitud
          pobMasc
          pobFem
          pobTotal
          totalVivHab
          createdAt
          updatedAt
          nombreEnt
          nombreEntAbrev
          nombreMun
          claveCarta
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnUpdateLocalidadSubscription>>;

  OnDeleteLocalidadListener: Observable<
    SubscriptionResponse<OnDeleteLocalidadSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteLocalidad {
        onDeleteLocalidad {
          __typename
          id
          nombre
          entidadId
          municipioId
          clave
          ambito
          latitud
          longitud
          altitud
          pobMasc
          pobFem
          pobTotal
          totalVivHab
          createdAt
          updatedAt
          nombreEnt
          nombreEntAbrev
          nombreMun
          claveCarta
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnDeleteLocalidadSubscription>>;

  OnCreateCodigoPostalListener: Observable<
    SubscriptionResponse<OnCreateCodigoPostalSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateCodigoPostal {
        onCreateCodigoPostal {
          __typename
          id
          codigo
          asentamiento
          tipoAsent
          String
          claveTipoAsent
          asentCpcons
          zona
          entidadId
          municipioId
          ciudad
          claveCd
          cpOficina
          createdAt
          updatedAt
          nombreMun
          nombreEnt
          dCP
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnCreateCodigoPostalSubscription>>;

  OnUpdateCodigoPostalListener: Observable<
    SubscriptionResponse<OnUpdateCodigoPostalSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateCodigoPostal {
        onUpdateCodigoPostal {
          __typename
          id
          codigo
          asentamiento
          tipoAsent
          String
          claveTipoAsent
          asentCpcons
          zona
          entidadId
          municipioId
          ciudad
          claveCd
          cpOficina
          createdAt
          updatedAt
          nombreMun
          nombreEnt
          dCP
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnUpdateCodigoPostalSubscription>>;

  OnDeleteCodigoPostalListener: Observable<
    SubscriptionResponse<OnDeleteCodigoPostalSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteCodigoPostal {
        onDeleteCodigoPostal {
          __typename
          id
          codigo
          asentamiento
          tipoAsent
          String
          claveTipoAsent
          asentCpcons
          zona
          entidadId
          municipioId
          ciudad
          claveCd
          cpOficina
          createdAt
          updatedAt
          nombreMun
          nombreEnt
          dCP
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnDeleteCodigoPostalSubscription>>;

  OnCreateCicloListener: Observable<
    SubscriptionResponse<OnCreateCicloSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateCiclo {
        onCreateCiclo {
          __typename
          id
          nombre
          fechaInicio
          fechaFin
          activo
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnCreateCicloSubscription>>;

  OnUpdateCicloListener: Observable<
    SubscriptionResponse<OnUpdateCicloSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateCiclo {
        onUpdateCiclo {
          __typename
          id
          nombre
          fechaInicio
          fechaFin
          activo
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnUpdateCicloSubscription>>;

  OnDeleteCicloListener: Observable<
    SubscriptionResponse<OnDeleteCicloSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteCiclo {
        onDeleteCiclo {
          __typename
          id
          nombre
          fechaInicio
          fechaFin
          activo
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnDeleteCicloSubscription>>;

  OnCreateInfoCicloListener: Observable<
    SubscriptionResponse<OnCreateInfoCicloSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateInfoCiclo {
        onCreateInfoCiclo {
          __typename
          id
          cicloId
          ciclo {
            __typename
            id
            nombre
            fechaInicio
            fechaFin
            activo
            createdAt
            updatedAt
          }
          productorId
          productor {
            __typename
            id
            searchField
            userid
            username
            folioProductorId
            organizacioncafeOrganizacionId
            Organizacion {
              __typename
              id
              searchFields
              nombre
              figuraJuridica
              telefono
              email
              anoConstitucion
              regionId
              direccion
              codigoPostal
              coloniaId
              estadoId
              municipioId
              localidadId
              reprNombre
              reprCargo
              reprTelefono
              reprEmail
              createdAt
              updatedAt
            }
            nombre
            aPaterno
            aMaterno
            fechaNac
            sexo
            curp
            rfc
            direccion
            codigoPostal
            estadoId
            estado {
              __typename
              id
              nombre
              nombreAbrev
              clave
              pobMasc
              pobFem
              pobTotal
              totalVivHab
              createdAt
              updatedAt
            }
            municipioId
            municipio {
              __typename
              id
              nombre
              clave
              entidadId
              cabecera
              claveCab
              pobMasc
              pobFem
              pobTotal
              totalVivHab
              createdAt
              updatedAt
              nombreEnt
              nombreEntAbrev
            }
            localidadId
            localidad {
              __typename
              id
              nombre
              entidadId
              municipioId
              clave
              ambito
              latitud
              longitud
              altitud
              pobMasc
              pobFem
              pobTotal
              totalVivHab
              createdAt
              updatedAt
              nombreEnt
              nombreEntAbrev
              nombreMun
              claveCarta
            }
            latitud
            longitud
            createdAt
            updatedAt
          }
          organizacionId
          organizacion {
            __typename
            id
            searchFields
            nombre
            figuraJuridica
            telefono
            email
            anoConstitucion
            regionId
            region {
              __typename
              id
              nombre
              createdAt
              updatedAt
            }
            direccion
            codigoPostal
            coloniaId
            colonia {
              __typename
              id
              codigo
              asentamiento
              tipoAsent
              String
              claveTipoAsent
              asentCpcons
              zona
              entidadId
              municipioId
              ciudad
              claveCd
              cpOficina
              createdAt
              updatedAt
              nombreMun
              nombreEnt
              dCP
            }
            estadoId
            estado {
              __typename
              id
              nombre
              nombreAbrev
              clave
              pobMasc
              pobFem
              pobTotal
              totalVivHab
              createdAt
              updatedAt
            }
            municipioId
            municipio {
              __typename
              id
              nombre
              clave
              entidadId
              cabecera
              claveCab
              pobMasc
              pobFem
              pobTotal
              totalVivHab
              createdAt
              updatedAt
              nombreEnt
              nombreEntAbrev
            }
            localidadId
            localidad {
              __typename
              id
              nombre
              entidadId
              municipioId
              clave
              ambito
              latitud
              longitud
              altitud
              pobMasc
              pobFem
              pobTotal
              totalVivHab
              createdAt
              updatedAt
              nombreEnt
              nombreEntAbrev
              nombreMun
              claveCarta
            }
            reprNombre
            reprCargo
            reprTelefono
            reprEmail
            createdAt
            updatedAt
          }
          datosGenerales {
            __typename
            areasTrabajo
            infraestructura
            equiposOficina
            certificaciones
            estatus
          }
          produccion {
            __typename
            superficie {
              __typename
              cafeOrganico
              cafeTransicion
              basicos
              potrero
              acahual
              montania
            }
            produccionPromedio {
              __typename
              estimacion
            }
            jornales {
              __typename
              primeraLimpia
              segundaLimpia
              cajete
              podaYdeshije
              desombre
              abonera
              regarAbono
              resiembra
              controlPlagas
              conservacionSuelo
              semilleroVivero
              cosecha
              beneficioHumedo
              otros
              precioPagado
            }
            insumos {
              __typename
              beauveria
              abonoFoliar
              calAgricola
              costalera
              canastos
              mantenimientoEquipos
              certificaciones
              alimentacionPersonal
              otros
            }
            rendimientoCafetales
            factoresRendimiento
            condicionesCafetales
            variedadesCafe
            edadPlantaciones
            disenioPlantaciones
            densidadPlantaciones
            alturaPlantaciones
            floracion
          }
          calidad {
            __typename
            despulpadoraTipos
            equipoCondicion
            tanqueTipos
            secadoInfraestructuras
            cafeCorte
            fermentacionHora
            conocenCalidad
            cafeCalidad
          }
          mercado {
            __typename
            mercado {
              __typename
              prodAcopiada
              cafeExportado
              cafeVendidoNacional
              cafeVendidoLocal
              precioExportacion
              precioNacional
              precioLocal
              sobreprecio
              clientesExtranjeros
            }
            exportacionMercados
          }
          desarrollo {
            __typename
            cursosRecibidos
            cursosTipos
            reforzarProcesos
          }
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnCreateInfoCicloSubscription>>;

  OnUpdateInfoCicloListener: Observable<
    SubscriptionResponse<OnUpdateInfoCicloSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateInfoCiclo {
        onUpdateInfoCiclo {
          __typename
          id
          cicloId
          ciclo {
            __typename
            id
            nombre
            fechaInicio
            fechaFin
            activo
            createdAt
            updatedAt
          }
          productorId
          productor {
            __typename
            id
            searchField
            userid
            username
            folioProductorId
            organizacioncafeOrganizacionId
            Organizacion {
              __typename
              id
              searchFields
              nombre
              figuraJuridica
              telefono
              email
              anoConstitucion
              regionId
              direccion
              codigoPostal
              coloniaId
              estadoId
              municipioId
              localidadId
              reprNombre
              reprCargo
              reprTelefono
              reprEmail
              createdAt
              updatedAt
            }
            nombre
            aPaterno
            aMaterno
            fechaNac
            sexo
            curp
            rfc
            direccion
            codigoPostal
            estadoId
            estado {
              __typename
              id
              nombre
              nombreAbrev
              clave
              pobMasc
              pobFem
              pobTotal
              totalVivHab
              createdAt
              updatedAt
            }
            municipioId
            municipio {
              __typename
              id
              nombre
              clave
              entidadId
              cabecera
              claveCab
              pobMasc
              pobFem
              pobTotal
              totalVivHab
              createdAt
              updatedAt
              nombreEnt
              nombreEntAbrev
            }
            localidadId
            localidad {
              __typename
              id
              nombre
              entidadId
              municipioId
              clave
              ambito
              latitud
              longitud
              altitud
              pobMasc
              pobFem
              pobTotal
              totalVivHab
              createdAt
              updatedAt
              nombreEnt
              nombreEntAbrev
              nombreMun
              claveCarta
            }
            latitud
            longitud
            createdAt
            updatedAt
          }
          organizacionId
          organizacion {
            __typename
            id
            searchFields
            nombre
            figuraJuridica
            telefono
            email
            anoConstitucion
            regionId
            region {
              __typename
              id
              nombre
              createdAt
              updatedAt
            }
            direccion
            codigoPostal
            coloniaId
            colonia {
              __typename
              id
              codigo
              asentamiento
              tipoAsent
              String
              claveTipoAsent
              asentCpcons
              zona
              entidadId
              municipioId
              ciudad
              claveCd
              cpOficina
              createdAt
              updatedAt
              nombreMun
              nombreEnt
              dCP
            }
            estadoId
            estado {
              __typename
              id
              nombre
              nombreAbrev
              clave
              pobMasc
              pobFem
              pobTotal
              totalVivHab
              createdAt
              updatedAt
            }
            municipioId
            municipio {
              __typename
              id
              nombre
              clave
              entidadId
              cabecera
              claveCab
              pobMasc
              pobFem
              pobTotal
              totalVivHab
              createdAt
              updatedAt
              nombreEnt
              nombreEntAbrev
            }
            localidadId
            localidad {
              __typename
              id
              nombre
              entidadId
              municipioId
              clave
              ambito
              latitud
              longitud
              altitud
              pobMasc
              pobFem
              pobTotal
              totalVivHab
              createdAt
              updatedAt
              nombreEnt
              nombreEntAbrev
              nombreMun
              claveCarta
            }
            reprNombre
            reprCargo
            reprTelefono
            reprEmail
            createdAt
            updatedAt
          }
          datosGenerales {
            __typename
            areasTrabajo
            infraestructura
            equiposOficina
            certificaciones
            estatus
          }
          produccion {
            __typename
            superficie {
              __typename
              cafeOrganico
              cafeTransicion
              basicos
              potrero
              acahual
              montania
            }
            produccionPromedio {
              __typename
              estimacion
            }
            jornales {
              __typename
              primeraLimpia
              segundaLimpia
              cajete
              podaYdeshije
              desombre
              abonera
              regarAbono
              resiembra
              controlPlagas
              conservacionSuelo
              semilleroVivero
              cosecha
              beneficioHumedo
              otros
              precioPagado
            }
            insumos {
              __typename
              beauveria
              abonoFoliar
              calAgricola
              costalera
              canastos
              mantenimientoEquipos
              certificaciones
              alimentacionPersonal
              otros
            }
            rendimientoCafetales
            factoresRendimiento
            condicionesCafetales
            variedadesCafe
            edadPlantaciones
            disenioPlantaciones
            densidadPlantaciones
            alturaPlantaciones
            floracion
          }
          calidad {
            __typename
            despulpadoraTipos
            equipoCondicion
            tanqueTipos
            secadoInfraestructuras
            cafeCorte
            fermentacionHora
            conocenCalidad
            cafeCalidad
          }
          mercado {
            __typename
            mercado {
              __typename
              prodAcopiada
              cafeExportado
              cafeVendidoNacional
              cafeVendidoLocal
              precioExportacion
              precioNacional
              precioLocal
              sobreprecio
              clientesExtranjeros
            }
            exportacionMercados
          }
          desarrollo {
            __typename
            cursosRecibidos
            cursosTipos
            reforzarProcesos
          }
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnUpdateInfoCicloSubscription>>;

  OnDeleteInfoCicloListener: Observable<
    SubscriptionResponse<OnDeleteInfoCicloSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteInfoCiclo {
        onDeleteInfoCiclo {
          __typename
          id
          cicloId
          ciclo {
            __typename
            id
            nombre
            fechaInicio
            fechaFin
            activo
            createdAt
            updatedAt
          }
          productorId
          productor {
            __typename
            id
            searchField
            userid
            username
            folioProductorId
            organizacioncafeOrganizacionId
            Organizacion {
              __typename
              id
              searchFields
              nombre
              figuraJuridica
              telefono
              email
              anoConstitucion
              regionId
              direccion
              codigoPostal
              coloniaId
              estadoId
              municipioId
              localidadId
              reprNombre
              reprCargo
              reprTelefono
              reprEmail
              createdAt
              updatedAt
            }
            nombre
            aPaterno
            aMaterno
            fechaNac
            sexo
            curp
            rfc
            direccion
            codigoPostal
            estadoId
            estado {
              __typename
              id
              nombre
              nombreAbrev
              clave
              pobMasc
              pobFem
              pobTotal
              totalVivHab
              createdAt
              updatedAt
            }
            municipioId
            municipio {
              __typename
              id
              nombre
              clave
              entidadId
              cabecera
              claveCab
              pobMasc
              pobFem
              pobTotal
              totalVivHab
              createdAt
              updatedAt
              nombreEnt
              nombreEntAbrev
            }
            localidadId
            localidad {
              __typename
              id
              nombre
              entidadId
              municipioId
              clave
              ambito
              latitud
              longitud
              altitud
              pobMasc
              pobFem
              pobTotal
              totalVivHab
              createdAt
              updatedAt
              nombreEnt
              nombreEntAbrev
              nombreMun
              claveCarta
            }
            latitud
            longitud
            createdAt
            updatedAt
          }
          organizacionId
          organizacion {
            __typename
            id
            searchFields
            nombre
            figuraJuridica
            telefono
            email
            anoConstitucion
            regionId
            region {
              __typename
              id
              nombre
              createdAt
              updatedAt
            }
            direccion
            codigoPostal
            coloniaId
            colonia {
              __typename
              id
              codigo
              asentamiento
              tipoAsent
              String
              claveTipoAsent
              asentCpcons
              zona
              entidadId
              municipioId
              ciudad
              claveCd
              cpOficina
              createdAt
              updatedAt
              nombreMun
              nombreEnt
              dCP
            }
            estadoId
            estado {
              __typename
              id
              nombre
              nombreAbrev
              clave
              pobMasc
              pobFem
              pobTotal
              totalVivHab
              createdAt
              updatedAt
            }
            municipioId
            municipio {
              __typename
              id
              nombre
              clave
              entidadId
              cabecera
              claveCab
              pobMasc
              pobFem
              pobTotal
              totalVivHab
              createdAt
              updatedAt
              nombreEnt
              nombreEntAbrev
            }
            localidadId
            localidad {
              __typename
              id
              nombre
              entidadId
              municipioId
              clave
              ambito
              latitud
              longitud
              altitud
              pobMasc
              pobFem
              pobTotal
              totalVivHab
              createdAt
              updatedAt
              nombreEnt
              nombreEntAbrev
              nombreMun
              claveCarta
            }
            reprNombre
            reprCargo
            reprTelefono
            reprEmail
            createdAt
            updatedAt
          }
          datosGenerales {
            __typename
            areasTrabajo
            infraestructura
            equiposOficina
            certificaciones
            estatus
          }
          produccion {
            __typename
            superficie {
              __typename
              cafeOrganico
              cafeTransicion
              basicos
              potrero
              acahual
              montania
            }
            produccionPromedio {
              __typename
              estimacion
            }
            jornales {
              __typename
              primeraLimpia
              segundaLimpia
              cajete
              podaYdeshije
              desombre
              abonera
              regarAbono
              resiembra
              controlPlagas
              conservacionSuelo
              semilleroVivero
              cosecha
              beneficioHumedo
              otros
              precioPagado
            }
            insumos {
              __typename
              beauveria
              abonoFoliar
              calAgricola
              costalera
              canastos
              mantenimientoEquipos
              certificaciones
              alimentacionPersonal
              otros
            }
            rendimientoCafetales
            factoresRendimiento
            condicionesCafetales
            variedadesCafe
            edadPlantaciones
            disenioPlantaciones
            densidadPlantaciones
            alturaPlantaciones
            floracion
          }
          calidad {
            __typename
            despulpadoraTipos
            equipoCondicion
            tanqueTipos
            secadoInfraestructuras
            cafeCorte
            fermentacionHora
            conocenCalidad
            cafeCalidad
          }
          mercado {
            __typename
            mercado {
              __typename
              prodAcopiada
              cafeExportado
              cafeVendidoNacional
              cafeVendidoLocal
              precioExportacion
              precioNacional
              precioLocal
              sobreprecio
              clientesExtranjeros
            }
            exportacionMercados
          }
          desarrollo {
            __typename
            cursosRecibidos
            cursosTipos
            reforzarProcesos
          }
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnDeleteInfoCicloSubscription>>;

  OnCreatePadronCafetaleroListener: Observable<
    SubscriptionResponse<OnCreatePadronCafetaleroSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreatePadronCafetalero {
        onCreatePadronCafetalero {
          __typename
          id
          type
          curp
          folioProductorId
          nombre
          aPaterno
          aMaterno
          fechaNac
          sexo
          rfc
          calle
          numero
          colonia
          entidadId
          nombreEnt
          municipioId
          nombreMun
          localidadId
          nombreLoc
          folioProdPred
          folioPredioId
          nombrePredio
          superficieCafe
          predioEntidadId
          predioNombreEnt
          predioMunicipioId
          predioNombreMun
          predioLocalidadId
          predioNombreLoc
          latitudGrados
          longitudGrados
          latitud
          longitud
          searchField
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnCreatePadronCafetaleroSubscription>>;

  OnUpdatePadronCafetaleroListener: Observable<
    SubscriptionResponse<OnUpdatePadronCafetaleroSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdatePadronCafetalero {
        onUpdatePadronCafetalero {
          __typename
          id
          type
          curp
          folioProductorId
          nombre
          aPaterno
          aMaterno
          fechaNac
          sexo
          rfc
          calle
          numero
          colonia
          entidadId
          nombreEnt
          municipioId
          nombreMun
          localidadId
          nombreLoc
          folioProdPred
          folioPredioId
          nombrePredio
          superficieCafe
          predioEntidadId
          predioNombreEnt
          predioMunicipioId
          predioNombreMun
          predioLocalidadId
          predioNombreLoc
          latitudGrados
          longitudGrados
          latitud
          longitud
          searchField
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnUpdatePadronCafetaleroSubscription>>;

  OnDeletePadronCafetaleroListener: Observable<
    SubscriptionResponse<OnDeletePadronCafetaleroSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeletePadronCafetalero {
        onDeletePadronCafetalero {
          __typename
          id
          type
          curp
          folioProductorId
          nombre
          aPaterno
          aMaterno
          fechaNac
          sexo
          rfc
          calle
          numero
          colonia
          entidadId
          nombreEnt
          municipioId
          nombreMun
          localidadId
          nombreLoc
          folioProdPred
          folioPredioId
          nombrePredio
          superficieCafe
          predioEntidadId
          predioNombreEnt
          predioMunicipioId
          predioNombreMun
          predioLocalidadId
          predioNombreLoc
          latitudGrados
          longitudGrados
          latitud
          longitud
          searchField
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnDeletePadronCafetaleroSubscription>>;

  OnCreateInfoCicloOrganizacionListener: Observable<
    SubscriptionResponse<OnCreateInfoCicloOrganizacionSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateInfoCicloOrganizacion {
        onCreateInfoCicloOrganizacion {
          __typename
          id
          ciclo {
            __typename
            id
            nombre
            fechaInicio
            fechaFin
            activo
            createdAt
            updatedAt
          }
          organizacion {
            __typename
            id
            searchFields
            nombre
            figuraJuridica
            telefono
            email
            anoConstitucion
            regionId
            region {
              __typename
              id
              nombre
              createdAt
              updatedAt
            }
            direccion
            codigoPostal
            coloniaId
            colonia {
              __typename
              id
              codigo
              asentamiento
              tipoAsent
              String
              claveTipoAsent
              asentCpcons
              zona
              entidadId
              municipioId
              ciudad
              claveCd
              cpOficina
              createdAt
              updatedAt
              nombreMun
              nombreEnt
              dCP
            }
            estadoId
            estado {
              __typename
              id
              nombre
              nombreAbrev
              clave
              pobMasc
              pobFem
              pobTotal
              totalVivHab
              createdAt
              updatedAt
            }
            municipioId
            municipio {
              __typename
              id
              nombre
              clave
              entidadId
              cabecera
              claveCab
              pobMasc
              pobFem
              pobTotal
              totalVivHab
              createdAt
              updatedAt
              nombreEnt
              nombreEntAbrev
            }
            localidadId
            localidad {
              __typename
              id
              nombre
              entidadId
              municipioId
              clave
              ambito
              latitud
              longitud
              altitud
              pobMasc
              pobFem
              pobTotal
              totalVivHab
              createdAt
              updatedAt
              nombreEnt
              nombreEntAbrev
              nombreMun
              claveCarta
            }
            reprNombre
            reprCargo
            reprTelefono
            reprEmail
            createdAt
            updatedAt
          }
          cicloId
          organizacionId
          datosGenerales {
            __typename
            numeroSocios {
              __typename
              numHombres
              numMujeres
            }
            haProduccion {
              __typename
              cafeOrganico
              cafeTransicion
              otrosCultivos
            }
            haPorcentajes {
              __typename
              haMenosDeMedia
              haDeMediaAUna
              haDeUnaADos
              haDeDosACinco
              haDeCincoADiez
              haMasDeDiez
            }
            areasTrabajo
            equiposOficina
            certificaciones
            estatus
          }
          produccion {
            __typename
            produccionXproductor {
              __typename
              maximo
              promedio
              minimo
            }
            jornales {
              __typename
              primeraLimpia
              segundaLimpia
              cajete
              podaYdeshije
              desombre
              abonera
              regarAbono
              resiembra
              controlPlagas
              conservacionSuelo
              semilleroVivero
              cosecha
              beneficioHumedo
              otros
              precioPagado
            }
            insumos {
              __typename
              beauveria
              abonoFoliar
              calAgricola
              costalera
              canastos
              mantenimientoEquipos
              certificaciones
              alimentacionPersonal
              otros
            }
            variedadesCafe {
              __typename
              typica
              bourbon
              mundoNovo
              caturra
              catimor
              otros
            }
            edadPlantaciones {
              __typename
              menosDe5
              entre5y15
              entre15y30
              masDe30
            }
            disenioPlantaciones {
              __typename
              tresbolillo
              rectangular
              curvasANivel
              sinMarcos
            }
            densidadPlantaciones {
              __typename
              menosDe1000
              entre1000y1500
              masDe1500
            }
            alturaPlantaciones {
              __typename
              menosDe600
              entre600y900
              entre900y1200
              masDe1200
            }
            floracion {
              __typename
              ninguna
              primera
              segunda
              tercera
            }
            rendimientoCafetales
            factoresRendimiento
            condicionesCafetales
          }
          calidad {
            __typename
            infraestructura
            despulpadoraTipos {
              __typename
              cilindro
              disco
              beneficio
            }
            equipoCondicion {
              __typename
              mala
              regular
              buena
              excelente
            }
            tanqueTipos {
              __typename
              madera
              cemento
              tina
            }
            secadoInfraestructuras {
              __typename
              patio
              zarandas
              gas
              excelente
            }
            cafeCorte {
              __typename
              uno
              dos
              masDeDos
            }
            fermentacionHora {
              __typename
              menosDe10
              entre10y15
              entre15y20
            }
            conocenCalidad
            cafeCalidad {
              __typename
              mala
              regular
              buena
              excelente
            }
          }
          mercado {
            __typename
            mercado {
              __typename
              prodAcopiada
              cafeExportado
              cafeVendidoNacional
              cafeVendidoLocal
              precioExportacion
              precioNacional
              precioLocal
              sobreprecio
              clientesExtranjeros
            }
            exportacionMercados
          }
          desarrollo {
            __typename
            cursosRecibidos
            cursosTipos
            reforzarProcesos
          }
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<OnCreateInfoCicloOrganizacionSubscription>
  >;

  OnUpdateInfoCicloOrganizacionListener: Observable<
    SubscriptionResponse<OnUpdateInfoCicloOrganizacionSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateInfoCicloOrganizacion {
        onUpdateInfoCicloOrganizacion {
          __typename
          id
          ciclo {
            __typename
            id
            nombre
            fechaInicio
            fechaFin
            activo
            createdAt
            updatedAt
          }
          organizacion {
            __typename
            id
            searchFields
            nombre
            figuraJuridica
            telefono
            email
            anoConstitucion
            regionId
            region {
              __typename
              id
              nombre
              createdAt
              updatedAt
            }
            direccion
            codigoPostal
            coloniaId
            colonia {
              __typename
              id
              codigo
              asentamiento
              tipoAsent
              String
              claveTipoAsent
              asentCpcons
              zona
              entidadId
              municipioId
              ciudad
              claveCd
              cpOficina
              createdAt
              updatedAt
              nombreMun
              nombreEnt
              dCP
            }
            estadoId
            estado {
              __typename
              id
              nombre
              nombreAbrev
              clave
              pobMasc
              pobFem
              pobTotal
              totalVivHab
              createdAt
              updatedAt
            }
            municipioId
            municipio {
              __typename
              id
              nombre
              clave
              entidadId
              cabecera
              claveCab
              pobMasc
              pobFem
              pobTotal
              totalVivHab
              createdAt
              updatedAt
              nombreEnt
              nombreEntAbrev
            }
            localidadId
            localidad {
              __typename
              id
              nombre
              entidadId
              municipioId
              clave
              ambito
              latitud
              longitud
              altitud
              pobMasc
              pobFem
              pobTotal
              totalVivHab
              createdAt
              updatedAt
              nombreEnt
              nombreEntAbrev
              nombreMun
              claveCarta
            }
            reprNombre
            reprCargo
            reprTelefono
            reprEmail
            createdAt
            updatedAt
          }
          cicloId
          organizacionId
          datosGenerales {
            __typename
            numeroSocios {
              __typename
              numHombres
              numMujeres
            }
            haProduccion {
              __typename
              cafeOrganico
              cafeTransicion
              otrosCultivos
            }
            haPorcentajes {
              __typename
              haMenosDeMedia
              haDeMediaAUna
              haDeUnaADos
              haDeDosACinco
              haDeCincoADiez
              haMasDeDiez
            }
            areasTrabajo
            equiposOficina
            certificaciones
            estatus
          }
          produccion {
            __typename
            produccionXproductor {
              __typename
              maximo
              promedio
              minimo
            }
            jornales {
              __typename
              primeraLimpia
              segundaLimpia
              cajete
              podaYdeshije
              desombre
              abonera
              regarAbono
              resiembra
              controlPlagas
              conservacionSuelo
              semilleroVivero
              cosecha
              beneficioHumedo
              otros
              precioPagado
            }
            insumos {
              __typename
              beauveria
              abonoFoliar
              calAgricola
              costalera
              canastos
              mantenimientoEquipos
              certificaciones
              alimentacionPersonal
              otros
            }
            variedadesCafe {
              __typename
              typica
              bourbon
              mundoNovo
              caturra
              catimor
              otros
            }
            edadPlantaciones {
              __typename
              menosDe5
              entre5y15
              entre15y30
              masDe30
            }
            disenioPlantaciones {
              __typename
              tresbolillo
              rectangular
              curvasANivel
              sinMarcos
            }
            densidadPlantaciones {
              __typename
              menosDe1000
              entre1000y1500
              masDe1500
            }
            alturaPlantaciones {
              __typename
              menosDe600
              entre600y900
              entre900y1200
              masDe1200
            }
            floracion {
              __typename
              ninguna
              primera
              segunda
              tercera
            }
            rendimientoCafetales
            factoresRendimiento
            condicionesCafetales
          }
          calidad {
            __typename
            infraestructura
            despulpadoraTipos {
              __typename
              cilindro
              disco
              beneficio
            }
            equipoCondicion {
              __typename
              mala
              regular
              buena
              excelente
            }
            tanqueTipos {
              __typename
              madera
              cemento
              tina
            }
            secadoInfraestructuras {
              __typename
              patio
              zarandas
              gas
              excelente
            }
            cafeCorte {
              __typename
              uno
              dos
              masDeDos
            }
            fermentacionHora {
              __typename
              menosDe10
              entre10y15
              entre15y20
            }
            conocenCalidad
            cafeCalidad {
              __typename
              mala
              regular
              buena
              excelente
            }
          }
          mercado {
            __typename
            mercado {
              __typename
              prodAcopiada
              cafeExportado
              cafeVendidoNacional
              cafeVendidoLocal
              precioExportacion
              precioNacional
              precioLocal
              sobreprecio
              clientesExtranjeros
            }
            exportacionMercados
          }
          desarrollo {
            __typename
            cursosRecibidos
            cursosTipos
            reforzarProcesos
          }
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<OnUpdateInfoCicloOrganizacionSubscription>
  >;

  OnDeleteInfoCicloOrganizacionListener: Observable<
    SubscriptionResponse<OnDeleteInfoCicloOrganizacionSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteInfoCicloOrganizacion {
        onDeleteInfoCicloOrganizacion {
          __typename
          id
          ciclo {
            __typename
            id
            nombre
            fechaInicio
            fechaFin
            activo
            createdAt
            updatedAt
          }
          organizacion {
            __typename
            id
            searchFields
            nombre
            figuraJuridica
            telefono
            email
            anoConstitucion
            regionId
            region {
              __typename
              id
              nombre
              createdAt
              updatedAt
            }
            direccion
            codigoPostal
            coloniaId
            colonia {
              __typename
              id
              codigo
              asentamiento
              tipoAsent
              String
              claveTipoAsent
              asentCpcons
              zona
              entidadId
              municipioId
              ciudad
              claveCd
              cpOficina
              createdAt
              updatedAt
              nombreMun
              nombreEnt
              dCP
            }
            estadoId
            estado {
              __typename
              id
              nombre
              nombreAbrev
              clave
              pobMasc
              pobFem
              pobTotal
              totalVivHab
              createdAt
              updatedAt
            }
            municipioId
            municipio {
              __typename
              id
              nombre
              clave
              entidadId
              cabecera
              claveCab
              pobMasc
              pobFem
              pobTotal
              totalVivHab
              createdAt
              updatedAt
              nombreEnt
              nombreEntAbrev
            }
            localidadId
            localidad {
              __typename
              id
              nombre
              entidadId
              municipioId
              clave
              ambito
              latitud
              longitud
              altitud
              pobMasc
              pobFem
              pobTotal
              totalVivHab
              createdAt
              updatedAt
              nombreEnt
              nombreEntAbrev
              nombreMun
              claveCarta
            }
            reprNombre
            reprCargo
            reprTelefono
            reprEmail
            createdAt
            updatedAt
          }
          cicloId
          organizacionId
          datosGenerales {
            __typename
            numeroSocios {
              __typename
              numHombres
              numMujeres
            }
            haProduccion {
              __typename
              cafeOrganico
              cafeTransicion
              otrosCultivos
            }
            haPorcentajes {
              __typename
              haMenosDeMedia
              haDeMediaAUna
              haDeUnaADos
              haDeDosACinco
              haDeCincoADiez
              haMasDeDiez
            }
            areasTrabajo
            equiposOficina
            certificaciones
            estatus
          }
          produccion {
            __typename
            produccionXproductor {
              __typename
              maximo
              promedio
              minimo
            }
            jornales {
              __typename
              primeraLimpia
              segundaLimpia
              cajete
              podaYdeshije
              desombre
              abonera
              regarAbono
              resiembra
              controlPlagas
              conservacionSuelo
              semilleroVivero
              cosecha
              beneficioHumedo
              otros
              precioPagado
            }
            insumos {
              __typename
              beauveria
              abonoFoliar
              calAgricola
              costalera
              canastos
              mantenimientoEquipos
              certificaciones
              alimentacionPersonal
              otros
            }
            variedadesCafe {
              __typename
              typica
              bourbon
              mundoNovo
              caturra
              catimor
              otros
            }
            edadPlantaciones {
              __typename
              menosDe5
              entre5y15
              entre15y30
              masDe30
            }
            disenioPlantaciones {
              __typename
              tresbolillo
              rectangular
              curvasANivel
              sinMarcos
            }
            densidadPlantaciones {
              __typename
              menosDe1000
              entre1000y1500
              masDe1500
            }
            alturaPlantaciones {
              __typename
              menosDe600
              entre600y900
              entre900y1200
              masDe1200
            }
            floracion {
              __typename
              ninguna
              primera
              segunda
              tercera
            }
            rendimientoCafetales
            factoresRendimiento
            condicionesCafetales
          }
          calidad {
            __typename
            infraestructura
            despulpadoraTipos {
              __typename
              cilindro
              disco
              beneficio
            }
            equipoCondicion {
              __typename
              mala
              regular
              buena
              excelente
            }
            tanqueTipos {
              __typename
              madera
              cemento
              tina
            }
            secadoInfraestructuras {
              __typename
              patio
              zarandas
              gas
              excelente
            }
            cafeCorte {
              __typename
              uno
              dos
              masDeDos
            }
            fermentacionHora {
              __typename
              menosDe10
              entre10y15
              entre15y20
            }
            conocenCalidad
            cafeCalidad {
              __typename
              mala
              regular
              buena
              excelente
            }
          }
          mercado {
            __typename
            mercado {
              __typename
              prodAcopiada
              cafeExportado
              cafeVendidoNacional
              cafeVendidoLocal
              precioExportacion
              precioNacional
              precioLocal
              sobreprecio
              clientesExtranjeros
            }
            exportacionMercados
          }
          desarrollo {
            __typename
            cursosRecibidos
            cursosTipos
            reforzarProcesos
          }
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<OnDeleteInfoCicloOrganizacionSubscription>
  >;

  OnCreateUsuarioListener: Observable<
    SubscriptionResponse<OnCreateUsuarioSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateUsuario($owner: String!) {
        onCreateUsuario(owner: $owner) {
          __typename
          id
          email
          nombreCompleto
          fechaNacimiento
          createdAt
          updatedAt
          owner
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnCreateUsuarioSubscription>>;

  OnUpdateUsuarioListener: Observable<
    SubscriptionResponse<OnUpdateUsuarioSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateUsuario($owner: String!) {
        onUpdateUsuario(owner: $owner) {
          __typename
          id
          email
          nombreCompleto
          fechaNacimiento
          createdAt
          updatedAt
          owner
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnUpdateUsuarioSubscription>>;

  OnDeleteUsuarioListener: Observable<
    SubscriptionResponse<OnDeleteUsuarioSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteUsuario($owner: String!) {
        onDeleteUsuario(owner: $owner) {
          __typename
          id
          email
          nombreCompleto
          fechaNacimiento
          createdAt
          updatedAt
          owner
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnDeleteUsuarioSubscription>>;
}
