import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthLayoutComponent } from './shared/layouts/auth-layout/auth-layout.component';
import { BlankLayoutComponent } from './shared/layouts/blank-layout/blank-layout.component';
import { MainLayoutComponent } from './shared/layouts/main-layout/main-layout.component';

const routes: Routes = [
    {
        path: '',
        redirectTo: '/home',
        pathMatch: 'full'
    },
    {
        path: '',
        component: MainLayoutComponent,
        children: [
            { path: 'home', loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule), },
            { path: 'organizaciones', loadChildren: () => import('./pages/organizaciones-admin/organizaciones-admin.module').then(m => m.OrganizacionesAdminModule) },
            { path: 'cuenta', loadChildren: () => import('./pages/cuenta/cuenta.module').then(m => m.CuentaModule) },
            { path: 'usuarios', loadChildren: () => import('./pages/usuarios/usuarios.module').then(m => m.UsuariosModule) },
            { path: 'regiones', loadChildren: () => import('./pages/regiones/regiones.module').then(m => m.RegionesModule) },
            { path: 'ciclos', loadChildren: () => import('./pages/ciclos/ciclos.module').then(m => m.CiclosModule) },
            { path: 'info-ciclos', loadChildren: () => import('./pages/info-ciclos/info-ciclos.module').then(m => m.InfoCiclosModule) },
            { path: 'info-ciclos-org', loadChildren: () => import('./pages/info-ciclos-orgs/info-ciclos-orgs.module').then(m => m.InfoCiclosOrgsModule) },
            { path: 'padron-cafetalero', loadChildren: () => import('./pages/padron-cafetalero/padron-cafetalero.module').then(m => m.PadronCafetaleroModule) },
            { path: 'accountTest', loadChildren: () => import('./pages/accountTest/account.module').then(m => m.AccountModule) },
            { path: 'productores', loadChildren: () => import('./pages/productores/productores.module').then(m => m.ProductoresModule) },
            { path: 'estadisticas', loadChildren: () => import('./pages/estadisticas/estadisticas.module').then(m => m.EstadisticasModule) }
        ]
    },
    {
        path: '',
        redirectTo: '/home',
        pathMatch: 'full'
    },
    {
        path: 'productor',
        component: MainLayoutComponent,
        children: [
            { path: 'info-ciclos', loadChildren: () => import('./pages/productor/prod-info-ciclos/prod-info-ciclos.module').then(m => m.ProdInfoCiclosModule) },
            { path: 'info-productor', loadChildren: () => import('./pages/productor/prod-info-productor/prod-info-productor.module').then(m => m.ProdInfoProductorModule) },
        ]
    },
    {
        path: 'login',
        component: BlankLayoutComponent,
        children: [
            { path: '', loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule) }
        ]
    },
    {
        path: 'auth',
        component: AuthLayoutComponent,
        children: [
            { path: '', loadChildren: () => import('./pages/auth/auth.module').then(m => m.AuthModule) }
        ]
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
