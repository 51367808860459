import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import Amplify, { I18n } from "aws-amplify";
import aws_exports from "./aws-exports";

import { dictionaries } from "./dic";

Amplify.configure(aws_exports);

I18n.setLanguage('es');

I18n.putVocabularies(dictionaries);

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
