import { ComponentFactoryResolver, ComponentRef, Injectable, ViewContainerRef } from '@angular/core';
import { LoadingComponent } from 'src/app/shared/components/loading/loading.component';

@Injectable({
  providedIn: 'root'
})

export class LoadingService {
  private viewContainerRef: ViewContainerRef;
  private componentRef: ComponentRef<LoadingComponent>;

  constructor(
    private cfr: ComponentFactoryResolver
  ) { }

  init(viewContainerRef: ViewContainerRef) {
    this.setRootViewContainerRef(viewContainerRef);
    const componentFactory = this.cfr.resolveComponentFactory(LoadingComponent);
    this.componentRef = this.viewContainerRef.createComponent<LoadingComponent>(componentFactory);
  }

  start(text: String = "Loading") {
    this.showComponent(text);
  }

  finish() {
    this.hideComponent();
  }

  private setRootViewContainerRef(viewContainerRef: ViewContainerRef) {
    this.viewContainerRef = viewContainerRef;
  }

  private showComponent(text: String) {
    setTimeout(() => {
      this.componentRef.instance.show = true;
      this.componentRef.instance.text = text;
    }, 0);
  }

  private hideComponent() {
    setTimeout(() => {
      this.componentRef.instance.show = false;
      this.componentRef.instance.text = "";
    }, 0);
  }
}
