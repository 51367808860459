import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { LoadingService } from './shared/services/loading.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'prodCafe-app';
  constructor(
    private vcr: ViewContainerRef,
    private loading: LoadingService
  ) { }

  ngOnInit() {
    this.loading.init(this.vcr);
  }
}
