export interface menuLink {
    name: string,
    path?: string,
    items?: menuLink[]
}

const adminMenu: menuLink[] = [
    {
        name: "Productores", 
        items: [
            { name: "Productores", path: "/productores" },
        ]
    },
    {
        name: "Organizaciones", 
        items: [
            { name: "Organizaciones", path: "/organizaciones" },
            { name: "Info Ciclos", path: "/info-ciclos-org" },
        ]
    },
    {
        name: "Catalogos", items: [
            { name: "Regiones", path: "/regiones" },
            { name: "Ciclos", path: "/ciclos" },
            { name: "Padrón cafetalero", path: "/padron-cafetalero" },
        ]
    },
    {
        name: "Estadísticas", items: [
            { name: "Análisis Producción", path: "/estadisticas/produccion" },
            { name: "Lista de Organizaciones por Municipio", path: "/estadisticas/organizacionesmunicipio" },
            { name: "Organizaciones por Municipio", path: "/estadisticas/organizacionesmunicipiomat" },
            { name: "Productores por Municipio", path: "/estadisticas/productoresmunicipiomat" },
            { name: "Producción en Quintales por Municipio", path: "/estadisticas/produccionquinmunicipiomat" },
            { name: "Hectáreas por Municipio", path: "/estadisticas/hectareasmunicipio" },
            { name: "Número de Mujeres por Municipio", path: "/estadisticas/mujeresmunicipio" },
            { name: "Número de Hombres por Municipio", path: "/estadisticas/hombresmunicipio" },
            { name: "Rentabilidad", path: "/estadisticas/rentabilidad" },
            { name: "Análisis en la Producción", path: "/estadisticas/analisisproduccion" },
            { name: "Análisis en la Producción Concentrado", path: "/estadisticas/analisisproduccionresumen" },
            { name: "Labores en 1Ha de Café", path: "/estadisticas/labores" },
            { name: "Labores en 1Ha de Café Concentrado", path: "/estadisticas/laboresresumen" },
            { name: "Datos de las Organizaciones", path: "/estadisticas/datosorganizacion" },
            { name: "Análisis de Calidad", path: "/estadisticas/calidad" },
            { name: "Análisis de Calidad Concentrado", path: "/estadisticas/calidadresumen" },
            { name: "Análisis de Mercado", path: "/estadisticas/mercado" },
            { name: "Desarrollo de Capacidades", path: "/estadisticas/capacidades" },
        ]
    },
    {
        name: "Usuarios", items: [
            { name: "Administradores", path: "/usuarios/admin" },
            { name: "Productores" },
            { name: "Público en General" },
        ]
    },
    // {
    //     name: "Reportes", items: [
    //         { name: "Productores por Genero" },
    //         { name: "Productores por Región" },
    //         { name: "Otros" },
    //     ]
    // },
]

const productorMenu: menuLink[] = [
    { name: "Datos de Productor", path: "/productor/info-productor" },
    { name: "Ciclos de Produccion", path: "/productor/info-ciclos" },
    { name: "Estadísticas", path: "/productor/estadisticas" },
]

export const menus = {
    admin: adminMenu,
    productor: productorMenu,
}