<style>
  :host {
    font-family: "Poppins", sans-serif !important;
    font-size: 16px;
    color: #333;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
</style>

<app-splash></app-splash>
<router-outlet></router-outlet>