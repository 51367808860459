import { registerLocaleData } from '@angular/common';
import localeESMX from '@angular/common/locales/es-MX';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
/* import AmplifyUIAngularModule  */
import { AmplifyUIAngularModule } from '@aws-amplify/ui-angular';
import { NgbAlertModule, NgbModalModule, NgbModule, NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrModule } from 'ngx-toastr';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoadingComponent } from './shared/components/loading/loading.component';
import { SplashComponent } from './shared/components/splash/splash.component';
import { AuthLayoutComponent } from './shared/layouts/auth-layout/auth-layout.component';
import { BlankLayoutComponent } from './shared/layouts/blank-layout/blank-layout.component';
import { MainFooterComponent } from './shared/layouts/main-layout/main-footer/main-footer.component';
import { MainLayoutComponent } from './shared/layouts/main-layout/main-layout.component';
import { MainTopbarComponent } from './shared/layouts/main-layout/main-topbar/main-topbar.component';
import { ConfirmAlertComponent } from './shared/services/alerts/confirm-alert/confirm-alert.component';

registerLocaleData(localeESMX, 'es-MX');

@NgModule({
  declarations: [
    AppComponent,
    BlankLayoutComponent,
    MainLayoutComponent,
    MainTopbarComponent,
    SplashComponent,
    LoadingComponent,
    MainFooterComponent,
    ConfirmAlertComponent,
    AuthLayoutComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    /* configure app with AmplifyUIAngularModule */
    AmplifyUIAngularModule,

    BrowserAnimationsModule,

    ReactiveFormsModule,
    FormsModule,

    NgbAlertModule,
    NgbPaginationModule,
    NgbModalModule,

    ToastrModule.forRoot(), // ToastrModule added

  ],
  providers: [
    { provide: LOCALE_ID, useValue: "es-MX" }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
