<div class="card">
  <div class="card-header" style="display: flex; align-items: center; justify-content: space-between;">
    <h5 class="m-0" style="display: inline">{{title}}</h5>
    <button type="button" class="close" (click)="onClose()">
      <i class="fas fa-times"></i>
    </button>
  </div>
  <div class="card-body">
    <p class="text-center m-0" [innerHTML]="message"></p>
  </div>
  <div class="card-footer text-right">
    <button class="btn btn-outline-secondary mr-2" (click)="onClose()"> Cancelar</button>
    <button ngbAutofocus class="btn btn-primary" (click)="onOk()"> Aceptar</button>
  </div>
</div>