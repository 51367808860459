<div class="d-flex justify-content-center bg-splash" *ngIf="show">
    <div class="d-flex align-items-center anim-item-splash">
        <img class="item-splash" src="assets/images/cafe-grano.png"/>
        <img class="item-splash item2-splash" src="assets/images/cafe-grano.png"/>
        <img class="item-splash item3-splash" src="assets/images/cafe-grano.png"/>
        &nbsp;
        &nbsp;
        &nbsp;
        <span class="brand-text-splash">ProCafe</span>
    </div>
</div>