import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Auth } from 'aws-amplify';
import { menuLink, menus } from 'src/app/config/menuTopBar';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-main-topbar',
  templateUrl: './main-topbar.component.html',
  styleUrls: ['./main-topbar.component.scss']
})
export class MainTopbarComponent implements OnInit {

  menuBar: menuLink[] = [];

  userGroups = [];
  isAdmin = false;
  isAuthenticated = false;
  email = '';
  name = '';

  constructor(
    private router: Router
  ) { 

    
    // if (!environment.production) {
    //   this.menuBar = menus.admin;
    // }
    

  }

  ngOnInit(): void {

    Auth.currentAuthenticatedUser()
      .then(user => {
        this.isAuthenticated = true;
        this.userGroups = user.signInUserSession.accessToken.payload["cognito:groups"]

        this.email = user.attributes.email;
        this.name = user.attributes.given_name + ' ' +  (user.attributes?.middle_name ?? (user.attributes?.family_name ?? '') ) ;

        this.menuBar = [];

        if (this.userGroups && this.userGroups.includes('Admin')) {
          this.menuBar = menus.admin;
          this.isAdmin = true;
        } else {
          this.isAdmin = false;
          this.menuBar = menus.productor; 
        } 

      })
      .catch(() => {
        console.log("Not signed in");
        this.email = '';
        this.isAuthenticated = false;
      });
  }

  signOut() {

    if (!environment.production) {
      this.menuBar = [];
    }
    
    Auth.signOut()
      .then(data => {
        console.log('User signed out');
        this.isAdmin = false;
        this.userGroups = [];
        this.isAuthenticated = false;
        this.email = '';

        this.router.navigate(['/']);
      })
      .catch(err => console.log('error signing out:', err));
  }

}
