<div class="main-layout-wrapper bg-light" style="height: 100%; min-height: 100vh">
    <app-main-topbar></app-main-topbar>
    <div class="container py-4 mt-3 mb-5" role="main">
        <router-outlet></router-outlet>
    </div>
    <div class="bg-light border p-3 font-weight-light"
        style="position: fixed; bottom: 0; left: 0; right: 0; z-index: 998">
        Jose Antonio Rocha & Red Oaxaca S.A. de C.V. S.F.C. &nbsp;&nbsp; Mexico
        &copy; 2020
    </div>
</div>

<ng-template #selectAccountTemplate>
    <div class="modal-header">
        <!-- <h5 class="modal-title">¿Eres Productor?</h5> -->
        <p class="lead modal-title">Tipo de Cuenta</p>
    </div>
    <div class="modal-body">
        <p>Elige un Tipo de Cuenta:</p>
        <p>
            Selecciona <b>Productor</b> si quieres subir información de tu producción
            y obtener datos acerca de su rentabilidad. Deberas llenar información para tu perfil.
        </p>
        <p>
            Selecciona <b>Público</b> si quieres obtener información estadistica
            acerca de la producción en México.
        </p>

        <form [formGroup]="accForm" (ngSubmit)="selAccModalRef.close()">
            <div class="form-group">
                <div class="p-3 border" *ngFor="let acc of accTypes; let i = index">
                    <div class="custom-control custom-radio">
                        <input type="radio" class="custom-control-input" [id]="'customRadio' + i" [value]="acc.val"
                            formControlName="accType" />
                        <label class="custom-control-label w-100" [for]="'customRadio' + i">
                            {{ acc.name }}
                        </label>
                    </div>
                </div>
            </div>

            <div class="form-group">
                <button type="submit" class="btn btn-primary btn-block" [disabled]="!this.accForm.valid">
                    Continuar
                </button>
            </div>
        </form>
    </div>
</ng-template>



<ng-template #addProductorModal>
    <div class="modal-header">
        <!-- <h5 class="modal-title">¿Eres Productor?</h5> -->
        <p class="lead modal-title">Datos de Prodcutor</p>
    </div>
    <div class="modal-body">
        <p>Elige un Tipo de Cuenta:</p>
        <p>
            Selecciona <b>Productor</b> si quieres subir información de tu producción
            y obtener datos acerca de su rentabilidad.
        </p>
        <p>
            Selecciona <b>Público</b> si quieres obtener información estadistica
            acerca de la producción en México.
        </p>

        <form [formGroup]="accForm" (ngSubmit)="selAccModalRef.close()">



        </form>
    </div>
</ng-template>