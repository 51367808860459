<div class="d-flex justify-content-center align-items-center bg-loading" *ngIf="show">
    <div class="d-flex flex-column justify-content-center align-items-center container-loading" role="status">
        <div class="spinner-border text-primary spinner-loading" role="status">
            <span class="sr-only">Loading...</span>
        </div>
        <div class="loading-ellipsis">
            &nbsp;
            <span>
                {{text}}
            </span>
            &nbsp;
        </div>
    </div>
</div>