<div class="shadow-sm ">
  <nav class="navbar navbar-expand-sm navbar-light bg-light">
    <a class="navbar-brand mr-auto" routerLink="/">
      <img src="assets/logos/mainLogo.png" class="mr-2" style="max-height: 35px;">
      ProCafe
    </a>
    <div class="ml-auto" *ngIf="isAuthenticated; else unAuthenticated">

      <div class="dropdown">
        <button class="btn dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true"
          aria-expanded="false">
          {{name}}
        </button>
        <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenu2">
          <button class="dropdown-item" type="button" routerLink="/cuenta/edit">Mi Cuenta</button>
          <button class="dropdown-item" type="button" routerLink="/cuenta/changePass">Cambiar contraseña</button>
          <div class="dropdown-divider"></div>
          <button class="dropdown-item" type="button" (click)="signOut()"> Salir </button>
        </div>
      </div>

    </div>
    <ng-template #unAuthenticated>
      <button class="btn btn-primary" routerLink="/auth">Ingresar</button>
    </ng-template>
  </nav>

  <div *ngIf="menuBar && menuBar.length > 0" class="nav-scroller bg-white py-1">
    <nav class="nav nav-underline">
      <ng-container *ngFor="let i of menuBar">
        <a *ngIf="!i?.items" class="nav-link" [routerLink]="i.path ? i.path : ''"> {{i.name}} </a>
        <div *ngIf="i?.items?.length > 0 " class="nav-link" ngbDropdown style="cursor: pointer;">
          <a ngbDropdownToggle class="text-decoration-none dropdown-toggle">{{i.name}}</a>
          <div ngbDropdownMenu class="dropdown-menu">
            <ng-container *ngFor="let it2 of i.items">
              <a ngbDropdownItem class="dropdown-item" [routerLink]="it2.path ? it2.path : ''">{{it2.name}}</a>
            </ng-container>
          </div>
        </div>
      </ng-container>

    </nav>
  </div>
</div>