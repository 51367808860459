/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_appsync_graphqlEndpoint": "https://5ka5jkxb7vd4rknamm3ux23wui.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-b2ttqd2q7jchbfw4ajdqbqvjr4",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://1xym4mjx5d.execute-api.us-west-2.amazonaws.com/production",
            "region": "us-west-2"
        }
    ],
    "aws_cognito_identity_pool_id": "us-west-2:e8acdd1e-4c2d-4cdf-9520-28ddb6e0dfbd",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_szYyYrJV7",
    "aws_user_pools_web_client_id": "3j9u3e9ov4t0a36dhttrq98kt5",
    "oauth": {},
    "aws_user_files_s3_bucket": "procafebucket182558-production",
    "aws_user_files_s3_bucket_region": "us-west-2"
};


export default awsmobile;
