import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { Auth } from 'aws-amplify';
import { catchError } from 'rxjs/operators';
import { APIService } from 'src/app/API.service';

const selAccModalOps: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
    centered: false,
}
const cognitoGroups = {
    publico: "Publico",
    admin: "Admin",
    productor: "Productor",
}


@Component({
    selector: 'app-main-layout',
    templateUrl: './main-layout.component.html',
    styleUrls: ['./main-layout.component.scss']
})
export class MainLayoutComponent implements OnInit, AfterViewInit {

    @ViewChild('selectAccountTemplate') selAccModalTemp: any;

    accTypes = [
        { val: cognitoGroups.productor, name: 'Productor' },
        { val: cognitoGroups.publico, name: 'Público' },
    ];

    accForm = this.fb.group({
        accType: ['', [Validators.required]]
    });

    selAccModalRef: NgbModalRef;

    constructor(
        private modSrv: NgbModal,
        private fb: FormBuilder,
        private router: Router,
        private apiSrv: APIService,
    ) { }

    ngOnInit(): void { }


    async ngAfterViewInit() {

        //await this.modSrv.open(this.selAccModalTemp, selAccModalOps);

        var userData: { user, groups, profile };
        userData = await this.getUserProfiles();

        console.log('userData', userData);

        // CHange fast attributes
        // Auth.updateUserAttributes(
        //     userData.user, {
        //     profile: ''
        // });

        // If doenst have a profile ask and create it
        if (userData.user && !userData.groups.includes(cognitoGroups.admin)) {



            if (!userData.profile) {

                // doesnt has profile. 

                // open modal
                this.selAccModalRef = this.modSrv.open(this.selAccModalTemp, selAccModalOps);

                // wait to modal to be closed.
                await this.selAccModalRef.result;

                // update user attribs to set group. 
                var accType = this.accForm.controls.accType.value;
                await Auth.updateUserAttributes(userData.user, { profile: accType });

                // update profile in client. 
                userData = await this.getUserProfiles();
            }



            if (userData.profile == cognitoGroups.productor) {
                
                var productores = await this.apiSrv.ListProductors();
                if( productores.items.length < 1 ) {
                    this.router.navigate(['cuenta', 'registrar', 'productor'])
                }
                
            }

            // switch (accType) {

            //     case cognitoGroups.publico:
            //         await Auth.updateUserAttributes(userData.user, { profile: accType });
            //         break;
            //     case cognitoGroups.productor:
            //     default:
            //         break;
            // }

            // update user data. if there is some change. 
        }

    }

    async getUserProfiles() {
        try {
            var user = await Auth.currentAuthenticatedUser({ bypassCache: true });

            var userGroups = user.signInUserSession.accessToken.payload["cognito:groups"]
            var profile = (user.attributes?.profile);

            var groups = userGroups == undefined || userGroups == null ? [] : userGroups;

            return { user, groups, profile };
        } catch {
            return { user, groups, profile };
        }
    }

}
