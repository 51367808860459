import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirm-alert',
  templateUrl: './confirm-alert.component.html',
  styleUrls: ['./confirm-alert.component.scss']
})
export class ConfirmAlertComponent implements OnInit {

  @Input() title: string = 'Confirmación';
  @Input() message: string = 'Mensaje';

  constructor(
    private actMod: NgbActiveModal
  ) { }

  ngOnInit() {
  }

  onClose() {
    this.actMod.dismiss();
  }

  onOk() {
    this.actMod.close('ok');
  }

}
