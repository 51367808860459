
export const dictionaries = {
    'es': {
        'User does not exist.': "El usuario no existe.",
        'Incorrect username or password.': "Usuario o contraseña incorrecto.",
        'Password attempts exceeded': "Demasiado intentos de inicio de sesión fallidos. Intente más tarde.",
        'User is not confirmed.': "El usuario no ha confirmado su correo electrónico.",
        'User is disabled.': "Usuario inactivo.",
        'User cannot be confirmed. Current status is CONFIRMED': "El correo electrónico ya ha sido confirmado.",
        'Password reset required for the user': "El usuario ha solicitado actualizar su contraseña.",
        'Username/client id combination not found.': "El usuario no existe.",
        'Invalid verification code provided, please try again.': "Código de verificación inválido. Intente de nuevo.",

        'An account with the given email already exists.': 'Ya existe una cuenta con el correo electrónico ingresado.',
        'Cannot reset password for the user as there is no registered/verified email or phone_number': 'Debe confirmar su correo electróncio para reestablecer la contraseña.',
        'Invalid code provided, please request a code again.':'Código de verificación expirado. Se ha enviado uno nuevo.',
        "Attempt limit exceeded, please try after some time.": "Se ha excedido el limite de intentos. Intenta de nuevo más tarde.", 
        "1 validation error detected: Value at 'previousPassword' failed to satisfy constraint: Member must have length greater than or equal to 6": "La contraseña es incorrecta."
    }
}