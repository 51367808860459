<div class="d-flex justify-content-center align-items-center min-vh-100">
  
    <div class="shadow bg-white" style="width: 420px; padding: 2.0rem;">
  

      <div class="d-block text-center" routerLink="/" style="cursor: pointer;" > 
        <img class="m-4" src="assets/logos/mainLogo.png" alt="ProdCafe" style="max-height: 55px;">
        <h3 class="font-weight-light">ProCafe</h3>
        <h5 class="font-weight-light text-muted">Plataforma de Productores</h5>
      </div>
      <hr>
  
      <div class="">
        <router-outlet></router-outlet>
      </div>
  
    </div>
    
  </div>